// @flow

import { Map, List } from 'immutable';
import draftService from '../src/net-auction/draft-service';
import draftMediaService from '../src/media/draft-media-service';

const FETCH_DRAFT = 'huutokaupat/draft-preview/FETCH_DRAFT';
const FETCH_DRAFT_DONE = 'huutokaupat/draft-preview/FETCH_DRAFT_DONE';
const FETCH_DRAFT_FAILED = 'huutokaupat/draft-preview/FETCH_DRAFT_FAILED';

const FETCH_COMPANY = 'huutokaupat/draft-preview/FETCH_COMPANY';
const FETCH_COMPANY_DONE = 'huutokaupat/draft-preview/FETCH_COMPANY_DONE';
const FETCH_COMPANY_FAILED = 'huutokaupat/draft-preview/FETCH_COMPANY_FAILED';

const FETCH_IMAGES = 'huutokaupat/draft-preview/FETCH_IMAGES';
const FETCH_IMAGES_DONE = 'huutokaupat/draft-preview/FETCH_IMAGES_DONE';
const FETCH_IMAGES_FAILED = 'huutokaupat/draft-preview/FETCH_IMAGES_FAILED';

const FETCH_ATTACHMENTS = 'huutokaupat/draft-preview/FETCH_ATTACHMENTS';
const FETCH_ATTACHMENTS_DONE = 'huutokaupat/draft-preview/FETCH_ATTACHMENTS_DONE';
const FETCH_ATTACHMENTS_FAILED = 'huutokaupat/draft-preview/FETCH_ATTACHMENTS_FAILED';

const initialState: Map<string, any> = Map({
  draft: Map(),
  company: Map(),
  images: List(),
  attachments: List(),
  loadingDraft: true,
  loadingCompany: true,
  loadingImages: true,
  loadingAttachments: true,
  failed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_COMPANY:
      return state.set('loadingCompany', true);
    case FETCH_IMAGES:
      return state.set('loadingImages', true);
    case FETCH_ATTACHMENTS:
      return state.set('loadingAttachments', true);
    case FETCH_DRAFT:
      return state.set('loadingDrafts', true);
    case FETCH_DRAFT_DONE:
      return state.set('loadingDraft', false).set('draft', Map(action.payload));
    case FETCH_COMPANY_DONE:
      return state.set('loadingCompany', false).set('company', Map(action.payload));

    case FETCH_IMAGES_DONE:
      return state.set('loadingImages', false).set('images', List(action.payload));

    case FETCH_ATTACHMENTS_DONE:
      return state.set('loadingAttachments', false).set('attachments', List(action.payload));

    case FETCH_DRAFT_FAILED:
    case FETCH_COMPANY_FAILED:
    case FETCH_IMAGES_FAILED:
    case FETCH_ATTACHMENTS_FAILED:
      return state.set('failed', true);

    default:
      return state;
  }
};

export function fetchCompany(draftId: number, uuid: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_COMPANY });

    return draftService.fetchPreviewCompanyData(draftId, uuid).then(
      response => dispatch({ type: FETCH_COMPANY_DONE, payload: response }),
      error => dispatch({ type: FETCH_COMPANY_FAILED, payload: { error } })
    );
  };
}

export function fetchDraft(draftId: number, uuid: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_DRAFT });

    return draftService.fetchDraft(draftId, uuid).then(
      response => dispatch({ type: FETCH_DRAFT_DONE, payload: { ...response.draft } }),
      error => dispatch({ type: FETCH_DRAFT_FAILED, payload: { error } })
    );
  };
}

export function fetchImages(id: number, uuid: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_IMAGES });

    return draftMediaService.fetchImages(id, uuid).then(
      response => dispatch({ type: FETCH_IMAGES_DONE, payload: response.data }),
      error => dispatch({ type: FETCH_IMAGES_FAILED, payload: { error } })
    );
  };
}

export function fetchAttachments(id: number, uuid: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_ATTACHMENTS });

    return draftMediaService.fetchAttachments(id, uuid).then(
      response => dispatch({ type: FETCH_ATTACHMENTS_DONE, payload: response.data }),
      error => dispatch({ type: FETCH_ATTACHMENTS_FAILED, payload: { error } })
    );
  };
}
