// @ts-ignore: TODO TypeScript netAuctionService
import netAuctionService from '@app/src/net-auction/net-auction-service';
import createClient from '@app/src/api/client';

export function shouldMarkEntryAsPaidOnApprove(
  isOwnPayment: boolean,
  netAuctionCategory: number,
  netAuctionClass: string
) {
  if (!isOwnPayment) {
    return false;
  }

  return (
    netAuctionService.isRealEstate(netAuctionCategory) || netAuctionService.isEntryClassPublicSector(netAuctionClass)
  );
}

export interface NetAuctionApprovalEntry {
  readonly publicId: number;
  readonly auctionEnd: string;
  readonly auctionStart: string;
  readonly title: string;
  readonly vatPercent: string;
  readonly isMarginalVat: boolean;
  readonly isApprovable: boolean;
  readonly isPayable: boolean;
  readonly isPostTradingAvailable: boolean;
  readonly isWaitingForPostTradingDecision: boolean;
  readonly city: {
    readonly nameFi: string;
  };
  readonly winningBid?: {
    readonly bidAmount: string;
    readonly isRejected: 'Y' | 'N';
  };
  readonly winner?: {
    readonly id?: number;
    readonly isStatusEnabled?: boolean;
    readonly fullName: string;
    readonly email: string;
    readonly phoneNumber: string;
    readonly address: string;
    readonly zipcode: string;
    readonly postOffice: string;
    readonly country: string;
    readonly buyerCompany?: {
      readonly businessId: string;
      readonly name: string;
    };
  };
  readonly company: {
    readonly id: number;
  };
}

export interface NetAuctionApprovalInfo {
  readonly netAuction: NetAuctionApprovalEntry;
  readonly approvalRatio: number;
}

export interface RejectBidResponse {
  readonly isContinuable: boolean;
  readonly isRiskFree: boolean;
}

export const fetchNetAuctionApprovalInfo = async (entryId: number) =>
  createClient()
    .get<NetAuctionApprovalInfo>(`/net-auctions/${entryId}/approval-info`)
    .then(response => response.data);

export const approveBid = async (entryId: number) =>
  createClient()
    .post<{ readonly success: true }>(`/net-auctions/${entryId}/approve`)
    .then(response => response.data);

export const rejectBid = async (entryId: number, shouldSendBidderMail = false) =>
  createClient()
    .post<RejectBidResponse>(`/net-auctions/${entryId}/reject`, {
      shouldSendBidderMail,
    })
    .then(response => response.data);

export const startPostTrading = async (entryId: number) =>
  createClient()
    .patch<{ readonly success: true }>(`/net-auctions/${entryId}/post-trading/activate`)
    .then(response => response.data);

export const finishPostTrading = async (entryId: number, sum: number) =>
  createClient()
    .patch<{ readonly success: true }>(`/net-auctions/${entryId}/post-trading/approve`, {
      approvedSum: sum,
    })
    .then(response => response.data);
