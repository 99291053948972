import { Map } from 'immutable';
import featureFeedbackService from '../src/feature-feedback/feature-feedback-service';

const OPEN_FEEDBACK_MODAL = 'huutokaupat/feedback/OPEN_FEEDBACK_MODAL';
const CLOSE_FEEDBACK_MODAL = 'huutokaupat/feedback/CLOSE_FEEDBACK_MODAL';

const SUBMIT_FEEDBACK = 'huutokaupat/feedback/SUBMIT_FEEDBACK';
const SUBMIT_FEEDBACK_DONE = 'huutokaupat/feedback/SUBMIT_FEEDBACK_DONE';
const SUBMIT_FEEDBACK_FAILED = 'huutokaupat/feedback/SUBMIT_FEEDBACK_FAILED';

const initialState = new Map({
  open: false,
  loading: false,
  failed: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_FEEDBACK_MODAL:
      return state.set('open', true);

    case CLOSE_FEEDBACK_MODAL:
      return state.set('open', false);

    case SUBMIT_FEEDBACK:
      return state.set('open', false).set('loading', true).set('failed', false);

    case SUBMIT_FEEDBACK_DONE:
      return state.set('loading', false);

    case SUBMIT_FEEDBACK_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function openFeedbackModal() {
  return { type: OPEN_FEEDBACK_MODAL };
}

export function closeFeedbackModal() {
  return { type: CLOSE_FEEDBACK_MODAL };
}

export function submitFeedback(feature, answers, email, extra = {}) {
  return dispatch => {
    dispatch({ type: SUBMIT_FEEDBACK });

    featureFeedbackService.submit(feature, answers, email, extra).then(
      response => dispatch({ type: SUBMIT_FEEDBACK_DONE, payload: response.success }),
      error => dispatch({ type: SUBMIT_FEEDBACK_FAILED, payload: error })
    );
  };
}
