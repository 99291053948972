export default {
  doesTitleContainNeedsPermit: title => {
    const wordList = [
      'alkoholi',
      'ammuks',
      'ammus',
      'ampuma',
      'ase',
      'aseita',
      'echa',
      'haulikko',
      'haulikkoja',
      'haulikoita',
      'haupitsi',
      'ilmalämpö',
      'ilotulite',
      'ilotulitte',
      'jalkajousi',
      'kalja',
      'kanuuna',
      'kemikaale',
      'kemikaali',
      'kivääre',
      'kivääri',
      'konjakki',
      'kranaatinheitin',
      'kylmäaine',
      'käsijousi',
      'likööri',
      'lipas',
      'lämpöpumppu',
      'lääke',
      'lääkke',
      'myyntilupa',
      'myyntiluvan',
      'nikotiini',
      'nuuska',
      'olut',
      'ostolupa',
      'ostoluvan',
      'patruuna',
      'patruuno',
      'pistoole',
      'pistooli',
      'pomppuperä',
      'r134a',
      'raketi',
      'rakett',
      'raketti',
      'riistaliha',
      'rohdoks',
      'rohdos',
      'ruho',
      'räjähde',
      'räjäytysaine',
      'siideri',
      'taljajousi',
      'tupakka',
      'tykki',
      'varsijousi',
      'viina',
      'viini',
      'viski',
      'vodka',
    ];

    return wordList.findIndex(subString => title.toLowerCase().includes(subString)) !== -1;
  },
};
