// @flow

import { List, Map } from 'immutable';

import companyMonitoringService from '../src/company/company-monitoring-service';

const FETCH_NOTIFICATIONS = 'huutokaupat/company-monitoring/FETCH_APPROVABLE_COMPANIES';
const FETCH_NOTIFICATIONS_DONE = 'huutokaupat/company-monitoring/FETCH_NOTIFICATIONS_DONE';
const FETCH_NOTIFICATIONS_FAILED = 'huutokaupat/company-monitoring/FETCH_NOTIFICATIONS_FAILED';

const UPDATE_NOTIFICATION_STATUS = 'huutokaupat/company-monitoring/UPDATE_NOTIFICATION_STATUS';
const UPDATE_NOTIFICATION_STATUS_DONE = 'huutokaupat/company-monitoring/UPDATE_NOTIFICATION_STATUS_DONE';
const UPDATE_NOTIFICATION_STATUS_FAILED = 'huutokaupat/company-monitoring/UPDATE_NOTIFICATION_STATUS_FAILED';

const initialState = Map({
  notifications: null,
  failed: false,
  loading: false,
});

function updateNotification(notifications: List<Object>, id: number, props: Object): List<Object> {
  const index = notifications.findIndex(item => item.id === id);

  if (index === -1) {
    return notifications;
  }

  return notifications.updateIn([index], notification => ({
    ...notification,
    ...props,
  }));
}

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return state.set('loading', true).set('failed', false).set('notifications', List());

    case FETCH_NOTIFICATIONS_DONE:
      return state.set('loading', false).set('failed', false).set('notifications', List(action.payload));

    case FETCH_NOTIFICATIONS_FAILED:
      return state.set('loading', false).set('failed', true);

    case UPDATE_NOTIFICATION_STATUS:
      return state.set('statusUpdateFailed', false).set(
        'notifications',
        updateNotification(state.get('notifications', List()), action.payload.id, {
          isUpdating: true,
          updatingStatusFailed: false,
        })
      );

    case UPDATE_NOTIFICATION_STATUS_DONE:
      return state.set(
        'notifications',
        updateNotification(state.get('notifications', List()), action.payload.id, {
          status: action.payload.status,
          isUpdating: false,
        })
      );

    case UPDATE_NOTIFICATION_STATUS_FAILED:
      return state.set(
        'notifications',
        updateNotification(state.get('notifications', List()), action.payload.id, {
          isUpdating: false,
          updatingStatusFailed: true,
        })
      );

    default:
      return state;
  }
};

export function fetchNotifications(params: Object = {}): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_NOTIFICATIONS });

    return companyMonitoringService
      .fetchNotifications(params)
      .then(companies => dispatch({ type: FETCH_NOTIFICATIONS_DONE, payload: companies }))
      .catch(error => dispatch({ type: FETCH_NOTIFICATIONS_FAILED, payload: error }));
  };
}

export function updateNotificationStatus(id: number, status: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPDATE_NOTIFICATION_STATUS, payload: { id, status } });

    return companyMonitoringService.updateNotificationStatus(id, status).then(
      () =>
        dispatch({
          type: UPDATE_NOTIFICATION_STATUS_DONE,
          payload: { id, status },
        }),
      error =>
        dispatch({
          type: UPDATE_NOTIFICATION_STATUS_FAILED,
          payload: { id, error },
        })
    );
  };
}
