// @flow

import { List, Map } from 'immutable';
import categoryService from '../../../src/category/category-service';

const LOAD_CATEGORIES = 'huutokaupat/category/LOAD_CATEGORIES';
const LOAD_CATEGORIES_DONE = 'huutokaupat/category/LOAD_CATEGORIES_DONE';
const LOAD_CATEGORIES_FAILED = 'huutokaupat/category/LOAD_CATEGORIES_FAILED';

const LOAD_CATEGORIES_WITH_COUNTS = 'huutokaupat/category/LOAD_CATEGORIES_WITH_COUNTS';
const LOAD_CATEGORIES_WITH_COUNTS_DONE = 'huutokaupat/category/LOAD_CATEGORIES_WITH_COUNTS_DONE';
const LOAD_CATEGORIES_WITH_COUNTS_FAILED = 'huutokaupat/category/LOAD_CATEGORIES_WITH_COUNTS_FAILED';

const initialState = Map({
  loading: true,
  failed: false,
  categories: List(),
  categoriesWithCounts: List(),
  endingCountToday: null,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case LOAD_CATEGORIES:
      return state.set('loading', true).set('failed', false);

    case LOAD_CATEGORIES_DONE:
      return state.set('loading', false).set('failed', false).set('categories', List(action.payload));

    case LOAD_CATEGORIES_FAILED:
      return state.set('loading', false).set('failed', true);

    case LOAD_CATEGORIES_WITH_COUNTS:
      return state.set('loading', true).set('failed', false);

    case LOAD_CATEGORIES_WITH_COUNTS_DONE:
      return state
        .set('loading', false)
        .set('failed', false)
        .set('categoriesWithCounts', List(action.payload.categoriesWithCounts));

    case LOAD_CATEGORIES_WITH_COUNTS_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function fetchCategories(): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: LOAD_CATEGORIES, payload: {} });

    return categoryService.fetch().then(
      response => dispatch({ type: LOAD_CATEGORIES_DONE, payload: response }),
      error => dispatch({ type: LOAD_CATEGORIES_FAILED, payload: { error } })
    );
  };
}
