// @flow

import { Map } from 'immutable';
import crawlerService from '../src/crawl/crawl-service';

const LOAD_NETTIX = 'huutokaupat/crawler/LOAD_NETTIX';
const LOAD_NETTIX_DONE = 'huutokaupat/crawler/LOAD_NETTIX_DONE';
const LOAD_NETTIX_FAILED = 'huutokaupat/crawler/LOAD_NETTIX_FAILED';

const LOAD_MASCUS = 'huutokaupat/crawler/LOAD_MASCUS';
const LOAD_MASCUS_DONE = 'huutokaupat/crawler/LOAD_MASCUS_DONE';
const LOAD_MASCUS_FAILED = 'huutokaupat/crawler/LOAD_MASCUS_FAILED';

const CRAWL = 'huutokaupat/crawler/CRAWL';
const CRAWL_LICENSE_NUMBER = 'huutokaupat/crawler/CRAWL_LICENSE_NUMBER';
const CRAWL_DONE = 'huutokaupat/crawler/CRAWL_DONE';
const CRAWL_FAILED = 'huutokaupat/crawler/CRAWL_FAILED';

const RESET_CRAWL = 'huutokaupat/crawler/RESET_CRAWL';

const SHOW_CRAWL_MODAL = 'huutokaupat/crawler/SHOW_CRAWL_MODAL';
const CLOSE_CRAWL_MODAL = 'huutokaupat/crawler/CLOSE_CRAWL_MODAL';

const initialState = Map({
  loading: false,
  error: false,
  crawledData: Map(),
  selectedCrawler: null,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case LOAD_NETTIX:
    case LOAD_MASCUS:
    case CRAWL:
      return state.set('loading', true).set('error', false);

    case CRAWL_LICENSE_NUMBER:
      return state.set('loading', true).set('error', false).set('selectedCrawler', action.payload);

    case LOAD_NETTIX_FAILED:
    case LOAD_MASCUS_FAILED:
    case CRAWL_FAILED:
      return state.set('loading', false).set('error', true);

    case LOAD_NETTIX_DONE:
    case LOAD_MASCUS_DONE:
      return state.set('loading', false).set('error', false).set('crawledData', Map(action.payload));

    case CRAWL_DONE:
      return state.set('loading', false).set('error', false).set('crawledData', Map(action.payload));

    case RESET_CRAWL:
      return state.set('crawledData', Map()).set('loading', false).set('error', false);

    case SHOW_CRAWL_MODAL:
      return state.set('selectedCrawler', action.payload);

    case CLOSE_CRAWL_MODAL:
      return state.set('selectedCrawler', null);

    default:
      return state;
  }
};

export function showCrawlModal(source: string): Function {
  return (dispatch: Function): void => {
    dispatch({ type: SHOW_CRAWL_MODAL, payload: source });
  };
}

export function closeCrawlModal(): Function {
  return (dispatch: Function): void => {
    dispatch({ type: CLOSE_CRAWL_MODAL });
  };
}

export function resetCrawl(): Function {
  return (dispatch: Function): void => {
    dispatch({ type: RESET_CRAWL });
  };
}

export function copyFromNettix(
  id: string,
  crawler: string,
  dataHandler: Function,
  draftId: ?number = null,
  companyId: ?number = null
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: LOAD_NETTIX, payload: {} });

    return crawlerService
      .crawlData(id, crawler, null, null, draftId, companyId)
      .then(response => dispatch({ type: LOAD_NETTIX_DONE, payload: response }))
      .then(action =>
        setTimeout((): void => {
          dataHandler(Map(action.payload), crawler);
        }, 0)
      )
      .catch(response => dispatch({ type: LOAD_NETTIX_FAILED, payload: { error: response } }));
  };
}

export function copyFromMascus(
  id: string,
  dataHandler: Function,
  draftId: ?number = null,
  companyId: ?number = null
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: LOAD_MASCUS, payload: {} });

    return crawlerService
      .crawlData(id, 'maskus', null, null, draftId, companyId)
      .then(response => dispatch({ type: LOAD_MASCUS_DONE, payload: response }))
      .then(action =>
        setTimeout((): void => {
          dataHandler(Map(action.payload), 'mascus');
        }, 0)
      )
      .catch(response => dispatch({ type: LOAD_MASCUS_FAILED, payload: { error: response } }));
  };
}

export function doCrawlData(
  id: string,
  source: string,
  categoryId: number,
  parentCategoryId: number,
  draftId: ?number = null,
  companyId: ?number = null
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CRAWL });

    return crawlerService
      .crawlData(id, source, categoryId, parentCategoryId, draftId, companyId)
      .then(response => dispatch({ type: CRAWL_DONE, payload: response }))
      .catch(response => dispatch({ type: CRAWL_FAILED, payload: { error: response } }));
  };
}

export function doCrawlDataWithLicenseNumber(
  licenseNumber: string,
  source: string,
  draftId: ?number = null,
  companyId: ?number = null,
  vehicleType: ?string = null
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CRAWL_LICENSE_NUMBER, payload: source });

    return crawlerService
      .crawlDataWithLicenseNumber(licenseNumber, source, draftId, companyId, vehicleType)
      .then(response => dispatch({ type: CRAWL_DONE, payload: response.data }))
      .catch(response => dispatch({ type: CRAWL_FAILED, payload: { error: response } }));
  };
}
