// @flow

import { Map, Set } from 'immutable';
import hermesClient from '../src/hermes/hermes-client';

const INITIALIZE = 'huutokaupat/hermes/INITIALIZE';
const SET_SKEW = 'huutokaupat/hermes/SET_SKEW';
const UPDATE_ENTRY = 'huutokaupat/hermes/UPDATE_ENTRY';
const SUBSCRIBE = 'huutokaupat/hermes/SUBSCRIBE';
const UNSUBSCRIBE = 'huutokaupat/hermes/UNSUBSCRIBE';
const FETCH_UPDATE = 'huutokaupat/hermes/FETCH_UPDATE';

const initialState = Map({
  entries: Map(),
  skew: null,
  fetchingUpdatesFor: Set(),
});

let hermesInitialized = false;

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case SET_SKEW:
      return state.set('skew', parseInt(action.payload, 10));

    case UPDATE_ENTRY:
      return state
        .update('entries', entries => entries.set(action.payload.id, action.payload))
        .set('fetchingUpdatesFor', state.get('fetchingUpdatesFor'))
        .filter(id => id !== action.payload.id);

    case FETCH_UPDATE:
      return state.update('fetchingUpdatesFor', list => list.add(action.payload));

    default:
      return state;
  }
};

export function initializeHermes(timestamp: number): Function {
  return (dispatch: Function): void => {
    if (hermesInitialized) {
      return;
    }

    const updateListener = (data: Object): void => {
      dispatch({ type: UPDATE_ENTRY, payload: data });
    };

    const skewListener = (skew: number): void => {
      dispatch({ type: SET_SKEW, payload: skew });
    };

    hermesClient.initialize();
    hermesClient.addListener(updateListener);
    hermesClient.requestSkew(timestamp, skewListener);
    hermesInitialized = true;

    dispatch({ type: INITIALIZE });
  };
}

export function subscribe(ids: Array<number>, namespace: string): Function {
  return (dispatch: Function): void => {
    hermesClient.subscribe(ids, namespace);

    dispatch({ type: SUBSCRIBE });
  };
}

export function unsubscribe(ids: Array<number>, namespace: string): Function {
  return (dispatch: Function): void => {
    hermesClient.unsubscribe(ids, namespace);

    dispatch({ type: UNSUBSCRIBE });
  };
}

export function unsubscribeAllByNamespace(namespace: string): Function {
  return (dispatch: Function): void => {
    hermesClient.unsubscribeAllByNamespace(namespace);

    dispatch({ type: UNSUBSCRIBE });
  };
}

export function fetchUpdate(id: number): Function {
  return (dispatch: Function): void => {
    hermesClient.fetchUpdate(id);

    dispatch({ type: FETCH_UPDATE, payload: id });
  };
}
