import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

// @ts-ignore: JS.
import auth from './auth';
// @ts-ignore: JS.
import hermes from './hermes';
// @ts-ignore: JS.
import netAuctionEntry from './net-auction-entry';
// @ts-ignore: JS.
import netAuctionForm from './net-auction-form';
// @ts-ignore: JS.
import draftPreview from './draft-preview';
// @ts-ignore: JS.
import netAuctionToolbar from './net-auction-toolbar';
// @ts-ignore: JS.
import netAuctionOngoing from './net-auction-ongoing';
// @ts-ignore: JS.
import feedback from './feature-feedback';
// @ts-ignore: JS.
import entryList from './entry-list';
import ownPage from './own-page';
// @ts-ignore: JS.
import netAuctionFeedback from './net-auction-feedback';
// @ts-ignore: JS.
import publicNetAuctionFeedback from './public-net-auction-feedback';
// @ts-ignore: JS.
import netAuctionReceipt from './net-auction-receipt';
// @ts-ignore: JS.
import netAuctionRunnerUpOffer from './net-auction-runner-up-offer';
// @ts-ignore: JS.
import tracking from './tracking';
import marketPrice from './market-price';
// @ts-ignore: JS.
import visualMedia from './visual-media';
// @ts-ignore: JS.
import attachment from './attachment';
// @ts-ignore: JS.
import crawler from './crawler';
// @ts-ignore: JS.
import city from './form/city';
// @ts-ignore: JS.
import region from './region';
// @ts-ignore: JS.
import netAuction from './net-auction';
// @ts-ignore: JS.
import company from './company';
// @ts-ignore: JS.
import companyMonitoring from './company-monitoring';
// @ts-ignore: JS.
import companyGroupList from './company-group-list';
// @ts-ignore: JS.
import companyGroup from './company-group';
// @ts-ignore: JS.
import companyGroupOAuthClient from './company-group-oauth-client';
// @ts-ignore: JS.
import companyCreate from './company-create';
// @ts-ignore: JS.
import companyArchive from './company-archive';
// @ts-ignore: JS.
import importedEntry from './imported-entry';
// @ts-ignore: JS.
import favorite from './favorite';
// @ts-ignore: JS.
import netAuctionClass from './net-auction-class';
// @ts-ignore: JS.
import publicCompanyPage from './public-company-page';
// @ts-ignore: JS.
import contactInfo from './contact-info';
// @ts-ignore: JS.
import contactMessage from './contact-message';
// @ts-ignore: JS.
import soleProprietorPersonalId from './sole-proprietor-personal-id';
import companySummary from './company-summary';
// @ts-ignore: JS.
import vehicleRegistration from './vehicle-registration';
// @ts-ignore: JS.
import salesContact from './sales-contact';
// @ts-ignore: JS.
import bankStatementList from './bank-statement-list';
// @ts-ignore: JS.
import user from './user';
// @ts-ignore: JS.
import newProductDetection from './net-auction-new-product-detection';
// @ts-ignore: JS.
import userDetails from './user-details';
// @ts-ignore: JS.
import log from './log';

// @ts-ignore: JS.
import category from './form/net-auction/category';
// @ts-ignore: JS.
import companySelect from './form/company-select';

// @ts-ignore: JS.
import statistics from './statistics';
// @ts-ignore: JS.
import entryModeration from './entry-moderation';
// @ts-ignore: JS.
import feedbackReview from './feedback-review';
// @ts-ignore: JS.
import userFeedback from './user-feedback';
// @ts-ignore: JS.
import insiderTradingModeration from './insider-trading-moderation';

import frozenNetAuction from './frozen-net-auction';
// @ts-ignore: JS.
import netAuctionVersion from './net-auction-version';
// @ts-ignore: JS.
import messageForm from './admin/message-form';
// @ts-ignore: JS.
import userTwoFactor from './user-two-factor';
// @ts-ignore: JS.
import disableEntryResaleNotifications from './do-disable-entry-resale-notifications';

const createRootReducer = () =>
  combineReducers({
    form: formReducer,
    toastr: toastrReducer,
    auth,
    hermes,
    netAuctionEntry,
    netAuctionForm,
    draftPreview,
    netAuctionToolbar,
    netAuctionOngoing,
    feedback,
    entryList,
    ownPage,
    netAuctionFeedback,
    publicNetAuctionFeedback,
    netAuctionReceipt,
    netAuctionRunnerUpOffer,
    tracking,
    marketPrice,
    visualMedia,
    attachment,
    crawler,
    city,
    netAuction,
    company,
    companyMonitoring,
    companyGroupList,
    companyGroup,
    companyCreate,
    companyArchive,
    category,
    region,
    companySelect,
    importedEntry,
    favorite,
    netAuctionClass,
    publicCompanyPage,
    contactInfo,
    contactMessage,
    soleProprietorPersonalId,
    vehicleRegistration,
    statistics,
    user,
    entryModeration,
    companySummary,
    companyGroupOAuthClient,
    salesContact,
    bankStatementList,
    feedbackReview,
    userFeedback,
    insiderTradingModeration,
    newProductDetection,
    userDetails,
    log,
    messageForm,
    userTwoFactor,
    frozenNetAuction,
    netAuctionVersion,
    disableEntryResaleNotifications,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
