// @flow

import { List, Map } from 'immutable';

import feedbackService from '../src/net-auction/feedback-service';

const FETCH_FEEDBACKS_FOR_REVIEW = 'huutokaupat/feedback-review/FETCH_FEEDBACKS_FOR_REVIEW';
const FETCH_FEEDBACKS_FOR_REVIEW_DONE = 'huutokaupat/feedback-review/FETCH_FEEDBACKS_FOR_REVIEW_DONE';
const FETCH_FEEDBACKS_FOR_REVIEW_FAILED = 'huutokaupat/feedback-review/FETCH_FEEDBACKS_FOR_REVIEW_FAILED';

const UPDATE_FEEDBACK_REVIEW_STATUS = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_REVIEW_STATUS';
const UPDATE_FEEDBACK_REVIEW_STATUS_DONE = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_REVIEW_STATUS_DONE';
const UPDATE_FEEDBACK_REVIEW_STATUS_FAILED = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_REVIEW_STATUS_FAILED';

const OPEN_MODERATE_FEEDBACK_MODAL = 'huutokaupat/feedback-review/OPEN_MODERATE_FEEDBACK_MODAL';
const CLOSE_MODERATE_FEEDBACK_MODAL = 'huutokaupat/feedback-review/CLOSE_MODERATE_FEEDBACK_MODAL';

const MODERATE_FEEDBACK = 'huutokaupat/feedback-review/MODERATE_FEEDBACK';
const MODERATE_FEEDBACK_DONE = 'huutokaupat/feedback-review/MODERATE_FEEDBACK_DONE';
const MODERATE_FEEDBACK_FAILED = 'huutokaupat/feedback-review/MODERATE_FEEDBACK_FAILED';

const UPDATE_FEEDBACK_VISIBILITY = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_VISIBILITY';
const UPDATE_FEEDBACK_VISIBILITY_DONE = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_VISIBILITY_DONE';
const UPDATE_FEEDBACK_VISIBILITY_FAILED = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_VISIBILITY_FAILED';

const OPEN_FEEDBACK_NOTES = 'huutokaupat/feedback-review/OPEN_FEEDBACK_NOTES';
const CLOSE_FEEDBACK_NOTES = 'huutokaupat/feedback-review/CLOSE_FEEDBACK_NOTES';

const UPDATE_FEEDBACK_NOTES = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_NOTES';
const UPDATE_FEEDBACK_NOTES_DONE = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_NOTES_DONE';
const UPDATE_FEEDBACK_NOTES_FAILED = 'huutokaupat/feedback-review/UPDATE_FEEDBACK_NOTES_FAILED';

const initialState = Map({
  loading: false,
  loadFailed: false,
  feedbacksForReview: List(),
  saveFailed: false,
  moderateFeedbackRow: null,
  updateFeedbackRowNotes: null,
});

function updateFeedback(feedbacks: List<Object>, feedbackId: number, props: Object): List<Object> {
  const index = feedbacks.findIndex(item => item.id === feedbackId);

  if (index === -1) {
    return feedbacks;
  }

  return feedbacks.updateIn([index], feedback => ({ ...feedback, ...props }));
}

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_FEEDBACKS_FOR_REVIEW:
      return state.set('loading', true).set('feedbacksForReview', List());

    case FETCH_FEEDBACKS_FOR_REVIEW_DONE:
      return state.set('loading', false).set('loadFailed', false).set('feedbacksForReview', List(action.payload));

    case FETCH_FEEDBACKS_FOR_REVIEW_FAILED:
      return state.set('loading', false).set('loadFailed', true).set('feedbacksForReview', List());

    case UPDATE_FEEDBACK_REVIEW_STATUS:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: true,
          isUpdatingFeedbackFailed: false,
        })
      );

    case UPDATE_FEEDBACK_REVIEW_STATUS_DONE:
      return state
        .set('loading', false)
        .set('saveFailed', false)
        .set(
          'feedbacksForReview',
          updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedback.id, {
            reviewStatus: action.payload.feedback.reviewStatus,
            isUpdatingFeedback: false,
          })
        );

    case UPDATE_FEEDBACK_REVIEW_STATUS_FAILED:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: false,
          isUpdatingFeedbackFailed: true,
        })
      );

    case OPEN_MODERATE_FEEDBACK_MODAL:
      return state.set('moderateFeedbackRow', action.payload.feedback);

    case CLOSE_MODERATE_FEEDBACK_MODAL:
      return state.set('moderateFeedbackRow', null);

    case MODERATE_FEEDBACK:
      return state.set('moderateFeedbackRow', null).set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: true,
          isUpdatingFeedbackFailed: false,
        })
      );

    case MODERATE_FEEDBACK_DONE:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedback.id, {
          message: action.payload.feedback.message,
          productRating: action.payload.feedback.productRating,
          deliveryRating: action.payload.feedback.deliveryRating,
          contactMe: action.payload.feedback.contactMe,
          reply: action.payload.feedback.reply,
          isUpdatingFeedback: false,
        })
      );

    case MODERATE_FEEDBACK_FAILED:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: false,
          isUpdatingFeedbackFailed: true,
        })
      );

    case UPDATE_FEEDBACK_VISIBILITY:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: true,
          isUpdatingFeedbackFailed: false,
        })
      );

    case UPDATE_FEEDBACK_VISIBILITY_DONE:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedback.id, {
          isHidden: action.payload.feedback.isHidden,
          isUpdatingFeedback: false,
        })
      );

    case UPDATE_FEEDBACK_VISIBILITY_FAILED:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: false,
          isUpdatingFeedbackFailed: true,
        })
      );

    case OPEN_FEEDBACK_NOTES:
      return state.set('updateFeedbackRowNotes', action.payload.feedback);

    case CLOSE_FEEDBACK_NOTES:
      return state.set('updateFeedbackRowNotes', null);

    case UPDATE_FEEDBACK_NOTES:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: true,
          isUpdatingFeedbackFailed: false,
        })
      );

    case UPDATE_FEEDBACK_NOTES_DONE:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedback.id, {
          notes: action.payload.feedback.notes,
          isUpdatingFeedback: false,
        })
      );

    case UPDATE_FEEDBACK_NOTES_FAILED:
      return state.set(
        'feedbacksForReview',
        updateFeedback(state.get('feedbacksForReview', List()), action.payload.feedbackId, {
          isUpdatingFeedback: false,
          isUpdatingFeedbackFailed: true,
        })
      );

    default:
      return state;
  }
};

export function fetchFeedbacksForReview(params: Object = {}): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_FEEDBACKS_FOR_REVIEW });

    return feedbackService.fetchFeedbacksForReview(params).then(
      feedbacksForReview =>
        dispatch({
          type: FETCH_FEEDBACKS_FOR_REVIEW_DONE,
          payload: feedbacksForReview,
        }),
      error => dispatch({ type: FETCH_FEEDBACKS_FOR_REVIEW_FAILED, payload: error })
    );
  };
}

export function updateFeedbackReviewStatus(feedbackId: number, status: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPDATE_FEEDBACK_REVIEW_STATUS, payload: { feedbackId } });

    return feedbackService.updateFeedbackReviewStatus(feedbackId, status).then(
      response =>
        dispatch({
          type: UPDATE_FEEDBACK_REVIEW_STATUS_DONE,
          payload: response,
        }),
      () =>
        dispatch({
          type: UPDATE_FEEDBACK_REVIEW_STATUS_FAILED,
          payload: { feedbackId },
        })
    );
  };
}

export function updateFeedbackVisibility(feedbackId: number, isHidden: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPDATE_FEEDBACK_VISIBILITY, payload: { feedbackId } });

    return feedbackService.updateFeedbackVisibility(feedbackId, isHidden).then(
      response => dispatch({ type: UPDATE_FEEDBACK_VISIBILITY_DONE, payload: response }),
      () =>
        dispatch({
          type: UPDATE_FEEDBACK_VISIBILITY_FAILED,
          payload: { feedbackId },
        })
    );
  };
}

export function updateFeedbackNotes(feedbackId: number, notes: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPDATE_FEEDBACK_NOTES, payload: { feedbackId } });

    return feedbackService.updateFeedbackNotes(feedbackId, notes).then(
      response => dispatch({ type: UPDATE_FEEDBACK_NOTES_DONE, payload: response }),
      () =>
        dispatch({
          type: UPDATE_FEEDBACK_NOTES_FAILED,
          payload: { feedbackId },
        })
    );
  };
}

export function moderateFeedback(
  feedbackId: number,
  productRating: number,
  deliveryRating: number,
  contactMe: boolean,
  message: string,
  reply: string
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MODERATE_FEEDBACK, payload: { feedbackId } });

    return feedbackService.moderateFeedback(feedbackId, productRating, deliveryRating, contactMe, message, reply).then(
      response => dispatch({ type: MODERATE_FEEDBACK_DONE, payload: response }),
      () => dispatch({ type: MODERATE_FEEDBACK_FAILED, payload: { feedbackId } })
    );
  };
}

export function openModerateFeedbackModal(feedback: Object): Object {
  return { type: OPEN_MODERATE_FEEDBACK_MODAL, payload: { feedback } };
}

export function closeModerateFeedbackModal(): Object {
  return { type: CLOSE_MODERATE_FEEDBACK_MODAL };
}

export function openFeedbackNotes(feedback: Object): Object {
  return { type: OPEN_FEEDBACK_NOTES, payload: { feedback } };
}

export function closeFeedbackNotes(): Object {
  return { type: CLOSE_FEEDBACK_NOTES };
}
