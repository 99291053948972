export function getFuelType(type: number) {
  switch (type) {
    case 1:
      return 'Bensiini';

    case 2:
      return 'Diesel';

    case 3:
      return 'Hybridi';

    case 4:
      return 'Kaasu';

    case 5:
      return 'Sähkö';

    case 6:
      return 'E85/bensiini';

    case 99:
    default:
      return 'Muu';
  }
}

export function getDriveType(type: number) {
  switch (type) {
    case 1:
      return 'Etuveto';

    case 2:
      return 'Takaveto';

    default:
      return 'Neliveto';
  }
}

export function getTransmissionType(type: number) {
  return type === 1 ? 'Manuaali' : 'Automaatti';
}
