import { type LinkResolverFunction } from '@prismicio/helpers';

/**
 * The project's Prismic Link Resolver. This function determines the URL for a given Prismic document.
 *
 * @type {LinkResolverFunction}
 */
// Update the Link Resolver to match your project's route structure
export const linkResolver: LinkResolverFunction = doc => {
  switch (doc.type) {
    case 'front_page':
    case 'settings':
      return '/';
    case 'page':
      return `/${doc.slug}`;
    case 'dynamic-page':
      return `/${doc.uid}`;
    default:
      return '';
  }
};
