import createClient from '../api/client';

export default {
  getAttachments: id =>
    createClient()
      .get(`/templates/${id}/media/attachments`)
      .then(response => response.data),

  removeAllAttachments: id =>
    createClient()
      .delete(`/templates/${id}/media/attachments`)
      .then(response => response.data),

  orderAttachments: (id, attachmentIdsInOrder) =>
    createClient()
      .patch(`/templates/${id}/media/attachments/order`, { attachmentIdsInOrder })
      .then(response => response.data),

  uploadAttachment: (referenceId, file) =>
    createClient()
      .post(`/templates/${referenceId}/media/attachments`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  removeAttachment: id =>
    createClient()
      .delete(`/templates/media/attachments/${id}`)
      .then(response => response.data),
};

const parseErrorMessage = ({ response }, defaultErrorMessage) => {
  if (response && response.data) {
    if ('invalidContent' in response.data) {
      return 'Virheellinen lähetyksen sisältö';
    }

    if ('attachmentProcessingError' in response.data) {
      return 'Virhe luotaessa liitetiedostoa';
    }

    if ('attachmentDeletingError' in response.data) {
      return 'Virhe poistaessa liitetiedostoa';
    }

    if ('attachmentsDeletingError' in response.data) {
      return 'Virhe poistaessa liitetiedostoja';
    }
  }
  return defaultErrorMessage || 'Tuntematon virhe';
};

export { parseErrorMessage };
