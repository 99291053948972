import { Dispatch } from 'redux';
import { fetchFrozenNetAuctions, FrozenNetAuction } from '@app/src/net-auction/net-auction-frozen-service';

export const FetchFrozenNetAuctions = 'huutokaupat/frozen-net-auction/FETCH_FROZEN_NET_AUCTIONS';

export const FetchFrozenNetAuctionsDone = 'huutokaupat/frozen-net-auction/FETCH_FROZEN_NET_AUCTIONS_DONE';

export const FetchFrozenNetAuctionsFailed = 'huutokaupat/frozen-net-auction/FETCH_FROZEN_NET_AUCTIONS_FAILED';

export interface FetchFrozenNetAuctionsAction {
  readonly type: typeof FetchFrozenNetAuctions;
}

export interface FetchFrozenNetAuctionsDoneAction {
  readonly type: typeof FetchFrozenNetAuctionsDone;
  readonly payload: FrozenNetAuction[];
}

export interface FetchFrozenNetAuctionsFailedAction {
  readonly type: typeof FetchFrozenNetAuctionsFailed;
}

export function fetchData(companyId?: number) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FetchFrozenNetAuctions });

    return fetchFrozenNetAuctions(companyId).then(
      response =>
        dispatch({
          type: FetchFrozenNetAuctionsDone,
          payload: response,
        }),
      () =>
        dispatch({
          type: FetchFrozenNetAuctionsFailed,
        })
    );
  };
}
