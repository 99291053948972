// converted from Flow --> JS

import { sum } from 'lodash-es';
import createClient from '../api/client';

export const REASON_TRANSLATIONS_SHORT = {
  'address-similarity': 'Sama osoite',
  'commission-owner': 'Toimeksiantajan sukunimi',
  'commission-owner-address-similarity': 'Toimeksiantajan osoite',
  'commission-owner-phone-similarity': 'Toimeksiantajan puhelinnumero.',
  'ip-address-similarity': 'Sama IP-osoite',
  'multiple-bids-for-seller': 'Huutanut muihin kohteisiin', // Dike does not produce new reasons with this key anymore.
  'multiple-bids-for-seller-with-rejected-bid-or-approved-own-payment-bid':
    'Huutanut muihin kohteisiin, joista vähintään yksi merkitty käyttäjien väliseksi tai peruttu',
  'name-similarity': 'Sama sukunimi',
  'person-in-charge-similarity': 'Vastuuhenkilön sukunimi',
  'phone-similarity': 'Sama puhelinnumero',
  'raise-own-bid': 'Korottanut omaa tarjousta',
};

export const REASON_TRANSLATIONS_LONG = {
  'address-similarity': 'Huutajan osoitetiedot ovat samat kuin ilmoittajayrityksen käyttäjän.',
  'commission-owner': 'Huutajan sukunimi on sama kuin kohteen toimeksiantajan.',
  'commission-owner-address-similarity': 'Huutajan osoitetiedot ovat samat kuin kohteen toimeksiantajan.',
  'commission-owner-phone-similarity': 'Huutajan puhelinnumero on sama kuin kohteen toimeksiantajan.',
  'ip-address-similarity': 'Huutajan IP-osoite on sama kuin ilmoituksen tekijän IP-osoite.',
  'multiple-bids-for-seller': 'Huutaja on huutanut myös muita ilmoittajayrityksen kohteita (10+ kohdetta).', // Dike does not produce new reasons with this key anymore.
  'multiple-bids-for-seller-with-rejected-bid-or-approved-own-payment-bid':
    'Huutanut myös muita ilmoittajayrityksen kohteita (10+), joista vähintään yksi kohde on merkitty ilmoittajan toimesta joko käyttäjien väliseksi kaupaksi TAI kohde on peruttu.',
  'name-similarity': 'Huutajan sukunimi on sama kuin ilmoittajayrityksen käyttäjän.',
  'person-in-charge-similarity': 'Huutajan sukunimi on sama kuin ilmoittajayrityksen vastuuhenkilön.',
  'phone-similarity': 'Huutajan puhelinnumero on sama kuin ilmoittajayrityksen käyttäjän.',
  'raise-own-bid': 'Huutaja on tehnyt tarjouksen suoraan oman aiemman tarjouksensa päälle.',
};

export const BID_ANALYSIS_STATUSES = {
  NEW: 1,
  IN_PROCESS: 2,
  PROCESSED: 3,
  FALSE_POSITIVE: 4,
};

export const BID_ANALYSIS_OPTIONS = [
  { value: BID_ANALYSIS_STATUSES.NEW, name: 'Uusi' },
  { value: BID_ANALYSIS_STATUSES.IN_PROCESS, name: 'Käsittelyssä' },
  { value: BID_ANALYSIS_STATUSES.PROCESSED, name: 'Aiheellinen' },
  { value: BID_ANALYSIS_STATUSES.FALSE_POSITIVE, name: 'Aiheeton' },
];

export default {
  fetchAll: params =>
    createClient()
      .get('/dike/analyses', { params })
      .then(response => response.data),
  // ids: number[], status: number
  updateMultiple: (ids, status) =>
    createClient()
      .patch('/dike/analyses', { ids, status })
      .then(response => response.data),

  // analyses: Analyse[]
  calculateScore: analyses =>
    analyses.reduce(
      (result, analysis) => {
        const { score, alreadyAdded } = result;

        const scoreToAdd = sum(
          Object.keys(analysis.reasons).map(scorer => {
            if (alreadyAdded[scorer]) {
              return 0;
            }

            alreadyAdded[scorer] = true;

            return analysis.reasons[scorer];
          })
        );

        return { score: score + scoreToAdd, alreadyAdded };
      },
      { score: 0, alreadyAdded: {} }
    ).score,

  getReasonSummary: analyses =>
    [
      ...new Set(
        analyses.flatMap(analysis => Object.keys(analysis.reasons).filter(scorer => analysis.reasons[scorer] > 0))
      ),
    ]
      .map(scorer => REASON_TRANSLATIONS_SHORT[scorer])
      .join(', '),
};
