import React from 'react';
import { PrismicLink } from '@prismicio/react';
import type { RichTextMapSerializer } from '@prismicio/richtext';

const richTextComponents: RichTextMapSerializer<JSX.Element> = {
  hyperlink: ({ children, node }) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- Prismic internal
    const { link_type, url } = node.data;

    if (link_type === 'Web') {
      return <a href={url}>{children}</a>;
    }
    return <PrismicLink field={node.data}>{children}</PrismicLink>;
  },
};

export default richTextComponents;
