import { useTokens } from '@mezzoforte/forge';
import { CheckCircle, Icon, Warning, WarningCircle } from '@mezzoforte/forge-icons';

const { colors } = useTokens.huutokaupat;

export enum CompanyStatuses {
  Active = 1,
  NoUsers = 2,
  UserNotEnabled = 3,
  UserNotIdentified = 4,
  WaitingForApproval = 5,
  Terminated = 9,
}

export type CompanyStatusType = 'success' | 'warning' | 'danger';

export const companyStatusIcons: Record<CompanyStatusType, Icon> = {
  success: CheckCircle,
  warning: Warning,
  danger: WarningCircle,
};

export const statusStrings = new Map<number, string>([
  [CompanyStatuses.Active, 'Aktiivinen'],
  [CompanyStatuses.NoUsers, 'Ei Käyttäjiä'],
  [CompanyStatuses.UserNotEnabled, 'Käyttäjä estetty'],
  [CompanyStatuses.UserNotIdentified, 'Odottaa tunnistautumista'],
  [CompanyStatuses.WaitingForApproval, 'Odottaa hyväksyntää'],
  [CompanyStatuses.Terminated, 'Yhteistyö päätetty'],
]);

export const statusColors = new Map<number[], string>([
  [[CompanyStatuses.UserNotEnabled, CompanyStatuses.NoUsers, CompanyStatuses.Terminated], colors.danger],
  [[CompanyStatuses.Active], colors.success],
  [[CompanyStatuses.UserNotIdentified, CompanyStatuses.WaitingForApproval], colors.text],
]);

export const statusIconType = new Map<number[], CompanyStatusType>([
  [[CompanyStatuses.UserNotEnabled, CompanyStatuses.NoUsers, CompanyStatuses.Terminated], 'danger'],
  [[CompanyStatuses.Active], 'success'],
  [[CompanyStatuses.UserNotIdentified, CompanyStatuses.WaitingForApproval], 'warning'],
]);

export const getStatusIconType = (status: string | number) => {
  const statusKey = typeof status === 'string' ? parseInt(status, 10) : status;
  const iconTypeKeys = [...statusIconType.keys()].find(it => it.includes(statusKey));
  return (iconTypeKeys && statusIconType.get(iconTypeKeys)) ?? 'default';
};

// types indicates that status = string, when mostly number - ensure to support both just incase
export const getStatusStringAndColor = (status: string | number) => {
  const statusKey = typeof status === 'string' ? parseInt(status, 10) : status;
  const colorKeys = [...statusColors.keys()].find(ck => ck.includes(statusKey));
  const color = (colorKeys && statusColors.get(colorKeys)) ?? colors.text;
  return {
    text: statusStrings.get(statusKey) ?? '',
    color,
  };
};
