// @flow

import createClient from '../api/client';

const SECRETARY_STATUS = {
  WAITING: 1,
  INFO_MISSING: 3,
  OTHER_INVESTIGATION: 4,
  TRANSFER_NOTIFICATION_DELIVERED: 5,
  CERTIFICATE_DELIVERED: 6,
  REGISTERED_BY_PUBLISHER: 7,
  WILL_NOT_REGISTER: 8,
  PRESUMABLY_REGISTERED_BY_PUBLISHER: 9,
  VEHICLE_RECLAIMED: 10,
  CONSUMER_NET_AUCTION: 11,
};

const ACTIVE_SECRETARY_STATUSES = [
  SECRETARY_STATUS.WAITING,
  SECRETARY_STATUS.INFO_MISSING,
  SECRETARY_STATUS.OTHER_INVESTIGATION,
  SECRETARY_STATUS.VEHICLE_RECLAIMED,
  SECRETARY_STATUS.CONSUMER_NET_AUCTION,
];

export { SECRETARY_STATUS };

export const RegisterFor = Object.freeze({
  USER: '1',
  COMPANY: '2',
});

export type RegisterForType = $Values<typeof RegisterFor>;

type RegistrationOption = {
  label: string,
  value: RegisterForType,
};

export type RegistrationInfoFormValues = {
  registeringType: RegisterForType,
  company: {
    name: string,
    businessId: string,
  },
  user: {
    ssn: string,
    firstName: string,
    lastName: string,
  },
};

export const getRegistrationOptions = (): RegistrationOption[] => [
  {
    label: 'Haluan rekisteröidä yksityishenkilölle',
    value: RegisterFor.USER,
  },
  {
    label: 'Haluan rekisteröidä yritykselle',
    value: RegisterFor.COMPANY,
  },
];

export type VehicleRegistration = {
  isInfoFilled: boolean,
  title?: string,
};

export default {
  translateSecretaryStatus(status: number): string {
    const translations = {
      [SECRETARY_STATUS.WAITING]: 'Odottaa luovutusilmoitusta ilmoittajalta',
      [SECRETARY_STATUS.INFO_MISSING]: 'Luovutusilmoitus saatu',
      [SECRETARY_STATUS.OTHER_INVESTIGATION]: 'Muu selvitys käynnissä',
      [SECRETARY_STATUS.TRANSFER_NOTIFICATION_DELIVERED]: 'Luovutusilmoitettu huutajalle',
      [SECRETARY_STATUS.CERTIFICATE_DELIVERED]: 'Varmenne toimitettu huutajalle',
      [SECRETARY_STATUS.REGISTERED_BY_PUBLISHER]: 'Rekisteröity ohi',
      [SECRETARY_STATUS.WILL_NOT_REGISTER]: 'Ei rekisteröidä',
      [SECRETARY_STATUS.PRESUMABLY_REGISTERED_BY_PUBLISHER]: 'Oletetusti rekisteröity ohi',
      [SECRETARY_STATUS.VEHICLE_RECLAIMED]: 'Reklamoitu/peruttu',
      [SECRETARY_STATUS.CONSUMER_NET_AUCTION]: 'KUHU',
    };

    return translations[status];
  },

  isActiveSecretaryStatus: (status: number): boolean => ACTIVE_SECRETARY_STATUSES.includes(status),

  fetchVehicleRegistrations: (params: Object) =>
    createClient()
      .get('/vehicle-registrations', { params })
      .then(response => response.data),

  fetchVehicleRegistrationAttachments: (id: number) =>
    createClient()
      .get(`/vehicle-registrations/attachments/${id}`)
      .then(response => response.data),

  uploadAttachment: (id: number, attachment: Object) =>
    createClient()
      .post(`/vehicle-registrations/attachments/${id}`, attachment, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  deleteAttachment: (vehicleRegistrationId: number, name: string) =>
    createClient()
      .delete(`/vehicle-registrations/attachments/${vehicleRegistrationId}?filename=${name}`)
      .then(response => response.data),

  updateVehicleRegistration: (id: number, data: Object) =>
    createClient()
      .patch(`/vehicle-registrations/${id}`, data)
      .then(response => response.data),

  fetchRegistrationInfo: (entryId: number): Promise<VehicleRegistration> =>
    createClient()
      .get(`/vehicle-registrations/info/${entryId}`)
      .then(response => response.data),

  submitRegistrationInfo: (entryId: number, values: RegistrationInfoFormValues) =>
    createClient()
      .post(`vehicle-registrations/info/${entryId}`, values)
      .then(response => response.data),
};
