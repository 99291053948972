/* eslint-disable @typescript-eslint/consistent-type-definitions -- keep existing */
import createClient from '../api/client';

type Company = {
  readonly id: number;
  readonly name: string;
};

export type Entry = {
  readonly id: number;
  readonly title: string;
  readonly auctionStart: string;
  readonly auctionEnd: string;
};

export type User = {
  readonly id: number;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
};

export type FetchGroupsParams = {
  readonly company?: number | null;
  readonly status?: string | null;
};

export type UpdateGroupParams = {
  readonly title?: string;
  readonly entries?: number[];
  readonly entriesToRemove?: number[];
  readonly users?: number[];
  readonly usersToRemove?: number[];
};

export type PrivateNetAuctionGroup = {
  readonly id: number;
  readonly title: string;
  readonly company?: Company;
  readonly entries?: Entry[];
  readonly users?: User[];
};

type AddGroupReturnType = {
  readonly id?: number;
  readonly invalidUserEmails?: string[];
  readonly validUserEmails?: string[];
};

const fetchGroups = async (params: FetchGroupsParams) =>
  createClient()
    .get<PrivateNetAuctionGroup[]>(`/net-auctions/groups/`, { params })
    .then(response => response.data);

const fetchGroup = async (id: number) =>
  createClient()
    .get<PrivateNetAuctionGroup>(`/net-auctions/groups/${id}`)
    .then(response => response.data);

const fetchGroupsByCompany = async (companyId: number) => fetchGroups({ company: companyId });

const createNewGroup = async (data: Partial<PrivateNetAuctionGroup>) =>
  createClient()
    .post<AddGroupReturnType>('/net-auctions/groups', { data })
    .then(response => response.data);

// Initially assume that updated Group is returned
const updateGroup = async (id: number, data: UpdateGroupParams) =>
  createClient()
    .patch<PrivateNetAuctionGroup>(`/net-auctions/groups/${id}`, { data })
    .then(response => response.data);

const deleteGroup = async (id: number) => createClient().delete(`/net-auctions/groups/${id}`);

const fetchEligibleNetAuctions = async (companyId: number) =>
  createClient()
    .get<Entry[]>(`/net-auctions/eligible-for-groups/${companyId}`)
    .then(response => response.data);

type GroupTimeFrame = {
  readonly startDate?: Date | null;
  readonly endDate?: Date | null;
};

const resolveStartDate = (existing: GroupTimeFrame['startDate'], dateFromEntry: Entry['auctionStart']) => {
  const entryDate = new Date(dateFromEntry);
  return !existing || existing === null || existing > entryDate ? entryDate : existing;
};
const resolveEndDate = (existing: GroupTimeFrame['endDate'], dateFromEntry: Entry['auctionEnd']) => {
  const entryDate = new Date(dateFromEntry);
  return !existing || existing === null || existing < entryDate ? entryDate : existing;
};

const initialValue: GroupTimeFrame = { startDate: null, endDate: null };

const getGroupTimeFrame = (entries: Entry[]) =>
  entries.reduce(
    (result, entry: Entry) => ({
      startDate: resolveStartDate(result.startDate, entry.auctionStart),
      endDate: resolveEndDate(result.endDate, entry.auctionEnd),
    }),
    initialValue
  );

type FormValidatorValue = string | number | symbol;

export default {
  fetchGroups,
  fetchGroupsByCompany,
  fetchGroup,
  createNewGroup,
  updateGroup,
  deleteGroup,
  fetchEligibleNetAuctions,
  getGroupTimeFrame,
  validators: {
    required: (value: FormValidatorValue) => (value ? undefined : 'Pakollinen kenttä'),
  },
};
