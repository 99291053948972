import React from 'react';
import ReactDOM from 'react-dom/client';
import { MezzoforteProvider, Toggletip } from '@mezzoforte/forge';

document.querySelectorAll('.huuto-tooltip').forEach(tooltip => {
  ReactDOM.createRoot(tooltip).render(
    <MezzoforteProvider
      brand="huutokaupat"
      notificationProvider={false}
      toastContainer={false}
      resetCss={false}
      disableGlobalStyle
    >
      <Toggletip>{tooltip.getAttribute('data-content')}</Toggletip>
    </MezzoforteProvider>
  );
});
