export default {
  ensureInternalUrl(url: string | null, domain?: string) {
    const validUrl = this.ensureValidUrl(url);

    const link = document.createElement('a');
    link.setAttribute('href', validUrl ?? '');
    // assume that if not domain specified, read from the window
    const host = domain ?? window.location.host;

    if (link.host && link.host !== host) {
      return null;
    }

    return validUrl;
  },

  ensureValidUrl(url: string | null) {
    if (!url) {
      return null;
    }

    const validatedUrl = url
      .replace(/[^\x20-\x7E]/, '')
      .replace('\\', '/')
      .trim();

    if (validatedUrl.includes(':') && !/^https?:\/\//i.test(validatedUrl)) {
      return null;
    }

    return validatedUrl;
  },
};
