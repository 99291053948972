import dayjs from 'dayjs';
import accounting from 'accounting';
import Cookies from 'js-cookie';
import { setDefaultOptions } from 'date-fns';
import { fi } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import './dayjs';

setDefaultOptions({ locale: fi });
registerLocale('fi', fi);
setDefaultLocale('fi');

const isTouchDevice = 'ontouchstart' in window;

// eslint-disable-next-line functional/immutable-data -- Set accounting.js settings.
accounting.settings.currency = {
  symbol: '€',
  format: '%v %s',
  decimal: ',',
  thousand: ' ',
  precision: 2,
};

// eslint-disable-next-line functional/immutable-data -- Set js-cookie settings.
Cookies.defaults = {
  secure: true,
  expires: 365,
};

// eslint-disable-next-line functional/immutable-data -- Legacy config global.
window.Huutokaupat = {
  ...window.Huutokaupat,

  urls: {
    login: '/login',
    logout: '/logout',
    checkBankAccount: '/company-bank-account/check-bank-account',
    addBankAccount: '/company-bank-account/add-bank-account',
  },

  isTouchDevice,

  mezzoforteAsSellerStartDate: dayjs('2018-08-21 11:04:00'),
  feedbackPublishingStartDate: dayjs('2019-06-26'),

  freshchat: {
    token: 'c08c3e95-6dc2-4251-bdc2-ec5a60ca0a9c',
    host: 'https://wchat.eu.freshchat.com',
    config: {
      headerProperty: {
        hideChatButton: true,
      },
      content: {
        placeholders: {
          search_field: 'Hae UKK:sta',
          reply_field: 'Kirjoita viestisi tähän...',
          csat_reply: 'Kirjoita kommenttisi tähän',
        },
        actions: {
          csat_yes: 'Kyllä',
          csat_no: 'Ei',
          push_notify_yes: 'Kyllä',
          push_notify_no: 'Ei',
          tab_faq: 'UKK',
          tab_chat: 'Chatti',
          csat_submit: 'Lähetä',
        },
        headers: {
          chat: 'Kirjoita viesti',
          chat_help: 'Hei, olemme täällä apunasi!',
          faq: 'UKK',
          faq_help: 'Selaa UKK:ta',
          faq_not_available: 'Ei artikkeleita',
          faq_search_not_available: 'Artikkeleita ei löytynyt haulla {{query}}',
          faq_useful: 'Oliko tästä artikkelista hyötyä?',
          faq_thankyou: 'Kiitos palautteestasi!',
          faq_message_us: 'Kirjoita meille',
          push_notification: 'Älä missaa viestejä! Salli ilmoitukset?',
          csat_question: 'Oliko meistä apua?',
          csat_yes_question: 'Miten arvioisit kokemustasi kanssamme?',
          csat_no_question: 'Miten voisimme mielestäsi kehittyä?',
          csat_thankyou: 'Kiitos palautteestasi!',
          csat_rate_here: 'Arviointisi',
          channel_response: {
            offline: 'Olemme pois chatista juuri nyt mutta jätä meille viesti.',
            online: {
              minutes: {
                one: 'Vastaamme muutamassa minuutissa.',
                more: 'Vastaamme alle {{time}} minuutissa.',
              },
              hours: {
                one: 'Vastaamme noin tunnin kuluessa',
                more: 'Vastaamme noin {{time}} tunnin kuluessa.',
              },
            },
          },
        },
      },
    },
  },
};

export default window.Huutokaupat;
