// @flow

import createClient from '../api/client';

export default {
  fetchNewProductsSoldAsUsed: (vat: ?number = null, ownPayment: ?string = null) =>
    createClient()
      .get('/net-auctions/possible-new-products', {
        params: { vat, 'own-payment': ownPayment },
      })
      .then(response => response.data),
};
