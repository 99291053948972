/* eslint-disable @typescript-eslint/naming-convention -- keep existing */

import metadataCategories from '../metadata/metadata-categories';

const CLASS_NET_AUCTION = 'Nettihuutokauppa';
const CLASS_METSAHALLITUS = 'Metsähallitus';
const CLASS_FINANCIERS = 'Rahoitusyhtiön netti';
const CLASS_BANKRUPTCIES = 'Konkurssipesän netti';
const CLASS_DEFENCE_FORCES = 'Puolustusvoimien net';
const CLASS_FORECLOSURE = 'Ulosotto';
const CLASS_POLICE = 'Poliisi';
const CLASS_PUBLIC_SECTOR = 'Julkinen sektori';
const CLASS_DEATH_ESTATE = 'Kuolinpesä';
const CLASS_BROKER_SERVICE = 'Meklari';

const labels: Record<string, string> = {
  [CLASS_NET_AUCTION]: 'Huutokauppa',
  [CLASS_PUBLIC_SECTOR]: 'Julkisen sektorin huutokauppa',
  [CLASS_BANKRUPTCIES]: 'Konkurssipesän huutokauppa',
  [CLASS_METSAHALLITUS]: 'Metsähallituksen huutokauppa',
  [CLASS_POLICE]: 'Poliisin huutokauppa',
  [CLASS_DEFENCE_FORCES]: 'Puolustusvoimien huutokauppa',
  [CLASS_FINANCIERS]: 'Rahoitusyhtiön huutokauppa',
  [CLASS_FORECLOSURE]: 'Ulosmitatun omaisuuden huutokauppa',
  [CLASS_DEATH_ESTATE]: 'Kuolinpesän huutokauppa',
  [CLASS_BROKER_SERVICE]: 'Meklaripalvelun huutokauppa',
};

const urls: Record<string, string> = {
  [CLASS_FORECLOSURE]: 'ulosotto',
  [CLASS_BANKRUPTCIES]: 'konkurssipesat',
  [CLASS_DEFENCE_FORCES]: 'puolustusvoimat',
  [CLASS_POLICE]: 'poliisi',
  [CLASS_METSAHALLITUS]: 'metsahallitus',
  [CLASS_FINANCIERS]: 'rahoitusyhtiot',
  [CLASS_PUBLIC_SECTOR]: 'julkinen-sektori',
  [CLASS_DEATH_ESTATE]: 'kuolinpesa',
  [CLASS_BROKER_SERVICE]: 'meklaripalvelu',
};

export default {
  CLASS_NET_AUCTION,

  CLASS_METSAHALLITUS,

  CLASS_FINANCIERS,

  CLASS_BANKRUPTCIES,

  CLASS_DEFENCE_FORCES,

  CLASS_FORECLOSURE,

  CLASS_POLICE,

  CLASS_PUBLIC_SECTOR,

  CLASS_DEATH_ESTATE,

  CLASS_BROKER_SERVICE,

  getAllNetAuctionClasses: (): string[] => [
    CLASS_NET_AUCTION,
    CLASS_METSAHALLITUS,
    CLASS_FINANCIERS,
    CLASS_BANKRUPTCIES,
    CLASS_DEFENCE_FORCES,
    CLASS_FORECLOSURE,
    CLASS_POLICE,
    CLASS_PUBLIC_SECTOR,
    CLASS_DEATH_ESTATE,
    CLASS_BROKER_SERVICE,
  ],

  getLabel: (className: string) => labels[className] || null,

  getPossibleMetadatas: (netAuctionClass: string): string[] => {
    const map: Record<string, string[]> = {
      [CLASS_FINANCIERS]: [metadataCategories.METADATA_REAL_ESTATE],
      [CLASS_BANKRUPTCIES]: [metadataCategories.METADATA_REAL_ESTATE],
      [CLASS_FORECLOSURE]: [metadataCategories.METADATA_REAL_ESTATE],
      [CLASS_NET_AUCTION]: [
        metadataCategories.METADATA_APARTMENT,
        metadataCategories.METADATA_REAL_ESTATE,
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
      ],
      [CLASS_METSAHALLITUS]: [
        metadataCategories.METADATA_APARTMENT,
        metadataCategories.METADATA_REAL_ESTATE,
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
      ],
      [CLASS_POLICE]: [
        metadataCategories.METADATA_APARTMENT,
        metadataCategories.METADATA_REAL_ESTATE,
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
      ],
      [CLASS_DEFENCE_FORCES]: [
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
        metadataCategories.METADATA_REAL_ESTATE,
      ],
      [CLASS_PUBLIC_SECTOR]: [
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
        metadataCategories.METADATA_REAL_ESTATE,
      ],
      [CLASS_DEATH_ESTATE]: [
        metadataCategories.METADATA_APARTMENT,
        metadataCategories.METADATA_REAL_ESTATE,
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
      ],
      [CLASS_BROKER_SERVICE]: [
        metadataCategories.METADATA_APARTMENT,
        metadataCategories.METADATA_REAL_ESTATE,
        metadataCategories.METADATA_BOAT,
        metadataCategories.METADATA_CONSTRUCTION_MACHINERY,
        metadataCategories.METADATA_FARMING_MACHINERY,
        metadataCategories.METADATA_FOREST_MACHINERY,
        metadataCategories.METADATA_OTHER_MACHINERY,
        metadataCategories.METADATA_PASSENGER_CAR,
        metadataCategories.METADATA_REGISTRABLE_VEHICLE,
        metadataCategories.METADATA_TRUCK,
        metadataCategories.METADATA_TRAILER,
      ],
    };

    return map[netAuctionClass] || [];
  },

  labels,

  urls,
};
