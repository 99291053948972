// @flow

import { Map, List, fromJS } from 'immutable';
import companyPageService from '../src/company/company-page-service';
import entryListService from '../src/entry-list/entry-list-service';

const FETCH_COMPANY = 'huutokaupat/company-page/FETCH_COMPANY';
const FETCH_COMPANY_DONE = 'huutokaupat/company-page/FETCH_COMPANY_DONE';
const FETCH_COMPANY_FAILED = 'huutokaupat/company-page/FETCH_COMPANY_FAILED';
const FETCH_ENTRIES = 'huutokaupat/company-page/FETCH_ENTRIES';
const FETCH_ENTRIES_DONE = 'huutokaupat/company-page/FETCH_ENTRIES_DONE';

const initialState = Map({
  loading: false,
  failed: false,
  company: Map(),
  entries: List(),
  entryPages: Map(),
  entriesLoading: false,
  sorting: 'paattyvat',
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_COMPANY:
      return state.set('loading', true).set('failed', false).set('company', Map());

    case FETCH_COMPANY_DONE:
      return state.set('loading', false).set('failed', false).set('company', fromJS(action.payload));

    case FETCH_COMPANY_FAILED:
      return state.set('loading', false).set('failed', true).set('company', Map());

    case FETCH_ENTRIES:
      return state
        .set('entriesLoading', true)
        .set('entriesFailed', false)
        .set('entries', List())
        .set('entryPages', Map());

    case FETCH_ENTRIES_DONE:
      return state
        .set('entriesLoading', false)
        .set('entriesFailed', true)
        .set('entries', List(action.payload.entries))
        .set('entryPages', Map(action.payload.pages))
        .set('page', parseInt(action.payload.page, 10));

    default:
      return state;
  }
};

export function fetchCompany(slug: string): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_COMPANY });

    return companyPageService.fetchCompanyPageData(slug).then(
      data => dispatch({ type: FETCH_COMPANY_DONE, payload: data }),
      error => dispatch({ type: FETCH_COMPANY_FAILED, payload: error })
    );
  };
}

export function fetchEntries(queryParams: ?string, companyId: number): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_ENTRIES });

    const commonQuery = `company=${companyId}&showPrivateAuctions=1`;

    const query = queryParams ? `${queryParams}&${commonQuery}` : `?${commonQuery}`;

    return entryListService.fetch(query).then(data => dispatch({ type: FETCH_ENTRIES_DONE, payload: data }));
  };
}
