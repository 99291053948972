// converted from Flow -> js

import { range, random } from 'lodash-es';
import dayjs from 'dayjs';

import netAuctionFormFields from './net-auction-form-fields';
import * as categories from './categories';
import metadataService from '../metadata/metadata-service';
import metadataCategories from '../metadata/metadata-categories';
import netAuctionService from './net-auction-service';
import netAuctionClassService from './class-service';
import { isSpecialVehicle } from './special-vehicle-service';
import { getApartmentType, getRoomCountType } from '../metadata/apartment';
import needsPermitService from './needs-permit-service';
import { getTransmissionType } from '../metadata/passenger-car';
import { COMPANY_ID_MEZZOFORTE_CONSUMER_NET_AUCTIONS } from '../company/company-service';
import { getVatOptions } from '../util/vat';

const MAX_IMAGE_UPLOADING_LIMIT = 10;

const CONTINUOUS_AUCTION_MIN_DURATION_DAYS = 3;
const CONTINUOUS_AUCTION_MIN_STARTING_PRICE = 1500;
const METHOD_OF_SALES_SOLD_TO_HIGHEST_BIDDER = 1;
const METHOD_OF_SALES_SELLER_DECIDES = 2;
const METHOD_OF_SALES_RESERVE_PRICE = 3;

const COMMISSION_OWNER_TYPE_PERSON = 1;
const COMMISSION_OWNER_TYPE_COMPANY = 2;

const MAXIMUM_COPIES_AMOUNT = 6;

const MAX_CUSTOM_REFERENCE_NUMBER_LENGTH = 40;

const MAX_AUCTION_DURATION_FOR_NEW_ENTRIES = 14;

const MAX_TITLE_LENGTH = 160;
const MAX_SUBTITLE_LENGTH = 160;

const MAX_ADDRESS_LENGTH = 120;

const MAX_RETRIEVAL_EXPENSES_VALUE = 200;

function generateVehicleTitle(metadata) {
  const titleParts = [
    metadata.manufacturer ? [metadata.manufacturer, metadata.model].join(' ').trim() : null,
    metadata.yearModel ? metadata.yearModel.toString() : null,
  ].filter(part => typeof part === 'string' && part.trim() !== '');

  return titleParts.join(', ') || null;
}

function generateVehicleSubtitle(metadata) {
  return (
    [
      metadata.engineDisplacement ? `${metadata.engineDisplacement} l` : null,
      metadata.fuelType ? metadataService.getFuelTypeLabel(metadata.fuelType) : null,
      metadata.power && metadata.powerType ? `${metadata.power} ${metadata.powerType}` : null,
      metadata.transmission ? getTransmissionType(parseInt(metadata.transmission, 10)) : null,
      metadata.odometer !== null && metadata.odometer !== undefined ? `${metadata.odometer} km` : null,
    ]
      .filter(part => typeof part === 'string')
      .join(', ') || null
  );
}

function generateApartmentTitle(metadata) {
  const roomCount = metadata.roomCount ? getRoomCountType(parseInt(metadata.roomCount, 10)) : '';
  const livingArea = metadata.livingArea ? `${metadata.livingArea} m²` : '';

  const titleParts = [
    metadata.apartmentType ? getApartmentType(metadata.apartmentType) : null,
    `${roomCount} ${livingArea}`.trimLeft().trim(),
    metadata.constructionYear ? metadata.constructionYear.toString() : null,
  ].filter(part => typeof part === 'string' && part.trim() !== '');

  return titleParts.join(', ') || null;
}

function shouldShowDeliveryFields(deliverySelectableMethod) {
  return [1, 100].includes(deliverySelectableMethod);
}

function shouldShowDeliveryExpensesUnavailableField(deliverySelectableMethod) {
  return deliverySelectableMethod === 1;
}

function shouldShowRetrievalFields(deliverySelectableMethod) {
  return [1, 200].includes(deliverySelectableMethod);
}

const shouldIgnoreCommissionOwnerFields = (commissionOwner, isPublished) =>
  Object.keys(commissionOwner).length === 0 && isPublished;

function isContinuousAuctionAvailable(methodOfSales, startingPrice) {
  return (
    methodOfSales === METHOD_OF_SALES_SOLD_TO_HIGHEST_BIDDER && startingPrice >= CONTINUOUS_AUCTION_MIN_STARTING_PRICE
  );
}

function isNetAuctionClassForeclosure(formValues) {
  return formValues.netAuctionClass.type === netAuctionClassService.CLASS_FORECLOSURE;
}

function hasDefects(formValues) {
  const { CLASS_FORECLOSURE, CLASS_BANKRUPTCIES, CLASS_FINANCIERS } = netAuctionClassService;

  const noDefectsMetadataNetAuctionClasses = [CLASS_FORECLOSURE, CLASS_BANKRUPTCIES, CLASS_FINANCIERS];

  if (formValues.isNew || noDefectsMetadataNetAuctionClasses.includes(formValues.netAuctionClass.type)) {
    return false;
  }

  return !netAuctionService.isRealEstate(formValues.category.id);
}

// These should match NetAuctionDraft.php/shouldHaveSubtitle
const shouldShowSubtitleField = categoryId =>
  [
    categories.CATEGORY_PASSENGER_CAR,
    categories.CATEGORY_VAN,
    categories.CATEGORY_TRUCK,
    categories.CATEGORY_HEAVY_EQUIPMENT,
  ].includes(categoryId);

function getDeliveryFields(formValues) {
  if (isNetAuctionClassForeclosure(formValues) || netAuctionService.isRealEstate(formValues.category.id)) {
    return [];
  }

  const deliveryFields = shouldShowDeliveryFields(formValues.deliverySelectableMethod)
    ? [
        'deliveryDescription',
        shouldShowDeliveryExpensesUnavailableField(formValues.deliverySelectableMethod)
          ? 'deliveryExpensesUnavailable'
          : null,
        !formValues.deliveryExpensesUnavailable ? 'deliveryExpenses' : null,
      ]
    : [];

  const retrievalFields = shouldShowRetrievalFields(formValues.deliverySelectableMethod)
    ? ['retrievalExpenses', 'retrievalDescription']
    : [];

  return ['deliverySelectableMethod', ...deliveryFields, ...retrievalFields].filter(value => value !== null);
}

function getFieldsDependentOnCopying(formvalues) {
  return formvalues.copiesAmount > 0 ? ['copiesAmount'] : [];
}

function getFieldsDependentOnMethodOfSales(formValues) {
  return [formValues.methodOfSales === METHOD_OF_SALES_RESERVE_PRICE ? 'reservePrice' : null].filter(
    value => value !== null
  );
}

function getDefectFields(formValues) {
  if (!hasDefects(formValues)) {
    return [];
  }

  return [!formValues.noDefects ? 'defects' : null, 'noDefects'].filter(value => value !== null);
}

function getCommissionFields(formValues) {
  if (!formValues.isCommission) {
    return ['isCommission'];
  }

  return [
    'isCommission',
    formValues.isCommission ? 'isResponsibleForValidity' : null,
    formValues.isCommission ? 'isOwnershipVerified' : null,
    formValues.isCommission ? 'isMoneyNotTransferredBeforeVerifiedDelivery' : null,
  ].filter(value => value !== null);
}

function getCategoryDependentFields(formValues) {
  const categoryId = formValues.category.id;

  return [
    !netAuctionService.isApartment(categoryId) ? netAuctionFormFields.VAT_PERCENT : null,
    shouldShowSubtitleField(categoryId) ? netAuctionFormFields.SUBTITLE : null,
  ].filter(value => value !== null);
}

function getContinuousAuctionFields(formValues) {
  return isContinuousAuctionAvailable(formValues.methodOfSales, formValues.startPrice) ? ['continuousAuction'] : [];
}

function getCommissionOwnerFields(formValues) {
  if (
    !formValues.isCommission ||
    shouldIgnoreCommissionOwnerFields(formValues.commissionOwner, formValues.isPublished)
  ) {
    return [];
  }

  const isConsumerNetAuction = formValues?.company?.id === COMPANY_ID_MEZZOFORTE_CONSUMER_NET_AUCTIONS;

  return [
    'type',
    formValues.commissionOwner.type === COMMISSION_OWNER_TYPE_PERSON ? 'firstName' : null,
    formValues.commissionOwner.type === COMMISSION_OWNER_TYPE_PERSON ? 'lastName' : null,
    formValues.commissionOwner.type === COMMISSION_OWNER_TYPE_PERSON && !isConsumerNetAuction ? 'ssn' : null,
    formValues.commissionOwner.type === COMMISSION_OWNER_TYPE_PERSON && !isConsumerNetAuction ? 'ssnHash' : null,
    formValues.commissionOwner.type === COMMISSION_OWNER_TYPE_COMPANY ? 'companyName' : null,
    formValues.commissionOwner.type === COMMISSION_OWNER_TYPE_COMPANY ? 'businessId' : null,
    'address',
    'phone',
    'email',
  ].filter(value => value !== null);
}

function getAuctionDateFields(formValues) {
  if (!formValues.copiesAmount) {
    return ['shouldUseAuctionDuration', 'auctionStart', 'auctionDuration', 'auctionEnd'];
  }

  return ['auctionDuration'];
}

function getNetAuctionClassDependentFields(formValues) {
  return [
    formValues.netAuctionClass.type === netAuctionClassService.CLASS_BANKRUPTCIES ? 'bankruptcyEstateName' : null,
  ].filter(value => value !== null);
}

function getNeedsPermitFieldIfNeeded(formValues) {
  // These categories and classes have their own basic info pages which do not have or need permit field.
  // All auctions that belong in the mandatory own payment group, we do not care about permits, as it is on sellers responsibility,
  // whether buying, selling or installation of product needs permit
  if (
    netAuctionService.doesClassHaveMandatoryOwnPayment(formValues.netAuctionClass.type) ||
    netAuctionService.doesCategoryHaveMandatoryOwnPayment(formValues.category.id)
  ) {
    return [];
  }

  return needsPermitService.doesTitleContainNeedsPermit(formValues.title || '') ||
    typeof formValues.needsPermit === 'boolean'
    ? ['needsPermit']
    : [];
}

function getApartmentMetadataFields(formValues) {
  const housingCompanyFields = netAuctionService.isApartmentPartOfHousingCompany(formValues.metadata.apartmentType)
    ? [
        'maintenanceFee',
        'loanPayment',
        'managementCharge',
        'debt',
        'dealDueDate',
        'depositType',
        formValues.metadata.depositType === metadataService.DEPOSIT_TYPE_FIXED_AMOUNT ? 'downPayment' : null,
      ]
    : [];

  return [
    'apartmentType',
    'roomCount',
    'livingArea',
    'constructionYear',
    'presentationLinks',
    ...housingCompanyFields,
  ].filter(value => value !== null);
}

function getRealEstateMetadataFields() {
  return ['presentationLinks'];
}

function getPassengerCarMetadataFields(formValues) {
  return [
    'registrationStatus',
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_IS_REGISTERED
      ? 'licenseNumber'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED
      ? 'notRegisteredReason'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED ? 'vin' : null,
    'vehicleInsurance',
    'inspectionStatus',
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_DONE ? 'nextInspection' : null,
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_NOT_DONE ? 'inspectionFailReason' : null,
    'manufacturer',
    'model',
    'yearModel',
    'commissioningDate',
    'fuelType',
    formValues.metadata.fuelType !== metadataService.FUEL_TYPE_ELECTRICITY ? 'engineDisplacement' : null,
    'power',
    'powerType',
    'drive',
    'transmission',
    'odometer',
    'maintenanceBook',
    formValues.metadata.maintenanceBook === metadataService.MAINTENANCE_BOOK_COMPLETE ||
    formValues.metadata.maintenanceBook === metadataService.MAINTENANCE_BOOK_PARTIAL
      ? 'maintenanceBookDescription'
      : null,
    'licensedTwice',
    formValues.metadata.licensedTwice === metadataService.LICENSED_TWICE_YES ? 'licensedTwiceReason' : null,
    formValues.metadata.licensedTwice === metadataService.LICENSED_TWICE_YES ? 'previousLicenseNumber' : null,
    formValues.metadata.licensedTwice === metadataService.LICENSED_TWICE_YES ? 'partsReplacedPercent' : null,
    'isImported',
    formValues.metadata.isImported === metadataService.IS_IMPORTED_YES ? 'isImportTaxPaid' : null,
    formValues.metadata.isImported && formValues.metadata.isImportTaxPaid === metadataService.IS_IMPORT_TAX_PAID_NO
      ? 'importedTaxEstimate'
      : null,
    formValues.metadata.isImported === metadataService.IS_IMPORTED_YES ? 'importedFrom' : null,
    formValues.metadata.isImported !== metadataService.IS_IMPORTED_YES ||
    formValues.metadata.isImportTaxPaid === metadataService.IS_IMPORT_TAX_PAID_YES
      ? 'taxesOverdue'
      : null,
    formValues.metadata.taxesOverdue === metadataService.TAXES_OVERDUE_YES &&
    (formValues.metadata.isImported !== metadataService.IS_IMPORTED_YES ||
      formValues.metadata.isImportTaxPaid === metadataService.IS_IMPORT_TAX_PAID_YES)
      ? 'taxesOverdueAmount'
      : null,
    'amountOfKeys',
    'faultyKeyRemote',
    formValues.metadata.faultyKeyRemote === metadataService.FAULTY_KEY_REMOTE_NOT_WORKING
      ? 'faultyKeyRemoteDescription'
      : null,
    'faultyWindshield',
    formValues.metadata.faultyWindshield === metadataService.FAULTY_WINDSHIELD_YES
      ? 'faultyWindshieldDescription'
      : null,
    'containsSummerTires',
    formValues.metadata.containsSummerTires === metadataService.CONTAINS_SUMMER_TIRES_YES
      ? 'areSummerTiresLegal'
      : null,
    'containsWinterTires',
    formValues.metadata.containsWinterTires === metadataService.CONTAINS_WINTER_TIRES_YES
      ? 'areWinterTiresLegal'
      : null,
    'faultyExterior',
    formValues.metadata.faultyExterior === metadataService.FAULTY_EXTERIOR_YES ? 'faultyExteriorDescription' : null,
    'faultyInterior',
    formValues.metadata.faultyInterior === metadataService.FAULTY_INTERIOR_YES ? 'faultyInteriorDescription' : null,
    'warningIndicator',
    formValues.metadata.warningIndicator === metadataService.WARNING_INDICATOR_YES
      ? 'warningIndicatorDescription'
      : null,
    'deliveryContainsAllItems',
    formValues.metadata.deliveryContainsAllItems === metadataService.DELIVERY_CONTAINS_ALL_ITEMS_NO
      ? 'deliveryDetails'
      : null,
    isSpecialVehicle(formValues) ? 'isSpecialVehicle' : null,
    'withdrawnFromTraffic',
    formValues.metadata.noRoadPermit ? 'noRoadPermit' : null,
    'emissionManipulation',
    formValues.metadata.emissionManipulation ? 'emissionManipulationDescription' : null,
  ].filter(value => value !== null);
}

function getTruckMetadataFields(formValues) {
  return [
    'registrationStatus',
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_IS_REGISTERED
      ? 'licenseNumber'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED
      ? 'notRegisteredReason'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED ? 'vin' : null,
    'vehicleInsurance',
    'inspectionStatus',
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_DONE ? 'nextInspection' : null,
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_NOT_DONE ? 'inspectionFailReason' : null,
    'manufacturer',
    'model',
    'yearModel',
    'odometer',
    'fuelType',
    formValues.metadata.fuelType !== metadataService.FUEL_TYPE_ELECTRICITY ? 'engineDisplacement' : null,
    isSpecialVehicle(formValues) ? 'isSpecialVehicle' : null,
    'withdrawnFromTraffic',
    'emissionManipulation',
    formValues.metadata.emissionManipulation === metadataService.EMISSION_MANIPULATION_YES
      ? 'emissionManipulationDescription'
      : null,
  ].filter(value => value !== null);
}

function getBoatMetadataFields(formValues) {
  return [
    'registrationStatus',
    [metadataService.REGISTRATION_STATUS_IS_REGISTERED, metadataService.REGISTRATION_STATUS_PROFESSIONAL].includes(
      formValues.metadata.registrationStatus
    )
      ? 'licenseNumber'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED
      ? 'notRegisteredReason'
      : null,
    'inspectionStatus',
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_DONE ? 'nextInspection' : null,
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_NOT_DONE ? 'inspectionFailReason' : null,
    isSpecialVehicle(formValues) ? 'isSpecialVehicle' : null,
  ].filter(value => value !== null);
}

function getRegistrableVehicleMetadataFields(formValues) {
  return [
    'registrationStatus',
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_IS_REGISTERED
      ? 'licenseNumber'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED
      ? 'notRegisteredReason'
      : null,
    [metadataService.REGISTRATION_STATUS_NOT_REGISTERED, metadataService.REGISTRATION_STATUS_NOT_REQUIRED].includes(
      formValues.metadata.registrationStatus
    )
      ? 'vin'
      : null,
    'vehicleInsurance',
    'inspectionStatus',
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_DONE ? 'nextInspection' : null,
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_NOT_DONE ? 'inspectionFailReason' : null,
    isSpecialVehicle(formValues) ? 'isSpecialVehicle' : null,
    'withdrawnFromTraffic',
    formValues.metadata.category !== metadataCategories.METADATA_TRAILER ? 'emissionManipulation' : null,
    formValues.metadata.emissionManipulation === metadataService.EMISSION_MANIPULATION_YES
      ? 'emissionManipulationDescription'
      : null,
  ].filter(value => value !== null);
}

function getMachineryMetadataFields(formValues) {
  return [
    'vin',
    'registrationStatus',
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_IS_REGISTERED
      ? 'licenseNumber'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED
      ? 'notRegisteredReason'
      : null,
    'vehicleInsurance',
    'inspectionStatus',
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_DONE ? 'nextInspection' : null,
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_NOT_DONE ? 'inspectionFailReason' : null,
    'ceMarkingStatus',
    'manufacturer',
    'model',
    !formValues.metadata.isYearModelUnknown ? 'yearModel' : null,
    'isYearModelUnknown',
    !formValues.metadata.isOperationalHoursUnknown ? 'operationalHours' : null,
    'isOperationalHoursUnknown',
    'drive',
    'inWorkingOrder',
    'emissionManipulation',
    formValues.metadata.emissionManipulation === metadataService.EMISSION_MANIPULATION_YES
      ? 'emissionManipulationDescription'
      : null,
  ].filter(value => value !== null);
}

function getTrailerMetadataFields(formValues) {
  return [
    'registrationStatus',
    'breaks',
    'totalMass',
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_IS_REGISTERED
      ? 'licenseNumber'
      : null,
    formValues.metadata.registrationStatus === metadataService.REGISTRATION_STATUS_NOT_REGISTERED
      ? 'notRegisteredReason'
      : null,
    [metadataService.REGISTRATION_STATUS_NOT_REGISTERED, metadataService.REGISTRATION_STATUS_NOT_REQUIRED].includes(
      formValues.metadata.registrationStatus
    )
      ? 'vin'
      : null,
    'vehicleInsurance',
    'inspectionStatus',
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_DONE ? 'nextInspection' : null,
    formValues.metadata.inspectionStatus === metadataService.INSPECTION_STATUS_NOT_DONE ? 'inspectionFailReason' : null,
    isSpecialVehicle(formValues) ? 'isSpecialVehicle' : null,
  ].filter(value => value !== null);
}

function getMetadataFields(formValues) {
  const possibleMetadatas = metadataService.getPossibleMetadatasForCategoryAndClass(
    formValues.category.id,
    formValues.netAuctionClass.type
  );

  if (possibleMetadatas.length === 0) {
    return [];
  }

  // Consumer form has trailer metadata fields, but seller form doesnt
  if (!formValues.consumerNetAuctionDraftStatus && possibleMetadatas.includes('Trailer')) {
    possibleMetadatas[possibleMetadatas.indexOf('Trailer')] = 'RegistrableVehicle';
  }

  const fieldsForMetadatas = {
    [metadataCategories.METADATA_APARTMENT]: getApartmentMetadataFields,
    [metadataCategories.METADATA_REAL_ESTATE]: getRealEstateMetadataFields,
    [metadataCategories.METADATA_PASSENGER_CAR]: getPassengerCarMetadataFields,
    [metadataCategories.METADATA_TRUCK]: getTruckMetadataFields,
    [metadataCategories.METADATA_BOAT]: getBoatMetadataFields,
    [metadataCategories.METADATA_REGISTRABLE_VEHICLE]: getRegistrableVehicleMetadataFields,
    [metadataCategories.METADATA_TRAILER]: getTrailerMetadataFields,
    [metadataCategories.METADATA_FARMING_MACHINERY]: getMachineryMetadataFields,
    [metadataCategories.METADATA_FOREST_MACHINERY]: getMachineryMetadataFields,
    [metadataCategories.METADATA_CONSTRUCTION_MACHINERY]: getMachineryMetadataFields,
    [metadataCategories.METADATA_OTHER_MACHINERY]: getMachineryMetadataFields,
  };

  return possibleMetadatas.reduce(
    (allFields, metadata) => {
      const newFields = fieldsForMetadatas[metadata] ? fieldsForMetadatas[metadata](formValues) : [];

      return [...newFields, ...allFields];
    },
    ['category', 'baseCategory']
  );
}

function getRelevantFields(formValues) {
  const fieldsAlwaysPresent = [
    'netAuctionClass',
    'isOwnPayment',
    'category',
    'title',
    'isNew',
    'description',
    'termsOfSale',
    'methodOfSales',
    'startPrice',
    'minBid',
    'continuousAuctionCount',
    'continuousAuctionDays',
    'streetAddress',
    'zipCode',
    'city',
    'contacts',
    'publishers',
    'billingAddress',
    'customReferenceNumber',
    'isHiddenFromLists',
    'pageAlwaysVisible',
    'ownPaymentOverride',
    'isSpecialEntry',
    'isVerifiedEntry',
    'hasCustomBadge',
    'customBadge',
    'isPrivateAuction',
    'privateNetAuctionGroup',
    'privateAuctionBidderIds',
    'notes',
    'silentSubmit',
    'exhibit',
    // Needed when validating net auctions.
    'auctionStartOriginal',
    'auctionEndOriginal',
    'auctionDurationOriginal',
    'isPublished',
    'isEnded',
    'confirmAndConsent',
    netAuctionFormFields.BOOK_VALUE,
  ];

  return {
    regularFields: [
      ...fieldsAlwaysPresent,
      ...getFieldsDependentOnMethodOfSales(formValues),
      ...getDeliveryFields(formValues),
      ...getDefectFields(formValues),
      ...getCommissionFields(formValues),
      ...getCategoryDependentFields(formValues),
      ...getContinuousAuctionFields(formValues),
      ...getAuctionDateFields(formValues),
      ...getNetAuctionClassDependentFields(formValues),
      ...getNeedsPermitFieldIfNeeded(formValues),
      ...getFieldsDependentOnCopying(formValues),
    ],
    metadataFields: getMetadataFields(formValues),
    commissionOwnerFields: getCommissionOwnerFields(formValues),
    categoryFields: ['id', 'parentCategory'],
  };
}

function parseCopyFields(formValues) {
  if (!formValues.copiesAmount) {
    return {};
  }

  return range(0, formValues.copiesAmount).reduce(
    (result, copyIndex) => ({
      ...result,
      [copyIndex]: {
        [netAuctionFormFields.AUCTION_START]: formValues.copy[copyIndex][netAuctionFormFields.AUCTION_START],
        [netAuctionFormFields.AUCTION_DURATION]: formValues.copy[copyIndex][netAuctionFormFields.AUCTION_DURATION],
        [netAuctionFormFields.AUCTION_END]: formValues.copy[copyIndex][netAuctionFormFields.AUCTION_END],
        primaryImage:
          formValues.orderedPrimaryImageIdsForCopies && formValues.orderedPrimaryImageIdsForCopies.length
            ? formValues.orderedPrimaryImageIdsForCopies[copyIndex]
            : undefined,
      },
    }),
    {}
  );
}

function getWarningFromFieldMetadata(meta) {
  // Redux form and Final form store warnings differently
  return meta.data ? meta.data.warning : meta.warning;
}

const setRandomAuctionEndTime = auctionEndDate =>
  auctionEndDate
    .hour(random(18, 20))
    .minute(random(0, 11) * 5)
    .second(0);

const setInitialAuctionEndTime = () => setRandomAuctionEndTime(dayjs().add(7, 'days'));

export default {
  MAX_IMAGE_UPLOADING_LIMIT,
  CONTINUOUS_AUCTION_MIN_STARTING_PRICE,
  CONTINUOUS_AUCTION_MIN_DURATION_DAYS,
  METHOD_OF_SALES_SOLD_TO_HIGHEST_BIDDER,
  METHOD_OF_SALES_SELLER_DECIDES,
  METHOD_OF_SALES_RESERVE_PRICE,
  COMMISSION_OWNER_TYPE_PERSON,
  COMMISSION_OWNER_TYPE_COMPANY,
  MAXIMUM_COPIES_AMOUNT,
  MAX_CUSTOM_REFERENCE_NUMBER_LENGTH,
  MAX_AUCTION_DURATION_FOR_NEW_ENTRIES,
  MAX_SUBTITLE_LENGTH,
  MAX_TITLE_LENGTH,
  MAX_ADDRESS_LENGTH,
  MAX_RETRIEVAL_EXPENSES_VALUE,
  DRAFT_TYPE_DRAFT: 'draft',
  DRAFT_TYPE_NET_AUCTION: 'netAuction',
  ERROR_RGB: 'rgb(160, 40, 37)',
  SUBMIT_ERROR_VAT: 'VAT_CHANGE',
  SUBMIT_ERROR_PRICE: 'PRICE_CHANGE',
  SUBMIT_ERROR_GENERAL: 'GENERAL',
  SUBMIT_ERROR_FORBIDDEN: 'FORBIDDEN',
  SUBMIT_ERROR_COMPANY_TERMINATED: 'COMPANY_TERMINATED',

  isContinuousAuctionAvailable,
  isNetAuctionClassForeclosure,
  shouldShowDeliveryFields,
  shouldShowDeliveryExpensesUnavailableField,
  shouldShowRetrievalFields,
  hasDefects,
  getWarningFromFieldMetadata,
  setRandomAuctionEndTime,
  setInitialAuctionEndTime,
  shouldShowSubtitleField,
  shouldIgnoreCommissionOwnerFields,

  isEditingAuctionStartEnabled(isPublishedNetAuction, auctionStart, now) {
    if (!isPublishedNetAuction) {
      return true;
    }

    return now.isBefore(auctionStart);
  },

  isEditingAuctionEndEnabled(isPublishedNetAuction, isUserAdmin, auctionStart, auctionEnd, now, hasBids) {
    if (!isPublishedNetAuction) {
      return true;
    }

    if (auctionStart.isAfter(now)) {
      return true;
    }

    if (isUserAdmin && auctionEnd.isAfter(now)) {
      return true;
    }

    if (hasBids) {
      return false;
    }

    if (auctionStart.isBetween(dayjs(now).subtract(1, 'hour'), now)) {
      return true;
    }

    if (auctionEnd.isBetween(now, dayjs(now).add(1, 'day'))) {
      return false;
    }

    if (auctionStart.isBetween(dayjs(now).subtract(1, 'day'), now)) {
      return true;
    }

    return false;
  },

  isEditingMethodOfSalesEnabled(
    isUserAdmin,
    isPublishedNetAuction,
    isEditingAuctionEndEnabled,
    methodOfSales,
    highestBid,
    startingPrice,
    reservePrice,
    auctionStart,
    now
  ) {
    if (isUserAdmin) {
      return true;
    }

    if (!isPublishedNetAuction) {
      return true;
    }

    if (auctionStart.isSameOrAfter(dayjs(now).subtract(24, 'hour'))) {
      return true;
    }

    if (methodOfSales === this.METHOD_OF_SALES_SELLER_DECIDES) {
      return true;
    }

    const reservePriceHasBeenPassed =
      parseInt(reservePrice, 10) <= parseInt(highestBid, 10) ||
      parseInt(reservePrice, 10) <= parseInt(startingPrice, 10);

    if (methodOfSales === this.METHOD_OF_SALES_RESERVE_PRICE && !reservePriceHasBeenPassed) {
      return true;
    }

    if (!isEditingAuctionEndEnabled) {
      return false;
    }

    if (methodOfSales === this.METHOD_OF_SALES_SOLD_TO_HIGHEST_BIDDER && highestBid) {
      return false;
    }

    if (methodOfSales === this.METHOD_OF_SALES_RESERVE_PRICE && reservePriceHasBeenPassed) {
      return false;
    }

    return true;
  },

  isEditingVatPercentEnabled(isUserAdmin, auctionStart, isPublishedNetAuction, now) {
    const isAuctionStarted = now.isAfter(auctionStart);

    if (isUserAdmin && !isAuctionStarted) {
      return true;
    }

    return !isPublishedNetAuction;
  },

  generateTitle(categoryId, metadata) {
    const categoriesWithVehicleTitle = [
      categories.CATEGORY_PASSENGER_CAR,
      categories.CATEGORY_VAN,
      categories.CATEGORY_TRUCK,
      categories.CATEGORY_CONSTRUCTION_MACHINERY,
      categories.CATEGORY_FARMING_MACHINERY,
      categories.CATEGORY_FOREST_MACHINERY,
      categories.CATEGORY_OTHER_MACHINERY,
      categories.CATEGORY_CARAVAN_AND_TRAILER,
      categories.CATEGORY_HEAVY_EQUIPMENT,
    ];

    const categoriesWithApartmentTitle = [categories.CATEGORY_APARTMENT];

    if (categoriesWithVehicleTitle.includes(categoryId)) {
      return generateVehicleTitle(metadata);
    }

    if (categoriesWithApartmentTitle.includes(categoryId)) {
      return generateApartmentTitle(metadata);
    }

    return null;
  },

  generateSubtitle(categoryId, metadata) {
    const categoriesWithVehicleSubtitle = [
      categories.CATEGORY_PASSENGER_CAR,
      categories.CATEGORY_VAN,
      categories.CATEGORY_TRUCK,
      categories.CATEGORY_HEAVY_EQUIPMENT,
    ];

    if (categoriesWithVehicleSubtitle.includes(categoryId)) {
      return generateVehicleSubtitle(metadata);
    }

    return null;
  },

  sortUsersByFullName(users, currentUserId) {
    return users.sort((userA, userB) => {
      if (userA.id === currentUserId) {
        return -1;
      }
      if (userB.id === currentUserId) {
        return 1;
      }

      const lastNameA = userA.name.slice(userA.name.lastIndexOf(' ') + 1).toUpperCase();
      const lastNameB = userB.name.slice(userB.name.lastIndexOf(' ') + 1).toUpperCase();

      if (lastNameA < lastNameB) {
        return -1;
      }

      if (lastNameB < lastNameA) {
        return 1;
      }

      if (userA.name.toUpperCase() < userB.name.toUpperCase()) {
        return -1;
      }

      return 1;
    });
  },

  shouldShowConditionPage(formValues) {
    if (!formValues.category) {
      return false;
    }

    const CATEGORIES_WITH_CONDITION_PAGE_ONLY_IF_DEFECTS_OR_SPECIAL_VEHICLE = [
      categories.CATEGORY_TRUCK,
      categories.CATEGORY_BOAT,
      categories.CATEGORY_MOTORCYCLE,
      categories.CATEGORY_CARAVAN_AND_TRAILER,
      categories.CATEGORY_OTHER_VEHICLE,
      categories.CATEGORY_SNOWMOBILE,
      categories.CATEGORY_HEAVY_EQUIPMENT,
    ];

    if (
      CATEGORIES_WITH_CONDITION_PAGE_ONLY_IF_DEFECTS_OR_SPECIAL_VEHICLE.indexOf(
        parseInt(formValues.category.id, 10)
      ) !== -1
    ) {
      return hasDefects(formValues) || isSpecialVehicle(formValues);
    }

    return (
      [
        categories.CATEGORY_PASSENGER_CAR,
        categories.CATEGORY_VAN,
        categories.CATEGORY_CONSTRUCTION_MACHINERY,
        categories.CATEGORY_FARMING_MACHINERY,
        categories.CATEGORY_FOREST_MACHINERY,
        categories.CATEGORY_OTHER_MACHINERY,
      ].indexOf(parseInt(formValues.category.id, 10)) !== -1
    );
  },

  isIsNewEnabled(isOwnPayment, isVatRegistered, commissionOwnerType) {
    if (commissionOwnerType === COMMISSION_OWNER_TYPE_PERSON) {
      return false;
    }

    return isOwnPayment || isVatRegistered;
  },

  getAuctionDurationSuggestionBasedOnCategory(categoryId) {
    if (netAuctionService.isRealEstate(categoryId)) {
      return 30;
    }
    if (netAuctionService.isVehicle(categoryId)) {
      return 3;
    }

    return 7;
  },

  getPossibleVatOptions(isOwnPayment, isVatRegistered, isNew, commissionOwnerType, date) {
    const vatOptionsByDate = getVatOptions(date, false, false, 'GENERAL').map(vatOption => vatOption.value);

    if (isOwnPayment) {
      return [0.0, 10.0, 14.0, ...vatOptionsByDate];
    }

    if (commissionOwnerType === COMMISSION_OWNER_TYPE_PERSON) {
      return [0.0];
    }

    if (commissionOwnerType === COMMISSION_OWNER_TYPE_COMPANY) {
      return ['MARGINAL_VAT', 10.0, 14.0, ...vatOptionsByDate];
    }

    if (!isVatRegistered) {
      return [0.0];
    }

    if (isNew) {
      return [10.0, 14.0, ...vatOptionsByDate];
    }

    return ['MARGINAL_VAT', 10.0, 14.0, ...vatOptionsByDate];
  },

  shouldUseHousingCompanyTermsOfSale(apartmentType, categoryId) {
    if (apartmentType && netAuctionService.isApartmentPartOfHousingCompany(parseInt(apartmentType, 10))) {
      return true;
    }

    return categoryId === categories.CATEGORY_TIMESHARE;
  },

  getTermsOfSaleEditingTabId(isForeclosure, isBankruptcy, isHousingCompany, isRealEstate, isOwnPayment) {
    if (isForeclosure) {
      return 'general-tos';
    }

    if (isBankruptcy) {
      return 'bankruptcy-estate-tos';
    }

    if (isHousingCompany) {
      return 'housing-company-tos';
    }

    if (isRealEstate) {
      return 'real-estate-tos';
    }

    if (isOwnPayment) {
      return 'own-payment-tos';
    }

    return 'general-tos';
  },

  getValidationStateString(touched, error, warning) {
    if (touched && error) {
      return 'error';
    }

    if (touched && warning) {
      return 'warning';
    }

    return null;
  },

  isSoldToHighestBidder(methodOfSales) {
    return methodOfSales === METHOD_OF_SALES_SOLD_TO_HIGHEST_BIDDER;
  },

  filterRelevantFormValues(formValues) {
    const { regularFields, metadataFields, commissionOwnerFields, categoryFields } = getRelevantFields(formValues);

    const parseSpecialFieldGroupFormValues = (fields, type) =>
      fields.reduce(
        (result, fieldKey) => ({
          ...result,
          [fieldKey]: Object.prototype.hasOwnProperty.call(formValues[type], fieldKey)
            ? formValues[type][fieldKey]
            : null,
        }),
        {}
      );

    const specialFieldGroupTypesMap = {
      metadata: metadataFields,
      commissionOwner: commissionOwnerFields,
      category: categoryFields,
    };

    const parsedFormValues = regularFields.reduce(
      (result, fieldKey) => ({
        ...result,
        [fieldKey]: Object.prototype.hasOwnProperty.call(formValues, fieldKey) ? formValues[fieldKey] : null,
      }),
      {}
    );

    parsedFormValues.copy = parseCopyFields(formValues);

    return Object.keys(specialFieldGroupTypesMap).reduce((result, specialFieldGroupType) => {
      if (specialFieldGroupTypesMap[specialFieldGroupType].length === 0) {
        return { ...result, [specialFieldGroupType]: null };
      }

      return {
        ...result,
        [specialFieldGroupType]: parseSpecialFieldGroupFormValues(
          specialFieldGroupTypesMap[specialFieldGroupType],
          specialFieldGroupType
        ),
      };
    }, parsedFormValues);
  },

  getTitleHelpText(categoryId) {
    if (
      [
        categories.CATEGORY_APARTMENT,
        categories.CATEGORY_TIMESHARE,
        categories.CATEGORY_PREMISES,
        categories.CATEGORY_PLOT,
        categories.CATEGORY_VACATION_HOMES,
        categories.CATEGORY_GARAGES,
        categories.CATEGORY_VEHICLE_SUPPLIES,
        categories.CATEGORY_PASSENGER_CAR,
        categories.CATEGORY_VAN,
        categories.CATEGORY_TRUCK,
        categories.CATEGORY_BOAT,
        categories.CATEGORY_MOTORCYCLE,
        categories.CATEGORY_CARAVAN_AND_TRAILER,
        categories.CATEGORY_OTHER_VEHICLE,
        categories.CATEGORY_SNOWMOBILE,
        categories.CATEGORY_CONSTRUCTION_MACHINERY,
        categories.CATEGORY_FARMING_MACHINERY,
        categories.CATEGORY_FOREST_MACHINERY,
        categories.CATEGORY_OTHER_MACHINERY,
        categories.CATEGORY_HEAVY_EQUIPMENT,
      ].indexOf(parseInt(categoryId, 10)) !== -1
    ) {
      return 'Napakka ja kuvaava nimi kerää eniten katsojia.';
    }

    return 'Napakka ja kuvaava nimi kerää eniten katsojia. Kerro kohteen tyyppi, merkki/malli ja kappalemäärä, esim. "Teippikone Hade-Matic 100, 4kpl".';
  },

  getMaxAuctionDuration(continuousAuctionCount, continuousAuctionDays) {
    return continuousAuctionCount && continuousAuctionDays
      ? MAX_AUCTION_DURATION_FOR_NEW_ENTRIES + continuousAuctionCount * continuousAuctionDays
      : MAX_AUCTION_DURATION_FOR_NEW_ENTRIES;
  },

  getNumericVatPercent(vatPercent) {
    if (!vatPercent) {
      return 0;
    }

    return vatPercent === 'MARGINAL_VAT' ? 0 : parseFloat(vatPercent);
  },

  getFormattedLicenseNumber(licenseNumber) {
    if (licenseNumber === undefined) {
      return '';
    }

    const numbersMatch = licenseNumber.match(/[0-9]+/);
    const lettersMatch = licenseNumber.match(/[A-Za-zåäöÅÄÖ]+/);

    if (!numbersMatch || !lettersMatch) {
      return licenseNumber;
    }

    if (numbersMatch.index < lettersMatch.index) {
      return numbersMatch[0].toUpperCase().concat('-', lettersMatch[0].toUpperCase());
    }

    return lettersMatch[0].toUpperCase().concat('-', numbersMatch[0].toUpperCase());
  },
};
