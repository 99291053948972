import createClient from '@app/src/api/client';

export async function fetchFavorites() {
  return createClient()
    .get<unknown>('/favorites')
    .then(response => response.data);
}

export async function addToFavorites(entryId: number) {
  return createClient()
    .post<{ readonly success: boolean }>('/favorites', { entryId })
    .then(response => response.data);
}

export async function removeFromFavorites(entryId: number) {
  return createClient()
    .delete<Record<string, never>>(`/favorites/${entryId}`)
    .then(response => response.data);
}

export async function removeEndedFromFavorites(entryId: number) {
  return createClient()
    .patch<Record<string, never>>('/favorites/remove-ended')
    .then(response => response.data);
}

export async function fetchFavoriteCountForUser(userId: number) {
  return createClient()
    .get<number>(`/users/${userId}/favorites/count`)
    .then(response => response.data);
}
