// @flow

import { List, Map } from 'immutable';

import newProductDetectionService from '../src/net-auction/new-product-detection-service';
import netAuctionService from '../src/net-auction/net-auction-service';

const FETCH_POSSIBLE_NEW_PRODUCTS = 'huutokaupat/net-auction/detect-new/FETCH_ENTRIES';
const FETCH_POSSIBLE_NEW_PRODUCTS_DONE = 'huutokaupat/net-auction/detect-new/FETCH_ENTRIES_DONE';
const FETCH_POSSIBLE_NEW_PRODUCTS_FAILED = 'huutokaupat/net-auction/detect-new/FETCH_ENTRIES_FAILED';
const CHANGE_MODERATION_STATUS_DONE = 'huutokaupat/net-auction/detect-new/CHANGE_MODERATION_STATUS_DONE';

const initialState = Map({
  fetchingEntries: false,
  entries: null,
  error: null,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_POSSIBLE_NEW_PRODUCTS:
      return state.set('entries', null).set('fetchingEntries', true).set('error', null);

    case FETCH_POSSIBLE_NEW_PRODUCTS_DONE:
      return state.set('entries', List(action.payload)).set('fetchingEntries', false);

    case FETCH_POSSIBLE_NEW_PRODUCTS_FAILED:
      return state.set('error', action.payload).set('entries', null).set('fetchingEntries', false);

    case CHANGE_MODERATION_STATUS_DONE: {
      const list = state.get('entries', List());
      const index = list.findIndex(item => item.id === action.payload.entryId);

      return state.updateIn(['entries', index], moderatedEntry => ({
        ...moderatedEntry,
        moderationStatus: action.payload.status,
      }));
    }

    default:
      return state;
  }
};

export function changeModerationStatus(entryId: number, status: string): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionService.updateModerationStatus(entryId, status).then(() =>
      dispatch({
        type: CHANGE_MODERATION_STATUS_DONE,
        payload: { entryId, status },
      })
    );
}

export function fetchPossibleNewProducts(vat: ?number, ownPayment: ?string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_POSSIBLE_NEW_PRODUCTS });

    return newProductDetectionService.fetchNewProductsSoldAsUsed(vat, ownPayment).then(
      response =>
        dispatch({
          type: FETCH_POSSIBLE_NEW_PRODUCTS_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: FETCH_POSSIBLE_NEW_PRODUCTS_FAILED,
          payload: error,
        })
    );
  };
}
