export default {
  METADATA_APARTMENT: 'Apartment',
  METADATA_REAL_ESTATE: 'RealEstate',
  METADATA_BOAT: 'Boat',
  METADATA_CONSTRUCTION_MACHINERY: 'ConstructionMachinery',
  METADATA_FARMING_MACHINERY: 'FarmingMachinery',
  METADATA_FOREST_MACHINERY: 'ForestMachinery',
  METADATA_OTHER_MACHINERY: 'OtherMachinery',
  METADATA_PASSENGER_CAR: 'PassengerCar',
  METADATA_REGISTRABLE_VEHICLE: 'RegistrableVehicle',
  METADATA_TRUCK: 'Truck',
  METADATA_TRAILER: 'Trailer',
};
