// @flow

import createClient from '../api/client';

const notificationTypes = {
  TYPE_COMPANY_MERGER: 100,
  TYPE_COMPANY_NAME_CHANGE: 101,
  TYPE_COMPANY_PAYMENT_DEFAULT: 102,
  TYPE_COMPANY_STATE_CHANGE: 103,
  TYPE_PERSON_IN_CHARGE_PAYMENT_DEFAULT: 120,
  TYPE_PERSON_IN_CHARGE_CHANGE: 121,
  TYPE_JUDGEMENT_CHANGE: 150,
  TYPE_PREPAYMENT_REGISTER_CHANGE: 151,
  TYPE_VAT_REGISTER_CHANGE: 152,
};

/* eslint-disable no-useless-computed-key */
const companyPaymentDefaultTypes = {
  ['8']: 'Uusi',
  ['7']: 'Oikaisu',
  ['72']: 'Vanhentuminen',
  ['73']: 'Poisto maksusuorituksen takia',
  ['74']: 'Ref-koodin lisäys',
  ['82']: 'Ref-koodin poisto',
};
/* eslint-enable no-useless-computed-key */

const personPaymentDefaultTypes = {
  VAMHO: 'Oikaisu',
  VAMHS: 'Poisto maksusuorituksen takia',
  VAMHV: 'Vanhentuminen',
  VAMRE: 'Ref-koodin lisäys',
  VAMRO: 'Ref-koodin poisto',
};

export default {
  fetchNotifications: (params: Object) =>
    createClient()
      .get('/company-monitoring/notifications', { params })
      .then(response => response.data),

  updateNotificationStatus: (id: number, status: number) =>
    createClient()
      .patch(`/company-monitoring/notifications/${id}`, { status })
      .then(response => response.data),

  getNotificationTypes: () => notificationTypes,

  getNotificationTypeString(notificationType: number): string {
    switch (notificationType) {
      case notificationTypes.TYPE_COMPANY_MERGER:
        return 'Yrityksen sulautuminen tai jakautuminen';

      case notificationTypes.TYPE_COMPANY_NAME_CHANGE:
        return 'Yrityksen nimenmuutos';

      case notificationTypes.TYPE_COMPANY_PAYMENT_DEFAULT:
        return 'Yrityksen maksuhäiriö';

      case notificationTypes.TYPE_COMPANY_STATE_CHANGE:
        return 'Yrityksen rekisteröinnin muutos';

      case notificationTypes.TYPE_PERSON_IN_CHARGE_PAYMENT_DEFAULT:
        return 'Vastuuhenkilön maksuhäiriö';

      case notificationTypes.TYPE_PERSON_IN_CHARGE_CHANGE:
        return 'Vastuuhenkilöiden muutos';

      case notificationTypes.TYPE_JUDGEMENT_CHANGE:
        return 'Yrityksen tuomio';

      case notificationTypes.TYPE_PREPAYMENT_REGISTER_CHANGE:
        return 'Ennakkoperintärekisteröinnin muutos';

      case notificationTypes.TYPE_VAT_REGISTER_CHANGE:
        return 'Arvonlisäverorekisteröinnin muutos';

      default:
        return `[Tuntematon ilmoitustyyppi: ${notificationType}]`;
    }
  },

  getExtraInfo(notificationType: number, contentType: string, paymentDefaultType: string): string {
    switch (notificationType) {
      case notificationTypes.TYPE_COMPANY_PAYMENT_DEFAULT:
        return this.getCompanyPaymentDefaultTypeStringForExtraInfo(paymentDefaultType);

      case notificationTypes.TYPE_PERSON_IN_CHARGE_PAYMENT_DEFAULT:
        return this.getPersonPaymentDefaultTypeStringForExtraInfo(contentType);

      default:
        return '';
    }
  },

  getCompanyPaymentDefaultTypeString(paymentDefaultType: number): string {
    return companyPaymentDefaultTypes[paymentDefaultType] || '[Tuntematon tyyppi]';
  },

  getCompanyPaymentDefaultTypeStringForExtraInfo(paymentDefaultType: string): string {
    if (paymentDefaultType === '8' || !companyPaymentDefaultTypes[paymentDefaultType]) {
      return '';
    }

    return `(${companyPaymentDefaultTypes[paymentDefaultType]})`.toLowerCase();
  },

  getPersonPaymentDefaultTypeString(paymentDefaultType: string): string {
    return personPaymentDefaultTypes[paymentDefaultType] || 'Uusi merkintä';
  },

  getPersonPaymentDefaultTypeStringForExtraInfo(paymentDefaultType: string): string {
    if (!paymentDefaultType || !personPaymentDefaultTypes[paymentDefaultType]) {
      return '';
    }

    return `(${personPaymentDefaultTypes[paymentDefaultType]})`.toLowerCase();
  },
};
