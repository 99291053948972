/* eslint-disable @typescript-eslint/naming-convention -- keep originals  */
/* eslint-disable @typescript-eslint/consistent-type-definitions  -- keep originals */
import createClient from '../api/client';

export type LogUser = {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
};

export type LogCompany = {
  readonly id: number;
  readonly name: string;
  readonly type: string;
};

export type EventCode = {
  readonly id: number;
  readonly message: string;
};

export type Log = {
  readonly id: number;
  readonly createdAt: string;
  readonly message: string;
  readonly status: number;
  readonly entryId: number;
  readonly ipAddress: string;
  readonly company: LogCompany;
  readonly eventCode: EventCode;
  readonly user: LogUser;
  readonly triggeredUser: LogUser;
  readonly debug: string;
  readonly metadata: string;
};

export const FetchType = Object.freeze({
  USER: 'user',
  COMPANY: 'company',
  ENTRY: 'entry',
  LATEST: 'latest',
});

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const STATUS_SUCCESS = 1;
export const STATUS_WARNING = 2;
export const STATUS_DANGER = 3;

export type LogType = (typeof FetchType)[keyof typeof FetchType];

export const getTypeHeaderText = (type: LogType, id: number = 0): string => {
  switch (type) {
    case FetchType.USER:
      return `Käyttäjän ${id} tapahtumat`;

    case FetchType.COMPANY:
      return `Yrityksen ${id} tapahtumat`;

    case FetchType.ENTRY:
      return `Ilmoituksen ${id} tapahtumat`;

    default:
      return 'Viimeisimmät tapahtumat';
  }
};

const getApiUrl = (type: LogType, id: number, page: number, order = 'desc'): string =>
  id
    ? `/logs/${type}/${id}?page=${page}&direction=${order}`
    : `/logs/${FetchType.LATEST}?page=${page}&direction=${order}`;

const fetchLogs = async (type: LogType, id: number, page: number, order: string) =>
  createClient()
    .get<Log[]>(getApiUrl(type, id, page, order))
    .then(response => response.data);

export default { fetchLogs };
