const get = (key: string) => localStorage.getItem(key);

const set = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const remove = (key: string): void => {
  localStorage.removeItem(key);
};

export default {
  get,
  set,
  remove,
};
