import * as c from '../types/Category';
import { CategoryIcons as icon } from './icons';

export const categoriesStaticData = new Map<number, c.CategoryStaticData>([
  [c.CATEGORY_OTHER, { icon: icon.Package, slug: 'muut' }],
  [c.CATEGORY_VEHICLES_AND_SUPPLIES, { icon: icon.Car, slug: 'ajoneuvot-ja-tarvikkeet' }],
  [c.CATEGORY_VEHICLE_SUPPLIES, { icon: icon.Wheel, slug: 'ajoneuvotarvikkeet' }],
  [c.CATEGORY_REAL_ESTATE, { icon: icon.City2, slug: 'asunnot-mokit-toimitilat-ja-tontit' }],
  [c.CATEGORY_LIGHT_MACHINERY_AND_SUPPLIES, { icon: icon.Lawnmower, slug: 'puutarhakoneet-ja-leikkurit' }],
  [c.CATEGORY_TOOLS, { icon: icon.Drill, slug: 'sahkotyokalut-ja-akkutyokalusarjat' }],
  [c.CATEGORY_FURNITURE_AND_KITCHEN_EQUIPMENT, { icon: icon.Lamp, slug: 'sisustaminen-ja-koti' }],
  [c.CATEGORY_ELECTRONICS, { icon: icon.Laptop, slug: 'elektroniikka' }],
  [c.CATEGORY_JEWELRY_CLOCKS_AND_ANTIQUES, { icon: icon.Watch, slug: 'kellot-ja-korut' }],
  [c.CATEGORY_INDUSTRIAL_MACHINERY, { icon: icon.Excavator, slug: 'tyokoneet' }],
  [c.CATEGORY_BULK_GOODS, { icon: icon.Packages, slug: 'tukkuerat' }],
  [c.CATEGORY_PASSENGER_CAR, { icon: icon.Car, slug: 'henkiloautot' }],
  [c.CATEGORY_VAN, { icon: icon.Van, slug: 'pakettiautot' }],
  [c.CATEGORY_TRUCK, { icon: icon.Truck, slug: 'kevytkuorma-autot' }],
  [c.CATEGORY_BOAT, { icon: icon.Boat, slug: 'veneet' }],
  [c.CATEGORY_MOTORCYCLE, { icon: icon.Motorcycle, slug: 'moottoripyorat-ja-mopot' }],
  [c.CATEGORY_CARAVAN_AND_TRAILER, { icon: icon.Trailer, slug: 'perakarryt-ja-asuntovaunut' }],
  [c.CATEGORY_OTHER_VEHICLE, { icon: icon.Bus, slug: 'muut-ajoneuvot' }],
  [c.CATEGORY_SNOWMOBILE, { icon: icon.Snowmobile, slug: 'moottorikelkat-ja-monkijat' }],
  [c.CATEGORY_APARTMENT, { icon: icon.City2, slug: 'asunnot' }],
  [c.CATEGORY_TIMESHARE, { icon: icon.Beach, slug: 'lomaosakkeet' }],
  [c.CATEGORY_PREMISES, { icon: icon.Storefront, slug: 'liike-ja-toimitilat' }],
  [c.CATEGORY_PLOT, { icon: icon.Map, slug: 'tontit-maa-ja-metsatilat' }],
  [c.CATEGORY_CONSTRUCTION_MACHINERY, { icon: icon.Excavator, slug: 'maarakennuskoneet' }],
  [c.CATEGORY_FARMING_MACHINERY, { icon: icon.Tractor, slug: 'maatalouskoneet' }],
  [c.CATEGORY_FOREST_MACHINERY, { icon: icon.ForestMachine, slug: 'metsakoneet' }],
  [c.CATEGORY_OTHER_MACHINERY, { icon: icon.Forklift, slug: 'muut-tyokoneet' }],
  [c.CATEGORY_CONSTRUCTION_SUPPLIES, { icon: icon.WoodPlank, slug: 'rakennusmateriaalit' }],
  [c.CATEGORY_HOUSEHOLD_APPLIANCES, { icon: icon.Plug, slug: 'kodin-pienkoneet-ja-sahkolaitteet' }],
  [c.CATEGORY_FURNITURE, { icon: icon.Chair, slug: 'huonekalut-ja-kalusteet' }],
  [c.CATEGORY_IT_EQUIPMENT, { icon: icon.Laptop, slug: 'tietokoneet-tabletit-ja-puhelimet' }],
  [c.CATEGORY_OTHER_ELECTRONICS, { icon: icon.GameController, slug: 'muu-viihde-elektroniikka' }],
  [c.CATEGORY_FREE_TIME, { icon: icon.Flashlight, slug: 'urheiluun-ja-ulkoiluun' }],
  [c.CATEGORY_MACHINERY_SUPPLIES, { icon: icon.Package, slug: 'tyokonetarvikkeet' }],
  [c.CATEGORY_VACATION_HOMES, { icon: icon.House, slug: 'loma-asunnot-ja-mokit' }],
  [c.CATEGORY_GARAGES, { icon: icon.Garage, slug: 'autotallit-siirrettavat-rakennelmat-ja-muut' }],
  [c.CATEGORY_HOBBY_SUPPLIES, { icon: icon.Bicycle, slug: 'harrastusvalineet-ja-vapaa-aika' }],
  [c.CATEGORY_BICYCLES, { icon: icon.Bicycle, slug: 'polkupyorat-ja-pyoraily' }],
  [c.CATEGORY_FISHING_AND_HUNTING, { icon: icon.Fish, slug: 'kalastustarvikkeet-ja-metsastystarvikkeet' }],
  [c.CATEGORY_INSTRUMENTS_AND_MUSIC, { icon: icon.PianoKeys, slug: 'soittimet-ja-muu-musiikki' }],
  [c.CATEGORY_GARDEN, { icon: icon.Lawnmower, slug: 'piha-ja-puutarha' }],
  [c.CATEGORY_GARDEN_FURNITURE, { icon: icon.Grill, slug: 'puutarhakalusteet-ja-pihagrillit' }],
  [c.CATEGORY_OUTDOOR_LIGHTS, { icon: icon.Lightbulb, slug: 'ulkovalaisimet-ja-terassilammittimet' }],
  [c.CATEGORY_YARD_DECORATION, { icon: icon.Wall, slug: 'pihakoristeet-ja-pihan-rakentaminen' }],
  [c.CATEGORY_YARD_BUILDINGS, { icon: icon.House, slug: 'piharakennukset-ja-piha-aidat' }],
  [c.CATEGORY_TOOLS_AND_TOOLSETS, { icon: icon.Drill, slug: 'tyokalut-ja-tyokalusarjat' }],
  [c.CATEGORY_HANDCRAFT_TOOLS, { icon: icon.Wrench, slug: 'kasityokalut-ja-kasityokalusarjat' }],
  [c.CATEGORY_CONSTRUCTION, { icon: icon.WoodPlank, slug: 'rakennustarvikkeet' }],
  [c.CATEGORY_BATHROOM_RENOVATION, { icon: icon.Bathtub, slug: 'kylpyhuoneen-saunan-ja-wc-n-remontointi' }],
  [c.CATEGORY_KITCHEN_RENOVATION, { icon: icon.Kitchen, slug: 'keittion-remontointi' }],
  [c.CATEGORY_HVAC_SUPPLIES, { icon: icon.Cylinder, slug: 'lvi-tarvikkeet-ja-putket' }],
  [c.CATEGORY_ELECTRONICS_SUPPLIES, { icon: icon.Plugs, slug: 'sahkotarvikkeet-ja-sahkolaitteet' }],
  [c.CATEGORY_TABLEWARE, { icon: icon.ForkKnife, slug: 'astiastot-ja-aterimet' }],
  [c.CATEGORY_DECOR, { icon: icon.Lamp, slug: 'sisustus' }],
  [c.CATEGORY_ANTIQUE, { icon: icon.Armchair, slug: 'antiikki-ja-taide' }],
  [c.CATEGORY_AUDIO, { icon: icon.Headphones, slug: 'audio' }],
  [c.CATEGORY_TV, { icon: icon.Tv, slug: 'tv' }],
  [c.CATEGORY_PHONE_AND_IT_ACCESSORIES, { icon: icon.Printer, slug: 'puhelintarvikkeet-ja-tietokoneen-oheislaitteet' }],
  [c.CATEGORY_COLLECTIBLES, { icon: icon.Watch, slug: 'keraily' }],
  [c.CATEGORY_OTHER_COLLECTIBLES, { icon: icon.Trophy, slug: 'muut-kerailyesineet' }],
  [c.CATEGORY_HEAVY_EQUIPMENT, { icon: icon.HeavyEquipment, slug: 'raskas-kalusto' }],
  [c.CATEGORY_HEAVY_EQUIPMENT_SPARE_PARTS, { icon: icon.ExhaustPipe, slug: 'raskaan-kaluston-varaosat' }],
  [c.CATEGORY_VEHICLE_SPARE_PARTS, { icon: icon.ExhaustPipe, slug: 'ajoneuvovaraosat' }],
]);
