import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { useToast } from '@mezzoforte/forge';
import { isServiceUnavailableError, isTooManyRequestsError } from '@app/src/util/axios-errors';

// eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: Move client creation into a hook.
const { playToast } = useToast();

const onError = (error: unknown) => {
  // Display toast about maintenance break.
  if (isServiceUnavailableError(error)) {
    playToast(
      'Pieni hetki, huollamme sivustoa',
      'Huutokaupat.com on pois käytöstä huoltotöiden takia. Pyrimme saamaan palvelun käyttöön mahdollisimman pian.',
      {
        toastId: 'service-unavailable-error',
        variant: 'danger',
        closeManually: true,
      }
    );
  }

  if (isTooManyRequestsError(error)) {
    playToast('Virhe', 'Liikaa pyyntöjä. Odota hetki ja yritä sen jälkeen uudestaan.', {
      toastId: 'too-many-requests-error',
      variant: 'danger',
      closeManually: true,
    });
  }
};

// Shared react-query cache between main, sidebar and header apps.
// TODO: Combine the apps and move this into a hook.
export const queryClient = new QueryClient({
  queryCache: new QueryCache({ onError }),
  mutationCache: new MutationCache({ onError }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Avoid retrying during maintenance break.
        if (isServiceUnavailableError(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

export const staticSettings = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
};
