import { City } from '@typings/City';
import { List } from 'immutable';
import { Dispatch } from 'redux';
import { RootState } from '@app/ducks';

import createClient from '../../src/api/client';

const LoadCities = 'huutokaupat/city/LOAD_CITIES';
const LoadCitiesDone = 'huutokaupat/city/LOAD_CITIES_DONE';
const LoadCitiesFailed = 'huutokaupat/city/LOAD_CITIES_FAILED';

const LoadCityByZipcode = 'huutokaupat/city/CITY_BY_ZIPCODE';
const LoadCityByZipcodeDone = 'huutokaupat/city/LOAD_CITY_BY_ZIPCODE_DONE';
const LoadCityByZipcodeFailed = 'huutokaupat/city/LOAD_CITY_BY_ZIPCODE_FAILED';

interface CityReducerState {
  readonly loading: boolean;
  readonly failed: boolean;
  readonly cities: List<City>;
}

interface LoadCitiesAction {
  readonly type: typeof LoadCities;
}
interface LoadCitiesDoneAction {
  readonly type: typeof LoadCitiesDone;
  readonly payload: City[];
}
interface LoadCitiesFailedAction {
  readonly type: typeof LoadCitiesFailed;
}
interface LoadCityByZipcodeAction {
  readonly type: typeof LoadCityByZipcode;
}
interface LoadCityByZipcodeDoneAction {
  readonly type: typeof LoadCityByZipcodeDone;
}
interface LoadCityByZipcodeFailedAction {
  readonly type: typeof LoadCityByZipcodeFailed;
}
type CityReducerAction =
  | LoadCitiesAction
  | LoadCitiesDoneAction
  | LoadCitiesFailedAction
  | LoadCityByZipcodeAction
  | LoadCityByZipcodeDoneAction
  | LoadCityByZipcodeFailedAction;

const initialState: CityReducerState = {
  loading: false,
  failed: false,
  cities: List(),
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Reducer
  state = initialState,
  action: CityReducerAction
) => {
  switch (action.type) {
    case LoadCities:
      return { ...state, loading: true };

    case LoadCitiesDone:
      return { ...state, loading: false, cities: List(action.payload) };

    case LoadCitiesFailed:
      return { ...state, loading: false, failed: true };

    case LoadCityByZipcode:
    case LoadCityByZipcodeDone:
    case LoadCityByZipcodeFailed:
    default:
      return state;
  }
};

export function loadCities() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().city.loading) {
      dispatch({ type: LoadCities, payload: {} });
      createClient()
        .get('/cities')
        .then(response => dispatch({ type: LoadCitiesDone, payload: response.data.resource }))
        .catch(response => dispatch({ type: LoadCitiesFailed, payload: { error: response } }));
    }
  };
}

export function searchCityByZipCode(zipCode: string, callback: (cityId: number) => void) {
  return (dispatch: Dispatch) => {
    dispatch({ type: LoadCityByZipcode, payload: {} });

    createClient()
      .get(`/cities/search?zip=${zipCode}`)
      .then(response => callback(response.data.id))
      .catch(response =>
        dispatch({
          type: LoadCityByZipcodeFailed,
          payload: { error: response },
        })
      );
  };
}
