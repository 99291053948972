// @flow

import { Map, List } from 'immutable';
import bankStatementService from '../src/bank-statement/bank-statement-service';

const FETCH_BANK_STATEMENTS = 'huutokaupat/bank-statement/FETCH_BANK_STATEMENTS';
const FETCH_BANK_STATEMENTS_DONE = 'huutokaupat/bank-statement/FETCH_BANK_STATEMENTS_DONE';
const FETCH_BANK_STATEMENTS_FAILED = 'huutokaupat/bank-statement/FETCH_BANK_STATEMENTS_FAILED';

const FETCH_BANK_STATEMENT_ROWS = 'huutokaupat/bank-statement/FETCH_BANK_STATEMENT_ROWS';
const FETCH_BANK_STATEMENT_ROWS_DONE = 'huutokaupat/bank-statement/FETCH_BANK_STATEMENT_ROWS_DONE';
const FETCH_BANK_STATEMENT_ROWS_FAILED = 'huutokaupat/bank-statement/FETCH_BANK_STATEMENT_ROWS_FAILED';

const initialState = Map({
  loading: false,
  failed: false,
  bankStatements: List(),
  bankStatementRows: List(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_BANK_STATEMENTS:
      return state
        .set('loading', true)
        .set('failed', false)
        .set('bankStatements', List())
        .set('bankStatementRows', List());

    case FETCH_BANK_STATEMENTS_DONE:
      return state.set('loading', false).set('bankStatements', List(action.payload));

    case FETCH_BANK_STATEMENTS_FAILED:
      return state.set('loading', false).set('failed', true);

    case FETCH_BANK_STATEMENT_ROWS:
      return state.set('loading', true).set('failed', false).set('bankStatementRows', List());

    case FETCH_BANK_STATEMENT_ROWS_DONE:
      return state.set('loading', false).set('bankStatementRows', List(action.payload));

    case FETCH_BANK_STATEMENT_ROWS_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function fetchBankStatements(): Function {
  return async (dispatch: Function): Promise<boolean> => {
    dispatch({ type: FETCH_BANK_STATEMENTS });

    try {
      const bankStatements = await bankStatementService.fetchBankStatements();
      dispatch({ type: FETCH_BANK_STATEMENTS_DONE, payload: bankStatements });
    } catch (e) {
      dispatch({ type: FETCH_BANK_STATEMENTS_FAILED, payload: e });

      return false;
    }

    return true;
  };
}

export function fetchBankStatementRows(bankStatementId: number): Function {
  return async (dispatch: Function): Promise<boolean> => {
    dispatch({ type: FETCH_BANK_STATEMENT_ROWS });

    try {
      const bankStatementRows = await bankStatementService.fetchBankStatementRows(bankStatementId);
      dispatch({
        type: FETCH_BANK_STATEMENT_ROWS_DONE,
        payload: bankStatementRows,
      });
    } catch (e) {
      dispatch({ type: FETCH_BANK_STATEMENT_ROWS_FAILED, payload: e });

      return false;
    }

    return true;
  };
}
