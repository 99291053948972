const SetTitle = 'huutokaupat/own-page/SET_TITLE';
const SetLink = 'huutokaupat/own-page/SET_LINK';

export interface OwnPageLinkProps {
  readonly text: string;
  readonly to: string;
}

interface OwnPageState {
  readonly title: string;
  readonly link?: OwnPageLinkProps;
}

interface OwnPageTitleAction {
  readonly type: typeof SetTitle;
  readonly payload: string;
}

interface OwnPageLinkAction {
  readonly type: typeof SetLink;
  readonly payload?: OwnPageLinkProps;
}

type OwnPageAction = OwnPageTitleAction | OwnPageLinkAction;

const initialState: OwnPageState = {
  title: '',
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Reducer.
  state: OwnPageState = initialState,
  action: OwnPageAction
): OwnPageState => {
  switch (action.type) {
    case SetTitle:
      return {
        ...state,
        title: action.payload,
      };

    case SetLink:
      return {
        ...state,
        link: action.payload,
      };

    default:
      return state;
  }
};

export function setOwnPageTitle(title: string): OwnPageAction {
  return { type: SetTitle, payload: title };
}

export function setOwnPageLink(link?: OwnPageLinkProps): OwnPageAction {
  return { type: SetLink, payload: link };
}
