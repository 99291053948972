// @flow

import createClient from '../api/client';
import { mediaUploadMaxSize } from './media-service';

export default {
  DRAFT_MEDIA_TYPE_IMAGE: 1,
  DRAFT_MEDIA_TYPE_VIDEO: 2,
  DRAFT_MEDIA_TYPE_ATTACHMENT: 3,

  fetchImages: (draftId: number, uuid: ?string) =>
    createClient()
      .get(`/net-auctions/drafts/${draftId}/images`, uuid ? { params: { uuid } } : {})
      .then(response => response.data),

  fetchImageCount: (draftId: number) =>
    createClient()
      .get(`/net-auctions/drafts/${draftId}/images/count`)
      .then(response => response.data),

  rotateImage: (id: number) =>
    createClient()
      .patch(`/net-auctions/drafts/images/${id}/rotate`)
      .then(response => response.data),

  deleteImage: (id: number) =>
    createClient()
      .delete(`/net-auctions/drafts/images/${id}`)
      .then(response => response.data),

  deleteAllImages: (draftId: number) =>
    createClient()
      .delete(`/net-auctions/drafts/${draftId}/images`)
      .then(response => response.data),

  orderImages: (draftId: number, imageIdsInOrder: Array<number>) =>
    createClient()
      .patch(`/net-auctions/drafts/${draftId}/images/order`, {
        imageIdsInOrder,
      })
      .then(response => response.data),

  uploadPhoto: (draftId: number, file: Object) =>
    createClient()
      .post(`/net-auctions/drafts/${draftId}/images`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  uploadExternalPhoto: (draftId: number, source: string, image: Object) =>
    createClient()
      .post(`/net-auctions/drafts/${draftId}/images/url`, { image, source })
      .then(response => response.data),

  uploadVideo: (draftId: number, url: string) =>
    createClient()
      .post(`/net-auctions/drafts/${draftId}/images/videos`, { url })
      .then(response => response.data),

  fetchAttachments: (draftId: number, uuid: ?string) =>
    createClient()
      .get(`/net-auctions/drafts/${draftId}/attachments`, uuid ? { params: { uuid } } : {})
      .then(response => response.data),

  uploadAttachment: (draftId: number, file: Object) =>
    createClient()
      .post(`/net-auctions/drafts/${draftId}/attachments`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  deleteAttachment: (id: number) =>
    createClient()
      .delete(`/net-auctions/drafts/attachments/${id}`)
      .then(response => response.data)
      .catch(() => {
        // TODO: Move attachment handling to a hook and use useDialog instead.
        window.alert('Liitteen poisto epäonnistui');
      }),

  orderAttachments: (draftId: number, attachmentIdsInOrder: Array<number>) =>
    createClient()
      .patch(`/net-auctions/drafts/${draftId}/attachments/order`, {
        attachmentIdsInOrder,
      })
      .then(response => response.data)
      .catch(() => {
        // TODO: Move attachment handling to a hook and use useDialog instead.
        window.alert('Liitteiden järjestäminen epäonnistui');
      }),

  getUploadFailedMessageByStatus: (status: number): string => {
    if (status === 413) {
      return `Tiedosto on liian suuri! (max. ${mediaUploadMaxSize.VALUE} ${mediaUploadMaxSize.UNIT})`;
    }

    return '';
  },
};
