import { Dispatch } from 'redux';
import marketPriceService, { MarketPriceEntry, MarketPriceInfoResults } from '../src/market-price/market-price-service';

export type CategoryType = 'car' | 'machinery' | 'none';

const FetchEntries = 'huutokaupat/seller/market-price/FETCH_ENTRIES';
const FetchEntriesDone = 'huutokaupat/seller/market-price/FETCH_ENTRIES_DONE';
const FetchEntriesFailed = 'huutokaupat/seller/market-price/FETCH_ENTRIES_FAILED';
const FetchInfoDone = 'huutokaupat/seller/market-price/FETCH_INFO_DONE';
const FetchInfoFailed = 'huutokaupat/seller/market-price/FETCH_INFO_FAILED';
const SelectType = 'huutokaupat/seller/market-price/SELECT_TYPE';

type MarketPriceReducerAction =
  | FetchEntriesAction
  | FetchEntriesDoneAction
  | FetchEntriesFailedAction
  | FetchInfoDoneAction
  | FetchInfoFailedAction
  | SelectTypeAction;

interface MarketPriceReducerState {
  readonly type: CategoryType;
  readonly loading: boolean;
  readonly failed: boolean;
  readonly entries: MarketPriceEntry[];
  readonly searched: boolean;
  readonly carsSoldTotal: number | null;
  readonly machinerySoldTotal: number | null;
}

const initialState: MarketPriceReducerState = {
  type: 'none',
  loading: false,
  failed: false,
  entries: [],
  searched: false,
  carsSoldTotal: null,
  machinerySoldTotal: null,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Reducer.
  state: MarketPriceReducerState = initialState,
  action: MarketPriceReducerAction
): MarketPriceReducerState => {
  switch (action.type) {
    case FetchEntries:
      return {
        ...state,
        loading: true,
        failed: false,
        entries: [],
        searched: true,
      };

    case FetchEntriesDone:
      return {
        ...state,
        loading: false,
        entries: action.payload,
      };

    case FetchEntriesFailed:
      return {
        ...state,
        loading: false,
        failed: true,
      };

    case FetchInfoDone:
      return {
        ...state,
        carsSoldTotal: action.payload.entriesSold.car,
        machinerySoldTotal: action.payload.entriesSold.machinery,
      };

    case FetchInfoFailed:
      return {
        ...state,
        carsSoldTotal: -1,
        machinerySoldTotal: -1,
      };

    case SelectType:
      return {
        ...state,
        type: action.payload,
        loading: false,
        entries: [],
        searched: false,
      };

    default:
      return state;
  }
};

interface FetchEntriesAction {
  readonly type: typeof FetchEntries;
}

interface FetchEntriesDoneAction {
  readonly type: typeof FetchEntriesDone;
  readonly payload: MarketPriceEntry[];
}

interface FetchEntriesFailedAction {
  readonly type: typeof FetchEntriesFailed;
}

interface FetchInfoDoneAction {
  readonly type: typeof FetchInfoDone;
  readonly payload: MarketPriceInfoResults;
}

interface FetchInfoFailedAction {
  readonly type: typeof FetchInfoFailed;
}

interface SelectTypeAction {
  readonly type: typeof SelectType;
  readonly payload: CategoryType;
}

export function fetchEntries(selectedMetadata: Record<string, unknown>) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FetchEntries });

    if (!selectedMetadata) {
      return Promise.resolve(dispatch({ type: FetchEntriesDone, payload: [] }));
    }

    return marketPriceService
      .fetchEntries(selectedMetadata)
      .then(entries => dispatch({ type: FetchEntriesDone, payload: entries }))
      .catch(() => dispatch({ type: FetchEntriesFailed }));
  };
}

export function fetchInfo() {
  return async (dispatch: Dispatch) =>
    marketPriceService
      .fetchInfo()
      .then(info => dispatch({ type: FetchInfoDone, payload: info }))
      .catch(error => dispatch({ type: FetchInfoFailed, payload: error }));
}

export function selectType(type: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SelectType, payload: type });
  };
}
