// @flow

import io from 'socket.io-client';
import { Set, Map } from 'immutable';
import { chunk } from 'lodash-es';
import dayjs from 'dayjs';
import config from '../../../config';
import { useToast } from '@mezzoforte/forge';

const ERROR_HIDE_DELAY_ON_CONNECT = 1000;
const ERROR_SHOW_DELAY_ON_DISCONNECT = 5000;
const POLLING_RECONNECT_INTERVAL = 1000;
const MAX_LATENCY_ALLOWED = 10000;
const DING_INTERVAL = 30000;

let socket: Object;
let subscriptions: Map<number, Set<string>>;
let updateListeners: Set<Function>;
let skewListeners: Set<Function>;
let dingInterval: ?IntervalID;

// eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: Move client creation into a hook.
const errorToast = useToast();

export default {
  initialize(): void {
    if (!subscriptions) {
      subscriptions = Map();
    }

    if (!updateListeners) {
      updateListeners = Set();
    }

    if (!skewListeners) {
      skewListeners = Set();
    }

    if (!socket) {
      this.createSocket();
    }
  },

  onConnect(): void {
    if (!dingInterval) {
      dingInterval = setInterval(() => {
        if (socket.connected) {
          this.ding();
        }
      }, DING_INTERVAL);
    }
  },

  onReconnect(): void {
    setTimeout((): void => {
      if (!socket.connected) {
        return;
      }

      errorToast.dismissToast();

      if (subscriptions.size > 0) {
        this.handleSubscribing(subscriptions.keySeq().toJS());
      }
    }, ERROR_HIDE_DELAY_ON_CONNECT);
  },

  createSocket(): void {
    socket = io(config.hermesHost, { transports: ['websocket', 'polling'] });

    socket.on('connect', () => this.onConnect());
    socket.on('reconnect', () => this.onReconnect());
    socket.on('error', error => this.onError(error));
    socket.on('connect_error', error => this.onError(error));

    socket.on('update', data => this.notifyListeners(data));
    socket.on('skew', skew => this.notifySkewListeners(skew));
    socket.on('subscribe:error', error => this.onError(error));

    socket.on('dong', data => this.dong(data));
  },

  displayError(): void {
    errorToast.playToast(
      'Nettiyhteydessä ongelmia',
      'Huutokauppojen tiedot eivät päivity. Kokeile ladata sivu uudelleen.',
      {
        toastId: 'hermes-error',
        variant: 'danger',
        closeManually: true,
      }
    );
  },

  onError(error?: Object): void {
    // If polling and we encounter an HTTP error, recreate the socket to get a new session ID.
    // (polling fails with "Session ID unknown" if we connect to a different instance)
    if (
      socket.io.engine.transport.name === 'polling' &&
      error &&
      error.type === 'TransportError' &&
      error.description === 400
    ) {
      socket.disconnect();

      setTimeout(() => this.createSocket(), POLLING_RECONNECT_INTERVAL);
    }

    // Allow some time to reconnect before displaying the error.
    setTimeout((): void => {
      if (!socket.connected) {
        this.displayError();
      }
    }, ERROR_SHOW_DELAY_ON_DISCONNECT);
  },

  addListener(listenerToAdd: Function): void {
    if (updateListeners.has(listenerToAdd)) {
      return;
    }

    updateListeners = updateListeners.add(listenerToAdd);
  },

  removeListener(listenerToRemove: Function): void {
    if (!updateListeners.has(listenerToRemove)) {
      return;
    }

    updateListeners = updateListeners.filter(listener => listener !== listenerToRemove);
  },

  notifyListeners(data: Object): void {
    updateListeners.forEach(listener => listener(data));
  },

  subscribe(ids: Array<number>, namespace: string): void {
    if (ids.length === 0) {
      return;
    }

    const nextSubscriptions = ids.reduce((result, id): Map<number, Set<string>> => {
      if (result.has(id)) {
        const idSubscribers = result.get(id);
        if (idSubscribers && idSubscribers.includes(namespace)) {
          return result;
        }
        return result.update(id, subscribers => subscribers.add(namespace));
      }

      return result.set(id, Set([namespace]));
    }, subscriptions);

    const newSubscriptions = nextSubscriptions.filter((value, id) => !subscriptions.has(id));

    if (newSubscriptions.size > 0) {
      this.handleSubscribing(newSubscriptions.keySeq().toJS());
    }

    subscriptions = nextSubscriptions;
  },

  handleSubscribing(idsToSubscribe: Array<number>) {
    chunk(idsToSubscribe, 100).forEach(ids => socket.emit('subscribe', { ids }));
  },

  unsubscribe(ids: Array<number>, namespace: string): void {
    if (ids.length === 0) {
      return;
    }

    subscriptions = ids.reduce((result, id): Map<number, Set<string>> => {
      const idSubscribers = result.get(id);
      if (idSubscribers && idSubscribers.includes(namespace)) {
        return result.update(id, subscribers => subscribers.delete(namespace));
      }

      return result;
    }, subscriptions);

    const idsToUnsubscribe = ids.filter((id): boolean => {
      const idSubscribers = subscriptions.get(id);

      return idSubscribers ? idSubscribers.size === 0 : false;
    });

    this.handleUnsubscribing(idsToUnsubscribe);
  },

  unsubscribeAllByNamespace(namespace: string): void {
    subscriptions = subscriptions.map((subscription): Set<string> => {
      if (subscription.includes(namespace)) {
        return subscription.delete(namespace);
      }

      return subscription;
    });

    const idsToUnsubscribe = Object.keys(
      subscriptions.filter((subscribers): boolean => subscribers.size === 0).toJS()
    ).map(id => parseInt(id, 10));

    this.handleUnsubscribing(idsToUnsubscribe);
  },

  handleUnsubscribing(idsToUnsubscribe: Array<number>): void {
    if (idsToUnsubscribe.length === 0) {
      return;
    }

    subscriptions = subscriptions.deleteAll(idsToUnsubscribe);

    socket.emit('unsubscribe', { ids: idsToUnsubscribe });
  },

  requestSkew(ts: number, callback: Function): void {
    if (!skewListeners.has(callback)) {
      skewListeners = skewListeners.add(callback);
    }

    socket.emit('skew', { ts });
  },

  notifySkewListeners(skew: number): void {
    skewListeners.forEach(listener => listener(skew));
    skewListeners = Set();
  },

  fetchUpdate(id: number): void {
    socket.emit('fetch', { ids: [id] });
  },

  ding(): void {
    socket.emit('ding', { ts: dayjs().valueOf() });
  },

  dong({ ts }: { ts: number }): void {
    const now = dayjs().valueOf();

    if (now - ts > MAX_LATENCY_ALLOWED) {
      this.displayError();
    }
  },
};
