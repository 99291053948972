import { List, Map } from 'immutable';
import dayjs from 'dayjs';

import feedbackService from '../src/net-auction/feedback-service';
import replyService from '../src/net-auction/reply-service';

const SAVE_NET_AUCTION_FEEDBACK = 'huutokaupat/net-auction/feedback/SAVE_NET_AUCTION_FEEDBACK';
const SAVE_NET_AUCTION_FEEDBACK_DONE = 'huutokaupat/net-auction/feedback/SAVE_NET_AUCTION_FEEDBACK_DONE';
const SAVE_NET_AUCTION_FEEDBACK_FAILED = 'huutokaupat/net-auction/feedback/SAVE_NET_AUCTION_FEEDBACK_FAILED';

const LOAD_NET_AUCTION_FEEDBACK = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK';
const LOAD_NET_AUCTION_FEEDBACK_DONE = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_DONE';
const LOAD_NET_AUCTION_FEEDBACK_FAILED = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_FAILED';

const LOAD_NET_AUCTION_FEEDBACKS = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACKS';
const LOAD_NET_AUCTION_FEEDBACKS_DONE = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACKS_DONE';
const LOAD_NET_AUCTION_FEEDBACKS_FAILED = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACKS_FAILED';

const LOAD_NET_AUCTION_FEEDBACK_SUMMARY = 'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_SUMMARY';
const LOAD_NET_AUCTION_FEEDBACK_SUMMARY_DONE =
  'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_SUMMARY_DONE';
const LOAD_NET_AUCTION_FEEDBACK_SUMMARY_FAILED =
  'huutokaupat/net-auction/feedback/LOAD_NET_AUCTION_FEEDBACK_SUMMARY_FAILED';

const SHOW_REPLY_TO_FIELD = 'huutokaupat/net-auction/feedback/SHOW_REPLY_TO_FIELD';
const HIDE_REPLY_TO_FIELD = 'huutokaupat/net-auction/feedback/HIDE_REPLY_TO_FIELD';

const SAVE_REPLY = 'huutokaupat/net-auction/feedback/SAVE_REPLY';
const SAVE_REPLY_DONE = 'huutokaupat/net-auction/feedback/SAVE_REPLY_DONE';
const SAVE_REPLY_FAILED = 'huutokaupat/net-auction/feedback/SAVE_REPLY_FAILED';

const initialState = new Map({
  loading: false,
  failed: false,
  feedbackTooOld: false,
  submittingExpired: false,
  feedbackNotFound: false,
  messageSent: false,
  message: null,
  rating: null,
  loadingFeedback: false,
  loadingFeedbacks: false,
  loadingFeedbacksFailed: false,
  loadingFeedbackSummary: false,
  loadingFeedbackSummaryFailed: false,
  feedbacks: new List(),
  feedbackSummary: new List(),
  savedRating: false,
  showSummary: true,
  showFeedbackList: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_NET_AUCTION_FEEDBACK:
      return state.set('loading', true).set('failed', false).set('feedbackNotFound', false);

    case LOAD_NET_AUCTION_FEEDBACK_DONE:
      return state.set('loading', false).set('feedback', action.payload.feedback);

    case SAVE_NET_AUCTION_FEEDBACK:
      return state.set('messageSent', false).set('loading', true).set('failed', false);

    case SAVE_NET_AUCTION_FEEDBACK_DONE:
      return state.set('messageSent', true).set('loading', false);

    case LOAD_NET_AUCTION_FEEDBACK_FAILED:
      return state
        .set('loading', false)
        .set('loadingFailed', true)
        .set('feedbackNotFound', action.payload.response.status === 404);

    case SAVE_NET_AUCTION_FEEDBACK_FAILED:
      return state
        .set('loading', false)
        .set('failed', true)
        .set('feedbackTooOld', !!action.payload.response.data.errors.date)
        .set('submittingExpired', !!action.payload.response.data.errors.submittingExpired);

    case LOAD_NET_AUCTION_FEEDBACKS:
      return state.set('loadingFeedbacks', true).set('loadingFeedbacksFailed', false);

    case LOAD_NET_AUCTION_FEEDBACKS_DONE:
      return state
        .set('loadingFeedbacks', false)
        .set('feedbacks', new List(action.payload))
        .set('showFeedbackList', true)
        .set('showSummary', false);

    case LOAD_NET_AUCTION_FEEDBACKS_FAILED:
      return state.set('loadingFeedbacks', false).set('loadingFeedbacksFailed', true);

    case LOAD_NET_AUCTION_FEEDBACK_SUMMARY:
      return state
        .set('loadingFeedbackSummary', true)
        .set('loadingFeedbackSummaryFailed', false)
        .set('showFeedbackList', false);

    case LOAD_NET_AUCTION_FEEDBACK_SUMMARY_DONE:
      return state
        .set('loadingFeedbackSummary', false)
        .set('feedbackSummary', new List(action.payload.summary))
        .set('publishingStarted', action.payload.publishingStarted)
        .set('showSummary', true);

    case LOAD_NET_AUCTION_FEEDBACK_SUMMARY_FAILED:
      return state.set('loadingFeedbackSummary', false).set('loadingFeedbackSummaryFailed', true);

    case HIDE_REPLY_TO_FIELD: {
      const list = state.get('feedbacks');
      const index = list.findIndex(item => item.uuid === action.payload);

      return state.set('updatedAt', dayjs()).updateIn(['feedbacks', index], feedback => ({
        ...feedback,
        isReplyToFieldVisible: false,
      }));
    }

    case SHOW_REPLY_TO_FIELD: {
      const list = state.get('feedbacks');
      const index = list.findIndex(item => item.uuid === action.payload);

      return state.set('updatedAt', dayjs()).updateIn(['feedbacks', index], feedback => ({
        ...feedback,
        isReplyToFieldVisible: true,
      }));
    }

    case SAVE_REPLY_DONE: {
      const list = state.get('feedbacks');
      const index = list.findIndex(item => item.uuid === action.payload.feedback.uuid);

      return state.set('updatedAt', dayjs()).updateIn(['feedbacks', index], feedback => ({
        ...feedback,
        reply: action.payload.feedback.reply,
        isReplyToFieldVisible: false,
      }));
    }

    default:
      return state;
  }
};

/**
 * This method has intentionally no error handling.
 *
 * @param {int} companyId
 */
export function markFeedbacksAsRead(companyId) {
  feedbackService.markFeedbacksAsRead(companyId);
}

export function loadFeedback(uuid) {
  return dispatch => {
    dispatch({ type: LOAD_NET_AUCTION_FEEDBACK });

    feedbackService
      .getFeedbackByUuid(uuid)
      .then(result => dispatch({ type: LOAD_NET_AUCTION_FEEDBACK_DONE, payload: result }))
      .catch(error => dispatch({ type: LOAD_NET_AUCTION_FEEDBACK_FAILED, payload: error }));
  };
}

export function loadFeedbacks(companyId) {
  return dispatch => {
    dispatch({ type: LOAD_NET_AUCTION_FEEDBACKS, payload: { companyId } });

    feedbackService
      .getFeedbacksByCompanyId(companyId)
      .then(result => dispatch({ type: LOAD_NET_AUCTION_FEEDBACKS_DONE, payload: result }))
      .catch(error => dispatch({ type: LOAD_NET_AUCTION_FEEDBACKS_FAILED, payload: error }));
  };
}

export function loadFeedbackSummary() {
  return dispatch => {
    dispatch({ type: LOAD_NET_AUCTION_FEEDBACK_SUMMARY });

    feedbackService
      .getFeedbackSummary()
      .then(result =>
        dispatch({
          type: LOAD_NET_AUCTION_FEEDBACK_SUMMARY_DONE,
          payload: {
            summary: result.summary,
            publishingStarted: result.publishing_started,
          },
        })
      )
      .catch(error =>
        dispatch({
          type: LOAD_NET_AUCTION_FEEDBACK_SUMMARY_FAILED,
          payload: error,
        })
      );
  };
}

export function saveFeedback(uuid, feedback) {
  return dispatch => {
    dispatch({ type: SAVE_NET_AUCTION_FEEDBACK, payload: feedback });

    return feedbackService
      .saveFeedback(uuid, feedback)
      .then(result => dispatch({ type: SAVE_NET_AUCTION_FEEDBACK_DONE, payload: result }))
      .catch(error => dispatch({ type: SAVE_NET_AUCTION_FEEDBACK_FAILED, payload: error }));
  };
}

export function showReplyToField(uuid) {
  return { type: SHOW_REPLY_TO_FIELD, payload: uuid };
}

export function hideReplyToField(uuid) {
  return { type: HIDE_REPLY_TO_FIELD, payload: uuid };
}

export function saveReply(reply) {
  return dispatch => {
    dispatch({ type: SAVE_REPLY });

    return replyService
      .saveReply(reply.uuid, reply.message)
      .then(result => dispatch({ type: SAVE_REPLY_DONE, payload: result }))
      .catch(error => dispatch({ type: SAVE_REPLY_FAILED, payload: error }));
  };
}
