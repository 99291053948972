// @flow

import createClient from '../api/client';

export const TEMPLATE_TYPES = {
  COMMISSION: 0,
  TEMPLATE: 1,
  IMPORTED_ENTRY: 2,
  VEHICLE_CONDITION_REPORT: 3,
};

export default {
  fetchTemplates: (companyId: number) =>
    createClient()
      .get(`/net-auction/templates?companyId=${companyId}`)
      .then(response => response.data),
  deleteTemplate: (templateId: number) =>
    createClient()
      .delete(`/net-auction/templates/${templateId}`)
      .then(response => response.data),
};
