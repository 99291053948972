import createClient from '../api/client';

export interface FrozenNetAuctionCompany {
  readonly id: number;
  readonly name: string;
}

export interface FrozenNetAuctionSeller {
  readonly id: number;
  readonly fullName: string;
}

export interface FrozenNetAuctionDelivery {
  readonly selectedMethod?: string;
  readonly handledAt: string;
}

export interface FrozenNetAuctionMoneyWithVat {
  readonly vatAmount: number;
  readonly amountWithoutVat: number;
  readonly vatPercent: number;
  readonly amount: number;
}

export interface FrozenNetAuctionBuyer {
  readonly id: number;
  readonly fullName: string;
  readonly email: string;
  readonly phone: string;
}

export interface FrozenNetAuction {
  readonly id: number;
  readonly title: string;
  readonly notesCount: number;
  readonly paymentDone: string;
  readonly company: FrozenNetAuctionCompany;
  readonly seller: FrozenNetAuctionSeller;
  readonly delivery: FrozenNetAuctionDelivery;
  readonly totalSum: FrozenNetAuctionMoneyWithVat;
  readonly buyer: FrozenNetAuctionBuyer;
  readonly isInReturn: boolean;
  readonly customerFundsFrozenAt: string;
  readonly frozenModerationStatus: number;
}

export interface CancelledCustomerFunds {
  readonly entryId: number;
  readonly customerFundsCancelledAt: string;
  readonly bankStatementRowCreatedAt: string;
  readonly amountDue: number;
  readonly company: { readonly id: string; readonly name: string };
  readonly bank: string;
}

export const fetchFrozenNetAuctions = async (companyId?: number) =>
  createClient()
    .get<FrozenNetAuction[]>('/net-auctions/frozen', {
      params: { companyId },
    })
    .then(response => response.data);

export const fetchNetAuctionsWithCancelledCustomerFunds = async () =>
  createClient()
    .get<CancelledCustomerFunds[]>('/net-auctions/customer-funds/cancelled')
    .then(response => response.data);

export const cancelNetAuctionCustomerFunds = async (entryId: number, message: string) =>
  createClient()
    .post<number>(`/net-auctions/customer-funds/${entryId}/cancel`, { message })
    .then(response => response.data);

export const unfreezeNetAuctionCustomerFunds = async ({ entryIds }: { readonly entryIds: number[] }) =>
  createClient()
    .patch<number[]>('/net-auctions/customer-funds/unfreeze', { entryIds })
    .then(response => response.data);

export const freezeNetAuctionCustomerFunds = async ({ entryId }: { readonly entryId: number }) =>
  createClient()
    .patch<number>(`/net-auctions/customer-funds/${entryId}/freeze`)
    .then(response => response.data);

export const updateNetAuctionModerationStatus = async (entryId: number, frozenModerationStatus: number) =>
  createClient()
    .patch<number[]>(`/net-auctions/frozen/${entryId}/frozen-moderation-status`, { frozenModerationStatus })
    .then(response => response.data);
