// @flow

import { List, Map } from 'immutable';
import companyGroupOAuthClientService from '../src/company-group/company-group-oauth-client-service';

const FETCH_OAUTH_CLIENTS = 'huutokaupat/company-oauth-client/FETCH_OAUTH_CLIENTS';
const FETCH_OAUTH_CLIENTS_DONE = 'huutokaupat/company-oauth-client/FETCH_OAUTH_CLIENTS_DONE';
const FETCH_OAUTH_CLIENTS_FAILED = 'huutokaupat/company-oauth-client/FETCH_OAUTH_CLIENTS_FAILED';

const CREATE_OAUTH_CLIENT = 'huutokaupat/company-oauth-client/CREATE_OAUTH_CLIENT';
const CREATE_OAUTH_CLIENT_DONE = 'huutokaupat/company-oauth-client/CREATE_OAUTH_CLIENT_DONE';
const CREATE_OAUTH_CLIENT_FAILED = 'huutokaupat/company-oauth-client/CREATE_OAUTH_CLIENT_FAILED';

const ON_CREATE_OAUTH_CLIENT_MODAL_CLOSE = 'huutokaupat/company-oauth-client/ON_CREATE_OAUTH_CLIENT_MODAL_CLOSE';

const DELETE_OAUTH_CLIENT = 'huutokaupat/company-oauth-client/DELETE_OAUTH_CLIENT';
const DELETE_OAUTH_CLIENT_DONE = 'huutokaupat/company-oauth-client/DELETE_OAUTH_CLIENT_DONE';
const DELETE_OAUTH_CLIENT_FAILED = 'huutokaupat/company-oauth-client/DELETE_OAUTH_CLIENT_FAILED';

const initialState = Map({
  oauthClients: List(),
  createdOAuthClient: {},
  failed: false,
  loading: false,
  creatingOAuthClient: false,
});

// Needed to preserve front end 'deleting' statuses when updating OAuthClients after delete.
function filterFrontEndOAuthClients(oauthClients: List<Object>, frontEndOAuthClients: List<Object>): List<Object> {
  return frontEndOAuthClients.filter(
    (frontEndOAuthClient): boolean => oauthClients.findIndex(item => item.id === frontEndOAuthClient.id) !== -1
  );
}

function updateOAuthClient(oauthClients: List<Object>, oauthClientId: number, props: Object): List<Object> {
  const index = oauthClients.findIndex(item => item.id === oauthClientId);

  if (index === -1) {
    return oauthClients;
  }

  return oauthClients.updateIn([index], oauthClient => ({
    ...oauthClient,
    ...props,
  }));
}

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_OAUTH_CLIENTS:
      return state.set('loading', true).set('failed', false).set('oauthClients', List());

    case FETCH_OAUTH_CLIENTS_DONE:
      return state.set('loading', false).set('failed', false).set('oauthClients', List(action.payload));

    case FETCH_OAUTH_CLIENTS_FAILED:
      return state.set('loading', false).set('failed', true);

    case CREATE_OAUTH_CLIENT:
      return state.set('creatingOAuthClient', true);

    case CREATE_OAUTH_CLIENT_DONE:
      return state
        .set('creatingOAuthClient', false)
        .set('failed', false)
        .set('oauthClients', List(action.payload.oauthClients))
        .set('createdOAuthClient', action.payload.createdOAuthClient);

    case CREATE_OAUTH_CLIENT_FAILED:
      return state.set('creatingOAuthClient', false).set('failed', true);

    case ON_CREATE_OAUTH_CLIENT_MODAL_CLOSE:
      return state.set('createdOAuthClient', {});

    case DELETE_OAUTH_CLIENT:
      return state.set(
        'oauthClients',
        updateOAuthClient(state.get('oauthClients', List()), action.payload, {
          deletingOAuthClient: true,
        })
      );

    case DELETE_OAUTH_CLIENT_DONE:
      return state
        .set('failed', false)
        .set('oauthClients', filterFrontEndOAuthClients(List(action.payload), state.get('oauthClients', List())));

    case DELETE_OAUTH_CLIENT_FAILED:
      return state
        .set(
          'oauthClients',
          updateOAuthClient(state.get('oauthClients', List()), action.payload, {
            deletingOAuthClient: false,
          })
        )
        .set('failed', true);

    default:
      return state;
  }
};

export function fetchOAuthClients(companyGroupId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_OAUTH_CLIENTS });

    return companyGroupOAuthClientService
      .fetchCompanyGroupOAuthClients(companyGroupId)
      .then(oauthClients => dispatch({ type: FETCH_OAUTH_CLIENTS_DONE, payload: oauthClients }))
      .catch(error => dispatch({ type: FETCH_OAUTH_CLIENTS_FAILED, payload: error }));
  };
}

export function deleteOAuthClient(companyGroupId: number, oauthClientId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: DELETE_OAUTH_CLIENT, payload: oauthClientId });

    return companyGroupOAuthClientService
      .deleteCompanyGroupOAuthClient(companyGroupId, oauthClientId)
      .then(oauthClients => dispatch({ type: DELETE_OAUTH_CLIENT_DONE, payload: oauthClients }))
      .catch(() => dispatch({ type: DELETE_OAUTH_CLIENT_FAILED, payload: oauthClientId }));
  };
}

export function createOAuthClient(companyGroupId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CREATE_OAUTH_CLIENT });

    return companyGroupOAuthClientService
      .createCompanyGroupOAuthClient(companyGroupId)
      .then(response => dispatch({ type: CREATE_OAUTH_CLIENT_DONE, payload: response }))
      .catch(error => dispatch({ type: CREATE_OAUTH_CLIENT_FAILED, payload: error }));
  };
}

export function onCreateOAuthClientModalClose(): Function {
  return (dispatch: Function): void => {
    dispatch({ type: ON_CREATE_OAUTH_CLIENT_MODAL_CLOSE });
  };
}
