import React from 'react';

const STATE_ERROR = 'error';
const STATE_WARNING = 'warning';
const STATE_SUCCESS = 'success';

const getViolation = (errors, warnings) => {
  if (errors) {
    return {
      state: STATE_ERROR,
      messages: errors.constructor === Array ? errors : [errors],
    };
  }

  if (warnings) {
    return {
      state: STATE_WARNING,
      messages: warnings.constructor === Array ? warnings : [warnings],
    };
  }

  return { state: STATE_SUCCESS, messages: false };
};

const violationMessage = violation => (
  <ul className={`violations violations-${violation.state}`}>
    {violation.messages.map((message, key) => (
      <li key={key}>{message}</li>
    ))}
  </ul>
);

export default function (errors, warnings, show) {
  const violation = getViolation(errors, warnings);

  return {
    state: show ? violation.state : null,
    message: show && violation.messages ? violationMessage(violation) : null,
  };
}
