import { Box, BoxProps } from '@mezzoforte/forge';
import useScrollClamp from './hooks/useScrollClamp';

interface Props extends BoxProps {
  height: number;
  topOffset: number;
}
export default function HidingStickyContainer({ background, height, topOffset, ...props }: Props) {
  const [top, prevScrollY] = useScrollClamp(height);
  const stickyHideMode = prevScrollY > topOffset + top;

  return (
    <Box
      className="stickhide"
      pt={{ base: 0, lg: height }}
      position={{ base: 'static', lg: 'relative' }}
      background={background}
    >
      <Box
        {...props}
        position={{ base: 'static', lg: stickyHideMode ? 'fixed' : 'absolute' }}
        transform={{
          base: undefined,
          lg: `translate3d(0px, ${stickyHideMode ? (top / height) * -100 : 0}%, 0px)`,
        }}
        top={0}
        background={background}
      />
    </Box>
  );
}
