import dayjs from 'dayjs';
import createClient from '../api/client';

export const entryStatuses = {
  UNPUBLISHED: 'U',
  PUBLISHED: 'P',
  ENDED: 'E',
  CANCELLED: 'C',
};

// This reason does not cancel, but starts the runner up offer process instead.
export const CANCELLATION_REASON_RUNNER_UP_OFFER = -1;

export const cancellationReasons = Object.freeze({
  CANCELLATION_REASON_OTHER: 1,
  CANCELLATION_REASON_RECLAMATION: 2,
  CANCELLATION_REASON_BUYER_CANCEL: 3,
  CANCELLATION_REASON_RUNNER_UP_CANCEL: 4,
  CANCELLATION_REASON_CUSTOMER_FUNDS_CANCEL: 5,
  CANCELLATION_REASON_RISK_FREE: 6,
  CANCELLATION_REASON_CONDITION: 7,
  CANCELLATION_REASON_MISTAKE: 8,
  CANCELLATION_REASON_UNPUBLISHED: 9,
});

export default {
  // entryId: string, type: string
  incrementContactStats: (entryId, type) =>
    createClient()
      .post(`/entries/${entryId}/contact-statistics`, { entryId, type })
      .then(response => response.data),

  fetchContactStats: (entryId, type) =>
    createClient()
      .get(`/entries/${entryId}/contact-statistics/${type}`)
      .then(response => response.data),
  // entry: Entry
  isPublished: entry => entry.status === entryStatuses.PUBLISHED,

  isCancelled: entry => !!(entry.status === entryStatuses.CANCELLED || entry.cancel_date || entry.cancelDate),

  isWaitingForPublishing: (entry, now = dayjs()) =>
    !!(entry.publishable_after && dayjs(entry.publishable_after).isAfter(dayjs(now))),
};
