// @flow

import { Map } from 'immutable';
import salesContactService from '../src/sales-contact/sales-contact-service';

const OPEN_SALES_CONTACT_MODAL = 'huutokaupat/sales-contact/OPEN_SALES_CONTACT_MODAL';
const CLOSE_SALES_CONTACT_MODAL = 'huutokaupat/sales-contact/CLOSE_SALES_CONTACT_MODAL';

const SUBMIT_SALES_CONTACT = 'huutokaupat/sales-contact/SUBMIT_SALES_CONTACT';
const SUBMIT_SALES_CONTACT_DONE = 'huutokaupat/sales-contact/SUBMIT_SALES_CONTACT_DONE';
const SUBMIT_SALES_CONTACT_FAILED = 'huutokaupat/sales-contact/SUBMIT_SALES_CONTACT_FAILED';

const initialState = Map({
  open: false,
  loading: false,
  failed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case OPEN_SALES_CONTACT_MODAL:
      return state.set('open', true);

    case CLOSE_SALES_CONTACT_MODAL:
      return state.set('open', false);

    case SUBMIT_SALES_CONTACT:
      return state.set('open', false).set('loading', true).set('failed', false);

    case SUBMIT_SALES_CONTACT_DONE:
      return state.set('loading', false);

    case SUBMIT_SALES_CONTACT_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function openSalesContactModal(): Object {
  return { type: OPEN_SALES_CONTACT_MODAL };
}

export function closeSalesContactModal(): Object {
  return { type: CLOSE_SALES_CONTACT_MODAL };
}

export function submitSalesContact(contactSubject: string, answers: string, contactInfo: Object = {}): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: SUBMIT_SALES_CONTACT });

    return salesContactService.submit(contactSubject, answers, contactInfo).then(
      response =>
        dispatch({
          type: SUBMIT_SALES_CONTACT_DONE,
          payload: response.success,
        }),
      error => dispatch({ type: SUBMIT_SALES_CONTACT_FAILED, payload: error })
    );
  };
}
