// @flow

import { List, Map } from 'immutable';

import feedbackService from '../src/net-auction/feedback-service';

const FETCH_USER_FEEDBACKS = 'huutokaupat/user-feedback/FETCH_USER_FEEDBACKS';
const FETCH_USER_FEEDBACKS_DONE = 'huutokaupat/user-feedback/FETCH_USER_FEEDBACKS_DONE';
const FETCH_USER_FEEDBACKS_FAILED = 'huutokaupat/user-feedback/FETCH_USER_FEEDBACKS_FAILED';

const initialState = Map({
  loading: false,
  loadFailed: false,
  user: null,
  feedbacks: List(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_USER_FEEDBACKS:
      return state.set('loading', true).set('loadFailed', false).set('user', null).set('feedbacks', List());

    case FETCH_USER_FEEDBACKS_DONE: {
      return state
        .set('loading', false)
        .set('loadFailed', false)
        .set('user', action.payload.user)
        .set('feedbacks', List(action.payload.feedbacks));
    }

    case FETCH_USER_FEEDBACKS_FAILED:
      return state.set('loading', false).set('loadFailed', true).set('user', null).set('feedbacks', List());

    default:
      return state;
  }
};

export function fetchUserFeedbacks(userId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_USER_FEEDBACKS });

    return feedbackService.fetchUserFeedbacks(userId).then(
      feedbacks => dispatch({ type: FETCH_USER_FEEDBACKS_DONE, payload: feedbacks }),
      () => dispatch({ type: FETCH_USER_FEEDBACKS_FAILED })
    );
  };
}
