// @flow

import createClient from '../api/client';

export default {
  saveContactMessage: (entryId: number, contactUuid: string, message: string): Promise<any> =>
    createClient()
      .post(`/net-auctions/${entryId}/contact-messages`, {
        entryId,
        contactUuid,
        message,
      })
      .then(
        response => response.data,
        error => error
      ),

  getContactMessages: (companyId: number, entryId: ?number) =>
    createClient()
      .get(`/net-auctions/contact-messages?companyId=${companyId}&entryId=${String(entryId)}`)
      .then(
        response => response.data,
        error => error
      ),
};
