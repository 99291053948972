import React, { useState } from 'react';
import { Flex } from '@mezzoforte/forge';
import { categoriesStaticData } from '../util/category';
import { useCategories } from '../hooks/useCategories';
import AccordionItem from './AccordionItem';
import { splitToChunks } from '../util/array';
import {
  BarchartDown,
  Bank,
  Metsahallitus,
  Package,
  Puolustusvoimat,
  Scale,
  City,
  HourglassHigh,
  Finland,
  Icon,
  Megaphone,
} from '@mezzoforte/forge-icons';

export interface CategoryAccordionSubItem {
  readonly name: string;
  readonly href: string;
}

export interface CategoryAccordionItem {
  readonly name: string;
  readonly icon: React.ComponentType<React.ComponentProps<Icon>>;
  readonly href: string;
  readonly children?: CategoryAccordionSubItem[];
}

const CategoryAccordion = () => {
  const [accordionItemOpen, setAccordionItemOpen] = useState<string | null>(null);
  const { data } = useCategories();

  if (!data) return null;

  const items: CategoryAccordionItem[] = [
    { name: 'Maakunnittain', icon: Finland, href: '/maakunnat' },
    ...data.categories.map(({ id, name, children }) => ({
      name,
      icon: categoriesStaticData.get(id)?.icon ?? Package, // Default for categories not supported by frontend
      href: `/osasto/${id}/${categoriesStaticData.get(id)?.slug ?? ''}`,
      children:
        Object.entries(children).length > 0
          ? Object.entries(children)
              .sort(([_idA, a], [_idB, b]) => a.order - b.order)

              .map(([id, child]) => ({
                name: child.name,
                href: `/osasto/${id}/${categoriesStaticData.get(Number(id))?.slug ?? ''}`,
              }))
          : undefined,
    })),
    {
      name: 'Perinteiset huutokaupat',
      icon: Megaphone,
      href: '/perinteiset-huutokaupat',
    },
    {
      name: 'Ulosotto',
      icon: Scale,
      href: '/ilmoittaja/ulosotto',
    },
    {
      name: 'Konkurssi&shy;pesät',
      icon: BarchartDown,
      href: '/ilmoittaja/konkurssipesat',
    },
    {
      name: 'Puolustus&shy;voimat',
      icon: Puolustusvoimat,
      href: '/ilmoittaja/puolustusvoimat',
    },
    {
      name: 'Metsä&shy;hallitus',
      icon: Metsahallitus,
      href: '/ilmoittaja/metsahallitus',
    },
    {
      name: 'Rahoitus&shy;yhtiöt',
      icon: Bank,
      href: '/ilmoittaja/rahoitusyhtiot',
    },
    {
      name: 'Julkinen sektori',
      icon: City,
      href: '/ilmoittaja/julkinen-sektori',
    },
    {
      name: 'Päättyvät',
      icon: HourglassHigh,
      href: '/osasto',
    },
  ];

  return (
    <Flex as="ul" p={0} flexDirection={{ base: 'column', lg: 'row' }} style={{ columnGap: '24px' }}>
      {splitToChunks(items, 3).map((itemChunk, index) => (
        <Flex key={index} listStyleType="none" flexDirection="column" flexGrow={1} width={{ lg: '310px', xl: '350px' }}>
          {itemChunk.map(item => (
            <AccordionItem
              key={item.name}
              item={item}
              toggleOpen={() => setAccordionItemOpen(accordionItemOpen !== item.name ? item.name : null)}
              isOpen={accordionItemOpen === item.name}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default CategoryAccordion;
