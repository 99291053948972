// @flow

import { List, Map } from 'immutable';
import dayjs from 'dayjs';
import netAuctionService from '../src/net-auction/net-auction-service';

const FETCH_ENTRIES_FOR_MODERATION = 'huutokaupat/entry-moderation/FETCH_ENTRIES_FOR_MODERATION';
const FETCH_ENTRIES_FOR_MODERATION_DONE = 'huutokaupat/entry-moderation/FETCH_ENTRIES_FOR_MODERATION_DONE';
const FETCH_ENTRIES_FOR_MODERATION_FAILED = 'huutokaupat/entry-moderation/FETCH_ENTRIES_FOR_MODERATION_FAILED';

const SAVE_ENTRIES_FOR_MODERATION = 'huutokaupat/entry-moderation/SAVE_ENTRIES_FOR_MODERATION';
const SAVE_ENTRIES_FOR_MODERATION_DONE = 'huutokaupat/entry-moderation/SAVE_ENTRIES_FOR_MODERATION_DONE';
const SAVE_ENTRIES_FOR_MODERATION_FAILED = 'huutokaupat/entry-moderation/SAVE_ENTRIES_FOR_MODERATION_FAILED';

const initialState = Map({
  loading: false,
  failed: false,
  entriesForModeration: List(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_ENTRIES_FOR_MODERATION:
      return state
        .set('loading', true)
        .set('loadFailed', false)
        .set('saveFailed', false)
        .set('entriesForModeration', List());

    case FETCH_ENTRIES_FOR_MODERATION_DONE:
      return state.set('loading', false).set('loadFailed', false).set('entriesForModeration', List(action.payload));

    case FETCH_ENTRIES_FOR_MODERATION_FAILED:
      return state.set('loading', false).set('loadFailed', true).set('entriesForModeration', List());

    case SAVE_ENTRIES_FOR_MODERATION:
      return state.set('saveFailed', false);

    case SAVE_ENTRIES_FOR_MODERATION_FAILED:
      return state.set('loading', false).set('saveFailed', true);

    case SAVE_ENTRIES_FOR_MODERATION_DONE: {
      const list = state.get('entriesForModeration', List());
      const index = list.findIndex(item => item.entry_id === action.payload.entryId);

      return state
        .updateIn(['entriesForModeration', index], moderatedEntry => ({
          ...moderatedEntry,
          moderation_status: action.payload.status,
          moderated_at: dayjs(action.payload.moderatedAt),
        }))
        .set('loading', false)
        .set('saveFailed', false);
    }

    default:
      return state;
  }
};

export function saveModerationStatus(entryId: number, status: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: SAVE_ENTRIES_FOR_MODERATION });

    return netAuctionService.updateModerationStatus(entryId, status).then(
      data =>
        dispatch({
          type: SAVE_ENTRIES_FOR_MODERATION_DONE,
          payload: { entryId, status, moderatedAt: data.moderatedAt },
        }),
      error => dispatch({ type: SAVE_ENTRIES_FOR_MODERATION_FAILED, payload: error })
    );
  };
}

export function fetchEntriesForModeration(): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_ENTRIES_FOR_MODERATION });

    return netAuctionService.fetchEntriesForModeration().then(
      entriesForModeration =>
        dispatch({
          type: FETCH_ENTRIES_FOR_MODERATION_DONE,
          payload: entriesForModeration,
        }),
      error => dispatch({ type: FETCH_ENTRIES_FOR_MODERATION_FAILED, payload: error })
    );
  };
}
