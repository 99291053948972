import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Flex, Link, Text, useTokens, VisuallyHidden } from '@mezzoforte/forge';
import { CategoryAccordionItem } from './CategoryAccordion';
import { CaretDown, CaretUp, IconContext } from '@mezzoforte/forge-icons';

const { breakpoints, colors, fonts, fontSizes, fontWeights, lineHeights } = useTokens.huutokaupat;

interface AccordionItemProps {
  readonly item: CategoryAccordionItem;
  readonly toggleOpen: () => void;
  readonly isOpen: boolean;
}

const AccordionItem = ({ item, isOpen, toggleOpen, ...props }: AccordionItemProps) => (
  <MenuItem as="li" lineHeight="inherit" margin={0} borderBottom="1px solid" borderColor={colors.lineBorder} {...props}>
    <LinkItem
      alignItems="center"
      width="100%"
      transition="all 0.2s"
      outline={0}
      padding={2}
      background="transparent"
      border={0}
      justifyContent="space-between"
      minHeight="58px"
    >
      <Link href={item.href} height="100%" width="100%" fontFamily={fonts.heading} _hover={{ textDecoration: 'none' }}>
        <Flex alignItems="center" textAlign="left">
          <Box width="50px">
            <item.icon weight="light" size={32} />
          </Box>
          <Text
            fontFamily="inherit"
            fontSize={fontSizes.button}
            fontWeight={fontWeights.button}
            lineHeight={lineHeights.button}
            dangerouslySetInnerHTML={{ __html: item.name }}
          />
        </Flex>
      </Link>
      {item.children ? (
        <IconButton onClick={toggleOpen} borderStyle="none" p={2}>
          <IconContext.Provider value={{ size: 16, color: colors.text }}>
            {isOpen ? <CaretUp /> : <CaretDown />}
          </IconContext.Provider>
          <VisuallyHidden>{isOpen ? 'Piilota' : 'Näytä'} alaosastot</VisuallyHidden>
        </IconButton>
      ) : null}
    </LinkItem>
    {item.children && isOpen ? (
      <Flex as="ul" listStyleType="none" direction="column" pl={0} pb={3} pt={0}>
        {item.children.map(child => (
          <Link href={child.href} key={child.name} fontFamily={fonts.heading} _hover={{ textDecoration: 'none' }}>
            <AccordionSubItem as="li" key={child.name} py={2} pl={59} m={0}>
              <Text
                fontFamily="inherit"
                fontSize={fontSizes.button}
                fontWeight={fontWeights.button}
                lineHeight={lineHeights.button}
                dangerouslySetInnerHTML={{ __html: child.name }}
              />
            </AccordionSubItem>
          </Link>
        ))}
      </Flex>
    ) : null}
  </MenuItem>
);

export default AccordionItem;

const MenuItem = styled(Box)`
  @media screen and (min-width: ${breakpoints.lg}) {
    &:last-child {
      border: none;
    }
  }
`;

const LinkItem = styled(Flex)`
  color: ${colors.text};

  :has(a:hover) {
    background-color: ${colors.backgroundGray};
    text-decoration: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const IconButton = styled(Button)`
  :hover {
    background-color: ${colors.backgroundGray};
  }
  svg {
    pointer-events: none;
  }
`;

const AccordionSubItem = styled(Box)`
  color: ${colors.text};

  :hover {
    background-color: ${colors.backgroundGray};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
