import { List } from 'immutable';
import manufacturers from './manufacturers.json';
import * as categories from './categories';

type Manufacturers = keyof typeof manufacturers;

interface ManufacturersGroupSelectType {
  readonly label: string;
  readonly options: { readonly label: string; readonly value: string }[];
}

const otherBrandManufacturer: ManufacturersGroupSelectType[] = [
  { label: '', options: [{ label: 'Muu merkki', value: 'Muu merkki' }] },
];

export default {
  getManufacturersByCategoryId(categoryId: number) {
    const manufacturerTypeMap: Record<number, Manufacturers> = {
      [categories.CATEGORY_PASSENGER_CAR]: 'passengerCar',
      [categories.CATEGORY_VAN]: 'passengerCar',
      [categories.CATEGORY_TRUCK]: 'truck',
      [categories.CATEGORY_HEAVY_EQUIPMENT]: 'truck',
      [categories.CATEGORY_CONSTRUCTION_MACHINERY]: 'machinery',
      [categories.CATEGORY_FARMING_MACHINERY]: 'machinery',
      [categories.CATEGORY_FOREST_MACHINERY]: 'machinery',
      [categories.CATEGORY_OTHER_MACHINERY]: 'machinery',
    };

    const manufacturerType = manufacturerTypeMap[categoryId] || null;

    if (!manufacturerType) {
      return [];
    }
    return manufacturers[manufacturerType];
  },

  groupManufacturersForSelect: (manufacturersToGroup: List<string>) =>
    manufacturersToGroup.reduce<ManufacturersGroupSelectType[]>((result, manufacturer) => {
      // Avoid showing other manufacturer option twice
      if (manufacturer === 'Muu merkki') {
        return result;
      }

      const initialLetter = manufacturer.charAt(0).toUpperCase();
      const foundGroupIndex = result.findIndex(item => item.label === initialLetter);

      if (foundGroupIndex !== -1) {
        const newOpts = [...result[foundGroupIndex].options, { label: manufacturer, value: manufacturer }];
        return [...result.slice(0, foundGroupIndex), { label: result[foundGroupIndex].label, options: newOpts }];
      }
      // avoid mutable error with
      return [...result, { label: initialLetter, options: [{ label: manufacturer, value: manufacturer }] }];
    }, otherBrandManufacturer),
};
