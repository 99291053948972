import { List, Map } from 'immutable';

import companyService from '../src/company/company-service';
import feedbackService from '../src/net-auction/public-feedback-service';

const LOAD_COMPANY_AND_FEEDBACKS = 'huutokaupat/net-auction/feedback/public/LOAD_COMPANY_AND_FEEDBACKS';
const LOAD_COMPANY_AND_FEEDBACKS_DONE = 'huutokaupat/net-auction/feedback/public/LOAD_COMPANY_AND_FEEDBACKS_DONE';
const LOAD_COMPANY_AND_FEEDBACKS_FAILED = 'huutokaupat/net-auction/feedback/public/LOAD_COMPANY_AND_FEEDBACKS_FAILED';

const createErrorMessage = responseCode => {
  switch (responseCode) {
    case '404':
      return 'Tietoja ei löytynyt. Ole hyvä ja tarkista osoite';
    default:
      return 'Tietoja ei voitu hakea virheen takia. Ole hyvä ja yritä uudestaan hetken päästä.';
  }
};

const initialState = new Map({
  company: null,
  loadingInfo: false,
  feedbacks: List(),
  feedbackCount: null,
  error: null,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_COMPANY_AND_FEEDBACKS:
      return state.set('company', null).set('feedbacks', List()).set('loadingInfo', true).set('error', null);

    case LOAD_COMPANY_AND_FEEDBACKS_DONE:
      return state
        .set('loadingInfo', false)
        .set('company', action.payload.company)
        .set('feedbackCount', action.payload.feedbackCount)
        .set('feedbacks', List(action.payload.feedbacks));

    case LOAD_COMPANY_AND_FEEDBACKS_FAILED:
      return state.set('loadingInfo', false).set('feedbacks', List()).set('error', createErrorMessage(action.payload));

    default:
      return state;
  }
};

export function loadCompanyAndFeedbacks(slug) {
  return dispatch => {
    dispatch({ type: LOAD_COMPANY_AND_FEEDBACKS });

    return companyService
      .fetchPublicInfoWithSlug(slug)
      .then(company => Promise.all([feedbackService.getFeedbacksByCompanyId(company.id), company]))
      .then(([data, company]) => {
        dispatch({
          type: LOAD_COMPANY_AND_FEEDBACKS_DONE,
          payload: {
            company,
            feedbackCount: data.feedbackCount,
            feedbacks: data.feedbacks,
          },
        });
      })
      .catch(error => dispatch({ type: LOAD_COMPANY_AND_FEEDBACKS_FAILED, payload: error }));
  };
}
