import { useEffect } from 'react';

export function useClickOutside<T extends HTMLElement>(ref: React.RefObject<T>, onClickOut: () => void) {
  useEffect(() => {
    const element = ref?.current;
    function onClick({ target }: Event) {
      if (element && !element.contains(target as Node | null)) {
        onClickOut();
      }
    }

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [ref, onClickOut]);
}
