import React from 'react';
import { Alert } from 'react-bootstrap';
import { Box } from '@mezzoforte/forge';

export interface LoginErrorProps {
  readonly isVisible: boolean;
  readonly title: string;
  readonly children: React.ReactNode;
}

export function LoginError({ isVisible, title, children }: LoginErrorProps) {
  if (!isVisible) {
    return null;
  }

  return (
    <Alert bsStyle="danger">
      <Box>
        <p>
          <b>{title}</b>
        </p>

        {children}
      </Box>
    </Alert>
  );
}
