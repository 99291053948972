// @flow

import { Map } from 'immutable';
import companyGroupService from '../src/company-group/company-group-service';

const FETCH_COMPANY_GROUP = 'huutokaupat/company-oauth-client/FETCH_COMPANY_GROUP';
const FETCH_COMPANY_GROUP_DONE = 'huutokaupat/company-oauth-client/FETCH_COMPANY_GROUP_DONE';
const FETCH_COMPANY_GROUP_FAILED = 'huutokaupat/company-oauth-client/FETCH_COMPANY_GROUP_FAILED';

const UPDATE_COMPANY_GROUP = 'huutokaupat/company-oauth-client/UPDATE_COMPANY_GROUP';
const UPDATE_COMPANY_GROUP_DONE = 'huutokaupat/company-oauth-client/UPDATE_COMPANY_GROUP_DONE';
const UPDATE_COMPANY_GROUP_FAILED = 'huutokaupat/company-oauth-client/UPDATE_COMPANY_GROUP_FAILED';

const initialState = Map({
  companyGroup: {},
  failed: false,
  loading: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_COMPANY_GROUP:
      return state.set('loading', true).set('failed', false).set('companyGroup', {});

    case FETCH_COMPANY_GROUP_DONE:
      return state.set('loading', false).set('failed', false).set('companyGroup', action.payload);

    case FETCH_COMPANY_GROUP_FAILED:
      return state.set('loading', false).set('failed', true);

    case UPDATE_COMPANY_GROUP_DONE:
      return state.set('failed', false).set('companyGroup', action.payload);

    case UPDATE_COMPANY_GROUP_FAILED:
      return state.set('failed', true);

    default:
      return state;
  }
};

export function fetchCompanyGroup(companyGroupId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_COMPANY_GROUP });

    return companyGroupService
      .fetchCompanyGroup(companyGroupId)
      .then(companyGroup => dispatch({ type: FETCH_COMPANY_GROUP_DONE, payload: companyGroup }))
      .catch(error => dispatch({ type: FETCH_COMPANY_GROUP_FAILED, payload: error }));
  };
}

export function updateCompanyGroup(companyGroupId: number, data: Object): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPDATE_COMPANY_GROUP });

    return companyGroupService
      .updateCompanyGroup(companyGroupId, data)
      .then(companyGroup => dispatch({ type: UPDATE_COMPANY_GROUP_DONE, payload: companyGroup }))
      .catch(error => dispatch({ type: UPDATE_COMPANY_GROUP_FAILED, payload: error }));
  };
}
