import React, { forwardRef, useState, type ChangeEventHandler } from 'react';
import { Box, VisuallyHidden, SearchInput, SearchInputProps } from '@mezzoforte/forge';
import { gtmService } from '@app/src/analytics/gtm-service';

export interface SearchFormProps {
  placeholder?: string;
  width?: SearchInputProps['width'];
}

const Component = forwardRef<HTMLInputElement, SearchFormProps>(({ placeholder, width = 260 }, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const onChange: ChangeEventHandler<HTMLInputElement> = event => setSearchTerm(event.currentTarget.value);
  const clearSearch = () => setSearchTerm('');

  return (
    <form action="/haku" onSubmit={() => gtmService.recommended.search(searchTerm)}>
      <Box as="label" m={0}>
        <VisuallyHidden>Hae sivustolta</VisuallyHidden>
        <SearchInput
          id="search-input"
          ref={ref}
          name="term"
          label=""
          onChange={onChange}
          onClickReset={clearSearch}
          value={searchTerm}
          placeholder={placeholder}
          width={width}
        />
      </Box>
    </form>
  );
});

Component.displayName = 'SearchForm';

export const SearchForm = Component;
