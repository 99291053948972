// @flow

import createClient from '../api/client';

export default {
  fetchCompanyGroupOAuthClients: (companyGroupId: number) =>
    createClient()
      .get(`/company-groups/${companyGroupId}/oauth-clients`)
      .then(response => response.data),

  deleteCompanyGroupOAuthClient: (companyGroupId: number, oauthClientId: number) =>
    createClient()
      .delete(`/company-groups/${companyGroupId}/oauth-clients/${oauthClientId}`)
      .then(response => response.data),

  createCompanyGroupOAuthClient: (companyGroupId: number) =>
    createClient()
      .post(`/company-groups/${companyGroupId}/oauth-clients`)
      .then(response => response.data),
};
