import { Dispatch } from 'redux';
import jwt, { AuthUser } from '../src/security/jwt';

const AuthDone = 'huutokaupat/auth/AUTH_DONE';

export interface AuthReducerState {
  readonly token: string | null;
  readonly user: AuthUser | null;
  readonly failed: boolean;
  readonly loading: boolean;
  readonly removing: boolean;
}

type AuthReducerAction = AuthDoneAction;

export interface AuthDoneAction {
  readonly type: typeof AuthDone;
  readonly payload: string;
}

const initialToken = jwt.getToken();

const initialState: AuthReducerState = {
  removing: false,
  loading: false,
  failed: false,
  token: initialToken,
  user: jwt.getUser(),
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Reducer.
  state: AuthReducerState = initialState,
  action: AuthReducerAction
): AuthReducerState => {
  switch (action.type) {
    case AuthDone:
      return {
        ...state,
        loading: false,
        failed: false,
        token: action.payload,
        user: jwt.getUser(),
      };
    default:
      return state;
  }
};

export const refresh = () => async (dispatch: Dispatch) => {
  await jwt.requestTokenWithSession();

  dispatch({ type: AuthDone, payload: jwt.getToken() });
};
