// @flow

import { List, Map } from 'immutable';

import userService from '../src/user/user-service';

const FETCH_WON_ENTRIES = 'huutokaupat/user/FETCH_WON_ENTRIES';
const FETCH_WON_ENTRIES_DONE = 'huutokaupat/user/FETCH_WON_ENTRIES_DONE';
const FETCH_WON_ENTRIES_FAILED = 'huutokaupat/user/FETCH_WON_ENTRIES_FAILED';

const initialState = Map({
  wonEntries: List(),
  fetchingWonEntries: false,
  failedToFetchWonEntries: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_WON_ENTRIES:
      return state.set('fetchingWonEntries', true).set('failedToFetchWonEntries', false);

    case FETCH_WON_ENTRIES_DONE:
      return state
        .set('fetchingWonEntries', false)
        .set('failedToFetchWonEntries', false)
        .set('wonEntries', List(action.payload));

    case FETCH_WON_ENTRIES_FAILED:
      return state.set('fetchingWonEntries', false).set('failedToFetchWonEntries', true).set('error', action.payload);

    default:
      return state;
  }
};

export function fetchWonEntries(id: number, type: ?string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_WON_ENTRIES });

    return userService.fetchWonEntries(id, type).then(
      entries => dispatch({ type: FETCH_WON_ENTRIES_DONE, payload: entries }),
      error => dispatch({ type: FETCH_WON_ENTRIES_FAILED, payload: error })
    );
  };
}
