import createClient from '../api/client';

export default {
  storeUserDetails: userDetails =>
    createClient()
      .patch('/user-details', userDetails)
      .then(response => response.data),

  fetchUserDetails: () =>
    createClient()
      .get('/user-details')
      .then(response => response.data),
};
