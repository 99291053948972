import { formatNumber, unformat } from 'accounting';

export function formatValue(value: number | string, thousand = ' ', decimal = ',') {
  return formatNumber(Number(value), 2, thousand, decimal);
}

export function parseValue(value: number | string, decimal = ',') {
  if (typeof value === 'number') {
    return value;
  }
  return unformat(value, decimal);
}

export function simplifyCurrency(value: string | number) {
  const formattedValue = formatValue(value);

  if (!formattedValue.includes(',')) {
    return formattedValue;
  }

  return formattedValue.replace(/,00$/, '');
}

export function simplifyPercent(value: string | number) {
  const formattedValue = formatValue(value);

  if (!formattedValue.includes(',')) {
    return formattedValue;
  }

  return formattedValue.replace(/0+$/, '').replace(/,+$/, '');
}

function addPrecisionToExponent(value: number, precision: number): number {
  const [number, exponent] = value.toExponential().split('e').map(Number);

  return Number(`${number}e${precision + exponent}`);
}

export function round(value: number, precision: number = 4): number {
  return (
    addPrecisionToExponent(Math.round(addPrecisionToExponent(Math.abs(value), precision)), precision * -1) *
    Math.sign(value)
  );
}
