import { List, Map } from 'immutable';
import trackingService from '../src/net-auction/tracking-service';

const FETCH_ENTRIES = 'huutokaupat/admin/tracking/FETCH_ENTRIES';
const FETCH_ENTRIES_DONE = 'huutokaupat/admin/tracking/FETCH_ENTRIES_DONE';
const FETCH_ENTRIES_FAILED = 'huutokaupat/admin/tracking/FETCH_ENTRIES_FAILED';

const initialState = new Map({
  loading: false,
  failed: false,
  entries: new List(),
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ENTRIES:
      return state.set('loading', true).set('failed', false).set('entries', new List());

    case FETCH_ENTRIES_DONE:
      return state.set('loading', false).set('entries', new List(action.payload));

    case FETCH_ENTRIES_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function fetchEntries(type) {
  return dispatch => {
    dispatch({ type: FETCH_ENTRIES, payload: type });

    if (!type) {
      dispatch({ type: FETCH_ENTRIES_DONE, payload: new List() });
      return;
    }

    trackingService
      .fetchEntries(type)
      .then(entries => dispatch({ type: FETCH_ENTRIES_DONE, payload: entries }))
      .catch(error => dispatch({ type: FETCH_ENTRIES_FAILED, payload: error }));
  };
}
