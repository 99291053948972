// @flow

import { Map } from 'immutable';
import companyCreateService from '../src/company/company-create-service';

const FETCH_CREATE_COMPANY_OPTIONS = 'huutokaupat/company-create/FETCH_CREATE_COMPANY_OPTIONS';
const FETCH_CREATE_COMPANY_DONE = 'huutokaupat/company-create/FETCH_CREATE_COMPANY_DONE';
const FETCH_CREATE_COMPANY_FAILED = 'huutokaupat/company-create/FETCH_CREATE_COMPANY_FAILED';

const initialState = Map({
  loading: false,
  failed: false,
  options: Map(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_CREATE_COMPANY_OPTIONS:
      return state.set('loading', true).set('failed', false).set('options', new Map());

    case FETCH_CREATE_COMPANY_DONE:
      return state.set('loading', false).set('failed', false).set('options', Map(action.payload));

    case FETCH_CREATE_COMPANY_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function fetchOptions(): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_CREATE_COMPANY_OPTIONS });

    return companyCreateService
      .fetchOptions()
      .then(details => dispatch({ type: FETCH_CREATE_COMPANY_DONE, payload: details }))
      .catch(error => dispatch({ type: FETCH_CREATE_COMPANY_FAILED, payload: error }));
  };
}
