// @flow

import createClient from '../api/client';

export default {
  fetchCompanyPageData: (slug: string) =>
    createClient()
      .get(`/companies/${slug}/page`)
      .then(response => response.data),
};
