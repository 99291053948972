// converted from flow --> jsx

import { List, Map } from 'immutable';
import netAuctionService from '../src/net-auction/net-auction-service';

export const FETCH_ONGOING_ENTRIES = 'huutokaupat/net-auction/FETCH_ONGOING_ENTRIES';
export const FETCH_ONGOING_ENTRIES_DONE = 'huutokaupat/net-auction/FETCH_ONGOING_ENTRIES_DONE';
export const FETCH_ONGOING_ENTRIES_FAILED = 'huutokaupat/net-auction/FETCH_ONGOING_ENTRIES_FAILED';

const MOST_VIEWED = 'katsotuimmat';

const initialState = Map({
  entries: List([]),
  loading: false,
  error: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ONGOING_ENTRIES:
      return state.set('loading', true).set('error', false).set('entries', List());

    case FETCH_ONGOING_ENTRIES_DONE:
      return state.set('loading', false).set('error', false).set('entries', List(action.payload.entries));

    case FETCH_ONGOING_ENTRIES_FAILED:
      return state.set('loading', false).set('error', true).set('entries', List());

    default:
      return state;
  }
};

export const fetchOnGoingMostViewedEntries = (category, limit) => dispatch => {
  dispatch({ type: FETCH_ONGOING_ENTRIES });

  return netAuctionService.getEntries(category, MOST_VIEWED, limit).then(
    data =>
      dispatch({
        type: FETCH_ONGOING_ENTRIES_DONE,
        payload: { entries: data.entries },
      }),
    error =>
      dispatch({
        type: FETCH_ONGOING_ENTRIES_FAILED,
        payload: { error },
      })
  );
};
