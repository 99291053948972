import createClient from '../api/client';

export const fetchArchiveIndex = companyId =>
  createClient()
    .get(`/companies/${companyId}/archive`)
    .then(response => response.data);

export const fetchArchiveEntriesByMonth = (companyId, year, month) =>
  createClient()
    .get(`/companies/${companyId}/archive`, { params: { year, month } })
    .then(response => response.data);
