// @flow

import { Map } from 'immutable';
import userTwoFactorService from '../src/user/user-two-factor-service';

const FETCH_USER_TWO_FACTOR_SECRET = 'huutokaupat/user/user-two-factor/FETCH_USER_TWO_FACTOR_SECRET';
const FETCH_USER_TWO_FACTOR_SECRET_DONE = 'huutokaupat/user/user-two-factor/FETCH_USER_TWO_FACTOR_SECRET_DONE';
const FETCH_USER_TWO_FACTOR_SECRET_FAILED = 'huutokaupat/user/user-two-factor/FETCH_USER_TWO_FACTOR_SECRET_FAILED';

const ENABLE_USER_TWO_FACTOR = 'huutokaupat/user/user-two-factor/ENABLE_USER_TWO_FACTOR';
const ENABLE_USER_TWO_FACTOR_DONE = 'huutokaupat/user/user-two-factor/ENABLE_USER_TWO_FACTOR_DONE';
const ENABLE_USER_TWO_FACTOR_FAILED = 'huutokaupat/user/user-two-factor/ENABLE_USER_TWO_FACTOR_FAILED';

const initialState = Map({
  loading: false,
  fetchedSecret: false,
  fetchingSecretFailed: false,
  provisioningUri: '',
  enablingTwoFactorFailed: false,
  enabledTwoFactorSuccessfully: false,
  errors: {},
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_USER_TWO_FACTOR_SECRET:
      return state
        .set('loading', true)
        .set('fetchedSecretSuccessfully', false)
        .set('fetchingSecretFailed', false)
        .set('provisioningUri', '');

    case FETCH_USER_TWO_FACTOR_SECRET_DONE:
      return state
        .set('loading', false)
        .set('fetchedSecretSuccessfully', true)
        .set('provisioningUri', action.payload.provisioningUri);

    case FETCH_USER_TWO_FACTOR_SECRET_FAILED:
      return state.set('loading', false).set('fetchingSecretFailed', true);

    case ENABLE_USER_TWO_FACTOR:
      return state.set('enablingTwoFactorFailed', false).set('errors', {}).set('enabledTwoFactorSuccessfully', false);

    case ENABLE_USER_TWO_FACTOR_DONE:
      return state
        .set('enablingTwoFactorFailed', false)
        .set('error', action.payload)
        .set('enabledTwoFactorSuccessfully', true);

    case ENABLE_USER_TWO_FACTOR_FAILED:
      return state.set('errors', action.payload).set('enablingTwoFactorFailed', true);

    default:
      return state;
  }
};

export function fetchSecret(): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_USER_TWO_FACTOR_SECRET });

    return userTwoFactorService
      .fetchSecret()
      .then((data): void => {
        dispatch({ type: FETCH_USER_TWO_FACTOR_SECRET_DONE, payload: data });
      })
      .catch(error => dispatch({ type: FETCH_USER_TWO_FACTOR_SECRET_FAILED, payload: error }));
  };
}

export function enableTwoFactor(passcode: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: ENABLE_USER_TWO_FACTOR });

    return userTwoFactorService
      .enableTwoFactor(passcode)
      .then((data): void => {
        dispatch({ type: ENABLE_USER_TWO_FACTOR_DONE, payload: data });
      })
      .catch(error =>
        dispatch({
          type: ENABLE_USER_TWO_FACTOR_FAILED,
          payload: error.message,
        })
      );
  };
}
