import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

export default class CompanySelect extends React.Component {
  constructor(props) {
    super(props);

    const initialValue = props.companies
      ? props.companies.find(company => company.id === parseInt(props.value, 10))
      : null;

    this.state = {
      suggestions: [],
      value: initialValue ? this.formatSuggestion(initialValue) : '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      const newValue = parseInt(nextProps.value, 10);
      const selectedCompany = nextProps.companies ? nextProps.companies.find(company => company.id === newValue) : null;

      this.setState({
        value: selectedCompany ? this.formatSuggestion(selectedCompany) : '',
      });
    } else {
      this.setState({ value: '' });
    }
  }

  onChange = (event, { newValue }) => {
    const { onChange, allowEmpty } = this.props;

    event.stopPropagation();

    if (allowEmpty && newValue.trim() === '') {
      this.setState({ value: '' });

      if (onChange) {
        onChange(null);
      }

      return;
    }

    this.setState({ value: newValue });
  };

  onKeyDown = e => {
    // Avoid submitting forms when enter is pressed.
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { companies } = this.props;
    const query = value.trim().toLowerCase().split(' ');

    if (!query.length) {
      return [];
    }

    this.setState({
      suggestions: companies
        .filter(company => {
          const name = company.name.toLowerCase();

          return query.every(part => name.includes(part));
        })
        .sort((a, b) => {
          if (a.name.length !== b.name.length) {
            return a.name.length - b.name.length;
          }

          return a.id - b.id;
        })
        .slice(0, 5),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(parseInt(suggestion.id, 10));
    }
  };

  formatSuggestion = company => `${company.name} (${company.id})`;

  renderSuggestion = company => {
    const option = this.formatSuggestion(company);
    const index = option.toLowerCase().indexOf(this.state.value.toLowerCase());
    const end = index + this.state.value.length;

    if (index !== -1) {
      return (
        <span>
          {option.slice(0, index)}
          <b>{option.slice(index, end)}</b>
          {option.slice(end)}
        </span>
      );
    }

    return <span>{option}</span>;
  };

  render() {
    const { companies, placeholder, disabled } = this.props;
    const { suggestions, value } = this.state;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={company => `${company.name} (${company.id})`}
        renderSuggestion={this.renderSuggestion}
        focusFirstSuggestion
        highlightFirstSuggestion
        inputProps={{
          className: 'form-control',
          onChange: this.onChange,
          onKeyDown: this.onKeyDown,
          value,
          placeholder,
          disabled: companies.length === 0 || disabled,
        }}
      />
    );
  }
}

CompanySelect.propTypes = {
  onChange: PropTypes.func,
  companies: PropTypes.array,
  placeholder: PropTypes.string,
  allowEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

CompanySelect.defaultProps = {
  companies: [],
  placeholder: '',
  allowEmpty: false,
  value: null,
  disabled: false,
};
