// @flow

import { Map } from 'immutable';
import messageFormService from '../../src/admin/message-form-service';

const SAVING_STARTED = 'huutokaupat/admin/message-form/SAVING_STARTED';
const SAVING_FAILED = 'huutokaupat/admin/message-form/SAVING_FAILED';
const SAVING_SUCCESS = 'huutokaupat/admin/message-form/SAVING_SUCCESS';

const initialState = Map({
  isSaving: false,
  failedToSave: false,
  validationErrors: Map(),
  successfullySaved: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case SAVING_STARTED:
      return state
        .set('isSaving', true)
        .set('failedToSave', false)
        .set('validationErrors', Map())
        .set('successfullySaved', false);

    case SAVING_FAILED:
      return state
        .set('isSaving', false)
        .set('failedToSave', true)
        .set('validationErrors', Map(action.payload.data.errors));

    case SAVING_SUCCESS:
      return state.set('isSaving', false).set('successfullySaved', true);

    default:
      return state;
  }
};

export function storeNewMessage(data: Object): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: SAVING_STARTED, payload: data });

    return messageFormService.createMessage(data).then(
      response => dispatch({ type: SAVING_SUCCESS, payload: response }),
      error => dispatch({ type: SAVING_FAILED, payload: error.response })
    );
  };
}

export function redirectToMessagesView(): void {
  window.location.href = '/yllapito/tiedotteet';
}
