import * as categories from './categories';
import classService from './class-service';
import { ProcessingCostInfo } from './types/ProcessingCost';

export enum DeliveryMethod {
  DeliveryOrRetrieval = 1,
  Delivery = 100,
  Retrieval = 200,
}

export enum PaymentType {
  Instant = 1,
  External = 2,
}

const categoriesWithDeliveryDisabled = [
  categories.CATEGORY_PASSENGER_CAR,
  categories.CATEGORY_VAN,
  categories.CATEGORY_TRUCK,
  categories.CATEGORY_BOAT,
  categories.CATEGORY_MOTORCYCLE,
  categories.CATEGORY_CARAVAN_AND_TRAILER,
  categories.CATEGORY_SNOWMOBILE,
  categories.CATEGORY_CONSTRUCTION_MACHINERY,
  categories.CATEGORY_FARMING_MACHINERY,
  categories.CATEGORY_FOREST_MACHINERY,
  categories.CATEGORY_OTHER_MACHINERY,
];

const categoriesWithNoDelivery = [
  categories.CATEGORY_APARTMENT,
  categories.CATEGORY_TIMESHARE,
  categories.CATEGORY_PREMISES,
  categories.CATEGORY_PLOT,
  categories.CATEGORY_VACATION_HOMES,
  categories.CATEGORY_GARAGES,
];

const classesWithNoDelivery = [classService.CLASS_FORECLOSURE];

export interface Delivery {
  readonly buyerSelectedMethod: DeliveryMethod;
  readonly paymentType: PaymentType;
  readonly retrievalExpenses: string;
  readonly deliveryExpenses: string;
  readonly vatPercent: string;
}

export interface EntryPageDelivery {
  readonly deliveryAvailable: boolean;
  readonly deliveryExpenses: number | null;
  readonly deliveryExpensesAvailable: boolean;
  readonly retrievalAvailable: boolean;
  readonly retrievalExpenses: number | null;
  readonly selectableMethods: DeliveryMethod[];
}

export const entryHasDeliveryObject = (categoryId: number, netAuctionClass: string) =>
  !categoriesWithNoDelivery.includes(categoryId) && !classesWithNoDelivery.includes(netAuctionClass);

export const isDeliveryDisabled = (categoryId: number) => categoriesWithDeliveryDisabled.includes(categoryId);

export const hasBuyerSelectedMethod = (delivery: Delivery): boolean => !!delivery.buyerSelectedMethod;

export const hasBuyerSelectedRetrieval = (delivery: Delivery): boolean =>
  delivery.buyerSelectedMethod === DeliveryMethod.Retrieval;

export const isExternalPayment = (delivery: Delivery): boolean => delivery.paymentType === PaymentType.External;

export const getExpenses = (delivery: Delivery): string => {
  if (!hasBuyerSelectedMethod(delivery)) {
    return '0.00';
  }

  const expenses = hasBuyerSelectedRetrieval(delivery) ? delivery.retrievalExpenses : delivery.deliveryExpenses;

  return expenses || '0.00';
};

export const getSmallestPossibleDeliveryExpenses = (delivery: EntryPageDelivery) => {
  const possibleDeliveryExpenses = [
    delivery.deliveryAvailable ? delivery.deliveryExpenses : null,
    delivery.retrievalAvailable ? delivery.retrievalExpenses : null,
  ]
    .filter(expenses => expenses !== null)
    .map(expensesString => Number(expensesString));

  return possibleDeliveryExpenses.length > 0 ? Math.min(...possibleDeliveryExpenses) : 0;
};

export const getPriceIncludesDeliveryText = ({
  processingCostInfo,
  delivery,
}: {
  readonly processingCostInfo?: ProcessingCostInfo;
  readonly delivery?: Delivery;
}) => {
  if (processingCostInfo && delivery) return 'Hintaan lisätään käsittely- ja toimituskulut';
  if (delivery) return 'Hintaan lisätään toimituskulut';
  if (processingCostInfo) return 'Hintaan lisätään käsittelykulu';

  return null;
};
