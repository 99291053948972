// @flow

import { List, Map } from 'immutable';

import userService from '../src/user/user-service';

const NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES = 'huutokaupat/net-auction/receipt/NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES';
const NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_DONE =
  'huutokaupat/net-auction/receipt/NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_DONE';
const NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_FAILED =
  'huutokaupat/net-auction/receipt/NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_FAILED';

const initialState = Map({
  soldEntries: List(),
  fetchingSoldEntries: false,
  failedToFetchSoldEntries: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES:
      return state.set('soldEntries', null).set('fetchingSoldEntries', true).set('failedToFetchSoldEntries', false);

    case NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_DONE:
      return state
        .set('soldEntries', List(action.payload))
        .set('fetchingSoldEntries', false)
        .set('failedToFetchSoldEntries', false);

    case NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_FAILED:
      return state.set('fetchingSoldEntries', false).set('failedToFetchSoldEntries', true);

    default:
      return state;
  }
};

export function fetchSoldEntries(userId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES });

    return userService.fetchSoldEntries(userId).then(
      response =>
        dispatch({
          type: NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: NET_AUCTION_RECEIPT_FETCH_SOLD_ENTRIES_FAILED,
          payload: error,
        })
    );
  };
}
