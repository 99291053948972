// @flow

import createClient from '../api/client';
import { base64ToBlob } from '../util/file';
import { mediaUploadMaxSize } from './media-service';

export default {
  MEDIA_TYPE_IMAGE: 1,
  MEDIA_TYPE_VIDEO: 2,
  MEDIA_TYPE_ATTACHMENT: 3,

  MEDIA_METADATA_IS_BUILDING_MANAGERS_LETTER: '1',

  fetchImages: (entryId: number) =>
    createClient()
      .get(`/net-auctions/${entryId}/images`)
      .then(response => response.data),

  rotateImage: (id: number) =>
    createClient()
      .patch(`/net-auctions/images/${id}/rotate`)
      .then(response => response.data),

  deleteImage: (id: number) =>
    createClient()
      .delete(`/net-auctions/images/${id}`)
      .then(response => response.data),

  deleteAllImages: (draftId: number) =>
    createClient()
      .delete(`/net-auctions/${draftId}/images`)
      .then(response => response.data),

  orderImages: (entryId: number, imageIdsInOrder: Array<number>) =>
    createClient()
      .patch(`/net-auctions/${entryId}/images/order`, { imageIdsInOrder })
      .then(response => response.data),

  uploadPhoto: (entryId: number, file: Object) =>
    createClient()
      .post(`/net-auctions/${entryId}/images`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  downloadPhotos: (entryId: number) =>
    createClient()
      .get(`/net-auctions/${entryId}/images/download`)
      .then(response => ({
        filename: response.data.filename,
        content: new Blob([base64ToBlob(response.data.content)], {
          type: 'application/zip',
        }),
      })),

  uploadExternalPhoto: (entryId: number, source: string, image: Object) =>
    createClient()
      .post(`/net-auctions/${entryId}/images/url`, { image, source })
      .then(response => response.data),

  uploadVideo: (entryId: number, url: string) =>
    createClient()
      .post(`/net-auctions/${entryId}/images/videos`, { url })
      .then(response => response.data),

  fetchAttachments: (entryId: number) =>
    createClient()
      .get(`/net-auctions/${entryId}/attachments`)
      .then(response => response.data),

  uploadAttachment: (entryId: number, file: Object) =>
    createClient()
      .post(`/net-auctions/${entryId}/attachments`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  deleteAttachment: (id: number) =>
    createClient()
      .delete(`/net-auctions/attachments/${id}`)
      .then(response => response.data),

  orderAttachments: (entryId: number, attachmentIdsInOrder: Array<number>) =>
    createClient()
      .patch(`/net-auctions/${entryId}/attachments/order`, {
        attachmentIdsInOrder,
      })
      .then(response => response.data),

  getUploadFailedMessageByStatus: (status: number): string => {
    if (status === 413) {
      return `Tiedosto on liian suuri! (max. ${mediaUploadMaxSize.VALUE} ${mediaUploadMaxSize.UNIT})`;
    }

    return '';
  },
};
