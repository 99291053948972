import { Dispatch } from 'redux';
import { cancelNetAuctionCustomerFunds } from '@app/src/net-auction/net-auction-frozen-service';

export const RemoveFrozenNetAuction = 'huutokaupat/frozen-net-auction/REMOVE_FROZEN_NET_AUCTION';

export const RemoveFrozenNetAuctionDone = 'huutokaupat/frozen-net-auction/REMOVE_FROZEN_NET_AUCTION_DONE';

export const RemoveFrozenNetAuctionFailed = 'huutokaupat/frozen-net-auction/REMOVE_FROZEN_NET_AUCTION_FAILED';

export interface RemoveFrozenNetAuctionAction {
  readonly type: typeof RemoveFrozenNetAuction;
}

export interface RemoveFrozenNetAuctionDoneAction {
  readonly type: typeof RemoveFrozenNetAuctionDone;
  readonly payload: { readonly entryIds: number[] };
}

export interface RemoveFrozenNetAuctionFailedAction {
  readonly type: typeof RemoveFrozenNetAuctionFailed;
}

export function cancelEntry(entryId: number, message: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RemoveFrozenNetAuction });

    return cancelNetAuctionCustomerFunds(entryId, message).then(result =>
      dispatch({
        type: RemoveFrozenNetAuctionDone,
        payload: { entryId: result },
      })
    );
  };
}
