import { List, Map } from 'immutable';
import companyService from '../src/company/company-service';

const CREATE = 'huutokaupat/company/CREATE';
const CREATE_DONE = 'huutokaupat/company/CREATE_DONE';
const CREATE_FAILED = 'huutokaupat/company/CREATE_FAILED';

const initialState = new Map({
  loading: false,
  failed: false,
  searched: false,
  companies: new List(),
  saving: false,
  saved: false,
  result: Map(),
  errors: Map(),
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE:
      return state.set('saved', false).set('saving', true).set('result', Map()).set('errors', Map());

    case CREATE_DONE:
      return state.set('saving', false).set('saved', true).set('result', Map(action.payload));

    case CREATE_FAILED:
      return state.set('saving', false).set('saved', false).set('errors', Map(action.payload));

    default:
      return state;
  }
};

export function createCompany(companyData) {
  return dispatch => {
    dispatch({ type: CREATE });

    companyService
      .create(companyData)
      .then(data => dispatch({ type: CREATE_DONE, payload: data }))
      .catch(error => {
        dispatch({ type: CREATE_FAILED, payload: error.response.data });
      });
  };
}
