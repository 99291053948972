// @flow

import { List, Map } from 'immutable';
import companyGroupService from '../src/company-group/company-group-service';

const FETCH_COMPANY_GROUPS = 'huutokaupat/company-oauth-client/FETCH_COMPANY_GROUPS';
const FETCH_COMPANY_GROUPS_DONE = 'huutokaupat/company-oauth-client/FETCH_COMPANY_GROUPS_DONE';
const FETCH_COMPANY_GROUPS_FAILED = 'huutokaupat/company-oauth-client/FETCH_COMPANY_GROUPS_FAILED';

const OPEN_CREATE_COMPANY_GROUP_MODAL = 'huutokaupat/company-oauth-client/OPEN_CREATE_COMPANY_GROUP_MODAL';
const CLOSE_CREATE_COMPANY_GROUP_MODAL = 'huutokaupat/company-oauth-client/CLOSE_CREATE_COMPANY_GROUP_MODAL';

const CREATE_COMPANY_GROUP = 'huutokaupat/company-oauth-client/CREATE_COMPANY_GROUP';
const CREATE_COMPANY_GROUP_DONE = 'huutokaupat/company-oauth-client/CREATE_COMPANY_GROUP_DONE';
const CREATE_COMPANY_GROUP_FAILED = 'huutokaupat/company-oauth-client/CREATE_COMPANY_GROUP_FAILED';

const DELETE_COMPANY_GROUP = 'huutokaupat/company-oauth-client/DELETE_COMPANY_GROUP';
const DELETE_COMPANY_GROUP_DONE = 'huutokaupat/company-oauth-client/DELETE_COMPANY_GROUP_DONE';
const DELETE_COMPANY_GROUP_FAILED = 'huutokaupat/company-oauth-client/DELETE_COMPANY_GROUP_FAILED';

const initialState = Map({
  companyGroups: List(),
  failed: false,
  loading: false,
  createCompanyGroupModalOpen: false,
});

// Needed to preserve front end 'deleting' statuses when updating CompanyGroups after delete.
function updateFrontEndCompanyGroups(companyGroups: List<Object>, frontEndCompanyGroups: List<Object>): List<Object> {
  return frontEndCompanyGroups.filter(
    (frontEndCompanyGroup): boolean => companyGroups.findIndex(item => item.id === frontEndCompanyGroup.id) !== -1
  );
}

function updateCompanyGroup(companyGroups: List<Object>, companyGroupId: number, props: Object): List<Object> {
  const index = companyGroups.findIndex(item => item.id === companyGroupId);

  if (index === -1) {
    return companyGroups;
  }

  return companyGroups.updateIn([index], companyGroup => ({
    ...companyGroup,
    ...props,
  }));
}

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_COMPANY_GROUPS:
      return state.set('loading', true).set('failed', false).set('companyGroups', List());

    case FETCH_COMPANY_GROUPS_DONE:
      return state.set('loading', false).set('failed', false).set('companyGroups', List(action.payload));

    case FETCH_COMPANY_GROUPS_FAILED:
      return state.set('loading', false).set('failed', true);

    case OPEN_CREATE_COMPANY_GROUP_MODAL:
      return state.set('createCompanyGroupModalOpen', true);

    case CLOSE_CREATE_COMPANY_GROUP_MODAL:
      return state.set('createCompanyGroupModalOpen', false);

    case CREATE_COMPANY_GROUP_DONE:
      return state
        .set('failed', false)
        .set('createCompanyGroupModalOpen', false)
        .set('companyGroups', List(action.payload));

    case CREATE_COMPANY_GROUP_FAILED:
      return state.set('failed', true);

    case DELETE_COMPANY_GROUP:
      return state.set(
        'companyGroups',
        updateCompanyGroup(state.get('companyGroups', List()), action.payload, {
          deletingCompanyGroup: true,
        })
      );

    case DELETE_COMPANY_GROUP_DONE:
      return state
        .set('failed', false)
        .set('companyGroups', updateFrontEndCompanyGroups(List(action.payload), state.get('companyGroups', List())));

    case DELETE_COMPANY_GROUP_FAILED:
      return state
        .set(
          'companyGroups',
          updateCompanyGroup(state.get('companyGroups', List()), action.payload, {
            deletingCompanyGroup: false,
          })
        )
        .set('failed', true);

    default:
      return state;
  }
};

export function openCreateCompanyGroupModal(): Function {
  return (dispatch: Function): void => {
    dispatch({ type: OPEN_CREATE_COMPANY_GROUP_MODAL });
  };
}

export function closeCreateCompanyGroupModal(): Function {
  return (dispatch: Function): void => {
    dispatch({ type: CLOSE_CREATE_COMPANY_GROUP_MODAL });
  };
}

export function fetchCompanyGroups(): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_COMPANY_GROUPS });

    return companyGroupService
      .fetchCompanyGroups()
      .then(companyGroups => dispatch({ type: FETCH_COMPANY_GROUPS_DONE, payload: companyGroups }))
      .catch(error => dispatch({ type: FETCH_COMPANY_GROUPS_FAILED, payload: error }));
  };
}

export function deleteCompanyGroup(companyGroupId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: DELETE_COMPANY_GROUP, payload: companyGroupId });

    return companyGroupService
      .deleteCompanyGroup(companyGroupId)
      .then(companyGroups => dispatch({ type: DELETE_COMPANY_GROUP_DONE, payload: companyGroups }))
      .catch(() => dispatch({ type: DELETE_COMPANY_GROUP_FAILED, payload: companyGroupId }));
  };
}

export function createCompanyGroup(name: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CREATE_COMPANY_GROUP });

    return companyGroupService
      .createCompanyGroup(name)
      .then(companyGroups => dispatch({ type: CREATE_COMPANY_GROUP_DONE, payload: companyGroups }))
      .catch(error => dispatch({ type: CREATE_COMPANY_GROUP_FAILED, payload: error }));
  };
}
