// @flow

import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from './ducks';
import transformInitialState from './src/redux/initial-state-transformer';

function createCustomStore(initialState: Object = {}): Object {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable no-underscore-dangle */

  return createStore(createRootReducer(), initialState, composeEnhancers(applyMiddleware(thunk)));
}

const initialStateElement = document.getElementById('initial-state');
const initialState = initialStateElement ? JSON.parse(initialStateElement.innerHTML) : {};

// Shared Redux store between main, sidebar and header apps.
export const store = createCustomStore(transformInitialState(initialState));
