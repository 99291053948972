export function getApartmentType(type: number) {
  switch (type) {
    case 1:
      return 'Kiinteistö';

    case 2:
      return 'Kerrostalo';

    case 3:
      return 'Rivitalo';

    case 4:
      return 'Paritalo';

    case 5:
      return 'Omakotitalo';

    case 6:
      return 'Puutalo';

    case 7:
      return 'Erillistalo';

    case 99:
    default:
      return 'Muu';
  }
}

export function getRoomCountType(count: number) {
  switch (count) {
    case 1:
      return 'Yksiö';

    case 2:
      return 'Kaksio';

    case 3:
      return '3h';

    case 4:
      return '4h';

    case 5:
      return '5h';

    case 99:
      return '5h+';

    default:
      return 'Muu';
  }
}
