export function resolve(href: string) {
  return [
    resolveInternalUrl,
    // other link resolvers can be chained here
  ].reduce((result, fn) => fn(result), href);
}

function resolveInternalUrl(href: string) {
  try {
    const u = new URL(href.toString());
    if (['huutokaupat.com', 'huutokaupat.dev'].includes(u.host)) return `${u.pathname}${u.search}${u.hash}`;
  } catch (_) {
    return href;
  }
  return href;
}
