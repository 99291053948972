// converted from Flow --> JS

import { List, Map } from 'immutable';
import insiderTradingModerationService from '../src/insider-trading-moderation/insider-trading-moderation-service';

const FETCH_BID_ANALYSES = 'huutokaupat/insider-trading/FETCH_BID_ANALYSES';
const FETCH_BID_ANALYSES_DONE = 'huutokaupat/insider-trading/FETCH_BID_ANALYSES_DONE';
const FETCH_BID_ANALYSES_FAILED = 'huutokaupat/insider-trading/FETCH_BID_ANALYSES_FAILED';

const OPEN_DETAILS_MODAL = 'huutokaupat/insider-trading/OPEN_DETAILS_MODAL';
const CLOSE_DETAILS_MODAL = 'huutokaupat/insider-trading/CLOSE_DETAILS_MODAL';

const UPDATE_MODERATION_STATUS = 'huutokaupat/insider-trading/UPDATE_MODERATION_STATUS';
const UPDATE_MODERATION_STATUS_DONE = 'huutokaupat/insider-trading/UPDATE_MODERATION_STATUS_DONE';
const UPDATE_MODERATION_STATUS_FAILED = 'huutokaupat/insider-trading/UPDATE_MODERATION_STATUS_FAILED';

const OPEN_UPDATE_MODAL = 'huutokaupat/insider-trading/OPEN_UPDATE_MODAL';
const CLOSE_UPDATE_MODAL = 'huutokaupat/insider-trading/CLOSE_UPDATE_MODAL';

const initialState = Map({
  isDetailsModalVisible: false,
  isUpdateModalVisible: false,
  bidAnalyses: new List(), // Actually groups of bid analyses.
  detailsModalEntryId: null,
  updateBidAnalysisIds: null,
  updateBidderId: null,
  updateSellerId: null,
  updateStatus: null,
  loading: false,
  fetchFailed: false,
  updateFailed: false,
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_BID_ANALYSES:
      return state.set('loading', true).set('bidAnalyses', List()).set('fetchFailed', false);

    case FETCH_BID_ANALYSES_DONE:
      return state.set('loading', false).set('bidAnalyses', List(action.payload)).set('fetchFailed', false);

    case FETCH_BID_ANALYSES_FAILED:
      return state.set('loading', false).set('bidAnalyses', List()).set('fetchFailed', true);

    case OPEN_DETAILS_MODAL:
      return state.set('isDetailsModalVisible', true).set('detailsModalEntryId', action.payload);

    case CLOSE_DETAILS_MODAL:
      return state.set('isDetailsModalVisible', false).set('detailsModalEntryId', null);

    case OPEN_UPDATE_MODAL:
      return state
        .set('isUpdateModalVisible', true)
        .set('updateBidAnalysisIds', action.payload.bidAnalysisIds)
        .set('updateBidderId', action.payload.bidderId)
        .set('updateSellerId', action.payload.sellerId)
        .set('updateStatus', action.payload.status);

    case CLOSE_UPDATE_MODAL:
      return state
        .set('isUpdateModalVisible', false)
        .set('updateBidAnalysisIds', null)
        .set('updateBidderId', null)
        .set('updateSellerId', null)
        .set('updateStatus', null);

    case UPDATE_MODERATION_STATUS:
      return state
        .set(
          'bidAnalyses',
          state.get('bidAnalyses', List()).map(bidAnalysisGroup => {
            const analyses = bidAnalysisGroup.analyses.map(analysis => {
              if (!action.payload.analyses.includes(analysis.id)) {
                return analysis;
              }

              return { ...analysis, status: action.payload.status };
            });

            return { ...bidAnalysisGroup, analyses };
          })
        )
        .set('updateFailed', false)
        .set('isUpdateModalVisible', false);

    case UPDATE_MODERATION_STATUS_FAILED:
      return state
        .set('updateFailed', true)
        .set('isUpdateModalVisible', false)
        .set('updateSidderId', null)
        .set('updateSellerId', null)
        .set('updateStatus', null)
        .set('updateBidAnalysisIds', null);

    case UPDATE_MODERATION_STATUS_DONE:
      return state
        .set('isUpdateModalVisible', false)
        .set('updateBidAnalysisIds', null)
        .set('updateBidderId', null)
        .set('updateSellerId', null)
        .set('updateStatus', null);

    default:
      return state;
  }
};

export function fetchBidAnalyses(filters) {
  return dispatch => {
    dispatch({ type: FETCH_BID_ANALYSES });

    return insiderTradingModerationService.fetchAll(filters).then(
      bidAnalyses => dispatch({ type: FETCH_BID_ANALYSES_DONE, payload: bidAnalyses }),
      error => dispatch({ type: FETCH_BID_ANALYSES_FAILED, payload: error })
    );
  };
}

export function openDetailsModal(selectedEntryId) {
  return { type: OPEN_DETAILS_MODAL, payload: selectedEntryId };
}

export function onCloseDetailsModal() {
  return { type: CLOSE_DETAILS_MODAL };
}

export function openUpdateModal(bidAnalysisIds, bidderId, sellerId, status) {
  return {
    type: OPEN_UPDATE_MODAL,
    payload: { bidAnalysisIds, bidderId, sellerId, status },
  };
}

export function onCloseUpdateModal() {
  return { type: CLOSE_UPDATE_MODAL };
}

export function updateMultiple(ids, status) {
  return dispatch => {
    dispatch({
      type: UPDATE_MODERATION_STATUS,
      payload: { analyses: ids, status },
    });

    return insiderTradingModerationService.updateMultiple(ids, status).then(
      () => dispatch({ type: UPDATE_MODERATION_STATUS_DONE }),
      error => dispatch({ type: UPDATE_MODERATION_STATUS_FAILED, payload: error })
    );
  };
}
