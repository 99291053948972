// @flow

import { List, Map } from 'immutable';
import entryService from '../src/entry/entry-service';

const OPEN_CONTACT_INFO_MODAL = 'huutokaupat/contact-info/OPEN_CONTACT_INFO_MODAL';
const CLOSE_CONTACT_INFO_MODAL = 'huutokaupat/contact-info/CLOSE_CONTACT_INFO_MODAL';

const MISSING_CONTACT_INFO = 'huutokaupat/contact-info/MISSING_CONTACT_INFO';
const LOG_CONTACT_INFO_STATISTICS_FAILED = 'huutokaupat/contact-info/LOG_CONTACT_INFO_STATISTICS_FAILED';

const initialState = Map({
  entryId: null,
  contacts: List(),
  open: false,
  missingContactInfo: false,
  logContactStatisticsFailed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case OPEN_CONTACT_INFO_MODAL:
      return state
        .set('open', true)
        .set('companyName', action.payload.companyName)
        .set('contacts', List(action.payload.contacts));

    case CLOSE_CONTACT_INFO_MODAL:
      return state.set('open', false).set('companyName', null).set('contacts', List());

    case MISSING_CONTACT_INFO:
      return state.set('missingContactInfo', true);

    case LOG_CONTACT_INFO_STATISTICS_FAILED:
      return state.set('logContactInfoStatisticsFailed', true);

    default:
      return state;
  }
};

export function openContactInfoModal(entryId: number, companyName: string): Object {
  return (dispatch: Function): Object =>
    entryService.incrementContactStats(entryId, 'call').then(
      response =>
        dispatch({
          type: OPEN_CONTACT_INFO_MODAL,
          payload: { companyName, contacts: response.contacts },
        }),
      () => dispatch({ type: LOG_CONTACT_INFO_STATISTICS_FAILED })
    );
}

export function closeContactInfoModal(): Object {
  return { type: CLOSE_CONTACT_INFO_MODAL };
}
