import { FrozenNetAuction } from '@app/src/net-auction/net-auction-frozen-service';
import {
  FetchFrozenNetAuctions,
  FetchFrozenNetAuctionsAction,
  FetchFrozenNetAuctionsDone,
  FetchFrozenNetAuctionsDoneAction,
  FetchFrozenNetAuctionsFailed,
  FetchFrozenNetAuctionsFailedAction,
} from '@app/ducks/frozen-net-auction/fetch-data';
import {
  RemoveFrozenNetAuction,
  RemoveFrozenNetAuctionAction,
  RemoveFrozenNetAuctionDone,
  RemoveFrozenNetAuctionDoneAction,
  RemoveFrozenNetAuctionFailed,
  RemoveFrozenNetAuctionFailedAction,
} from '@app/ducks/frozen-net-auction/cancel-entry';
import {
  IncreaseNoteCountForFrozenNetAuction,
  IncreaseNoteCountForFrozenNetAuctionAction,
} from '@app/ducks/frozen-net-auction/increase-note-count';
import {
  DecreaseNoteCountForFrozenNetAuction,
  DecreaseNoteCountForFrozenNetAuctionAction,
} from '@app/ducks/frozen-net-auction/decrease-note-count';

export { fetchData } from './fetch-data';
export { cancelEntry } from './cancel-entry';
export { unfreezeEntries } from './unfreeze-entries';
export { increaseNoteCount } from './increase-note-count';
export { decreaseNoteCount } from './decrease-note-count';

type FrozenNetAuctionReducerAction =
  | FetchFrozenNetAuctionsAction
  | FetchFrozenNetAuctionsDoneAction
  | FetchFrozenNetAuctionsFailedAction
  | RemoveFrozenNetAuctionAction
  | RemoveFrozenNetAuctionDoneAction
  | RemoveFrozenNetAuctionFailedAction
  | IncreaseNoteCountForFrozenNetAuctionAction
  | DecreaseNoteCountForFrozenNetAuctionAction;

interface FrozenNetAuctionReducerState {
  readonly entries: FrozenNetAuction[];
  readonly failed: boolean;
  readonly loading: boolean;
  readonly removing: boolean;
}

const initialState: FrozenNetAuctionReducerState = {
  entries: [],
  failed: false,
  loading: false,
  removing: false,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Reducer.
  state: FrozenNetAuctionReducerState = initialState,
  action: FrozenNetAuctionReducerAction
): FrozenNetAuctionReducerState => {
  switch (action.type) {
    case FetchFrozenNetAuctions:
      return {
        ...state,
        loading: true,
        failed: false,
        entries: [],
      };

    case FetchFrozenNetAuctionsDone:
      return {
        ...state,
        loading: false,
        entries: action.payload,
      };

    case FetchFrozenNetAuctionsFailed:
      return {
        ...state,
        loading: false,
        failed: true,
        entries: [],
      };

    case RemoveFrozenNetAuction:
      return {
        ...state,
        removing: true,
      };

    case RemoveFrozenNetAuctionDone:
      return {
        ...state,
        removing: false,
        entries: state.entries.filter(entry => !action.payload.entryIds.includes(entry.id)),
      };

    case RemoveFrozenNetAuctionFailed:
      return {
        ...state,
        removing: false,
      };

    case IncreaseNoteCountForFrozenNetAuction:
      return {
        ...state,
        removing: false,
        entries: state.entries.map(entry => {
          if (entry.id === action.payload.entryId) {
            return {
              ...entry,
              notesCount: entry.notesCount + 1,
            };
          }

          return entry;
        }),
      };

    case DecreaseNoteCountForFrozenNetAuction:
      return {
        ...state,
        removing: false,
        entries: state.entries.map(entry => {
          if (entry.id === action.payload.entryId) {
            return {
              ...entry,
              notesCount: entry.notesCount - 1,
            };
          }

          return entry;
        }),
      };

    default:
      return state;
  }
};

export default reducer;
