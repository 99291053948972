// @flow

import { List, Map } from 'immutable';
import fileSaver from 'file-saver';

import { fetchArchiveIndex, fetchArchiveEntriesByMonth } from '../src/company/company-archive-service';
import netAuctionService from '../src/net-auction/net-auction-service';

const FETCH_ARCHIVE_INDEX = 'huutokaupat/company-archive/FETCH_ARCHIVE_INDEX';
const FETCH_ARCHIVE_INDEX_DONE = 'huutokaupat/company-archive/FETCH_ARCHIVE_INDEX_DONE';
const FETCH_ARCHIVE_INDEX_FAILED = 'huutokaupat/company-archive/FETCH_ARCHIVE_INDEX_FAILED';
const FETCH_ARCHIVE_ENTRIES_BY_MONTH = 'huutokaupat/company-archive/FETCH_ARCHIVE_ENTRIES_BY_MONTH';
const FETCH_ARCHIVE_ENTRIES_BY_MONTH_DONE = 'huutokaupat/company-archive/FETCH_ARCHIVE_ENTRIES_BY_MONTH_DONE';
const FETCH_ARCHIVE_ENTRIES_BY_MONTH_FAILED = 'huutokaupat/company-archive/FETCH_ARCHIVE_ENTRIES_BY_MONTH_FAILED';
const FETCH_BILL_OF_SALE = 'huutokaupat/company-archive/FETCH_BILL_OF_SALE';
const FETCH_BILL_OF_SALE_DONE = 'huutokaupat/company-archive/FETCH_BILL_OF_SALE_DONE';
const FETCH_BILL_OF_SALE_FAILED = 'huutokaupat/company-archive/FETCH_BILL_OF_SALE_FAILED';

const initialState = Map({
  archiveIndex: List(),
  archivedEntries: Map({}),
  error: null,
  loadingEntries: false,
  loadingIndex: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_ARCHIVE_INDEX:
      return state.set('loadingIndex', true).set('error', null);

    case FETCH_ARCHIVE_INDEX_DONE:
      return state.set('loadingIndex', false).set('archiveIndex', List(action.payload));

    case FETCH_ARCHIVE_INDEX_FAILED:
      return state.set('loadingIndex', false).set('error', action.payload);

    case FETCH_ARCHIVE_ENTRIES_BY_MONTH:
      return state.set('loadingEntries', true).set('error', null);

    case FETCH_ARCHIVE_ENTRIES_BY_MONTH_DONE:
      return state
        .update('archivedEntries', (entriesMap: Map<string, Object>): Map<string, Object> => {
          let year = entriesMap.get(action.payload.year);
          if (!year) {
            year = {};
          }
          return entriesMap.set(action.payload.year, {
            ...year,
            [action.payload.month]: action.payload.entries,
          });
        })
        .set('loadingEntries', false);

    case FETCH_ARCHIVE_ENTRIES_BY_MONTH_FAILED:
      return state.set('loadingEntries', false).set('error', action.payload);

    default:
      return state;
  }
};

export function fetchCompanyArchiveIndex(companyId: number): Function {
  return (dispatch): Promise<Object> => {
    dispatch({ type: FETCH_ARCHIVE_INDEX });

    return fetchArchiveIndex(companyId).then(
      (response): void => {
        dispatch({
          type: FETCH_ARCHIVE_INDEX_DONE,
          payload: response,
        });
      },
      (error): void => {
        dispatch({
          type: FETCH_ARCHIVE_INDEX_FAILED,
          payload: error.response.data,
        });
      }
    );
  };
}

export function fetchArchivedEntriesByMonth(companyId: number, year: number, month: number): Function {
  return (dispatch): Promise<Object> => {
    dispatch({ type: FETCH_ARCHIVE_ENTRIES_BY_MONTH });

    return fetchArchiveEntriesByMonth(companyId, year, month).then(
      (response): void => {
        dispatch({
          type: FETCH_ARCHIVE_ENTRIES_BY_MONTH_DONE,
          payload: { year, month, entries: response },
        });
      },
      (error): void => {
        dispatch({
          type: FETCH_ARCHIVE_ENTRIES_BY_MONTH_FAILED,
          payload: error.response.data,
        });
      }
    );
  };
}

export function fetchBillOfSale(entryId: string, role: string): Function {
  return (dispatch): Promise<Object> => {
    dispatch({ type: FETCH_BILL_OF_SALE });

    return netAuctionService.fetchBillOfSale(entryId, role).then(
      (response): void => {
        dispatch({
          type: FETCH_BILL_OF_SALE_DONE,
        });
        fileSaver.saveAs(response.content, response.filename);
      },
      (error): void => {
        dispatch({
          type: FETCH_BILL_OF_SALE_FAILED,
          payload: error,
        });
      }
    );
  };
}
