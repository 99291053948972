import axios from 'axios';
import dayjs from 'dayjs';
import { SubmissionError } from 'redux-form';
import createClient from '../api/client';
import { base64ToBlob } from '../util/file';
import entryService, { cancellationReasons, entryStatuses } from '../entry/entry-service';
import * as categories from './categories';
import classService from './class-service';
import jwt from '../security/jwt';
import config from '../../../config';
import { round } from '../util/currency';
import { getSmallestPossibleDeliveryExpenses } from './delivery-service';
import metadataService from '../metadata/metadata-service';
import { formatDateLong } from '../util/date';

export const netAuctionStatuses = {
  WAITING_FOR_BIDS: 'W',
  ENDED: 'E',
  APPROVED: 'A',
  REJECTED: 'R',
  SOLD: 'S',
  NO_BIDS: 'N',
};

export const ownPaymentStatus = Object.freeze({
  YES: 'Y',
  NO: 'N',
});

export const runnerUpOfferStatuses = Object.freeze({
  ACTIVE: 1,
  ACCEPTED: 2,
  DECLINED: 3,
  EXPIRED: 4,
});

export const FAVORITE_LIST_MAX_SIZE = 500;
export const MAXIMUM_SAVED_SEARCH_COUNT = 15;

const apartmentTypes = {
  PROPERTY: 1,
  CONDO: 2,
  ROWHOUSE: 3,
  DUPLEX: 4,
  HOUSE: 5,
  WOODEN_HOUSE: 6,
  DETACHED_HOUSE: 7,
  OTHER: 99,
};

const apartmentTypesPartOfHousingCompany = [
  apartmentTypes.CONDO,
  apartmentTypes.ROWHOUSE,
  apartmentTypes.DETACHED_HOUSE,
];

export const dashboardEntryLimit = 500;

const backToTopLinkLimit = 20;

const isDashboardHidden = () => window.outerWidth < 768;

const cancelTokens = [];

const CATEGORIES_WITH_MANDATORY_OWN_PAYMENT = [
  categories.CATEGORY_APARTMENT,
  categories.CATEGORY_TIMESHARE,
  categories.CATEGORY_PREMISES,
  categories.CATEGORY_PLOT,
  categories.CATEGORY_VACATION_HOMES,
  categories.CATEGORY_GARAGES,
  categories.CATEGORY_ANTIQUE,
];

const CLASSES_WITH_MANDATORY_OWN_PAYMENT = [
  classService.CLASS_FORECLOSURE,
  classService.CLASS_BANKRUPTCIES,
  classService.CLASS_FINANCIERS,
];

const FORCED_OWN_PAYMENT_LIMIT = 50000;

export const CONTINUOUS_MIN_STARTING_PRICE = 1500;

export const CONTINUOUS_AUCTION_MAX_COUNT = 10;

const doesClassHaveMandatoryOwnPayment = netAuctionClass =>
  CLASSES_WITH_MANDATORY_OWN_PAYMENT.includes(netAuctionClass);

const doesCategoryHaveMandatoryOwnPayment = categoryId => CATEGORIES_WITH_MANDATORY_OWN_PAYMENT.includes(categoryId);

export default {
  isOwnPayment: (
    isOwnPaymentAdminOverride,
    categoryId,
    netAuctionClass,
    isSpecialVehicle,
    isRegisteredForProfessionalUse,
    startPrice,
    minimumRaise,
    isOwnPaymentCompany,
    isConsumerNetAuction,
    needsPermit = false
  ) => {
    if (isOwnPaymentAdminOverride) {
      return true;
    }

    if (isConsumerNetAuction) {
      return false;
    }

    if (doesCategoryHaveMandatoryOwnPayment(categoryId)) {
      return true;
    }

    if (doesClassHaveMandatoryOwnPayment(netAuctionClass)) {
      return true;
    }

    if (isSpecialVehicle) {
      return true;
    }

    if (categoryId === categories.CATEGORY_BOAT && isRegisteredForProfessionalUse) {
      return true;
    }

    if (startPrice + minimumRaise > FORCED_OWN_PAYMENT_LIMIT) {
      return true;
    }

    if (needsPermit) {
      return true;
    }

    return isOwnPaymentCompany;
  },

  isEditingTermsOfSaleAllowed(netAuctionClass, categoryId) {
    return (
      netAuctionClass === classService.CLASS_FORECLOSURE ||
      netAuctionClass === classService.CLASS_BANKRUPTCIES ||
      this.isRealEstate(categoryId)
    );
  },

  doesClassHaveMandatoryOwnPayment,

  doesCategoryHaveMandatoryOwnPayment,

  translateStatus: (status, netAuctionStatus, cancelDate, postTrading, annulDate) => {
    const translatedNetAuctionStatuses = {
      [netAuctionStatuses.WAITING_FOR_BIDS]: 'Käynnissä',
      [netAuctionStatuses.ENDED]: 'Huutaminen päättynyt',
      [netAuctionStatuses.APPROVED]: 'Hyväksytty',
      [netAuctionStatuses.REJECTED]: 'Hylätty',
      [netAuctionStatuses.SOLD]: 'Myyty',
      [netAuctionStatuses.NO_BIDS]: 'Rauennut',
    };

    if (annulDate) {
      return 'Purettu';
    }

    if (cancelDate) {
      return 'Peruttu';
    }

    if (status === entryStatuses.UNPUBLISHED) {
      return 'Julkaisematon';
    }

    if (postTrading && netAuctionStatus === netAuctionStatuses.ENDED) {
      return 'Hintaneuvottelussa';
    }

    return translatedNetAuctionStatuses[netAuctionStatus];
  },

  simpleTranslateStatus(netAuctionStatus) {
    return this.translateStatus(null, netAuctionStatus, null, null);
  },

  calculateNextBidTotalSum(suggestedBid, vatPerc, delivery) {
    return (
      this.calculateRoundedSumWithVat(suggestedBid, vatPerc) +
      (delivery ? getSmallestPossibleDeliveryExpenses(delivery) : 0)
    );
  },

  calculateSumWithVat: (amountWithoutVat, vat) => amountWithoutVat * (1 + vat / 100),

  calculateRoundedSumWithVat: (amountWithoutVat, vat) => Math.ceil(round(amountWithoutVat * (1 + vat / 100))),

  calculateVatAmount: (amountWithVat, vat) => (vat * amountWithVat) / (100 + vat),

  calculateSumWithoutVat: (amountWithVat, vat) => amountWithVat / (1 + vat / 100),

  getEndedStatusForEntryPage: entry => {
    if (entry.entryStatus === entryStatuses.CANCELLED) {
      return 'Huutokauppa on peruttu';
    }

    switch (entry.netAuctionStatus) {
      case netAuctionStatuses.WAITING_FOR_BIDS:
        if (entry.bidCount) {
          return 'Päättynyt, tarkistetaan tarjouksia';
        }

        if (entry.isContinuable) {
          return 'Ei tarjouksia, huutokauppa jatkuu kohta';
        }

        return 'Päättynyt, ei tarjouksia';

      case netAuctionStatuses.ENDED:
        return 'Päättynyt, tarkistetaan tarjouksia';

      case netAuctionStatuses.APPROVED:
        return 'Päättynyt, tarjous hyväksytty';

      case netAuctionStatuses.REJECTED:
        return 'Päättynyt, tarjous hylätty';

      case netAuctionStatuses.SOLD:
        return 'Myyty';

      case netAuctionStatuses.NO_BIDS:
        return 'Päättynyt, ei tarjouksia';

      default:
    }
  },

  getEntryCustomerFundsStatus: (entry, entryNetAuctionPaid) => {
    if (entry.netAuctionStatus !== netAuctionStatuses.SOLD) {
      return 'Odottaa ostajan maksua';
    }

    if (entry.manualCustomerFundsTransferredAt) {
      const paidAt = dayjs(entry.manualCustomerFundsTransferredAt);

      return `Maksettu manuaalisesti ${formatDateLong(paidAt)}`;
    }

    if (!entryNetAuctionPaid) {
      return 'Merkitty käsin maksetuksi';
    }

    if (!entryNetAuctionPaid.customerfundsFile) {
      return 'Odottaa siirtoa myyjälle (aikaisintaan seuraavana päivänä kohteen maksusta)';
    }

    if (!entryNetAuctionPaid.customerfundsFile.transferDate) {
      return 'Siirto käynnissä (siirretään tänään)';
    }

    return `Siirretty ${formatDateLong(entryNetAuctionPaid.customerfundsFile.transferDate)}`;
  },

  isPayable(entry) {
    return (
      entry.ownPayment === ownPaymentStatus.NO &&
      !entryService.isCancelled(entry) &&
      this.isApproved(entry) &&
      (entryService.isPublished(entry) || this.isEnded(entry.status, entry.netAuctionStatus))
    );
  },

  isPaid: entry => entry.netAuctionStatus === netAuctionStatuses.SOLD,

  isEnded: (entryStatus, netAuctionStatus) =>
    entryStatus !== entryStatuses.UNPUBLISHED && netAuctionStatus !== netAuctionStatuses.WAITING_FOR_BIDS,

  isPostTradeable: highestBid => highestBid >= 3000,

  isApproved: entry =>
    entry.netauction_status === netAuctionStatuses.APPROVED || entry.netAuctionStatus === netAuctionStatuses.APPROVED,

  isWaitingForBids: entry => entry.netauction_status === netAuctionStatuses.WAITING_FOR_BIDS,

  isRunnerUpOfferActive: entry => entry.runnerUpOfferStatus === runnerUpOfferStatuses.ACTIVE,

  isPaymentOverdue: (entry, now = dayjs()) => {
    if (entry.payment_done || !entry.payment_overdue) {
      return false;
    }

    return dayjs(now).isAfter(dayjs(entry.payment_overdue).endOf('day'));
  },
  isCancellable(entry) {
    if (entryService.isCancelled(entry)) {
      return false;
    }

    if (entryService.isWaitingForPublishing(entry) || !entryService.isPublished(entry)) {
      return true;
    }

    if (!entryService.isPublished(entry)) {
      return true;
    }

    const isNotCancellable =
      (!this.isWaitingForBids(entry) && !this.isApproved(entry)) || this.isRunnerUpOfferActive(entry);

    if (isNotCancellable) {
      return false;
    }

    if (jwt.getUser() ? jwt.getUser().isAdmin : false) {
      return true;
    }

    if (jwt.getUser() ? jwt.getUser().isSeller : false) {
      if (this.isApproved(entry)) {
        return this.isPaymentOverdue(entry);
      }

      if (!this.hasBeenBidded(entry)) {
        return !this.isSoldToHighestBidder(entry.reserve_price) || this.isPublishedLessThanHourAgo(entry.auction_start);
      }
    }

    return false;
  },

  canBeMarkedAsPaidByAdmin(entry) {
    return (
      !entryService.isCancelled(entry) &&
      this.isApproved(entry) &&
      (entryService.isPublished(entry) || this.isEnded(entry))
    );
  },

  hasBeenBidded: entry => parseInt(entry.bid_count, 10) > 0,
  isSoldToHighestBidder: reservePrice => (reservePrice !== null ? parseInt(reservePrice, 10) === 0 : false),

  isPublishedLessThanHourAgo: auctionStart => dayjs(dayjs()).diff(auctionStart, 'h', true) < 1,

  isDeliveryExpensesUnavailable: deliveryExpensesUnavailable => !!parseInt(deliveryExpensesUnavailable, 10),

  containsRetrieval: method => method !== 100,

  isContinuousAuction: continuousAuction => !!parseInt(continuousAuction, 10),

  isCancelFreeOfCharge: reason =>
    [
      cancellationReasons.CANCELLATION_REASON_MISTAKE,
      cancellationReasons.CANCELLATION_REASON_RECLAMATION,
      cancellationReasons.CANCELLATION_REASON_BUYER_CANCEL,
      cancellationReasons.CANCELLATION_REASON_UNPUBLISHED,
    ].includes(reason),

  isDashboardHidden,

  isOverDashboardEntryLimit: entryCount => entryCount > dashboardEntryLimit,

  isBackToTopShown: entryCount => entryCount > backToTopLinkLimit,

  isSortingEnabled: type => type !== 'dashboard',

  isBillOfSaleAvailable: entry =>
    (entry.netauction_status === netAuctionStatuses.SOLD || entry.netAuctionStatus === netAuctionStatuses.SOLD) &&
    (entry.own_payment === 'N' || entry.ownPayment === 'N') &&
    (dayjs(entry.payment_done) >= config.mezzoforteAsSellerStartDate ||
      dayjs(entry.paymentDone) >= config.mezzoforteAsSellerStartDate),

  isCustomerFundsMarkableManually(entry, netAuctionPaid) {
    return (
      (this.isPayable(entry) ||
        (entry.netAuctionStatus === netAuctionStatuses.SOLD &&
          !(netAuctionPaid && netAuctionPaid.customerfundsFile) &&
          !entry.manualCustomerFundsTransferredAt)) &&
      (!entry.customerFundsFrozenAt || entry.customerFundsUnfrozenAt)
    );
  },

  areCustomerFundsTransferred: (entry, netAuctionPaid) => {
    const areCustomerFundsTransferredNormally =
      netAuctionPaid && netAuctionPaid.customerfundsFile && netAuctionPaid.customerfundsFile.transferDate;

    const areCustomerFundsTransferredManually = entry && entry.manualCustomerFundsTransferredAt;

    return !!(areCustomerFundsTransferredNormally || areCustomerFundsTransferredManually);
  },

  validateFinishedPostTrading: (highestBid, sum, sumWithoutVat) => {
    if (!Number.isInteger(sum)) {
      throw new SubmissionError({
        sum: 'Kauppahinnan tulee olla kokonaisluku.',
      });
    }

    if (sumWithoutVat < highestBid) {
      throw new SubmissionError({
        sum: 'Kauppahinnan tulee olla suurempi tai yhtä suuri kuin korkein tarjous.',
      });
    }

    return true;
  },

  validateShipment: (carrier, weight, parcels) => {
    if (carrier === 'matkahuolto') {
      if (weight <= 0) {
        throw new SubmissionError({
          sum: 'Lähetyksen painon tulee olla vähintään yksi.',
        });
      }
    }

    if (parcels <= 0) {
      throw new SubmissionError({
        sum: 'Lähetyksen kollien määrän tulee olla vähintään yksi.',
      });
    }

    return true;
  },

  findFirstTypeWithEntries: listCounts => {
    const sectionsWithEntries = listCounts
      .get('sections')
      .filter(count => count > 0)
      .keySeq();

    if (sectionsWithEntries.size === 0) {
      return 'ongoing';
    }

    return sectionsWithEntries
      .sortBy(key => {
        const order = [
          'ongoing',
          'unpublished',
          'ended',
          'post-trading',
          'approved',
          'deliverable',
          'handover',
          'archive',
          'draft',
        ];

        return order.indexOf(key);
      })
      .first();
  },

  generateEntryUrl: (entryId, entrySlug = '') => (entrySlug ? `/kohde/${entryId}/${entrySlug}` : `/kohde/${entryId}`),

  trimTitle: title => (title ? title.replace(/\xA0/u, ' ').trim() : ''), // Removes UTF-8 non-breaking spaces.

  openShipmentLabel: shipment => {
    const windowObj = window.open(shipment.link, shipment.label_file_id);
    windowObj.opener = null;

    // automatically open print dialog once pdf has loaded (works in Chrome at least)
    windowObj[windowObj.addEventListener ? 'addEventListener' : 'attachEvent'](
      `${windowObj.attachEvent ? 'on' : ''}load`,
      () => {
        windowObj.print();
      },
      false
    );
  },

  parseAdminListTerm: term => {
    if (!term) {
      return {};
    }

    // Split by spaces that aren't inside quotes.
    const parts = term.match(/(?:[^\s"]+|"[^"]*")+/g, term);

    if (!parts || !parts.length) {
      return {};
    }

    return parts.reduce((result, part) => {
      const trimmedPart = part.trim();

      if (trimmedPart.length === 0) {
        return result;
      }

      const matches = trimmedPart.match(/^([^:]+)(:"([^"]*)")?$/);

      if (matches[3] === undefined) {
        if (result.keyword) {
          return { ...result, keyword: `${result.keyword} ${matches[1]}` };
        }

        return { ...result, keyword: matches[1] };
      }

      return { ...result, [matches[1]]: matches[3] };
    }, {});
  },

  createAdminListTerm: fields =>
    Object.keys(fields)
      .reduce((result, key) => {
        if (key === 'keyword') {
          return `${result} ${fields[key]}`;
        }

        return `${result} ${key}:"${fields[key].replace('"', '').trim()}"`;
      }, '')
      .trim(),

  // API calls

  getEntryFullInfo: id =>
    createClient()
      .get(`/net-auctions/${id}/full-info`)
      .then(response => response.data),

  // Public

  getEntry: id =>
    createClient()
      .get(`/net-auctions/${id}`)
      .then(response => response.data),

  fetchEntryWithSlug: (id, slug) =>
    createClient()
      .get(`/net-auctions/${id}/slug/${slug}`)
      .then(response => response.data),

  getEntries: (category, order, limit) =>
    createClient()
      .get('/net-auctions/list', {
        params: { category, jarjestys: order, limit },
      })
      .then(response => response.data),

  getGroupEntries: (groupId, page) =>
    createClient()
      .get('/net-auctions/list', {
        params: { groupId, sivu: page },
      })
      .then(response => response.data),

  getHandPickedEntriesById: entryIds =>
    createClient()
      .post('/net-auctions/list/hand-picked-by-id', {
        entryIds,
      })
      .then(response => response.data),

  getPopularEntries: categoryId =>
    createClient()
      .get(`/net-auctions/list/popular/${categoryId}`)
      .then(response => response.data),

  getEntriesBySearchTerms: searchTerms =>
    createClient()
      .post('/net-auctions/list/search', {
        searchTerms,
      })
      .then(response => response.data),

  quickEditCategory: (entryId, categoryId) =>
    createClient()
      .post(`/net-auctions/${entryId}/quick-edit`, { categoryId })
      .then(response => response.data),

  getReceipt: id =>
    createClient()
      .get(`/net-auctions/${id}/receipt`)
      .then(response => ({
        filename: response.data.filename,
        content: new Blob([base64ToBlob(response.data.content)], {
          type: 'application/pdf',
        }),
      })),
  saveSelectionsForEntry: async (entryId, deliveryType, receiptType) =>
    createClient()
      .post(`/net-auctions/${entryId}/selection`, {
        deliveryType,
        receiptType,
      })
      .then(response => response),
  // Admin

  fetchListInfo: (term = null, company = null, includeOld = null, cancelPreviousRequests = true) => {
    if (cancelPreviousRequests) {
      cancelTokens.forEach(cancelToken => cancelToken());
    }

    return createClient()
      .post(
        '/net-auctions/list-info',
        { term, company, includeOld },
        { cancelToken: new axios.CancelToken(cancel => cancelTokens.push(cancel)) }
      )
      .then(response => response.data);
  },

  fetchEntries: (type, term = null, company = null, includeOld = null, cancelPreviousRequests = true) => {
    if (cancelPreviousRequests) {
      cancelTokens.forEach(cancelToken => cancelToken());
    }

    return createClient()
      .post(
        '/net-auctions/net-auction-list',
        { type, term, company, includeOld },
        { cancelToken: new axios.CancelToken(cancel => cancelTokens.push(cancel)) }
      )
      .then(response => response.data);
  },

  fetchEntriesForDashboard: (types, term = null, company = null, includeOld = null, cancelPreviousRequests = true) => {
    if (cancelPreviousRequests) {
      cancelTokens.forEach(cancelToken => cancelToken());
    }

    return axios
      .all(
        types.map(type =>
          createClient().post(
            '/net-auctions/net-auction-list',
            { type, term, company, includeOld, limit: dashboardEntryLimit },
            { cancelToken: new axios.CancelToken(cancel => cancelTokens.push(cancel)) }
          )
        )
      )
      .then(responses =>
        responses.reduce(
          (result, response, index) => ({
            ...result,
            [types[index]]: response.data,
          }),
          {}
        )
      );
  },

  fetchDelivery: entryId =>
    createClient()
      .get(`/net-auctions/${entryId}/delivery`)
      .then(response => response.data),

  fetchReport: (type, term = null, company = null) =>
    createClient()
      .get('/net-auctions/report', { params: { type, term, company } })
      .then(response => ({
        filename: response.data.filename,
        content: new Blob([base64ToBlob(response.data.content)], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      })),

  fetchReportByStatus: (action = null, term = null, company = null, sortByName = null) =>
    createClient()
      .post(action, { term, company, sortByName })
      .then(response => ({
        filename: response.data.filename,
        content: new Blob([base64ToBlob(response.data.content)], {
          type: 'application/pdf',
        }),
      })),

  fetchDeliverableReport(term = null, company = null, sortByName = null) {
    return this.fetchReportByStatus('/net-auctions/printable-deliverable-report', term, company, sortByName);
  },

  fetchHandoverReport(term = null, company = null, sortByName = null) {
    return this.fetchReportByStatus('/net-auctions/printable-handover-report', term, company, sortByName);
  },

  markAsPaid: entryId =>
    createClient()
      .post(`/net-auctions/${entryId}/mark-as-paid`)
      .then(response => response.data),

  markAsDelivered: entryIds =>
    createClient()
      .post('/net-auctions/delivery', { entryIds })
      .then(response => response.data),

  markAsHandover: entryIds =>
    createClient()
      .post('/net-auctions/handover', { entryIds })
      .then(response => response.data),

  markAsArchived: entryIds =>
    createClient()
      .post('/net-auctions/archive', { entryIds })
      .then(response => response.data),

  checkCancellable: entryId =>
    createClient()
      .get(`/net-auctions/${entryId}/cancellable`)
      .then(response => response.data),

  cancel: (entryId, cancelReason, cancelExplanation, resetCancelPrice) =>
    createClient()
      .post(`/net-auctions/${entryId}/cancel`, {
        cancelReason,
        cancelExplanation,
        resetCancelPrice,
      })
      .then(response => response.data),

  createShipment: (entryId, carrier, service, description, weight, parcels) =>
    createClient()
      .post(`/net-auctions/${entryId}/shipment`, {
        carrier,
        service,
        description,
        weight,
        parcels,
      })
      .then(response => response.data),

  // TODO: Legacy, should be moved to /api.

  startPostTrading: entryId =>
    createClient()
      .patch(`/net-auctions/${entryId}/post-trading/activate`)
      .then(response => response.data),

  finishPostTrading: (entryId, sum) =>
    createClient()
      .patch(`/net-auctions/${entryId}/post-trading/approve`, {
        approvedSum: sum,
      })
      .then(response => response.data),

  // Entry page actions

  bid: (entryId, bid) =>
    createClient()
      .post('/net-auction-bid/bid', { id: entryId, bid_amount: bid })
      .then(response => response.data),

  rejectBid: bidId =>
    createClient()
      .post(`/net-auction-bid/${bidId}/reject`)
      .then(response => response.data),

  activateBiddingBot: (entryId, maxBid) =>
    createClient()
      .post('/net-auction-bid/autobid', { id: entryId, bid_max: maxBid })
      .then(response => response.data),

  deactivateBiddingBot: entryId =>
    createClient()
      .post('/net-auction-bid/autobiddeactivate', { id: entryId })
      .then(response => response.data),

  toggleSmsNotification: (entryId, isEnabled) =>
    createClient()
      .patch(`/favorites/${entryId}`, { entryId, notify: isEnabled })
      .then(response => response.data),

  updateSmsNotificationPhoneNumber: (userId, phoneNumber) =>
    createClient()
      .patch(`/users/${userId}/update-phone-number`, { phoneNumber })
      .then(response => response.data),

  fetchRunnerUpOfferInfo: entryId =>
    createClient()
      .get(`/runner-up-offers/${entryId}/info`)
      .then(response => response.data),

  offerToRunnerUp: entryId =>
    createClient()
      .post('/runner-up-offers', { entryId })
      .then(response => response.data),

  fetchRunnerUpOffer: entryId =>
    createClient()
      .get(`/runner-up-offers/${entryId}`)
      .then(response => response.data),

  acceptRunnerUpOffer: entryId =>
    createClient()
      .post(`/runner-up-offers/${entryId}/accept`)
      .then(response => response.data),

  declineRunnerUpOffer: entryId =>
    createClient()
      .post(`/runner-up-offers/${entryId}/decline`)
      .then(response => response.data),

  isBankruptcy: entryClass => entryClass === classService.CLASS_BANKRUPTCIES,

  isBroker: entryClass => entryClass === classService.CLASS_BROKER_SERVICE,

  isEntryClassPublicSector: entryClass => entryClass === classService.CLASS_PUBLIC_SECTOR,

  isApartmentPartOfHousingCompany: apartmentType => apartmentTypesPartOfHousingCompany.includes(apartmentType),

  isRealEstate: categoryId =>
    [
      categories.CATEGORY_APARTMENT,
      categories.CATEGORY_TIMESHARE,
      categories.CATEGORY_PREMISES,
      categories.CATEGORY_PLOT,
      categories.CATEGORY_VACATION_HOMES,
      categories.CATEGORY_GARAGES,
    ].includes(Number(categoryId)),

  isPlot: categoryId => [categories.CATEGORY_PLOT].includes(categoryId),

  isVehicle: categoryId =>
    [
      categories.CATEGORY_PASSENGER_CAR,
      categories.CATEGORY_VAN,
      categories.CATEGORY_TRUCK,
      categories.CATEGORY_BOAT,
      categories.CATEGORY_MOTORCYCLE,
      categories.CATEGORY_CARAVAN_AND_TRAILER,
      categories.CATEGORY_OTHER_VEHICLE,
      categories.CATEGORY_SNOWMOBILE,
      categories.CATEGORY_HEAVY_EQUIPMENT,
    ].includes(categoryId),

  isPremises: categoryId => parseInt(categoryId, 10) === categories.CATEGORY_PREMISES,

  isApartment: categoryId => parseInt(categoryId, 10) === categories.CATEGORY_APARTMENT,

  hasPassengerCarFields: categoryId =>
    parseInt(categoryId, 10) === categories.CATEGORY_PASSENGER_CAR ||
    parseInt(categoryId, 10) === categories.CATEGORY_VAN,

  hasDebt: entry => entry.metadata && entry.metadata.debt && parseInt(entry.metadata.debt, 10) > 0,

  RUNNER_UP_OFFER_EXPIRES_AFTER_DAYS: 1,

  fetchBillOfSale: (entryId, role) =>
    createClient()
      .get(`/net-auctions/${entryId}/file/bill-of-sale`, { params: { role } })
      .then(response => ({
        filename: response.data.filename,
        content: new Blob([base64ToBlob(response.data.content)], {
          type: 'application/pdf',
        }),
      })),

  renewNetAuction: (entryId, data) => {
    const params = {
      ca_entry_id: entryId,
      ca_start_price: data.ca_start_price,
      ca_release_type: data.ca_release_type,
      ca_publish_date: data.ca_publish_date ? data.ca_publish_date.format() : null,
      ca_auction_end: data.ca_auction_end.format(),
    };

    return createClient()
      .post(`/net-auctions/drafts/copy-to-continuous-auction/net-auction/${entryId}`, { ...params })
      .then(response => response.data);
  },

  fetchEntriesForModeration: () =>
    createClient()
      .post('/net-auctions/net-auction-list', { type: 'moderation' })
      .then(response => response.data),

  setManualCustomerFundsPayment: (entryId, data) =>
    createClient()
      .patch(`/net-auctions/${entryId}`, {
        ...data,
        type: 'customer-funds-dates',
      })
      .then(response => response.data),

  updateModerationStatus: (entryId, status) =>
    createClient()
      .patch(`/net-auctions/${entryId}`, {
        entryId,
        status,
        type: 'moderation',
      })
      .then(response => response.data),

  fetchDataForToolbar: entryId =>
    createClient()
      .get(`/net-auctions/${entryId}/toolbar`)
      .then(response => response.data),

  getConsumerNetAuctions: type =>
    createClient()
      .get('/net-auctions/consumer', { params: { type } })
      .then(response => response.data),

  getForDecideOnSellConsumerNetAuction: entryId =>
    createClient()
      .get(`/net-auctions/consumer/${entryId}/decide-on-sell`)
      .then(response => response.data),

  decideOnSellConsumerNetAuction: (entryId, isApproved) =>
    createClient()
      .post(`/net-auctions/consumer/${entryId}/decide-on-sell`, { isApproved })
      .then(response => response.data),
};

export const getExtraDescription = (metadata, isConsumerNetAuction) => {
  if (isConsumerNetAuction && metadata.withdrawnFromTraffic === metadataService.WITHDRAWN_FROM_TRAFFIC_YES) {
    return `
      Ajoneuvo on poistettu liikennekäytöstä, mutta kaikki ajoneuvot otetaan oletuksena
      liikennekäyttöön ennen luovutusta. Jos voittaja haluaa luovutuksen liikennekäytöstä
      poistettuna, hänen tulee olla maksun jälkeen yhteydessä Huutokaupat.comin asiakaspalveluun.`;
  }

  return null;
};
