import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { OwnInfoAPIResponse } from '../types/ApiResponse';

const client = axios.create({
  withCredentials: true,
  xsrfCookieName: 'csrf-token',
  xsrfHeaderName: 'X-Csrf-Token',
  headers: {
    'Cache-Control': 'no-cache',
  },
});

const fetchCurrentUser = async (): Promise<OwnInfoAPIResponse> =>
  client.get<OwnInfoAPIResponse>('/api/users/current-user').then<OwnInfoAPIResponse>(response => response.data);

export const useSession = () => {
  const userQuery = useQuery<OwnInfoAPIResponse>(['currentUser'], fetchCurrentUser, {
    staleTime: 1000 * 60,
  });
  return {
    currentUser: userQuery,
  };
};
