// @flow

import { Map, List } from 'immutable';
import createRootReducer from '../../ducks';

/**
 * Transforms plain objects/etc. to Immutable types for Redux.
 */
export default function transformInitialState(state: Object): Object {
  const initialState = { ...state };
  const emptyState = createRootReducer()({}, {});

  if (initialState.category) {
    initialState.category.categoriesWithCounts = List(initialState.category.categoriesWithCounts);
    initialState.category = emptyState.category.merge(Map(initialState.category));
  }

  if (initialState.netAuctionClass) {
    initialState.netAuctionClass.classesWithCounts = Map(initialState.netAuctionClass.classesWithCounts);
    initialState.netAuctionClass = emptyState.netAuctionClass.merge(Map(initialState.netAuctionClass));
  }

  if (initialState.region) {
    initialState.region.regions = List(initialState.region.regions);
    initialState.region = emptyState.region.merge(Map(initialState.region));
  }

  if (initialState.favorite) {
    initialState.favorite.favoriteIds = List(initialState.favorite.favoriteIds);
    initialState.favorite.favoriteIdsWithNotifications = List(initialState.favorite.favoriteIdsWithNotifications);
    initialState.favorite = emptyState.favorite.merge(Map(initialState.favorite));
  }

  if (initialState.userDetails) {
    initialState.userDetails = emptyState.userDetails.set('details', initialState.userDetails);
  }

  return initialState;
}
