import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { PasswordInput, PasswordInputProps } from '@mezzoforte/forge';

type RenderProps = FieldRenderProps<string>;

export type PasswordInputFieldProps = RenderProps & Omit<PasswordInputProps, keyof RenderProps['input']>;

export function PasswordInputField({ input, meta, ...inputProps }: PasswordInputFieldProps) {
  return (
    <PasswordInput
      w="100%"
      label=""
      isInvalid={meta.invalid && meta.touched}
      errorInfo={meta.error ?? meta.submitError}
      {...inputProps}
      {...input}
    />
  );
}
