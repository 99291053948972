import dayjs from 'dayjs';
import { List, Map } from 'immutable';
import importedEntryService from '../src/net-auction/imported-entry-service';

const FETCH_IMPORTED_ENTRIES = 'huutokaupat/imported-entry/FETCH_IMPORTED_ENTRIES';
const FETCH_IMPORTED_ENTRIES_DONE = 'huutokaupat/imported-entry/FETCH_IMPORTED_ENTRIES_DONE';
const FETCH_IMPORTED_ENTRIES_FAILED = 'huutokaupat/imported-entry/FETCH_IMPORTED_ENTRIES_FAILED';

const REMOVE_IMPORTED_ENTRY = 'huutokaupat/imported-entry/REMOVE_IMPORTED_ENTRY';
const REMOVE_IMPORTED_ENTRY_DONE = 'huutokaupat/imported-entry/REMOVE_IMPORTED_ENTRY_DONE';
const REMOVE_IMPORTED_ENTRY_FAILED = 'huutokaupat/imported-entry/REMOVE_IMPORTED_ENTRY_FAILED';

const initialState = new Map({
  loading: false,
  failed: false,
  importedEntries: new List(),
  updatedAt: null,
});

const findImportedEntryIndexById = function (entries, id) {
  return parseInt(
    entries.findIndex(entry => parseInt(entry.id, 10) === parseInt(id, 10)),
    10
  );
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_IMPORTED_ENTRIES:
      return state.set('loading', true).set('importedEntries', new List());

    case FETCH_IMPORTED_ENTRIES_DONE:
      return state
        .set('loading', false)
        .set('failed', false)
        .set('updatedAt', dayjs())
        .set('importedEntries', new List(action.payload));

    case FETCH_IMPORTED_ENTRIES_FAILED:
      return state.set('loading', false).set('failed', true);

    case REMOVE_IMPORTED_ENTRY:
      return state;

    case REMOVE_IMPORTED_ENTRY_DONE:
      return state
        .set('updatedAt', dayjs())
        .updateIn(
          ['importedEntries', findImportedEntryIndexById(state.get('importedEntries'), action.payload)],
          entry => ({ ...entry, rejected: true })
        );

    case REMOVE_IMPORTED_ENTRY_FAILED:
      return state
        .set('updatedAt', dayjs())
        .updateIn(
          ['importedEntries', findImportedEntryIndexById(state.get('importedEntries'), action.payload)],
          entry => ({ ...entry, failed: true })
        );

    default:
      return state;
  }
};

export function fetchImportedEntries(companyId) {
  return dispatch => {
    dispatch({ type: FETCH_IMPORTED_ENTRIES });

    return importedEntryService
      .fetchAll(companyId)
      .then(importedEntries =>
        dispatch({
          type: FETCH_IMPORTED_ENTRIES_DONE,
          payload: importedEntries,
        })
      )
      .catch(error => dispatch({ type: FETCH_IMPORTED_ENTRIES_FAILED, payload: error }));
  };
}

export function removeImportedEntry(id) {
  return dispatch =>
    importedEntryService
      .delete(id)
      .then(() => dispatch({ type: REMOVE_IMPORTED_ENTRY_DONE, payload: id }))
      .catch(error => dispatch({ type: REMOVE_IMPORTED_ENTRY_FAILED, payload: error }));
}
