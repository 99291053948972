// @flow

import { fromJS, List, Map, Seq } from 'immutable';
import { SubmissionError } from 'redux-form';
import netAuctionService from '../src/net-auction/net-auction-service';
import entryService, { CANCELLATION_REASON_RUNNER_UP_OFFER } from '../src/entry/entry-service';
import netAuctionDraftService from '../src/net-auction/draft-service';
import { listTypes } from '../components/seller/net-auction/NetAuctionListFilter';
import {
  approveBid as doApproveBid,
  rejectBid as doRejectBid,
} from '../src/net-auction/approval/net-auction-approval-service';

const FETCH_LIST_INFO = 'huutokaupat/net-auction/FETCH_LIST_INFO';
const FETCH_LIST_INFO_DONE = 'huutokaupat/net-auction/FETCH_LIST_INFO_DONE';
const FETCH_LIST_INFO_FAILED = 'huutokaupat/net-auction/FETCH_LIST_INFO_FAILED';

const FETCH_ENTRIES_AND_DRAFTS = 'huutokaupat/net-auction/FETCH_ENTRIES_AND_DRAFTS';
const FETCH_ENTRIES_DONE = 'huutokaupat/net-auction/FETCH_ENTRIES_DONE';
const FETCH_ENTRIES_FAILED = 'huutokaupat/net-auction/FETCH_ENTRIES_FAILED';
const FETCH_DRAFTS_DONE = 'huutokaupat/net-auction/FETCH_DRAFTS_DONE';
const FETCH_DRAFTS_FAILED = 'huutokaupat/net-auction/FETCH_DRAFTS_FAILED';
const FETCH_ENTRIES_AND_DRAFTS_DONE = 'huutokaupat/net-auction/FETCH_ENTRIES_AND_DRAFTS_DONE';

const FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD = 'huutokaupat/net-auction/FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD';
const FETCH_ENTRIES_FOR_DASHBOARD_DONE = 'huutokaupat/net-auction/FETCH_ENTRIES_FOR_DASHBOARD_DONE';
const FETCH_ENTRIES_FOR_DASHBOARD_FAILED = 'huutokaupat/net-auction/FETCH_ENTRIES_FOR_DASHBOARD_FAILED';
const FETCH_DRAFTS_FOR_DASHBOARD_DONE = 'huutokaupat/net-auction/FETCH_DRAFTS_FOR_DASHBOARD_DONE';
const FETCH_DRAFTS_FOR_DASHBOARD_FAILED = 'huutokaupat/net-auction/FETCH_DRAFTS_FOR_DASHBOARD_FAILED';
const FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD_DONE =
  'huutokaupat/net-auction/FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD_DONE';

const RESET_LISTS = 'huutokaupat/net-auction/RESET_LISTS';

const DELETE_DRAFT = 'huutokaupat/net-auction/DELETE_DRAFT';
const DELETE_DRAFT_DONE = 'huutokaupat/net-auction/DELETE_DRAFT_DONE';
const DELETE_DRAFT_FAILED = 'huutokaupat/net-auction/DELETE_DRAFT_FAILED';

const DELETE_DRAFTS = 'huutokaupat/net-auction/DELETE_DRAFTS';
const DELETE_DRAFTS_DONE = 'huutokaupat/net-auction/DELETE_DRAFTS_DONE';
const DELETE_DRAFTS_FAILED = 'huutokaupat/net-auction/DELETE_DRAFTS_FAILED';

const APPROVE_BID = 'huutokaupat/net-auction/APPROVE_BID';
const APPROVE_BID_DONE = 'huutokaupat/net-auction/APPROVE_BID_DONE';
const APPROVE_BID_FAILED = 'huutokaupat/net-auction/APPROVE_BID_FAILED';

const REJECT_BID = 'huutokaupat/net-auction/REJECT_BID';
const REJECT_BID_DONE = 'huutokaupat/net-auction/REJECT_BID_DONE';
const REJECT_BID_FAILED = 'huutokaupat/net-auction/REJECT_BID_FAILED';

const START_REJECT_BID = 'huutokaupat/net-auction/START_REJECT_BID';
const CLEAR_REJECT_BID = 'huutokaupat/net-auction/CLEAR_REJECT_BID';

const MARK_AS_PAID = 'huutokaupat/net-auction/MARK_AS_PAID';
const MARK_AS_PAID_DONE = 'huutokaupat/net-auction/MARK_AS_PAID_DONE';
const MARK_AS_PAID_FAILED = 'huutokaupat/net-auction/MARK_AS_PAID_FAILED';

const MARK_AS_DELIVERED = 'huutokaupat/net-auction/MARK_AS_DELIVERED';
const MARK_AS_DELIVERED_DONE = 'huutokaupat/net-auction/MARK_AS_DELIVERED_DONE';
const MARK_AS_DELIVERED_FAILED = 'huutokaupat/net-auction/MARK_AS_DELIVERED_FAILED';

const MARK_AS_HANDOVER = 'huutokaupat/net-auction/MARK_AS_HANDOVER';
const MARK_AS_HANDOVER_DONE = 'huutokaupat/net-auction/MARK_AS_HANDOVER_DONE';
const MARK_AS_HANDOVER_FAILED = 'huutokaupat/net-auction/MARK_AS_HANDOVER_FAILED';

const MARK_AS_ARCHIVED = 'huutokaupat/net-auction/MARK_AS_ARCHIVED';
const MARK_AS_ARCHIVED_DONE = 'huutokaupat/net-auction/MARK_AS_ARCHIVED_DONE';
const MARK_AS_ARCHIVED_FAILED = 'huutokaupat/net-auction/MARK_AS_ARCHIVED_FAILED';

const CHECK_CANCELLABLE = 'huutokaupat/net-auction/CHECK_CANCELLABLE';
const CHECK_CANCELLABLE_DONE = 'huutokaupat/net-auction/CHECK_CANCELLABLE_DONE';
const CHECK_CANCELLABLE_FAILED = 'huutokaupat/net-auction/CHECK_CANCELLABLE_FAILED';
const CLEAR_CANCELLABLE = 'huutokaupat/net-auction/CLEAR_CANCELLABLE';

const CANCEL = 'huutokaupat/net-auction/CANCEL';
const CANCEL_DONE = 'huutokaupat/net-auction/CANCEL_DONE';
const CANCEL_FAILED = 'huutokaupat/net-auction/CANCEL_FAILED';

const FETCH_RUNNER_UP_OFFER_INFO = 'huutokaupat/net-auction/FETCH_RUNNER_UP_OFFER_INFO';
const FETCH_RUNNER_UP_OFFER_INFO_DONE = 'huutokaupat/net-auction/FETCH_RUNNER_UP_OFFER_INFO_DONE';
const FETCH_RUNNER_UP_OFFER_INFO_FAILED = 'huutokaupat/net-auction/FETCH_RUNNER_UP_OFFER_INFO_FAILED';
const CLEAR_RUNNER_UP_OFFER_INFO = 'huutokaupat/net-auction/CLEAR_RUNNER_UP_OFFER_INFO';

const OFFER_TO_RUNNER_UP = 'huutokaupat/net-auction/OFFER_TO_RUNNER_UP_BIDDER';
const OFFER_TO_RUNNER_UP_DONE = 'huutokaupat/net-auction/OFFER_TO_RUNNER_UP_BIDDER_DONE';
const OFFER_TO_RUNNER_UP_FAILED = 'huutokaupat/net-auction/OFFER_TO_RUNNER_UP_BIDDER_FAILED';

const OPEN_CREATE_SHIPMENT_MODAL = 'huutokaupat/net-auction/OPEN_CREATE_SHIPMENT_MODAL';
const CLOSE_CREATE_SHIPMENT_MODAL = 'huutokaupat/net-auction/CLOSE_CREATE_SHIPMENT_MODAL';

const CREATE_SHIPMENT = 'huutokaupat/net-auction/CREATE_SHIPMENT';
const CREATE_SHIPMENT_DONE = 'huutokaupat/net-auction/CREATE_SHIPMENT_DONE';
const CREATE_SHIPMENT_FAILED = 'huutokaupat/net-auction/CREATE_SHIPMENT_FAILED';

const START_POST_TRADING = 'huutokaupat/net-auction/START_POST_TRADING';
const START_POST_TRADING_DONE = 'huutokaupat/net-auction/START_POST_TRADING_DONE';
const START_POST_TRADING_FAILED = 'huutokaupat/net-auction/START_POST_TRADING_FAILED';

const OPEN_FINISH_POST_TRADING_MODAL = 'huutokaupat/net-auction/OPEN_FINISH_POST_TRADING_MODAL';
const CLOSE_FINISH_POST_TRADING_MODAL = 'huutokaupat/net-auction/CLOSE_FINISH_POST_TRADING_MODAL';

const FINISH_POST_TRADING = 'huutokaupat/net-auction/FINISH_POST_TRADING';
const FINISH_POST_TRADING_DONE = 'huutokaupat/net-auction/FINISH_POST_TRADING_DONE';
const FINISH_POST_TRADING_FAILED = 'huutokaupat/net-auction/FINISH_POST_TRADING_FAILED';

const CANCEL_POST_TRADING = 'huutokaupat/net-auction/CANCEL_POST_TRADING';
const CANCEL_POST_TRADING_DONE = 'huutokaupat/net-auction/CANCEL_POST_TRADING_DONE';
const CANCEL_POST_TRADING_FAILED = 'huutokaupat/net-auction/CANCEL_POST_TRADING_FAILED';

const FETCH_CONTACT_VIEWS = 'huutokaupat/net-auction/FETCH_CONTACT_VIEWS';
const FETCH_CONTACT_VIEWS_DONE = 'huutokaupat/net-auction/FETCH_CONTACT_VIEWS_DONE';
const FETCH_CONTACT_VIEWS_FAILED = 'huutokaupat/net-auction/FETCH_CONTACT_VIEWS_FAILED';
const CLEAR_CONTACT_VIEWS = 'huutokaupat/net-auction/CLEAR_CONTACT_VIEWS';

const CLEAR_CONTINUOUS_AUCTION = 'huutokaupat/net-auction/CLEAR_CONTINUOUS_AUCTION';
const START_CONTINUOUS_AUCTION = 'huutokaupat/net-auction/START_CONTINUOUS_AUCTION';

const RENEW_CONTINUOUS_AUCTION_DONE = 'huutokaupat/net-auction/RENEW_CONTINUOUS_AUCTION_DONE';
const RENEW_CONTINUOUS_AUCTION_FAILED = 'huutokaupat/net-auction/RENEW_CONTINUOUS_AUCTION_FAILED';

const SHOW_CONTINUE_MODAL = 'huutokaupat/net-auction/SHOW_CONTINUE_MODAL';
const HIDE_CONTINUE_MODAL = 'huutokaupat/net-auction/HIDE_CONTINUE_MODAL';

const initialState = Map({
  loading: false,
  failed: false,
  entries: Map(),

  listInfoLoading: false,
  listInfoFailed: false,
  listInfo: Map(),

  buyerInfo: null,

  cancellableEntry: null,
  isCancellableDueToMistake: false,
  isWaitingForPublishing: false,
  isCancellableEntryRiskFree: false,
  runnerUpBid: null,

  runnerUpOfferInfo: null,
  runnerUpOfferInfoEntry: null,

  postTradingEntry: null,

  isRejectBidModalVisible: false,
  isOfferContinuousAuctionModalVisible: false,
  isContinuousAuctionModalVisible: false,
  rejectableBid: {
    entryId: null,
    title: null,
    highestBid: null,
  },
  continuousAuction: {
    entryId: null,
    isRiskFree: false,
  },
  renewContinuousAuctionSucceeded: false,
  renewContinuousAuctionFailed: false,
  renewedContinuousAuctionEntryId: null,
});

type Entries = Map<string, List<Object>>;
type KeyPath = [string, number];

function findEntryKeyPaths(entries: Entries, entryId: number): Seq.Indexed<KeyPath> {
  return entries
    .keySeq()
    .filter(key => entries.get(key, List()).filter(entry => entry.id === entryId).size > 0)
    .map(key => [key, entries.get(key, List()).findIndex(entry => entry.id === entryId)]);
}

function updateEntry(entries: Entries, entryId: number, props: Object): Entries {
  return findEntryKeyPaths(entries, entryId).reduce(
    (result, keyPath) => result.updateIn(keyPath, entry => ({ ...entry, ...props })),
    entries
  );
}

function updateEntries(entries: Entries, entryIds: Array<number>, props: Object): Entries {
  return entryIds.reduce((result, entryId) => updateEntry(result, entryId, props), entries);
}

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_LIST_INFO:
      return state.set('listInfoLoading', true).set('listInfoFailed', false).set('listInfo', Map());

    case FETCH_LIST_INFO_DONE:
      return state
        .set('listInfoLoading', false)
        .set('listInfoFailed', false)
        .set('listInfo', fromJS(action.payload.info));

    case FETCH_LIST_INFO_FAILED:
      return state.set('listInfoLoading', false).set('listInfoFailed', true).set('listInfo', Map());

    case FETCH_ENTRIES_AND_DRAFTS:
      return state.set('loading', true).set('failed', false).set('entries', Map());

    case FETCH_ENTRIES_DONE:
      return state.setIn(['entries', action.payload.type], List(action.payload.entries));

    case FETCH_ENTRIES_FAILED:
    case FETCH_DRAFTS_FAILED:
      return state.set('loading', false).set('failed', true).set('entries', Map());

    case FETCH_DRAFTS_DONE:
      return state.setIn(['entries', 'draft'], List(action.payload));

    case FETCH_ENTRIES_AND_DRAFTS_DONE:
      return state.set('loading', false);

    case FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD:
      return state.set('loading', true).set('failed', false).set('entries', Map());

    case FETCH_ENTRIES_FOR_DASHBOARD_DONE:
      return state.set(
        'entries',
        Map(action.payload.entries).reduce(
          (result, value, key) => result.set(key, List(action.payload.entries[key])),
          state.get('entries', Map())
        )
      );

    case FETCH_DRAFTS_FOR_DASHBOARD_DONE:
      return state.setIn(['entries', 'draft'], List(action.payload.drafts));

    case FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD_DONE:
      return state.set('loading', false);

    case FETCH_ENTRIES_FOR_DASHBOARD_FAILED:
    case FETCH_DRAFTS_FOR_DASHBOARD_FAILED:
      return state.set('loading', false).set('failed', true).set('entries', Map());

    case RESET_LISTS:
      return state
        .set('listInfoLoading', false)
        .set('listInfoFailed', false)
        .set('loading', false)
        .set('failed', false)
        .set('entries', Map())
        .set('listInfo', Map());

    case DELETE_DRAFT: {
      const drafts = state.getIn(['entries', 'draft']);

      if (!drafts) {
        return state;
      }

      const deletedDraftIndex = drafts.findIndex(draft => draft.id === action.payload.draftId);

      if (deletedDraftIndex === -1) {
        return state;
      }

      return state.setIn(['entries', 'draft', deletedDraftIndex], {
        deleted: true,
        ...drafts.get(deletedDraftIndex),
      });
    }

    case DELETE_DRAFT_DONE: {
      const drafts = state.getIn(['entries', 'draft']);

      if (!drafts) {
        return state;
      }

      return state.setIn(
        ['entries', 'draft'],
        drafts.filter(draft => draft.id !== action.payload.draftId)
      );
    }

    case DELETE_DRAFTS: {
      const drafts = state.getIn(['entries', 'draft']);

      if (!drafts) {
        return state;
      }

      return state.setIn(
        ['entries', 'draft'],
        drafts.map(draft => ({
          ...draft,
          deleted: action.payload.draftIds.includes(draft.id),
        }))
      );
    }

    case DELETE_DRAFTS_DONE: {
      const drafts = state.getIn(['entries', 'draft']);

      if (!drafts) {
        return state;
      }

      return state.setIn(
        ['entries', 'draft'],
        drafts.filter(draft => !action.payload.draftIds.includes(draft.id))
      );
    }

    case APPROVE_BID:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isApproving: true,
          isApproved: false,
          isApproveFailed: false,
        })
      );

    case APPROVE_BID_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isApproving: false,
          isApproved: true,
        })
      );

    case APPROVE_BID_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isApproving: false,
          isApproveFailed: true,
        })
      );

    case REJECT_BID:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isRejecting: true,
          isRejected: false,
          isRejectFailed: false,
        })
      );

    case REJECT_BID_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isRejecting: false,
          isRejected: true,
        })
      );

    case REJECT_BID_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isRejecting: false,
          isRejectFailed: true,
        })
      );

    case MARK_AS_PAID:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isMarkingAsPaid: true,
          isMarkedAsPaid: false,
          isMarkAsPaidFailed: false,
        })
      );

    case MARK_AS_PAID_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isMarkingAsPaid: false,
          isMarkedAsPaid: true,
        })
      );

    case MARK_AS_PAID_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isMarkingAsPaid: false,
          isMarkAsPaidFailed: true,
        })
      );

    case MARK_AS_DELIVERED:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsDelivered: true,
          isMarkedAsDelivered: false,
          isMarkAsDeliveredFailed: false,
        })
      );

    case MARK_AS_DELIVERED_DONE:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsDelivered: false,
          isMarkedAsDelivered: true,
        })
      );

    case MARK_AS_DELIVERED_FAILED:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsDelivered: false,
          isMarkAsDeliveredFailed: true,
        })
      );

    case MARK_AS_HANDOVER:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsHandover: true,
          isMarkedAsHandover: false,
          isMarkAsHandoverFailed: false,
        })
      );

    case MARK_AS_HANDOVER_DONE:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsHandover: false,
          isMarkedAsHandover: true,
        })
      );

    case MARK_AS_HANDOVER_FAILED:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsHandover: false,
          isMarkAsHandoverFailed: true,
        })
      );

    case MARK_AS_ARCHIVED:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsArchived: true,
          isMarkedAsArchived: false,
          isMarkAsArchivedFailed: false,
        })
      );

    case MARK_AS_ARCHIVED_DONE:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsArchived: false,
          isMarkedAsArchived: true,
          isMarkAsArchivedFailed: false,
        })
      );

    case MARK_AS_ARCHIVED_FAILED:
      return state.set(
        'entries',
        updateEntries(state.get('entries', Map()), action.payload.entryIds, {
          isMarkingAsArchived: false,
          isMarkedAsArchived: false,
          isMarkAsArchivedFailed: true,
        })
      );

    case CHECK_CANCELLABLE:
      return state
        .set('cancellableEntry', null)
        .set('isCancellableDueToMistake', false)
        .set('isWaitingForPublishing', false)
        .set('runnerUpBid', null);

    case CHECK_CANCELLABLE_DONE: {
      const path = findEntryKeyPaths(state.get('entries', Map()), action.payload.entryId).first();
      if (!path) {
        return state;
      }

      return state
        .set('cancellableEntry', state.getIn(['entries', ...path]))
        .set('isCancellableDueToMistake', action.payload.isCancellableDueToMistake)
        .set('isWaitingForPublishing', action.payload.isWaitingForPublishing)
        .set('isCancellableEntryRiskFree', action.payload.isRiskFree)
        .set('runnerUpBid', parseInt(action.payload.runnerUpBid, 10));
    }

    case CLEAR_CANCELLABLE:
      return state
        .set('cancellableEntry', null)
        .set('isCancellableDueToMistake', false)
        .set('isWaitingForPublishing', false)
        .set('isCancellableEntryRiskFree', false)
        .set('runnerUpBid', null);

    case CANCEL:
      return state
        .set('cancellableEntry', null)
        .set('isCancellableDueToMistake', false)
        .set('isWaitingForPublishing', false)
        .set('isCancellableEntryRiskFree', false)
        .set(
          'entries',
          updateEntry(state.get('entries', Map()), action.payload.entryId, {
            isCanceling: true,
            isCanceled: false,
            isCancelFailed: false,
          })
        );

    case CANCEL_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCanceling: false,
          isCanceled: true,
        })
      );

    case CANCEL_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCanceling: false,
          isCancelFailed: true,
        })
      );

    case FETCH_RUNNER_UP_OFFER_INFO:
      return state.set('runnerUpOfferInfoEntry', null).set('runnerUpOfferInfo', null);

    case FETCH_RUNNER_UP_OFFER_INFO_DONE: {
      const path = findEntryKeyPaths(state.get('entries', Map()), action.payload.entryId).first();
      if (!path) {
        return state;
      }

      return state
        .set('runnerUpOfferInfoEntry', state.getIn(['entries', ...path]))
        .set('runnerUpOfferInfo', action.payload.runnerUpOfferInfo);
    }

    case CLEAR_RUNNER_UP_OFFER_INFO:
      return state.set('runnerUpOfferInfoEntry', null).set('runnerUpOfferInfo', null);

    case OFFER_TO_RUNNER_UP:
      return state
        .set('cancellableEntry', null)
        .set('isCancellableDueToMistake', false)
        .set('isWaitingForPublishing', false)
        .set('isCancellableEntryRiskFree', false)
        .set(
          'entries',
          updateEntry(state.get('entries', Map()), action.payload.entryId, {
            isBeingOfferedToRunnerUp: true,
            isOfferedToRunnerUp: false,
            isOfferFailed: false,
          })
        );

    case OFFER_TO_RUNNER_UP_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isBeingOfferedToRunnerUp: false,
          isOfferedToRunnerUp: true,
        })
      );

    case OFFER_TO_RUNNER_UP_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isBeingOfferedToRunnerUp: false,
          isOfferFailed: true,
        })
      );

    case OPEN_CREATE_SHIPMENT_MODAL: {
      const path = findEntryKeyPaths(state.get('entries', Map()), action.payload.entryId).first();
      if (!path) {
        return state;
      }

      return state.set('createShipmentEntry', state.getIn(['entries', ...path]));
    }

    case CLOSE_CREATE_SHIPMENT_MODAL:
      return state.set('createShipmentEntry', null);

    case CREATE_SHIPMENT:
      return state.set('createShipmentEntry', null).set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCreatingShipment: true,
          isCreatedShipment: false,
          isCreateShipmentFailed: false,
        })
      );

    case CREATE_SHIPMENT_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCreatingShipment: false,
          isCreatedShipment: true,
          shipments: action.payload.allShipments,
        })
      );

    case CREATE_SHIPMENT_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCreatingShipment: false,
          isCreateShipmentFailed: true,
        })
      );

    case START_POST_TRADING:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isStartingPostTrading: true,
          isStartedPostTrading: false,
          isStartPostTradingFailed: false,
        })
      );

    case START_POST_TRADING_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isStartingPostTrading: false,
          isStartedPostTrading: true,
        })
      );

    case START_POST_TRADING_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isStartingPostTrading: false,
          isStartPostTradingFailed: true,
        })
      );

    case OPEN_FINISH_POST_TRADING_MODAL: {
      const path = findEntryKeyPaths(state.get('entries', Map()), action.payload.entryId).first();
      if (!path) {
        return state;
      }

      return state.set('postTradingEntry', state.getIn(['entries', ...path]));
    }

    case CLOSE_FINISH_POST_TRADING_MODAL:
      return state.set('postTradingEntry', null);

    case FINISH_POST_TRADING:
      return state.set('postTradingEntry', null).set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isFinishingPostTrading: true,
          isFinishedPostTrading: false,
          isFinishPostTradingFailed: false,
        })
      );

    case FINISH_POST_TRADING_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isFinishingPostTrading: false,
          isFinishedPostTrading: true,
        })
      );

    case FINISH_POST_TRADING_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isFinishingPostTrading: false,
          isFinishPostTradingFailed: true,
        })
      );

    case CANCEL_POST_TRADING:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCancelingPostTrading: true,
          isCanceledPostTrading: false,
          isCancelPostTradingFailed: false,
        })
      );

    case CANCEL_POST_TRADING_DONE:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCancelingPostTrading: false,
          isCanceledPostTrading: true,
        })
      );

    case CANCEL_POST_TRADING_FAILED:
      return state.set(
        'entries',
        updateEntry(state.get('entries', Map()), action.payload.entryId, {
          isCancelingPostTrading: false,
          isCancelPostTradingFailed: true,
        })
      );

    case FETCH_CONTACT_VIEWS_DONE:
      return state.set('contactViews', List(action.payload.contactViews));

    case FETCH_CONTACT_VIEWS_FAILED:
      return state.set('fetchContactViewsFailed', action.error);

    case CLEAR_CONTACT_VIEWS:
      return state.set('contactViews', null);

    case CLEAR_CONTINUOUS_AUCTION:
      return state
        .set('isOfferContinuousAuctionModalVisible', false)
        .set('isContinuousAuctionModalVisible', false)
        .set('renewContinuousAuctionFailed', false);

    case START_CONTINUOUS_AUCTION:
      return state
        .set('isOfferContinuousAuctionModalVisible', false)
        .set('isContinuousAuctionModalVisible', true)
        .set('renewContinuousAuctionFailed', false);

    case SHOW_CONTINUE_MODAL:
      return state
        .set('continuousAuction', {
          entryId: action.payload.entryId,
          isRiskFree: action.payload.isRiskFree,
        })
        .set('isOfferContinuousAuctionModalVisible', true);

    case HIDE_CONTINUE_MODAL:
      return state.set('isOfferContinuousAuctionModalVisible', false);

    case CLEAR_REJECT_BID:
      return state.set('isRejectBidModalVisible', false);

    case START_REJECT_BID:
      return state.set('isRejectBidModalVisible', true).set('rejectableBid', action.payload);

    case RENEW_CONTINUOUS_AUCTION_DONE:
      return state
        .set('renewedContinuousAuctionEntryId', action.payload.new_entry_id)
        .set('renewContinuousAuctionSucceeded', true);

    case RENEW_CONTINUOUS_AUCTION_FAILED:
      return state.set('renewContinuousAuctionFailed', true);

    default:
      return state;
  }
};

export function startContinuousAuction(): Object {
  return { type: START_CONTINUOUS_AUCTION };
}

export function clearContinuousAuction(): Object {
  return { type: CLEAR_CONTINUOUS_AUCTION };
}

export function clearRejectBid(): Object {
  return { type: CLEAR_REJECT_BID };
}

export function hideContinueModal(): Object {
  return { type: HIDE_CONTINUE_MODAL };
}

export function renewNetAuction(entryId: number, data: Object): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionService.renewNetAuction(entryId, data).then(
      (result): void => {
        if (result.error && result.error === true) {
          throw new SubmissionError(result.errors);
        }

        if (result.error_message) {
          return dispatch({
            type: RENEW_CONTINUOUS_AUCTION_FAILED,
            payload: result,
          });
        }

        return dispatch({
          type: RENEW_CONTINUOUS_AUCTION_DONE,
          payload: result,
        });
      },
      error => dispatch({ type: RENEW_CONTINUOUS_AUCTION_FAILED, payload: error })
    );
}

export function fetchListInfo(term: ?string = null, company: ?number = null, includeOld: ?boolean = null): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_LIST_INFO });

    return netAuctionService
      .fetchListInfo(term, company, includeOld)
      .then(response =>
        dispatch({
          type: FETCH_LIST_INFO_DONE,
          payload: { info: response },
        })
      )
      .catch(error => {
        dispatch({ type: FETCH_LIST_INFO_FAILED, payload: error });

        return Promise.reject();
      });
  };
}

function fetchEntries(
  type: string,
  term: ?string = null,
  company: ?number = null,
  includeOld: ?boolean = null
): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionService
      .fetchEntries(type, term, company, includeOld)
      .then(entries =>
        dispatch({
          type: FETCH_ENTRIES_DONE,
          payload: { entries, type },
        })
      )
      .catch(error => dispatch({ type: FETCH_ENTRIES_FAILED, payload: error }));
}

function fetchDrafts(term: ?string = null, company: ?number = 1): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionDraftService
      .fetchDraftsForAdminList(company, term)
      .then(drafts => dispatch({ type: FETCH_DRAFTS_DONE, payload: drafts }))
      .catch(error => dispatch({ type: FETCH_DRAFTS_FAILED, payload: error }));
}

function fetchEntriesAndDraftsRequest(
  type: string,
  term: ?string = null,
  company: ?number = null,
  includeOld: ?boolean = null
): Function {
  return (dispatch: Function): Promise<any> =>
    type === 'draft' ? dispatch(fetchDrafts(term, company)) : dispatch(fetchEntries(type, term, company, includeOld));
}

export function fetchEntriesAndDrafts(
  type: string,
  term: ?string = null,
  company: ?number = null,
  includeOld: ?boolean = null
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({
      type: FETCH_ENTRIES_AND_DRAFTS,
    });

    return dispatch(fetchEntriesAndDraftsRequest(type, term, company, includeOld)).then(() =>
      dispatch({ type: FETCH_ENTRIES_AND_DRAFTS_DONE })
    );
  };
}

function fetchEntriesForDashboard(
  types: Array<string>,
  term: ?string,
  company: ?number,
  includeOld: ?boolean = null
): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionService
      .fetchEntriesForDashboard(types, term, company, includeOld)
      .then(entries =>
        dispatch({
          type: FETCH_ENTRIES_FOR_DASHBOARD_DONE,
          payload: { entries },
        })
      )
      .catch(error => dispatch({ type: FETCH_ENTRIES_FOR_DASHBOARD_FAILED, payload: error }));
}

function fetchDraftsForDashboard(term: ?string, company: ?number): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionDraftService
      .fetchDraftsForDashboard(company, term)
      .then(drafts =>
        dispatch({
          type: FETCH_DRAFTS_FOR_DASHBOARD_DONE,
          payload: { drafts },
        })
      )
      .catch(error => dispatch({ type: FETCH_DRAFTS_FOR_DASHBOARD_FAILED, payload: error }));
}

function fetchEntriesAndDraftsForDashboardRequest(
  types: Array<string>,
  term: ?string,
  company: ?number,
  includeOld: ?boolean = null
): Function {
  return (dispatch: Function): Promise<any> =>
    Promise.all([
      dispatch(
        fetchEntriesForDashboard(
          types.filter(type => type !== 'draft'),
          term,
          company,
          includeOld
        )
      ),
      types.includes('draft') ? dispatch(fetchDraftsForDashboard(term, company)) : Promise.resolve(),
    ]);
}

export function fetchEntriesAndDraftsForDashboard(
  term: ?string = null,
  company: ?number = null,
  includeOld: ?boolean = null
): Function {
  return (dispatch: Function): Promise<any> => {
    const types = listTypes.map(item => item.value);

    dispatch({
      type: FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD,
    });

    return dispatch(fetchEntriesAndDraftsForDashboardRequest(types, term, company, includeOld)).then(() =>
      dispatch({ type: FETCH_ENTRIES_AND_DRAFTS_FOR_DASHBOARD_DONE })
    );
  };
}

export function resetLists(): Object {
  return { type: RESET_LISTS };
}

export function deleteDraft(draftId: number): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: DELETE_DRAFT, payload: { draftId } });

    return netAuctionDraftService
      .deleteDraft(draftId)
      .then(response => dispatch({ type: DELETE_DRAFT_DONE, payload: response }))
      .catch(() => dispatch({ type: DELETE_DRAFT_FAILED }));
  };
}

export function deleteDrafts(draftIds: number[]): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: DELETE_DRAFTS, payload: { draftIds } });

    return netAuctionDraftService
      .deleteDrafts(draftIds)
      .then(() => dispatch({ type: DELETE_DRAFTS_DONE, payload: { draftIds } }))
      .catch(() => dispatch({ type: DELETE_DRAFTS_FAILED }));
  };
}

export function approveBid(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: APPROVE_BID, payload: { entryId } });

    return doApproveBid(entryId)
      .then(() => dispatch({ type: APPROVE_BID_DONE, payload: { entryId } }))
      .catch(error =>
        dispatch({
          type: APPROVE_BID_FAILED,
          payload: { entryId, error },
        })
      );
  };
}

function startPostTradingRequest(dispatch: Function, entryId: number): Promise<any> {
  dispatch({ type: START_POST_TRADING, payload: { entryId } });

  return netAuctionService
    .startPostTrading(entryId)
    .then(() => dispatch({ type: START_POST_TRADING_DONE, payload: { entryId } }))
    .catch(error => dispatch({ type: START_POST_TRADING_FAILED, payload: { entryId, error } }));
}

export function startPostTrading(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    return startPostTradingRequest(dispatch, entryId);
  };
}

export function rejectBid(entryId: number, title: number, highestBid: number): Function {
  return (dispatch: Function): void => {
    dispatch({
      type: START_REJECT_BID,
      payload: { entryId, title, highestBid },
    });
  };
}

export function handleRejectBid(entryId: number, shouldSendBidderMail?: boolean): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CLEAR_REJECT_BID });

    return doRejectBid(entryId, shouldSendBidderMail).then(
      (response): void => {
        dispatch({ type: REJECT_BID_DONE, payload: { entryId } });

        if (response.isContinuable) {
          dispatch({
            type: SHOW_CONTINUE_MODAL,
            payload: { entryId, isRiskFree: response.isRiskFree },
          });
        }
      },
      error => dispatch({ type: REJECT_BID_FAILED, payload: error })
    );
  };
}

export function markAsPaid(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_PAID, payload: { entryId } });

    return netAuctionService
      .markAsPaid(entryId)
      .then(() => dispatch({ type: MARK_AS_PAID_DONE, payload: { entryId } }))
      .catch(error =>
        dispatch({
          type: MARK_AS_PAID_FAILED,
          payload: { entryId, error },
        })
      );
  };
}

export function markAsDelivered(entryIds: Array<Object>): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_DELIVERED, payload: { entryIds } });

    return netAuctionService
      .markAsDelivered(entryIds)
      .then(() =>
        dispatch({
          type: MARK_AS_DELIVERED_DONE,
          payload: { entryIds },
        })
      )
      .catch(error =>
        dispatch({
          type: MARK_AS_DELIVERED_FAILED,
          payload: { entryIds, error },
        })
      );
  };
}

export function markAsHandover(entryIds: Array<Object>): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_HANDOVER, payload: { entryIds } });

    return netAuctionService
      .markAsHandover(entryIds)
      .then(() => dispatch({ type: MARK_AS_HANDOVER_DONE, payload: { entryIds } }))
      .catch(() => dispatch({ type: MARK_AS_HANDOVER_FAILED, payload: { entryIds } }));
  };
}

export function markAsArchived(entryIds: Array<number>): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_ARCHIVED, payload: { entryIds } });

    return netAuctionService
      .markAsArchived(entryIds)
      .then(() => dispatch({ type: MARK_AS_ARCHIVED_DONE, payload: { entryIds } }))
      .catch(() => dispatch({ type: MARK_AS_ARCHIVED_FAILED, payload: { entryIds } }));
  };
}

export function checkCancellable(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CHECK_CANCELLABLE, payload: { entryId } });

    return netAuctionService
      .checkCancellable(entryId)
      .then(response =>
        dispatch({
          type: CHECK_CANCELLABLE_DONE,
          payload: {
            entryId,
            isCancellableDueToMistake: response.isCancellableDueToMistake,
            isWaitingForPublishing: response.isWaitingForPublishing,
            isRiskFree: response.isRiskFree,
            runnerUpBid: parseInt(response.runnerUpBid, 10),
          },
        })
      )
      .catch(error =>
        dispatch({
          type: CHECK_CANCELLABLE_FAILED,
          payload: { entryId, error },
        })
      );
  };
}

export function clearCancellable(): Object {
  return { type: CLEAR_CANCELLABLE };
}

export function cancel(entryId: number, reason: number, otherReason: string): Function {
  return (dispatch: Function): Promise<any> => {
    if (reason === CANCELLATION_REASON_RUNNER_UP_OFFER) {
      dispatch({ type: OFFER_TO_RUNNER_UP, payload: { entryId } });
      return netAuctionService
        .offerToRunnerUp(entryId)
        .then(() => dispatch({ type: OFFER_TO_RUNNER_UP_DONE, payload: { entryId } }))
        .catch(error =>
          dispatch({
            type: OFFER_TO_RUNNER_UP_FAILED,
            payload: { entryId, error },
          })
        );
    }

    dispatch({ type: CANCEL, payload: { entryId } });
    return netAuctionService
      .cancel(entryId, reason, otherReason, netAuctionService.isCancelFreeOfCharge(reason))
      .then(() => dispatch({ type: CANCEL_DONE, payload: { entryId } }))
      .catch(error => dispatch({ type: CANCEL_FAILED, payload: { entryId, error } }));
  };
}

export function openCreateShipmentModal(entryId: number): Object {
  return { type: OPEN_CREATE_SHIPMENT_MODAL, payload: { entryId } };
}

export function closeCreateShipmentModal(): Object {
  return { type: CLOSE_CREATE_SHIPMENT_MODAL };
}

export function createShipment(
  entryId: number,
  carrier: string,
  service: string,
  description: string,
  weight: number,
  parcels: number
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CREATE_SHIPMENT, payload: { entryId } });

    return netAuctionService
      .createShipment(entryId, carrier, service, description, weight, parcels)
      .then(
        (response): Object => {
          dispatch({
            type: CREATE_SHIPMENT_DONE,
            payload: {
              entryId,
              shipment: response.shipment,
              allShipments: response.allShipments,
            },
          });

          return response;
        },
        (error): Promise<any> => {
          if (error.response.data.errorCode) {
            /* eslint-disable no-useless-computed-key */
            const errorMessages = {
              [500]: 'Lähetyksen luominen epäonnistui.',
              [1000]: 'Lähetyksen paino on suurempi kuin valitussa palvelussa on sallittu.',
              [1001]: 'Lähetyksen kollimäärä on suurempi kuin valitussa palvelussa on sallittu.',
              [1002]: 'Vastaanottajan postinumero on virheellinen.',
            };
            /* eslint-enable no-useless-computed-key */

            // TODO: Move net auction handling to a hook and use useDialog instead.
            window.alert(`Virhe lähetyksen luonnissa. Virhe: ${errorMessages[error.response.data.errorCode]}.`);
          } else if (error.response.data.message) {
            // TODO: Move net auction handling to a hook and use useDialog instead.
            window.alert(`Virhe lähetyksen luonnissa. Virhe: ${error.response.data.message}.`);
          } else {
            // TODO: Move net auction handling to a hook and use useDialog instead.
            window.alert('Virhe lähetyksen luonnissa. Tuntematon virhe.');
          }

          dispatch({
            type: CREATE_SHIPMENT_FAILED,
            payload: { entryId, error },
          });

          return Promise.reject();
        }
      )
      .then(response => netAuctionService.openShipmentLabel(response.shipment))
      .catch((): void => {});
  };
}

export function openFinishPostTradingModal(entryId: number): Object {
  return { type: OPEN_FINISH_POST_TRADING_MODAL, payload: { entryId } };
}

export function closeFinishPostTradingModal(): Object {
  return { type: CLOSE_FINISH_POST_TRADING_MODAL };
}

export function finishPostTrading(entryId: number, sum: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FINISH_POST_TRADING, payload: { entryId } });

    return netAuctionService
      .finishPostTrading(entryId, sum)
      .then(() => dispatch({ type: FINISH_POST_TRADING_DONE, payload: { entryId } }))
      .catch(error =>
        dispatch({
          type: FINISH_POST_TRADING_FAILED,
          payload: { entryId, error },
        })
      );
  };
}

export function cancelPostTrading(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CANCEL_POST_TRADING, payload: { entryId } });

    return doRejectBid(entryId)
      .then(() =>
        dispatch({
          type: CANCEL_POST_TRADING_DONE,
          payload: { entryId },
        })
      )
      .catch(error =>
        dispatch({
          type: CANCEL_POST_TRADING_FAILED,
          payload: { entryId, error },
        })
      );
  };
}

export function fetchRunnerUpOfferInfo(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_RUNNER_UP_OFFER_INFO, payload: { entryId } });

    return netAuctionService
      .fetchRunnerUpOfferInfo(entryId)
      .then(response =>
        dispatch({
          type: FETCH_RUNNER_UP_OFFER_INFO_DONE,
          payload: {
            entryId,
            runnerUpOfferInfo: response,
          },
        })
      )
      .catch(error =>
        dispatch({
          type: FETCH_RUNNER_UP_OFFER_INFO_FAILED,
          payload: { entryId, error },
        })
      );
  };
}

export function clearRunnerUpOfferInfo(): Object {
  return { type: CLEAR_RUNNER_UP_OFFER_INFO };
}

export function fetchContactViews(entryId: number, type: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_CONTACT_VIEWS, payload: { entryId, type } });

    return entryService.fetchContactStats(entryId, type).then(
      response =>
        dispatch({
          type: FETCH_CONTACT_VIEWS_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: FETCH_CONTACT_VIEWS_FAILED,
          payload: { entryId, error },
        })
    );
  };
}

export function clearContactViews(): Object {
  return { type: CLEAR_CONTACT_VIEWS };
}
