import React from 'react';
import { Modal, ModalBody, Row, Col, Button } from 'react-bootstrap';
import { LoginForm, LoginFormValues } from '@components/login/LoginForm';
import { RegistrationContent } from '@components/login/RegistrationContent';
import { CustomerServiceDetails } from '@components/customer-service/CustomerServiceDetails';
import { LoginError } from '@components/login/LoginError';
import { MfaForm, MfaFormValues } from '@components/login/MfaForm';

export type LoginModalState = 'login' | 'mfa';
export type LoginModalError = 'failed' | 'ip-restricted' | 'banned' | 'forced-password-reset' | 'internal-error';

export interface LoginModalProps {
  readonly isOpen: boolean;
  readonly isSimplified?: boolean;
  readonly returnUrl?: string;
  readonly state: LoginModalState;
  readonly error?: LoginModalError;
  readonly onLoginSubmit: (values: LoginFormValues) => Promise<void>;
  readonly onMfaSubmit: (values: MfaFormValues) => Promise<void>;
  readonly onClose: () => void;
}

/**
 * Crude React version of the old login modal (still mostly using Bootstrap).
 * TODO: After we are using a more recent version of the design system:
 *   - Add missing features to huutokaupat-frontend's login modal
 *     - Expired passwords
 *     - ...?
 *   - Copy new login modal from huutokaupat-frontend and get rid of this one
 */
export function LoginModal({ isOpen, returnUrl, state, error, onLoginSubmit, onMfaSubmit, onClose }: LoginModalProps) {
  return (
    <Modal show={isOpen} onHide={onClose} className="modal-login">
      <ModalBody>
        <div className="container">
          <button type="button" aria-label="Sulje" className="close hidden-xs" onClick={onClose} />

          <Row className="inner-container new-here-top">
            <Col xs={12} className="visible-xs-block">
              <p>
                <Button bsStyle="success" block href="/tee-tunnus">
                  Tee ilmainen tunnus
                </Button>
              </p>
              <div className="want-to-be-seller modal-login__want-to-be-seller--xs">
                <a href="/myy-huutokaupatcomissa">Aloita myyminen tästä</a>
              </div>
            </Col>
          </Row>

          <Row className="inner-container">
            <Col sm={6} md={4} className="login-container">
              <button type="button" aria-label="Sulje" className="close visible-xs-block" onClick={onClose} />

              <div className="login-block">
                <Row>
                  <Col sm={12} className="form-wrapper">
                    <h2>Kirjaudu sisään</h2>

                    <LoginError isVisible={error === 'failed'} title="Kirjautuminen epäonnistui">
                      <a className="forgot-password" href="/unohditko-salasanasi">
                        Ongelmia sisäänkirjautumisessa?
                      </a>
                    </LoginError>

                    <LoginError isVisible={error === 'ip-restricted'} title="Kirjautuminen epäonnistui">
                      <p>
                        Ylläpitäjänä voit kirjautua vain sallituista IP-osoitteista. Kokeile yhdistämistä VPN-verkkoon.
                      </p>
                    </LoginError>

                    <LoginError isVisible={error === 'banned'} title="Käyttäjän kirjautuminen on estetty">
                      <p>Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme.</p>
                      <CustomerServiceDetails />
                    </LoginError>

                    <LoginError
                      isVisible={error === 'forced-password-reset'}
                      title="Salasana on vaihdettava ennen kuin voit kirjautua sisään."
                    >
                      <p>Saat vaihdettua salasanasi sähköpostiisi lähetetyn linkin avulla.</p>
                      <p>Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme.</p>
                      <CustomerServiceDetails />
                    </LoginError>

                    <LoginError isVisible={error === 'internal-error'} title="Kirjautumisessa tapahtui virhe">
                      <p>Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme.</p>
                      <CustomerServiceDetails />
                    </LoginError>

                    {state === 'login' && <LoginForm onSubmit={onLoginSubmit} />}
                    {state === 'mfa' && <MfaForm onSubmit={onMfaSubmit} />}
                  </Col>
                </Row>
              </div>
            </Col>

            <Col sm={6} md={8} className="new-here hidden-xs">
              <RegistrationContent returnUrl={returnUrl} />
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
}
