// @flow

import { Map } from 'immutable';

import userService from '../src/user/user-service';

const DISABLE_ENTRY_RESALE_NOTIFICATIONS =
  'huutokaupat/user/disable-entry-resale-notifications/DISABLE_ENTRY_RESALE_NOTIFICATIONS';
const DISABLE_ENTRY_RESALE_NOTIFICATIONS_DONE =
  'huutokaupat/user/disable-entry-resale-notifications/DISABLE_ENTRY_RESALE_NOTIFICATIONS_DONE';
const DISABLE_ENTRY_RESALE_NOTIFICATIONS_FAILED =
  'huutokaupat/user/disable-entry-resale-notifications/DISABLE_ENTRY_RESALE_NOTIFICATIONS_FAILED';

const initialState = Map({
  loading: true,
  failed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case DISABLE_ENTRY_RESALE_NOTIFICATIONS_DONE:
      return state.set('loading', false);

    case DISABLE_ENTRY_RESALE_NOTIFICATIONS_FAILED:
      return state.set('loading', false).set('failed', true);

    default:
      return state;
  }
};

export function doDisableEntryResaleNotifications(): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: DISABLE_ENTRY_RESALE_NOTIFICATIONS });

    return userService.disableEntryResaleNotifications().then(
      () => dispatch({ type: DISABLE_ENTRY_RESALE_NOTIFICATIONS_DONE }),
      () => dispatch({ type: DISABLE_ENTRY_RESALE_NOTIFICATIONS_FAILED })
    );
  };
}
