import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoginModal, LoginModalError, LoginModalState } from '@components/login/LoginModal';
import jwt from '@app/src/security/jwt';
import urlFilter from '@app/src/security/url-filter';
import createClient from '@app/src/api/client';
import { LoginFormValues } from '@components/login/LoginForm';
import { MfaFormValues } from '@components/login/MfaForm';
import { Deferred } from '@app/src/util/deferred';
import { refresh } from '@app/ducks/auth';
import { gtmService } from '@app/src/analytics/gtm-service';
import config from '../../../config';
import { getWidget, updateSessionIdentifier, updateLoggedInState } from '@app/src/integration/freshchat';
import { useSession } from '@app/src/header/hooks/useSession';

interface LoginOptions {
  /**
   * URL to return to after logging in.
   */
  readonly returnUrl?: string;
}

interface LoginContextValue {
  readonly requireLogin: (options?: LoginOptions) => Promise<void>;
  readonly logout: () => void;
}

const defaultMethod = () => {
  throw new Error('LoginProvider not found');
};

const LoginContext = createContext<LoginContextValue>({
  requireLogin: defaultMethod,
  logout: defaultMethod,
});

export interface LoginProviderProps {
  readonly children: React.ReactNode;
}

export function LoginProvider({ children }: LoginProviderProps) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginOptions, setLoginOptions] = useState<LoginOptions>();
  const [modalState, setModalState] = useState<LoginModalState>('login');
  const [modalError, setModalError] = useState<LoginModalError>();
  const [credentialsForMfa, setCredentialsForMfa] = useState({ username: '', password: '' });
  const deferredRef = useRef<Deferred<void>>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSession();

  const replace = useCallback(
    (path: string) => {
      if (history) {
        history.replace(path);
      } else {
        // If this is loaded from a non-React page, router is not available.
        // eslint-disable-next-line functional/immutable-data -- Change page.
        window.location.href = path;
      }
    },
    [history]
  );

  const contextValue = useMemo(
    (): LoginContextValue => ({
      requireLogin: async (options): Promise<void> => {
        if (currentUser.data?.isAuthenticated) {
          return Promise.resolve();
        }

        setIsLoginModalOpen(true);
        setLoginOptions({
          ...options,

          // Sanitize return URL.
          returnUrl: options?.returnUrl ? urlFilter.ensureInternalUrl(options.returnUrl) ?? undefined : undefined,
        });

        gtmService.event('login:open');

        // eslint-disable-next-line functional/immutable-data -- Update ref value.
        deferredRef.current = new Deferred<void>();

        return deferredRef.current.promise;
      },
      logout: () => {
        jwt.clearToken();
        // eslint-disable-next-line functional/immutable-data -- Change page.
        window.location.href = config.urls.logout;
      },
    }),
    []
  );

  const updateFreshchatProperties = useCallback(() => {
    const freshChatWidget = getWidget();
    if (!freshChatWidget) {
      return;
    }

    updateLoggedInState(freshChatWidget, true);
    if (freshChatWidget.isOpen()) {
      void updateSessionIdentifier(freshChatWidget);
    }
  }, []);

  const login = useCallback(
    async (username: string, password: string, passcode?: string) => {
      try {
        // TODO: Use a react-query mutation instead.
        const response = await createClient('').post(config.urls.login, {
          username,
          password,
          passcode,
          uid: Cookies.get('UID'),
        });
        const { data } = response;

        if (!data.success) {
          if (data.banned) {
            setModalError('banned');
          } else if (data.isPasswordExpired) {
            setModalError('forced-password-reset');
          } else if (data.ipRestriction) {
            setModalError('ip-restricted');
          } else {
            setModalError('failed');
          }

          return;
        }

        if (data.promptTwoFactor) {
          // Store credentials for upcoming MFA request.
          setCredentialsForMfa({ username, password });
          setModalState('mfa');
          return;
        }

        gtmService.recommended.login('Password');

        // Update current user in react-query cache.
        await currentUser.refetch();
        // Update legacy JWT from response.
        jwt.setToken(response.data.token);
        // Update current user in Redux store (from JWT).
        dispatch(refresh());

        setModalState('login');
        setModalError(undefined);
        setIsLoginModalOpen(false);

        // Clear credentials from state.
        setCredentialsForMfa({ username: '', password: '' });

        if (loginOptions?.returnUrl) {
          replace(loginOptions.returnUrl);
          return;
        }

        updateFreshchatProperties();

        deferredRef.current?.resolve();
      } catch (error) {
        // Login always returns 200, only internal errors end up here.
        console.error(error);
        setModalError('internal-error');
        deferredRef.current?.reject(error);
      }
    },
    [loginOptions, updateFreshchatProperties]
  );

  const onLoginSubmit = useCallback(
    async (values: LoginFormValues) => {
      await login(values.username, values.password);
    },
    [login]
  );

  const onMfaSubmit = useCallback(
    async (values: MfaFormValues) => {
      await login(credentialsForMfa.username, credentialsForMfa.password, values.passcode);
    },
    [login, credentialsForMfa]
  );

  const onLoginModalClose = useCallback(() => {
    setIsLoginModalOpen(false);
    deferredRef.current?.reject();
  }, []);

  return (
    <LoginContext.Provider value={contextValue}>
      {children}

      <LoginModal
        isOpen={isLoginModalOpen}
        returnUrl={loginOptions?.returnUrl}
        state={modalState}
        error={modalError}
        onLoginSubmit={onLoginSubmit}
        onMfaSubmit={onMfaSubmit}
        onClose={onLoginModalClose}
      />
    </LoginContext.Provider>
  );
}

export const useLogin = () => useContext(LoginContext);
