// @flow

import React from 'react';
import { FormControl, ControlLabel } from 'react-bootstrap';

type Props = {
  input: Object,
  type: string,
  disabled: boolean,
  meta: Object,
  label: String,
};

export default function InputField({ input, type, disabled = false, label }: Props): React$Node {
  const id = input.name
    .replace('.', '-')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase();

  return (
    <>
      <ControlLabel>{label}</ControlLabel>
      <FormControl componentClass="input" id={id} type={type} disabled={disabled} {...input} />
    </>
  );
}
