import React from 'react';
import { Button, Row, Col, FormGroup } from 'react-bootstrap';
import { Field, Form as FinalForm } from 'react-final-form';
import { TextInputField } from '@components/final-form/text-input/TextInputField';

export interface MfaFormValues {
  readonly passcode: string;
}

export interface MfaFormProps {
  readonly onSubmit: (values: MfaFormValues) => Promise<void>;
}

export function MfaForm({ onSubmit }: MfaFormProps) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Field
              name="passcode"
              label="Anna kaksivaiheisen tunnistautumisen vahvistuskoodi"
              placeholder="Koodi"
              autoComplete="one-time-code"
              autoFocus
              isRequired
              component={TextInputField}
            />
          </FormGroup>

          <Row>
            <Col xs={12}>
              <Button type="submit" bsStyle="success" className="add-bottom" disabled={submitting}>
                Tarkista
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a className="forgot-password" href="/unohditko-salasanasi">
                Ongelmia sisäänkirjautumisessa?
              </a>
            </Col>
          </Row>
        </form>
      )}
    />
  );
}
