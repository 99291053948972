// @flow

import createClient from '../api/client';

export default {
  getFeedbackSummary: () =>
    createClient()
      .get('/net-auctions/feedbacks/summary')
      .then(response => response.data),

  getFeedbacksByCompanyId: (companyId: number) =>
    createClient()
      .get('/net-auctions/feedbacks', { params: { companyId } })
      .then(response => response.data),

  getFeedbackByUuid: (uuid: string) =>
    createClient()
      .get(`/net-auctions/feedbacks/${uuid}`)
      .then(response => response.data),

  saveFeedback: (uuid: string, params: Object) =>
    createClient()
      .patch(`/net-auctions/feedbacks/${uuid}`, params)
      .then(response => response.data),

  markFeedbacksAsRead: (companyId: number) =>
    createClient()
      .patch(`net-auctions/feedbacks/mark-as-read`, { companyId })
      .then(response => response.data),

  fetchFeedbacksForReview: (params: Object) =>
    createClient()
      .get('net-auctions/feedback-reviews', { params })
      .then(response => response.data),

  updateFeedbackReviewStatus: (feedbackId: number, status: number) =>
    createClient()
      .patch(`net-auctions/feedback-reviews/${feedbackId}`, { status })
      .then(response => response.data),

  updateFeedbackVisibility: (feedbackId: number, isHidden: number) =>
    createClient()
      .patch(`net-auctions/feedback-reviews/${feedbackId}`, { isHidden })
      .then(response => response.data),

  moderateFeedback: (
    feedbackId: number,
    productRating: number,
    deliveryRating: number,
    contactMe: boolean,
    message: string,
    reply: string
  ) =>
    createClient()
      .patch(`net-auctions/feedback-reviews/${feedbackId}`, {
        productRating,
        deliveryRating,
        contactMe,
        message,
        reply,
      })
      .then(response => response.data),

  updateFeedbackNotes: (feedbackId: number, notes: string) =>
    createClient()
      .patch(`net-auctions/feedback-reviews/${feedbackId}`, { notes })
      .then(response => response.data),

  fetchUserFeedbacks: (userId: number) =>
    createClient()
      .get(`users/${userId}/feedbacks`)
      .then(response => response.data),
};
