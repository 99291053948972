import { BankStatement, BankStatementRow } from '@typings/BankStatement';
import createClient from '../api/client';

export default {
  fetchBankStatements: async () =>
    createClient()
      .get<BankStatement[]>('/accounting/bank-statements')
      .then(response => response.data),

  fetchBankStatementRows: async (bankStatementId: number) =>
    createClient()
      .get<BankStatementRow[]>(`/accounting/bank-statements/${bankStatementId}/rows`)
      .then(response => response.data),

  fetchLatestBankStatementRowByReference: async (reference: string) =>
    createClient()
      .get<BankStatementRow | null>(`/accounting/bank-statements/latest-row-by-reference/${reference}`)
      .then(response => response.data),
};
