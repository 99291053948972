// @flow

import { List, Map } from 'immutable';
import vehicleRegistrationService from '../src/vehicle-registration/vehicle-registration-service';

const FETCH_VEHICLE_REGISTRATIONS = 'huutokaupat/vehicle-registration/FETCH_VEHICLE_REGISTRATIONS';
const FETCH_VEHICLE_REGISTRATIONS_DONE = 'huutokaupat/vehicle-registration/FETCH_VEHICLE_REGISTRATIONS_DONE';
const FETCH_VEHICLE_REGISTRATIONS_FAILED = 'huutokaupat/vehicle-registration/FETCH_VEHICLE_REGISTRATIONS_FAILED';

const FETCH_VEHICLE_REGISTRATION_ATTACHMENTS =
  'huutokaupat/vehicle-registration/FETCH_VEHICLE_REGISTRATION_ATTACHMENTS';
const FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_DONE =
  'huutokaupat/vehicle-registration/FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_DONE';
const FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_FAILED =
  'huutokaupat/vehicle-registration/FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_FAILED';

const UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT =
  'huutokaupat/vehicle-registration/UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT';
const UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_DONE =
  'huutokaupat/vehicle-registration/UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_DONE';
const UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_FAILED =
  'huutokaupat/vehicle-registration/UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_FAILED';

const DELETE_VEHICLE_REGISTRATION_ATTACHMENT =
  'huutokaupat/vehicle-registration/DELETE_VEHICLE_REGISTRATION_ATTACHMENT';
const DELETE_VEHICLE_REGISTRATION_ATTACHMENT_DONE =
  'huutokaupat/vehicle-registration/DELETE_VEHICLE_REGISTRATION_ATTACHMENT_DONE';
const DELETE_VEHICLE_REGISTRATION_ATTACHMENT_FAILED =
  'huutokaupat/vehicle-registration/DELETE_VEHICLE_REGISTRATION_ATTACHMENT_FAILED';

const UPDATE_VEHICLE_REGISTRATION = 'huutokaupat/vehicle-registration/UPDATE_VEHICLE_REGISTRATION';
const UPDATE_VEHICLE_REGISTRATION_DONE = 'huutokaupat/vehicle-registration/UPDATE_VEHICLE_REGISTRATION_DONE';
const UPDATE_VEHICLE_REGISTRATION_FAILED = 'huutokaupat/vehicle-registration/UPDATE_VEHICLE_REGISTRATION_FAILED';

const initialState = Map({
  loading: false,
  failed: false,
  vehicleRegistrations: List(),
  loadingAttachments: false,
  failedLoadingAttachments: false,
  uploadingAttachment: false,
  failedUploadingAttachment: false,
  attachmentNameBeingDeleted: '',
  failedDeletingAttachment: false,
  updatingVehicleRegistration: false,
  vehicleRegistrationAttachments: List(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_VEHICLE_REGISTRATIONS:
      return state.set('loading', true).set('failed', false).set('vehicleRegistrations', List());

    case FETCH_VEHICLE_REGISTRATIONS_DONE:
      return state.set('loading', false).set('vehicleRegistrations', List(action.payload));

    case FETCH_VEHICLE_REGISTRATIONS_FAILED:
      return state.set('loading', false).set('failed', true).set('vehicleRegistrations', List());

    case FETCH_VEHICLE_REGISTRATION_ATTACHMENTS:
      return state
        .set('loadingAttachments', true)
        .set('failedLoadingAttachments', false)
        .set('failedUploadingAttachment', false)
        .set('failedDeletingAttachment', false)
        .set('vehicleRegistrationAttachments', List());

    case FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_DONE:
      return state.set('loadingAttachments', false).set('vehicleRegistrationAttachments', List(action.payload));

    case FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_FAILED:
      return state
        .set('loadingAttachments', false)
        .set('failedLoadingAttachments', true)
        .set('vehicleRegistrationAttachments', List());

    case UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT:
      return state
        .set('uploadingAttachment', true)
        .set('attachmentNameBeingDeleted', '')
        .set('failedDeletingAttachment', false)
        .set('failedUploadingAttachment', false);

    case UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_DONE:
      return state.set('uploadingAttachment', false).set('vehicleRegistrationAttachments', List(action.payload));

    case UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_FAILED:
      return state.set('uploadingAttachment', false).set('failedUploadingAttachment', true);

    case DELETE_VEHICLE_REGISTRATION_ATTACHMENT:
      return state
        .set('attachmentNameBeingDeleted', action.payload)
        .set('failedUploadingAttachment', false)
        .set('failedDeletingAttachment', false);

    case DELETE_VEHICLE_REGISTRATION_ATTACHMENT_DONE:
      return state.set('attachmentNameBeingDeleted', '').set('vehicleRegistrationAttachments', List(action.payload));

    case DELETE_VEHICLE_REGISTRATION_ATTACHMENT_FAILED:
      return state.set('attachmentNameBeingDeleted', '').set('failedDeletingAttachment', true);

    case UPDATE_VEHICLE_REGISTRATION:
      return state.set('updatingVehicleRegistration', true);

    case UPDATE_VEHICLE_REGISTRATION_DONE: {
      const vehicleRegistrations = state.get('vehicleRegistrations');
      if (!vehicleRegistrations) {
        return state;
      }

      const index = vehicleRegistrations.findIndex(
        vehicleRegistration => parseInt(vehicleRegistration.id, 10) === action.payload.id
      );

      return state
        .updateIn(['vehicleRegistrations', index], v => ({
          ...v,
          secretary_status: action.payload.secretaryStatus,
          notes: action.payload.notes,
        }))
        .set('updatingVehicleRegistration', false);
    }

    case UPDATE_VEHICLE_REGISTRATION_FAILED:
      return state.set('updatingVehicleRegistration', false);

    default:
      return state;
  }
};

export function fetchVehicleRegistrations(inactiveOnly: boolean): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_VEHICLE_REGISTRATIONS });

    return vehicleRegistrationService.fetchVehicleRegistrations(inactiveOnly).then(
      vehicleRegistrations =>
        dispatch({
          type: FETCH_VEHICLE_REGISTRATIONS_DONE,
          payload: vehicleRegistrations,
        }),
      error => dispatch({ type: FETCH_VEHICLE_REGISTRATIONS_FAILED, payload: error })
    );
  };
}

export function fetchVehicleRegistrationAttachments(id: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_VEHICLE_REGISTRATION_ATTACHMENTS });

    return vehicleRegistrationService.fetchVehicleRegistrationAttachments(id).then(
      vehicleRegistrationAttachments =>
        dispatch({
          type: FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_DONE,
          payload: vehicleRegistrationAttachments,
        }),
      error =>
        dispatch({
          type: FETCH_VEHICLE_REGISTRATION_ATTACHMENTS_FAILED,
          payload: error,
        })
    );
  };
}

export function uploadAttachment(id: number, attachment: Object): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT });

    const data = new FormData();
    data.append('attachment', attachment);

    return vehicleRegistrationService.uploadAttachment(id, data).then(
      response =>
        dispatch({
          type: UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: UPLOAD_VEHICLE_REGISTRATION_ATTACHMENT_FAILED,
          payload: error,
        })
    );
  };
}

export function deleteAttachment(id: number, name: string): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: DELETE_VEHICLE_REGISTRATION_ATTACHMENT, payload: name });

    return vehicleRegistrationService.deleteAttachment(id, name).then(
      response =>
        dispatch({
          type: DELETE_VEHICLE_REGISTRATION_ATTACHMENT_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: DELETE_VEHICLE_REGISTRATION_ATTACHMENT_FAILED,
          payload: error,
        })
    );
  };
}

export function updateVehicleRegistration(id: number, data: Object): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: UPDATE_VEHICLE_REGISTRATION });

    return vehicleRegistrationService.updateVehicleRegistration(id, data).then(
      response =>
        dispatch({
          type: UPDATE_VEHICLE_REGISTRATION_DONE,
          payload: response,
        }),
      error => dispatch({ type: UPDATE_VEHICLE_REGISTRATION_FAILED, payload: error })
    );
  };
}
