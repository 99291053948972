// @flow

import { Map } from 'immutable';

const initialState = Map({
  classesWithCounts: new Map(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    default:
      return state;
  }
};

// No action creators for now, the data is preloaded in #initial-state.
