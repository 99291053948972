// @flow

import { List, Map } from 'immutable';
import queryString from 'query-string';
import entryListService from '../src/entry-list/entry-list-service';

const SHOW_ENTRIES = 'huutokaupat/entry-list/SHOW_ENTRIES';
const RESET_ENTRY_LIST_FOR_LOADING = 'huutokaupat/entry-list/RESET_ENTRY_LIST_FOR_LOADING';
const PRELOAD_ENTRIES = 'huutokaupat/entry-list/PRELOAD_ENTRIES';
const PRELOAD_ENTRIES_DONE = 'huutokaupat/entry-list/PRELOAD_ENTRIES_DONE';
const SET_BIDDER_IDS = 'huutokaupat/entry-list/SET_BIDDER_IDS';

const initialState = Map({
  entries: List(),
  pages: Map(),
  title: null,
  description: null,
  isLoading: true,
  isInitialLoadDone: false,
  searchSaved: false,
  saveSearchLoading: false,
  savedSearchId: null,
  email: null,
  removed: false,
  emailConfirmationModalVisible: false,
  preloaded: null,
  bidderIds: Map(),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case SHOW_ENTRIES:
      return state
        .set('entries', List(action.payload.entries))
        .set('pages', Map(action.payload.pages))
        .set('isLoading', false)
        .set('isInitialLoadDone', true)
        .set('title', action.payload.title)
        .set('description', action.payload.description);

    case RESET_ENTRY_LIST_FOR_LOADING:
      return state.set('isLoading', true).set('shouldAnimate', true);

    case PRELOAD_ENTRIES:
      return state.set('preloaded', null);

    case PRELOAD_ENTRIES_DONE:
      return state.set('preloaded', state.get('preloaded') || action.payload.data).set('shouldAnimate', false);

    case SET_BIDDER_IDS:
      return state.set('bidderIds', Map(action.payload));

    default:
      return state;
  }
};

export function showEntries(entries: List<any>, pages: Map<string, any>, title: ?string, description: ?string): Object {
  return {
    type: SHOW_ENTRIES,
    payload: { entries, pages, title, description },
  };
}

export function resetEntryListForLoading(): Object {
  return { type: RESET_ENTRY_LIST_FOR_LOADING };
}

export function preloadNextPage(
  query: Object,
  entries: Object,
  pages: Map<string, any>,
  favoritesOnly: boolean
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: PRELOAD_ENTRIES });

    if (entries && pages.get('current', 0) >= pages.get('pageCount', 0)) {
      return new Promise(resolve => resolve());
    }

    const nextPageNumber = query.sivu ? parseInt(query.sivu, 10) + 1 : 2;
    const nextPageQuery = { ...query, sivu: nextPageNumber };

    return entryListService.fetch(`?${queryString.stringify(nextPageQuery)}`, favoritesOnly).then((data): void => {
      dispatch({ type: PRELOAD_ENTRIES_DONE, payload: { data } });
      return data;
    });
  };
}

export function setBidderIdsForEntries(bidderIds: Object): Object {
  return { type: SET_BIDDER_IDS, payload: bidderIds };
}
