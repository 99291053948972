import { Dispatch } from 'redux';

export const DecreaseNoteCountForFrozenNetAuction =
  'huutokaupat/frozen-net-auction/DECREASE_NOTE_COUNT_FOR_FROZEN_NET_AUCTION';

export interface DecreaseNoteCountForFrozenNetAuctionAction {
  readonly type: typeof DecreaseNoteCountForFrozenNetAuction;
  readonly payload: { readonly entryId: number };
}

export function decreaseNoteCount(entryId: number) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: DecreaseNoteCountForFrozenNetAuction,
      payload: { entryId },
    });
  };
}
