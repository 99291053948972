// @flow

import { Map } from 'immutable';

import {
  approveBid as doApproveBid,
  rejectBid as doRejectBid,
} from '../src/net-auction/approval/net-auction-approval-service';
import netAuctionService from '../src/net-auction/net-auction-service';
import { CANCELLATION_REASON_RUNNER_UP_OFFER } from '../src/entry/entry-service';

const FETCH_DATA = 'huutokaupat/net-auction-toolbar/FETCH_DATA';
const FETCH_DATA_DONE = 'huutokaupat/net-auction-toolbar/FETCH_DATA_DONE';
const FETCH_DATA_FAILED = 'huutokaupat/net-auction-toolbar/FETCH_DATA_FAILED';

const CHECK_CANCELLABLE = 'huutokaupat/net-auction-toolbar/CHECK_CANCELLABLE';
const CHECK_CANCELLABLE_DONE = 'huutokaupat/net-auction-toolbar/CHECK_CANCELLABLE_DONE';
const CHECK_CANCELLABLE_FAILED = 'huutokaupat/net-auction-toolbar/CHECK_CANCELLABLE_FAILED';

const CANCEL = 'huutokaupat/net-auction-toolbar/CANCEL';
const CANCEL_DONE = 'huutokaupat/net-auction-toolbar/CANCEL_DONE';
const CANCEL_FAILED = 'huutokaupat/net-auction-toolbar/CANCEL_FAILED';

const OFFER_TO_RUNNER_UP = 'huutokaupat/net-auction-toolbar/OFFER_TO_RUNNER_UP';
const OFFER_TO_RUNNER_UP_DONE = 'huutokaupat/net-auction-toolbar/OFFER_TO_RUNNER_UP_DONE';
const OFFER_TO_RUNNER_UP_FAILED = 'huutokaupat/net-auction-toolbar/OFFER_TO_RUNNER_UP_FAILED';

const APPROVE_BID = 'huutokaupat/net-auction-toolbar/APPROVE_BID';
const APPROVE_BID_DONE = 'huutokaupat/net-auction-toolbar/APPROVE_BID_DONE';
const APPROVE_BID_FAILED = 'huutokaupat/net-auction-toolbar/APPROVE_BID_FAILED';

const REJECT_BID = 'huutokaupat/net-auction-toolbar/REJECT_BID';
const REJECT_BID_DONE = 'huutokaupat/net-auction-toolbar/REJECT_BID_DONE';
const REJECT_BID_FAILED = 'huutokaupat/net-auction-toolbar/REJECT_BID_FAILED';

const START_POST_TRADING = 'huutokaupat/net-auction-toolbar/START_POST_TRADING';
const START_POST_TRADING_DONE = 'huutokaupat/net-auction-toolbar/START_POST_TRADING_DONE';
const START_POST_TRADING_FAILED = 'huutokaupat/net-auction-toolbar/START_POST_TRADING_FAILED';

const APPROVE_POST_TRADING = 'huutokaupat/net-auction-toolbar/APPROVE_POST_TRADING';
const APPROVE_POST_TRADING_DONE = 'huutokaupat/net-auction-toolbar/APPROVE_POST_TRADING_DONE';
const APPROVE_POST_TRADING_FAILED = 'huutokaupat/net-auction-toolbar/APPROVE_POST_TRADING_FAILED';

const REJECT_POST_TRADING = 'huutokaupat/net-auction-toolbar/REJECT_POST_TRADING';
const REJECT_POST_TRADING_DONE = 'huutokaupat/net-auction-toolbar/REJECT_POST_TRADING_DONE';
const REJECT_POST_TRADING_FAILED = 'huutokaupat/net-auction-toolbar/REJECT_POST_TRADING_FAILED';

const MARK_AS_PAID = 'huutokaupat/net-auction-toolbar/MARK_AS_PAID';
const MARK_AS_PAID_DONE = 'huutokaupat/net-auction-toolbar/MARK_AS_PAID_DONE';
const MARK_AS_PAID_FAILED = 'huutokaupat/net-auction-toolbar/MARK_AS_PAID_FAILED';

const MARK_AS_DELIVERED = 'huutokaupat/net-auction-toolbar/MARK_AS_DELIVERED';
const MARK_AS_DELIVERED_DONE = 'huutokaupat/net-auction-toolbar/MARK_AS_DELIVERED_DONE';
const MARK_AS_DELIVERED_FAILED = 'huutokaupat/net-auction-toolbar/MARK_AS_DELIVERED_FAILED';

const MARK_AS_HANDOVER = 'huutokaupat/net-auction-toolbar/MARK_AS_HANDOVER';
const MARK_AS_HANDOVER_DONE = 'huutokaupat/net-auction-toolbar/MARK_AS_HANDOVER_DONE';
const MARK_AS_HANDOVER_FAILED = 'huutokaupat/net-auction-toolbar/MARK_AS_HANDOVER_FAILED';

const OPEN_CONTINUOUS_AUCTION_MODAL = 'huutokaupat/net-auction-toolbar/OPEN_CONTINUOUS_AUCTION_MODAL';
const CLOSE_CONTINUOUS_AUCTION_MODAL = 'huutokaupat/net-auction-toolbar/CLOSE_CONTINUOUS_AUCTION_MODAL';
const START_CONTINUOUS_AUCTION_DONE = 'huutokaupat/net-auction-toolbar/START_CONTINUOUS_AUCTION_DONE';
const START_CONTINUOUS_AUCTION_FAILED = 'huutokaupat/net-auction-toolbar/START_CONTINUOUS_AUCTION_FAILED';

const initialState = Map({
  runnerUpBid: null,
  netAuctionStatus: null,
  title: null,
  highestBid: null,
  companyId: null,
  vatPercent: null,

  isBeingOfferedToRunnerUp: false,
  offeredToRunnerUpAt: null,

  isCancellable: false,
  isWaitingForPublishing: false,
  isCancellableDueToMistake: false,
  isRiskFree: false,
  isBeingSoldToHighestBidder: false,
  cancelDone: false,
  cancelFailed: false,
  runnerUpOfferDone: false,
  runnerUpOfferFailed: false,
  cancelling: false,

  isApprovable: null,
  approving: false,
  approveDone: false,
  approveFailed: false,
  rejectDone: false,
  rejectFailed: false,
  startPostTradingDone: false,
  startPostTradingFailed: false,
  isOwnPayment: false,
  categoryId: null,
  netAuctionClass: null,

  isWaitingForPostTradingDecision: false,
  decidingPostTrading: false,
  finishPostTradingDone: false,
  finishPostTradingFailed: false,

  isMarkableAsPaid: false,
  markingAsPaid: false,
  markAsPaidDone: false,
  markAsPaidFailed: false,

  isWaitingForDelivery: false,
  markingAsDelivered: false,
  markAsDeliveredDone: false,
  markAsDeliveredFailed: false,

  isWaitingForHandover: false,
  markingAsHandover: false,
  markAsHandoverDone: false,
  markAsHandoverFailed: false,

  isContinuousAuctionModalVisible: false,
  isStartContinuousAuctionDone: false,
  isStartContinuousAuctionFailed: false,
  createdContinuousAuctionId: null,

  failed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case CHECK_CANCELLABLE_DONE:
      return state
        .set('isCancellable', action.payload.isCancellable)
        .set('isWaitingForPublishing', action.payload.isWaitingForPublishing)
        .set('isCancellableDueToMistake', action.payload.isCancellableDueToMistake)
        .set('isRiskFree', action.payload.isRiskFree)
        .set('runnerUpBid', action.payload.runnerUpBid)
        .set('title', action.payload.title)
        .set('netAuctionStatus', action.payload.netAuctionStatus)
        .set('isBeingSoldToHighestBidder', action.payload.isSoldToHighestBidder);

    case CANCEL:
    case OFFER_TO_RUNNER_UP:
      return state.set('cancelling', true);

    case CANCEL_DONE:
      return state.set('cancelDone', true).set('cancelling', false);

    case CANCEL_FAILED:
      return state.set('cancelFailed', true);

    case OFFER_TO_RUNNER_UP_DONE:
      return state.set('runnerUpOfferDone', true).set('cancelling', false);

    case OFFER_TO_RUNNER_UP_FAILED:
      return state.set('runnerUpOfferFailed', true);

    case FETCH_DATA_DONE:
      return state
        .set('isApprovable', action.payload.isApprovable)
        .set('isWaitingForPostTradingDecision', action.payload.isWaitingForPostTradingDecision)
        .set('isMarkableAsPaid', action.payload.isMarkableAsPaid)
        .set('isWaitingForDelivery', action.payload.isWaitingForDelivery)
        .set('isWaitingForHandover', action.payload.isWaitingForHandover)
        .set('isBeingOfferedToRunnerUp', action.payload.isBeingOfferedToRunnerUp)
        .set('offeredToRunnerUpAt', action.payload.offeredToRunnerUpAt)
        .set('companyId', action.payload.companyId)
        .set('title', action.payload.title)
        .set('vatPercent', action.payload.vatPercent ? Number(action.payload.vatPercent) : null)
        .set('highestBid', action.payload.highestBid ? Number(action.payload.highestBid) : null)
        .set('isOwnPayment', action.payload.isOwnPayment)
        .set('categoryId', action.payload.categoryId)
        .set('netAuctionClass', action.payload.netAuctionClass);

    case APPROVE_BID:
    case REJECT_BID:
    case START_POST_TRADING:
      return state.set('approving', true);

    case APPROVE_BID_DONE:
      return state.set('approveDone', true).set('approving', false);

    case APPROVE_BID_FAILED:
      return state.set('approveFailed', true).set('approving', false);

    case REJECT_BID_DONE:
      return state.set('rejectDone', true).set('approving', false);

    case REJECT_BID_FAILED:
      return state.set('rejectFailed', true).set('approving', false);

    case START_POST_TRADING_DONE:
      return state.set('startPostTradingDone', true).set('approving', false);

    case START_POST_TRADING_FAILED:
      return state.set('startPostTradingFailed', true).set('approving', false);

    case APPROVE_POST_TRADING:
    case REJECT_POST_TRADING:
      return state.set('decidingPostTrading', true);

    case APPROVE_POST_TRADING_DONE:
    case REJECT_POST_TRADING_DONE:
      return state.set('finishPostTradingDone', true).set('decidingPostTrading', false);

    case APPROVE_POST_TRADING_FAILED:
    case REJECT_POST_TRADING_FAILED:
      return state.set('finishPostTradingFailed', true);

    case MARK_AS_PAID:
      return state.set('markingAsPaid', true);

    case MARK_AS_PAID_DONE:
      return state.set('markingAsPaid', false).set('markAsPaidDone', true);

    case MARK_AS_PAID_FAILED:
      return state.set('markAsPaidFailed', true);

    case MARK_AS_DELIVERED:
      return state.set('markingAsDelivered');

    case MARK_AS_DELIVERED_DONE:
      return state.set('markAsDeliveredDone', true).set('markingAsDelivered', false);

    case MARK_AS_DELIVERED_FAILED:
      return state.set('markAsDeliveredFailed', true);

    case MARK_AS_HANDOVER:
      return state.set('markingAsHandover', true);

    case MARK_AS_HANDOVER_DONE:
      return state.set('markAsHandoverDone', true).set('markingAsHandover', false);

    case MARK_AS_HANDOVER_FAILED:
      return state.set('markAsHandoverFailed', true);

    case OPEN_CONTINUOUS_AUCTION_MODAL:
      return state.set('isContinuousAuctionModalOpen', true);

    case CLOSE_CONTINUOUS_AUCTION_MODAL:
      return state.set('isContinuousAuctionModalOpen', false);

    case START_CONTINUOUS_AUCTION_DONE:
      return state
        .set('startContinuousAuctionDone', true)
        .set('createdContinuousAuctionId', action.payload.new_entry_id);

    case START_CONTINUOUS_AUCTION_FAILED:
      return state.set('startContinuousAuctionFailed', true);

    default:
      return state;
  }
};

export function checkCancellable(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: CHECK_CANCELLABLE, payload: { entryId } });

    return netAuctionService.checkCancellable(entryId).then(
      response => dispatch({ type: CHECK_CANCELLABLE_DONE, payload: response }),
      error => dispatch({ type: CHECK_CANCELLABLE_FAILED, payload: error })
    );
  };
}

export function cancel(entryId: number, reason: number, otherReason: string): Function {
  return (dispatch: Function): Promise<any> => {
    if (reason === CANCELLATION_REASON_RUNNER_UP_OFFER) {
      dispatch({ type: OFFER_TO_RUNNER_UP, payload: { entryId } });
      return netAuctionService.offerToRunnerUp(entryId).then(
        () => dispatch({ type: OFFER_TO_RUNNER_UP_DONE }),
        error => dispatch({ type: OFFER_TO_RUNNER_UP_FAILED, payload: error })
      );
    }

    dispatch({ type: CANCEL, payload: { entryId } });

    return netAuctionService.cancel(entryId, reason, otherReason, netAuctionService.isCancelFreeOfCharge(reason)).then(
      () => dispatch({ type: CANCEL_DONE }),
      error => dispatch({ type: CANCEL_FAILED, payload: error })
    );
  };
}

export function fetchData(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_DATA });

    return netAuctionService.fetchDataForToolbar(entryId).then(
      response => dispatch({ type: FETCH_DATA_DONE, payload: response }),
      error => dispatch({ type: FETCH_DATA_FAILED, payload: error })
    );
  };
}

export function approveBid(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: APPROVE_BID });

    return doApproveBid(entryId).then(
      () => dispatch({ type: APPROVE_BID_DONE }),
      error => dispatch({ type: APPROVE_BID_FAILED, payload: error })
    );
  };
}

export function rejectBid(entryId: number, shouldSendBidderMail: boolean): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: REJECT_BID });

    return doRejectBid(entryId, shouldSendBidderMail).then(
      response => {
        if (response.isContinuable) {
          return response;
        }

        return dispatch({ type: REJECT_BID_DONE, payload: response });
      },
      error => dispatch({ type: REJECT_BID_FAILED, payload: error })
    );
  };
}

export function startPostTrading(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: START_POST_TRADING });

    return netAuctionService.startPostTrading(entryId).then(
      () => dispatch({ type: START_POST_TRADING_DONE }),
      error => dispatch({ type: START_POST_TRADING_FAILED, payload: error })
    );
  };
}

export function approvePostTrading(entryId: number, sum: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: APPROVE_POST_TRADING });

    return netAuctionService
      .finishPostTrading(entryId, sum)
      .then(() => dispatch({ type: APPROVE_POST_TRADING_DONE, payload: { entryId } }))
      .catch(error => dispatch({ type: APPROVE_POST_TRADING_FAILED, payload: error }));
  };
}

export function rejectPostTrading(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: REJECT_POST_TRADING });

    return doRejectBid(entryId).then(
      () => dispatch({ type: REJECT_POST_TRADING_DONE }),
      error => dispatch({ type: REJECT_POST_TRADING_FAILED, payload: error })
    );
  };
}

export function markAsPaid(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_PAID, payload: { entryId } });

    return netAuctionService.markAsPaid(entryId).then(
      () => dispatch({ type: MARK_AS_PAID_DONE }),
      error => dispatch({ type: MARK_AS_PAID_FAILED, payload: error })
    );
  };
}

export function markAsDelivered(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_DELIVERED });

    return netAuctionService.markAsDelivered([entryId]).then(
      () => dispatch({ type: MARK_AS_DELIVERED_DONE }),
      error => dispatch({ type: MARK_AS_DELIVERED_FAILED, payload: error })
    );
  };
}

export function markAsHandover(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: MARK_AS_HANDOVER, payload: { entryId } });

    return netAuctionService
      .markAsHandover([entryId])
      .then(() =>
        dispatch({
          type: MARK_AS_HANDOVER_DONE,
          payload: { entryId },
        })
      )
      .catch(error =>
        dispatch({
          type: MARK_AS_HANDOVER_FAILED,
          payload: error,
        })
      );
  };
}

export function closeContinuousAuctionModal(): any {
  return { type: CLOSE_CONTINUOUS_AUCTION_MODAL };
}

export function startContinuousAuction(entryId: number, data: any): Function {
  return (dispatch: Function): Promise<any> =>
    netAuctionService.renewNetAuction(entryId, data).then(
      response =>
        dispatch({
          type: START_CONTINUOUS_AUCTION_DONE,
          payload: response,
        }),
      () => dispatch({ type: START_CONTINUOUS_AUCTION_FAILED })
    );
}
