import createClient from '../api/client';

export default {
  getVisualMedias: id =>
    createClient()
      .get(`/templates/${id}/media/visuals`)
      .then(response => response.data),

  removeAllMedias: id =>
    createClient()
      .delete(`/templates/${id}/media/visuals`)
      .then(response => response.data),

  /**
   * Reorders medias based on given array of media ids.
   * Replaces former 'moveMedia'.
   * @param {number} referenceId
   * @param {number[]} sortedIds
   * @returns
   */
  orderMedias: (referenceId, sortedIds) =>
    createClient()
      .patch(`/templates/media/visuals/order/${referenceId}`, { ids: sortedIds })
      .then(response => response.data),

  uploadVideo: (referenceId, url) =>
    createClient()
      .put(`templates/${referenceId}/media/videos`, { url })
      .then(response => response.data),

  removeVideo: id =>
    createClient()
      .delete(`/templates/media/videos/${id}`)
      .then(response => response.data),

  uploadImage: (referenceId, file) =>
    createClient()
      .post(`/templates/${referenceId}/media/images`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => response.data),

  uploadCrawledImage: (referenceId, image, sourceType) =>
    createClient()
      .put(`/templates/${referenceId}/media/images/crawl`, {
        image,
        sourceType,
      })
      .then(response => response.data),

  removeImage: id =>
    createClient()
      .delete(`/templates/media/images/${id}`)
      .then(response => response.data),

  rotateImage: id =>
    createClient()
      .patch(`/templates/media/images/${id}`)
      .then(response => response.data),
};

const MISSING_PARAMETER = 'missing_parameter';

const parseErrorMessage = ({ response }, defaultErrorMessage) => {
  if (response && response.data) {
    if ('videoCreatingError' in response.data) {
      return 'Virhe videolinkin luonnissa';
    }

    if ('invalidContent' in response.data) {
      return 'Virheellinen lähetyksen sisältö';
    }

    if ('imageProcessingError' in response.data) {
      return 'Virhe luotaessa kuvaa';
    }

    if ('mediaDeletingError' in response.data) {
      return 'Virhe poistaessa mediaa';
    }

    if ('mediasDeletingError' in response.data) {
      return 'Virhe poistaessa medioita';
    }
  }

  return defaultErrorMessage || 'Tuntematon virhe';
};

export { parseErrorMessage, MISSING_PARAMETER };
