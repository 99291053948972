import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

interface RegistrationContentProps {
  readonly returnUrl?: string;
}

export function RegistrationContent({ returnUrl }: RegistrationContentProps) {
  return (
    <div className="login-block">
      <h2>Ei tunnusta vielä?</h2>

      <p>
        Tee <b>täysin ilmainen</b> tunnus parissa minuutissa. Pääset huutamaan kohteita ja saat käyttöösi nämä:
      </p>

      <Row>
        <Col md={6}>
          <i className="icon icon-notification" />
          <div className="feature">
            <h3>Muistutukset</h3>
            <p>Tilaa ilmainen tekstari tuntia ennen kiinnostavan kohteen päättymistä. </p>
          </div>
        </Col>
        <Col md={6}>
          <i className="icon icon-star" />
          <div className="feature">
            <h3>Seuraa kohteita</h3>
            <p>Kerää kiinnostavat kohteet omalle listalle, jota on helppo seurata kaikilla laitteillasi.</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <i className="icon icon-robot" />
          <div className="feature">
            <h3>Korotusautomaatti</h3>
            <p>Anna automaatin huutaa puolestasi valitsemaasi kattohintaan asti.</p>
          </div>
        </Col>
        <Col md={6}>
          <i className="icon icon-binoculars" />
          <div className="feature">
            <h3>Hakuvahti</h3>
            <p>Etsitkö jotain tiettyä? Saat sähköpostin, kun sopivia kohteita tulee myyntiin.</p>
          </div>
        </Col>
      </Row>

      <Row className="row actions">
        <Col sm={6}>
          <Button bsStyle="success" href={`/tee-tunnus${returnUrl ? `?return=${returnUrl}` : ''}`}>
            Tee ilmainen tunnus
          </Button>
        </Col>

        <Col md={6}>
          <p className="want-to-be-seller">
            <a href="/myy-huutokaupatcomissa">Aloita myyminen tästä</a>
          </p>
        </Col>
      </Row>
    </div>
  );
}
