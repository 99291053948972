import React from 'react';

export const CustomerServiceDetails = () => (
  <>
    Asiakaspalvelun yhteystiedot ja aukioloajat löytyvät{' '}
    <a
      href="https://asiakaspalvelu.huutokaupat.com/support/solutions/articles/80001081969-asiakaspalvelun-yhteystiedot-ja-aukioloajat"
      target="_blank"
      rel="noopener noreferrer"
    >
      täältä.
    </a>
  </>
);
