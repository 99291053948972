import { datadogRum } from '@datadog/browser-rum-slim';
import { getStatisticsConsent } from '@app/src/consent/consent-service';

const isProduction = /huutokaupat.com\//.test(window.location.href);

// Check if statistics consent was given
const isConsentGiven = getStatisticsConsent();

if (isProduction && isConsentGiven) {
  datadogRum.init({
    applicationId: 'bad282f2-0094-4fa5-a71b-4e431cedae9b',
    clientToken: 'pub2964840f71ca5e84336584b2627f9e9f',
    site: 'datadoghq.eu',
    service: 'huutokaupat',
    env: 'prod',
    version: import.meta.env.VITE_VERSION,
    sessionSampleRate: 0.5,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    allowedTracingUrls: ['https://huutokaupat.com'],
  });
}
