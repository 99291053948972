import dayjs, { ConfigType } from 'dayjs';

export const parseDate = (date: string) => {
  return dayjs(date, 'D.M.YYYY');
};

export const formatDate = (date: ConfigType, format = 'DD.MM.YYYY', defaultValue: string = '') => {
  const dayjsDate = dayjs(date);

  return dayjsDate.isValid() ? dayjsDate.format(format) : defaultValue;
};

export const formatDateLong = (date: ConfigType, defaultValue: string = '') => {
  const dayjsDate = dayjs(date);

  return dayjsDate.isValid() ? dayjsDate.format('dd DD.MM.YYYY HH.mm') : defaultValue;
};

export const formatFromDate = (date: ConfigType, defaultValue: string = '') => {
  const dayjsDate = dayjs(date);

  return dayjsDate.isValid() ? `${dayjsDate.format('MMMM')}sta ${dayjsDate.format('YYYY')}` : defaultValue;
};

export const sortDates = (a: ConfigType, b: ConfigType) => dayjs(a).diff(dayjs(b));

export const getStartOfMonth = (date: ConfigType) => dayjs(date).startOf('month').toDate();

export const getEndOfMonth = (date: ConfigType) => dayjs(date).endOf('month').toDate();

export const isAfterNow = (date: ConfigType) => dayjs().isAfter(date);
