import { useState, useEffect } from 'react';

export default function useScrollClamp(max: number) {
  const [amount, setAmount] = useState(0);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const calculateScroll = () => {
      const deltaScrollY = prevScrollY - window.scrollY;
      setAmount(amount => Math.max(0, Math.min(max, amount - deltaScrollY)));
      setPrevScrollY(window.scrollY);
    };

    window.addEventListener('scroll', calculateScroll);
    return () => {
      window.removeEventListener('scroll', calculateScroll);
    };
  }, [max, prevScrollY]);

  return [amount, prevScrollY];
}
