//

import { Map } from 'immutable';
import userDetailsService from '../src/user/user-details-service';

const FETCH_USER_DETAILS = 'huutokaupat/user/user-details/FETCH_USER_DETAILS';
const FETCH_USER_DETAILS_DONE = 'huutokaupat/user/user-details/FETCH_USER_DETAILS_DONE';
const FETCH_USER_DETAILS_FAILED = 'huutokaupat/user/user-details/FETCH_USER_DETAILS_FAILED';

const STORE_USER_DETAILS = 'huutokaupat/user/user-details/STORE_USER_DETAILS';
const STORE_USER_DETAILS_DONE = 'huutokaupat/user/user-details/STORE_USER_DETAILS_DONE';
const STORE_USER_DETAILS_FAILED = 'huutokaupat/user/user-details/STORE_USER_DETAILS_FAILED';
const STORE_USER_DETAILS_EMAIL_CHANGED = 'huutokaupat/user/user-details/STORE_USER_DETAILS_EMAIL_CHANGED';
const STORE_USER_DETAILS_EXPIRED = 'huutokaupat/user/user-details/STORE_USER_DETAILS_EXPIRED';

const initialState = Map({
  loading: false,
  failed: false,
  emailChanged: false,
  expired: false,
  saving: false,
  details: {},
  errors: {},
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USER_DETAILS:
      return state
        .set('loading', true)
        .set('failed', false)
        .set('emailChanged', false)
        .set('expired', false)
        .set('details', {});

    case FETCH_USER_DETAILS_DONE:
      return state.set('loading', false).set('details', action.payload);

    case FETCH_USER_DETAILS_FAILED:
      return state.set('loading', false).set('failed', true);

    case STORE_USER_DETAILS:
      return state.set('saving', true).set('saveFailed', false).set('errors', {});

    case STORE_USER_DETAILS_DONE:
      return state.set('saving', false).set('saveFailed', false).set('errors', action.payload);

    case STORE_USER_DETAILS_FAILED:
      return state.set('saving', false).set('saveFailed', true);

    case STORE_USER_DETAILS_EMAIL_CHANGED:
      return state.set('emailChanged', true);

    case STORE_USER_DETAILS_EXPIRED:
      return state.set('expired', true);

    default:
      return state;
  }
};

export function fetchUserDetails() {
  return dispatch => {
    dispatch({ type: FETCH_USER_DETAILS });

    return userDetailsService
      .fetchUserDetails()
      .then(data => {
        dispatch({ type: FETCH_USER_DETAILS_DONE, payload: data });
        if (data.expired) {
          dispatch({ type: STORE_USER_DETAILS_EXPIRED });
        }
      })
      .catch(error => dispatch({ type: FETCH_USER_DETAILS_FAILED, payload: error }));
  };
}

export function storeUserDetails(details) {
  return dispatch => {
    dispatch({ type: STORE_USER_DETAILS });

    return userDetailsService
      .storeUserDetails(details)
      .then(data => {
        dispatch({ type: STORE_USER_DETAILS_DONE, payload: data });
        if (data.emailChanged) {
          dispatch({ type: STORE_USER_DETAILS_EMAIL_CHANGED });
        }
        if (data.expired) {
          dispatch({ type: STORE_USER_DETAILS_EXPIRED });
        }
      })
      .catch(error => dispatch({ type: STORE_USER_DETAILS_FAILED, payload: error }));
  };
}
