// @flow

import { List, Map } from 'immutable';
import { fetchNetAuctionVersions } from '../src/net-auction/net-auction-version-service';

const FETCH_NET_AUCTION_VERSIONS = 'huutokaupat/net-auction-version/FETCH_NET_AUCTION_VERSIONS';
const FETCH_NET_AUCTION_VERSIONS_FAILED = 'huutokaupat/net-auction-version/FETCH_NET_AUCTION_VERSIONS_FAILED';
const FETCH_NET_AUCTION_VERSIONS_DONE = 'huutokaupat/net-auction-version/FETCH_NET_AUCTION_VERSIONS_DONE';

const initialState: Map<string, any> = Map({
  versions: List(),
  loading: false,
  failed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Map<string, any> => {
  switch (action.type) {
    case FETCH_NET_AUCTION_VERSIONS:
      return state.set('loading', true).set('failed', false).set('versions', List());

    case FETCH_NET_AUCTION_VERSIONS_FAILED:
      return state.set('loading', false).set('failed', true);

    case FETCH_NET_AUCTION_VERSIONS_DONE:
      return state.set('loading', false).set('failed', false).set('versions', List(action.payload.versions));

    default:
      return state;
  }
};

export function fetchVersions(entryId: number): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_NET_AUCTION_VERSIONS });

    return fetchNetAuctionVersions(entryId).then(
      response =>
        dispatch({
          type: FETCH_NET_AUCTION_VERSIONS_DONE,
          payload: response,
        }),
      error =>
        dispatch({
          type: FETCH_NET_AUCTION_VERSIONS_FAILED,
          payload: error,
        })
    );
  };
}
