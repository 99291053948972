// @flow

import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from '../application/src/security/jwt';

export default function (): void {
  // Automatically adds a CSRF field to regular HTML forms.
  const csrfTokenField = document.createElement('input');
  /* eslint-disable functional/immutable-data -- Modify created input tag. */
  csrfTokenField.type = 'hidden';
  csrfTokenField.name = 'csrf-token';
  csrfTokenField.value = Cookies.get('csrf-token') ?? '';
  /* eslint-enable -- Restore. */
  document.querySelectorAll('form[method="post"]').forEach(form => form.prepend(csrfTokenField));

  /* eslint-disable functional/immutable-data -- Modify axios default. */
  axios.defaults.xsrfCookieName = 'csrf-token';
  axios.defaults.xsrfHeaderName = 'X-Csrf-Token';
  /* eslint-enable -- Restore. */

  // Used by login to force client to fetch new user info, name highly confusing.
  if (Cookies.get('request-jwt')) {
    void jwt.requestTokenWithSession().then(f => f);
    Cookies.remove('request-jwt');
  } else if (!jwt.getToken()) {
    // We might still have a session client doesn't know about.
    void jwt.requestTokenWithSession().then(f => f);
  }

  // Check if a refresh is necessary every 5 minutes (mostly used by the React app)
  setInterval(
    () =>
      // FIXME Add user info check here.
      undefined,
    1000 * 60 * 5
  );
}
