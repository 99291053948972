// @flow

import { Map } from 'immutable';
import statisticsService from '../src/util/statistics-service';

const FETCH_BID_AMOUNT = 'huutokaupat/statistics/FETCH_BID_AMOUNT';
const FETCH_BID_AMOUNT_DONE = 'huutokaupat/statistics/FETCH_BID_AMOUNT_DONE';
const FETCH_BID_AMOUNT_FAILED = 'huutokaupat/statistics/FETCH_BID_AMOUNT_FAILED';

const initialState = Map({
  bidsLastMonth: null,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_BID_AMOUNT:
      return state;

    case FETCH_BID_AMOUNT_DONE:
      return state.set('bidsLastMonth', action.payload.bidsLastMonth);

    case FETCH_BID_AMOUNT_FAILED:
      return state;

    default:
      return state;
  }
};

export function fetchBidAmountLastMonth(): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: FETCH_BID_AMOUNT });

    return statisticsService.fetchBidAmountLastMonth().then(
      response => dispatch({ type: FETCH_BID_AMOUNT_DONE, payload: response }),
      error => dispatch({ type: FETCH_BID_AMOUNT_FAILED, payload: error })
    );
  };
}
