import createClient from '../api/client';

export interface MarketPriceEntry {
  readonly bid_sum: string;
  readonly auction_end: string;
  readonly entry_id: string;
  readonly view_count: string;
  readonly bid_count: string;
  readonly manufacturer: string;
  readonly model: string;
  readonly year_model: string;
  readonly odometer: string;
  readonly engine_displacement: string;
  readonly operational_hours: string;
}

export interface MarketPriceInfoResults {
  readonly entriesSold: {
    readonly car: number;
    readonly machinery: number;
  };
}

export default {
  fetchEntries: async (params: Record<string, unknown>) =>
    createClient()
      .get<MarketPriceEntry[]>('/market-price/search', { params })
      .then(response => response.data),

  fetchInfo: async () =>
    createClient()
      .get<MarketPriceInfoResults>('/market-price')
      .then(response => response.data),
};
