// @flow

import { Map, fromJS } from 'immutable';
import netAuctionService from '../src/net-auction/net-auction-service';

const GET_ENTRY = 'huutokaupat/net-auction-entry/GET_ENTRY';
const GET_ENTRY_DONE = 'huutokaupat/net-auction-entry/GET_ENTRY_DONE';
const GET_ENTRY_FAILED = 'huutokaupat/net-auction-entry/GET_ENTRY_FAILED';
const ACTIVATE_BIDDING_BOT = 'huutokaupat/net-auction-entry/ACTIVATE_BIDDING_BOT';
const DEACTIVATE_BIDDING_BOT = 'huutokaupat/net-auction-entry/DEACTIVATE_BIDDING_BOT';
const BID = 'huutokaupat/net-auction-entry/BID';
const QUICK_SELECT_CATEGORY = 'huutokaupat/net-auction-entry/QUICK_SELECT_CATEGORY';
const SELECTION_FOR_ENTRY = 'huutokaupat/net-auction-entry/SELECTION_FOR_ENTRY';

const SET_BIDDER_ID = 'huutokaupat/net-auction-entry/SET_BIDDER_ID';
const AFTER_BID_UPDATE_RECEIVED = 'huutokaupat/net-auction-entry/AFTER_BID_UPDATE_RECEIVED';
const RESET_ENTRY = 'huutokaupat/net-auction-entry/RESET_ENTRY';

const initialState = Map({
  loading: false,
  failed: false,
  isWaitingForAfterBidUpdate: false,
  data: Map({
    entry: {},
    user: {},
    seller: {},
  }),
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case GET_ENTRY:
      return state
        .set('loading', true)
        .set('failed', false)
        .set(
          'data',
          Map({
            entry: {},
            user: {},
            seller: {},
          })
        );

    case GET_ENTRY_DONE:
      return state.set('loading', false).set('failed', false).set('data', fromJS(action.payload));

    case GET_ENTRY_FAILED:
      return state.set('loading', false).set('failed', true);

    case ACTIVATE_BIDDING_BOT:
      return state.setIn(['data', 'entry', 'biddingBot'], {
        maxBid: action.payload,
      });

    case DEACTIVATE_BIDDING_BOT:
      return state.setIn(['data', 'entry', 'biddingBot'], null);

    case BID:
      return state.set('isWaitingForAfterBidUpdate', true);

    case AFTER_BID_UPDATE_RECEIVED:
      return state.set('isWaitingForAfterBidUpdate', false);

    // TODO: Implement this.
    case QUICK_SELECT_CATEGORY:
      return state;

    case SET_BIDDER_ID:
      return state.setIn(['data', 'user', 'bidderId'], action.payload);

    case SELECTION_FOR_ENTRY:
      return state
        .setIn(['data', 'user', 'selectedReceiptType'], action.payload.receiptType)
        .setIn(['data', 'user', 'selectedDeliveryType'], action.payload.deliveryType);
    case RESET_ENTRY:
      return initialState;

    default:
      return state;
  }
};

export function getEntry(entryId: number, slug: string, isRefresh: boolean = false): Function {
  return (dispatch: Function): Promise<any> => {
    if (!isRefresh) {
      dispatch({ type: GET_ENTRY });
    }

    if (!slug) {
      return netAuctionService.getEntry(entryId).then(
        data => dispatch({ type: GET_ENTRY_DONE, payload: data }),
        error =>
          dispatch({
            type: GET_ENTRY_FAILED,
            payload: { error },
          })
      );
    }

    return netAuctionService.fetchEntryWithSlug(entryId, slug).then(
      data => dispatch({ type: GET_ENTRY_DONE, payload: data }),
      error =>
        dispatch({
          type: GET_ENTRY_FAILED,
          payload: { error },
        })
    );
  };
}

export function activateBiddingBot(maxBid: number): Object {
  return { type: ACTIVATE_BIDDING_BOT, payload: maxBid };
}

export function deactivateBiddingBot(): Object {
  return { type: DEACTIVATE_BIDDING_BOT };
}

export function bid(): Object {
  return { type: BID };
}

export function resetEntryState(): Object {
  return { type: RESET_ENTRY };
}

export function quickEditCategory(entryId: number, categoryId: number): Object {
  return (dispatch: Function): Promise<any> =>
    netAuctionService
      .quickEditCategory(entryId, categoryId)
      .then(data => dispatch({ type: QUICK_SELECT_CATEGORY, payload: data }))
      .catch(f => f);
}

export const saveSelectionsForEntry =
  (entryId: number, deliveryType: string, receiptType: number) =>
  (dispatch: Function): Promise<any> =>
    netAuctionService
      .saveSelectionsForEntry(entryId, deliveryType, receiptType)
      .then(() =>
        dispatch({
          type: SELECTION_FOR_ENTRY,
          payload: { deliveryType, receiptType },
        })
      )
      .catch(f => f);

export function setBidderId(bidderId: number): Object {
  return { type: SET_BIDDER_ID, payload: bidderId };
}

export function afterBidUpdateReceived(): Object {
  return { type: AFTER_BID_UPDATE_RECEIVED };
}
