/* eslint-disable @typescript-eslint/consistent-type-definitions -- keep as is */
import { List } from 'immutable';

import createClient from '../api/client';
import metadataCategories from '../metadata/metadata-categories';
import * as categories from '../net-auction/categories';
import { categoriesStaticData } from '../header/util/category';

export type Category = {
  readonly htmlName: string;
  readonly id: number;
  readonly name: string;
  readonly parentCategoryId: number | null;
  readonly priority: number;
  readonly showDefects: number;
};

export type CategoryWithCounts = {
  readonly category: Category;
  readonly count: number;
  readonly children: CategoryWithCounts[];
};

export type FlatCategory = Category & {
  readonly count: number;
};

const categoryService = {
  createUrl: (category?: Pick<Category, 'id' | 'name'> | null): string => {
    const categoryStaticData = category && categoriesStaticData.get(category.id);
    return categoryStaticData ? `/osasto/${category.id}/${categoryStaticData.slug}` : '/osasto';
  },

  getPossibleMetadatas: (categoryId: number): string[] => {
    const map: Record<number, string[]> = {
      [categories.CATEGORY_PASSENGER_CAR]: [metadataCategories.METADATA_PASSENGER_CAR],
      [categories.CATEGORY_VAN]: [metadataCategories.METADATA_PASSENGER_CAR],
      [categories.CATEGORY_TRUCK]: [metadataCategories.METADATA_TRUCK],
      [categories.CATEGORY_HEAVY_EQUIPMENT]: [metadataCategories.METADATA_TRUCK],
      [categories.CATEGORY_BOAT]: [metadataCategories.METADATA_BOAT],
      [categories.CATEGORY_MOTORCYCLE]: [metadataCategories.METADATA_REGISTRABLE_VEHICLE],
      [categories.CATEGORY_CARAVAN_AND_TRAILER]: [metadataCategories.METADATA_TRAILER],
      [categories.CATEGORY_OTHER_VEHICLE]: [metadataCategories.METADATA_REGISTRABLE_VEHICLE],
      [categories.CATEGORY_SNOWMOBILE]: [metadataCategories.METADATA_REGISTRABLE_VEHICLE],
      [categories.CATEGORY_APARTMENT]: [metadataCategories.METADATA_APARTMENT, metadataCategories.METADATA_REAL_ESTATE],
      [categories.CATEGORY_TIMESHARE]: [metadataCategories.METADATA_REAL_ESTATE],
      [categories.CATEGORY_PREMISES]: [metadataCategories.METADATA_REAL_ESTATE],
      [categories.CATEGORY_PLOT]: [metadataCategories.METADATA_REAL_ESTATE],
      [categories.CATEGORY_GARAGES]: [metadataCategories.METADATA_REAL_ESTATE],
      [categories.CATEGORY_VACATION_HOMES]: [metadataCategories.METADATA_REAL_ESTATE],
      [categories.CATEGORY_CONSTRUCTION_MACHINERY]: [metadataCategories.METADATA_CONSTRUCTION_MACHINERY],
      [categories.CATEGORY_FARMING_MACHINERY]: [metadataCategories.METADATA_FARMING_MACHINERY],
      [categories.CATEGORY_FOREST_MACHINERY]: [metadataCategories.METADATA_FOREST_MACHINERY],
      [categories.CATEGORY_OTHER_MACHINERY]: [metadataCategories.METADATA_OTHER_MACHINERY],
    };

    return map[categoryId] || [];
  },

  isMachinery: (categoryId: number) => categories.MACHINERY_CATEGORIES.includes(categoryId),

  shouldShowPassengerCarFilters: (categoryId: number) => {
    const validCategories = [categories.CATEGORY_PASSENGER_CAR, categories.CATEGORY_VAN, categories.CATEGORY_TRUCK];
    return validCategories.includes(categoryId);
    // return validCategories.indexOf(categoryId) !== -1;
  },

  flattenCategories: (categoriesWithCounts: List<CategoryWithCounts>): List<FlatCategory> =>
    List([
      ...categoriesWithCounts,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- keep
      ...([] as CategoryWithCounts['children']).concat(
        ...categoriesWithCounts.map(co => (co.children.length > 0 ? co.children : []))
      ),
    ])
      .map(container => ({ ...container.category, count: container.count }))
      .sortBy((category): number => {
        const parent = categoriesWithCounts.find(container => container.category.id === category.parentCategoryId);
        const order = parent ? parent.category.priority * 100 + category.priority : category.priority * 100;
        return order;
      }),

  getCategoryById: (categoriesWithCounts: List<CategoryWithCounts>, categoryId: number) =>
    categoryService.flattenCategories(categoriesWithCounts).find(categoryInfo => categoryInfo.id === categoryId),

  fetch: async () =>
    createClient()
      .get<Category[]>('/net-auctions/categories')
      .then(response => response.data),

  categoryIsAParentCategory: (categoryId: number): boolean => categories.PARENT_CATEGORIES.includes(categoryId),

  getCategoryPageTitle: (category: Category) => {
    const categoriesWithCustomTitles: Record<number, string> = {
      [categories.CATEGORY_INDUSTRIAL_MACHINERY]: 'Myytävät työkoneet ja raskas kalusto',
      [categories.CATEGORY_CONSTRUCTION_MACHINERY]: 'Maarakennuskoneet ja kaivinkoneet',
      [categories.CATEGORY_FARMING_MACHINERY]: 'Myytävät maatalouskoneet ja traktorit',
      [categories.CATEGORY_FOREST_MACHINERY]: 'Myytävät metsäkoneet',
      [categories.CATEGORY_HEAVY_EQUIPMENT]: 'Myytävä raskas kalusto ja kuljetuskalusto',
      [categories.CATEGORY_OTHER_MACHINERY]: 'Muut myytävät työkoneet ja laitteet',
      [categories.CATEGORY_HEAVY_EQUIPMENT_SPARE_PARTS]: 'Myytävät raskaan kaluston varaosat',
      [categories.CATEGORY_MACHINERY_SUPPLIES]: 'Myytävät työkonetarvikkeet',
    };

    if (categoriesWithCustomTitles[category.id]) {
      return categoriesWithCustomTitles[category.id];
    }

    return category.name;
  },

  getCategoryDescription: (category: Category) => {
    const categoriesWithDescriptions: Record<number, string> = {
      [categories.CATEGORY_VEHICLES_AND_SUPPLIES]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin autokauppa netissä. Meillä myydään yritysten ja yksityisten ilmoittamat edulliset käytetyt autot ja ajoneuvot sekä varaosat. Hakusessa halvat käyttöautot? Tutustu ja osta alta!',
      [categories.CATEGORY_VEHICLE_SUPPLIES]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin halpojen ajoneuvotarvikkeiden kauppa. Meiltä ostat edulliset ja käytetyt sekä uudet renkaat, pyörät, penkit, kaapelit, liinat, tunkit ja peruutuskamerat. Tutustu, tee tarjous ja osta alta!',
      [categories.CATEGORY_REAL_ESTATE]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja samalla maan mielenkiintoisin kiinteistökaupan markkinapaikka. Meillä myynnissä olevia asuntoja, taloja ja kiinteistöjä kauppaavat tunnistetut kiinteistövälitysketjut ja -yritykset. Katso ilmoitukset ja huuda alta!',
      [categories.CATEGORY_TOOLS]:
        'Remppahommat saat tehdyksi kunnon vehkeillä. Ammattilaisen ja kodin remontoijan sähkötyökalut ja akkutyökalusarjat löydät meiltä. Sivustoltamme ostat aggregaatit, porakoneet, hiomakoneet ja hitsauskoneet.',
      [categories.CATEGORY_FURNITURE_AND_KITCHEN_EQUIPMENT]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin sisustustavaroiden myymälä. Meillä myydään viikottain vaihtuvia sisustustuotteita ja asunnon sekä talon sisustamiseen tarkoitettuja esineitä. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_INDUSTRIAL_MACHINERY]:
        'Ammattilaisen työkoneella urakkahommat tulee tehdyksi. Huutokaupat.com on Suomen mielenkiintoisin konekauppa ja meiltä löydät laadukkaat, myytävät käytetyt ja uudet työkoneet, sekä varaosat ja muun kaluston. Koneiden huutokauppavalikoima vaihtuu viikottain!',
      [categories.CATEGORY_PASSENGER_CAR]:
        'Huutokaupat.comin laajasta autovalikoimasta ostat halvat ja edulliset vaihtoautot, oli kyseessä sitten hybridiauto, sähköauto, diesel- tai bensa-auto. Myytävien vanhojen autojen ja vaihtoautojen valikoima vaihtuu jatkuvasti, joten tutustu alta ja huuda nyt!',
      [categories.CATEGORY_VAN]:
        'Huutokaupat.comin autovalikoimasta ostat halvat ja edulliset pakettiautot ja lava-autot joka tilanteeseen, sillä tarjoat pakusta vain sen verran kuin olet valmis maksamaan. Valikoimassamme on käytetyt ja esittelykäytössä olleet pakettiautot. Tutustu alta ja huuda nyt!',
      [categories.CATEGORY_TRUCK]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin käytettyjen ja vähän käytettyjen kevytkuorma-autojen kauppa. Monipuolinen valikoimamme kevyt-kuormureita vaihtuu lähes viikottain. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_BOAT]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin venekauppa netissä. Käytetyt moottoriveneet, soutupaatit, vesiskootterit ja venetrailerit myydään meillä edullisesti. Valikoima vaihtuu jatkuvasti, joten tutustu ja osta alta nyt!',
      [categories.CATEGORY_MOTORCYCLE]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin prätkäkauppa netissä. Edulliset, käytetyt moottoripyörät eri merkeiltä, kuten Harley Davidsonilta, Suzukilta ja Triumphilta myydään meillä. Löydä vaihtopyörä ja osta alta!',
      [categories.CATEGORY_CARAVAN_AND_TRAILER]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin peräkärry- ja asuntovaunukauppa netissä. Edulliset, käytetyt trailerit, lavetit, peräkärryt, perävaunut ja matkailuvaunut ostat meiltä halpaan hintaan. Tutustu ja huuda!',
      [categories.CATEGORY_OTHER_VEHICLE]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa. Meiltä ostat edulliset ja harvinaiset käytetyt linja-autot, taksit, minibussit, karavaanit, matkailuautot, retkeilyautot, kilpa-autot sekä museoautot ja muut ajoneuvot. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_SNOWMOBILE]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa. Meiltä ostat käytetyt ja uudehkot mönkijät ja moottorikelkat edullisesti eri merkeiltä, kuten Polaris, Can-AM, Lynx, Arctic Cat, Honda, Yamaha, Ski-Doo ja Segwey. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_APARTMENT]:
        'Huutokaupat.comissa myydään jatkuvalla tahdilla huutokaupattavat kerrostaloasunnot, omakotitalot, yksiöt, kaksiot, rivi- ja paritalot uusille omistajille. Kun olet asuntokauppa-aikeissa, katso myytävät asunnot alta. Tee tarjous ja elämäsi talokaupat jo tänään!',
      [categories.CATEGORY_TIMESHARE]:
        'Huutokaupat.comista löydät viikottain vaihtuvan valikoiman myytävänä olevia lomaosakkeita. Meillä lomapaikkoja myyvät vain tunnistetut kiinteistövälitysketjut ja -yritykset. Katso myynnissä olevat lomaosakkeet ja huuda alta!',
      [categories.CATEGORY_PREMISES]:
        'Huutokaupat.comista löydät viikottain vaihtuvan valikoiman myytävänä olevia, huutokaupalla myytäviä halli- ja liiketiloja sekä muita toimitiloja yrityksesi tarpeisiin. Katso myynnissä olevat liikekiinteistöt, tee edullinen löytö ja anna tarjous alta!',
      [categories.CATEGORY_PLOT]:
        'Huutokaupat.comissa teet elämäsi tonttikaupat. Valikoimamme kattaa erilaiset myytävät maatilat ja metsätilat ympäri Suomea ja myynti-ilmoitukset vaihtuvat viikottain. Tutustu huutokauppakohteisiin alta. Löydä sopiva rantatontti tai metsäpalsta ja tee tarjous!',
      [categories.CATEGORY_CONSTRUCTION_MACHINERY]:
        'Haluatko ostaa käytetyn kaivurikuormaajan, kaivinkoneen, traktorikaivurin, minikaivurin, tai vaikka pyöräkuormaajan? Huutokaupat.comissa myydään tuhansia maansiirtokoneita huutokaupassa vuosittain. Katso valikoima alta, löydä kaivuri ja tee tarjous jo tänään!',
      [categories.CATEGORY_FARMING_MACHINERY]:
        'Huutokaupat.comissa myydään käytetyt traktorit, puimurit, kylvökoneet, paalaimet, leikkuupuimurit, niittomurskaimet ja muut niittokoneet hyvään hintaan. Myytävät maatalouskoneet vaihtuvat viikottain joten tee tarjous ja osta omaksi nyt!',
      [categories.CATEGORY_FOREST_MACHINERY]:
        'Myytävät metsäkoneet, niin vähänkäytetyt kuin vanhatkin energiakourat, puutavarakuormaimet, metsäkuormaimet, harvesterit ja klapikoneet ostat helposti nettihuutokaupastamme. Katso huutokauppavalikoima alta ja tee tarjous nyt!',
      [categories.CATEGORY_OTHER_MACHINERY]:
        'Huutokaupat.comista ostat trukit ja pienet työkoneet edullisesti, sillä myytävän koneen hinta määräytyy aina annettujen tarjousten perusteella. Huutokauppavalikoima vaihtuu koko ajan ja hyviä löytöjä teet jatkuvasti. Löydä sopiva trukki alta ja aloita huutaminen!',
      [categories.CATEGORY_CONSTRUCTION_SUPPLIES]:
        'Huutokaupat.comissa myydään viikottain runsaasti edullisia talon rakennusmateriaaleja, kuten ruuveja, piha- ja betonilaattoja, harkkoja, paneeleita, lautoja, lankkuja, halpaa vanerilevyä, terassilautaa sekä ylijäämä puutavaraa. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_HOUSEHOLD_APPLIANCES]:
        'Huutokaupat.comin nettihuutokaupasta ostat kodinkoneet edullisesti. Meillä myydään viikottain runsaasti kodin pienkoneita ja sähkölaitteita kuten robotti-imureita, pakastimia, uuneja, jääkaappeja ja astianpesukoneita. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_FURNITURE]:
        'Hakusessa halpa sisustuskauppa? Meidän nettihuutokaupastamme myydään kodin huonekalut ja kalusteet kuten käytetyt ja uudet sohvat, keittiön pöydät, ruokapöydän tuolit, sähköpöydät ja vaatekaapit sekä komerot ja nojatuolit. Tutustu ja osta alta!',
      [categories.CATEGORY_MACHINERY_SUPPLIES]:
        'Huutokaupat.comista ostat laadukkaat, käytetyt ja uudet työkonetarvikkeet edullisesti, sillä tarjoat tuotteista vain sen verran kuin olet valmis maksamaan. Myytävät työkonetarvikkeet ovat alan yritysten myymiä ja tarvikevalikoima vaihtuu koko ajan!',
      [categories.CATEGORY_VACATION_HOMES]:
        'Huutokaupat.comissa myydään jatkuvalla tahdilla edulliset merenranta-, järvenranta- ja mummonmökit, sekä huvilat ja halvat kesäasunnot. Selaa alta myytävänä olevia mökkejä sekä vapaa- ja loma-asuntoja. Tee edulliset, elämäsi mökkikaupat jo tänään!',
      [categories.CATEGORY_GARAGES]:
        'Huutokaupat.comista ostat autotalleja edullisesti. Valikoimamme kattaa lähes kaikenlaiset siirrettävät rakennelmat. Myytävänä olevat autotallit vaihtuvat viikottain. Tarjoat vain sen verran kuin olet tallista valmis maksamaan. Katso kohteet ja huuda alta!',
      [categories.CATEGORY_TOOLS_AND_TOOLSETS]:
        'Oikeilla työkaluilla remontti hoituu huoletta kuntoon. Meiltä löydät laadukkaat ja ammattikäyttöön tarkoitetut nikkarointilaitteet rakentamiseen sekä remontointiin. Valikoima vaihtuu viikottain ja hinnat ovat edulliset.',
      [categories.CATEGORY_HANDCRAFT_TOOLS]:
        'Olitpa puuseppä tai kodin nikkaroija, käsityökalut ja työkalusarjat löydät edullisesti meiltä. Verkkohuutokauppamme tarjoaa työkaluvaunut, työkalusarjat, kirveet, korjaussarjat ja maalipensselit joilla sorvaa ja kovertaa paikat kuntoon.',
      [categories.CATEGORY_CONSTRUCTION]:
        'Laadukas rakentaminen kysyy ammattilaisen rakennustavaraa. Huutokaupat.comin nettihuutokaupassa myydään uudet, vanhat ja käytetyt rakennustarvikkeet sekä ylijäämä rakennustavaraa edullisesti. Löydä rakennustarvikkeita alta - huuda sekä osta nyt!',
      [categories.CATEGORY_BATHROOM_RENOVATION]:
        'Huutokaupat.comista ostat materiaalit keittiön, vessan tai saunan remppaan. Valikoimamme sisältää lattia- ja seinälaatat, kylpy- ja poreammeet, suihkukaapit, pesualtaat, sähkökiukaat, vesipadat sekä kylpyhuone- ja wc-kalustesarjat. Tutustu ja huuda!',
      [categories.CATEGORY_KITCHEN_RENOVATION]:
        'Keittiöremontti on hintava projekti. Huutokaupat.comista hankit kuitenkin fiksusti keittiön kalusteet ja pintamateriaalit edullisesti. Valikoimamme keittiökaappeja,  pesualtaita, keittiökalustesarjoja ja valmiskeittiöitä vaihtuu viikottain. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_HVAC_SUPPLIES]:
        'Onko hakusessa vesi-ilmalämpöpumppu, vesiputket, ilmanvaihtokone, kiertovesipumppu tai kenties pihakaivon osat? Huutokaupat.comissa LVI-tarvikkeet myydään edullisesti. Valikoima vaihtuu viikottain ja tarjoat vain sen mitä haluat maksaa. Tutustu ja osta alta!',
      [categories.CATEGORY_ELECTRONICS_SUPPLIES]:
        'Huutokaupat.comista löydät uudet sekä ylijäämä sähkötarvikkeet edullisesti. Invertterit, LED-loisteputket, aurinkopaneelit, otsonaattorit, sähkökaapelit ja kelat sekä myös sähköpatterit kuuluvat vaihtuvaan valikoimaamme. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_TABLEWARE]:
        'Keittiön astiastot myydään edullisemmin Huutokaupat.comissa. Vaihtuvasta valikoimasta löydät kaikenlaiset astiasarjat, ruokailusetit, design-astiat, aterimet sekä muut keittiötarvikkeet. Tutustu ruokailuvälineisiin, huuda ja osta alta!',
      [categories.CATEGORY_DECOR]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa sekä maan mielenkiintoisin design-tavaroiden sisustuskauppa. Meillä myydään viikottain vaihtuvia, edullisia koriste-esineitä, patsaita, design-valaisimia sekä käytettyjä esineitä. Tutustu, huuda ja osta alta!',
      [categories.CATEGORY_ANTIQUE]:
        'Suomen mielenkiintoisin taide- ja antiikkihuutokauppa on meillä. Nettihuutokauppamme tarjoaa viikottain vaihtuvan valikoiman myytäviä antiikki- ja taide-esineitä, kuten maalauksia, veistoksia, antiikkihuonekaluja sekä antiikkipöytiä ja -tuoleja. Tutustu alta!',
      [categories.CATEGORY_HEAVY_EQUIPMENT]:
        'Meiltä löydät vaihtuvan valikoiman tunnistettujen kuljetusalan yritysten käytettyä ja uutta kuljetuskalustoa. Huutokauppamme tarjoaa myytävät rekat, rekka-autot, rekan nupit, kuormurit, puoliperävaunuyhdistelmät ja muut raskaat ajoneuvot. Tutustu ja tee tarjous!',
      [categories.CATEGORY_HEAVY_EQUIPMENT_SPARE_PARTS]:
        'Huutokaupat.comista ostat laadukkaat raskaan kaluston purku- ja varaosat edullisesti, sillä tarjoat kaivinkoneen osasta vain sen verran kuin olet valmis maksamaan. Myyjinä toimivat alan yritykset ja varaosavalikoimamme vaihtuu lähes viikottain. Tutustu alta!',
      [categories.CATEGORY_VEHICLE_SPARE_PARTS]:
        'Huutokaupat.com on Suomen suurin nettihuutokauppa ja maan mielenkiintoisin autojen ja ajoneuvojen varaosaliike netissä. Viikottain vaihtuvasta valikoimasta teet edullisia löytöjä ja ostat halvat, käytetyt auton varaosat, lamput ja moottorit. Tutustu ja osta alta!',
    };

    if (categoriesWithDescriptions[category.id]) {
      return categoriesWithDescriptions[category.id];
    }

    return null;
  },
};

export default categoryService;
