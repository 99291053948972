import createClient from '../api/client';

export default {
  fetchAll: companyId =>
    createClient()
      .get('/imported-entries', { params: { companyId } })
      .then(response => response.data),

  delete: id =>
    createClient()
      .delete(`/imported-entries/${id}`)
      .then(response => response.data),
};
