// @flow

import { Map } from 'immutable';
import soleProprietorPersonalIdService from '../src/company/sole-proprietor-personal-id-service';

const SUBMIT_PERSONAL_ID = 'huutokaupat/sole-proprietor-personal-id/SUBMIT_PERSONAL_ID';
const SUBMIT_PERSONAL_ID_DONE = 'huutokaupat/sole-proprietor-personal-id/SUBMIT_PERSONAL_ID_SUCCESS';
const SUBMIT_PERSONAL_ID_FAILED = 'huutokaupat/sole-proprietor-personal-id/SUBMIT_PERSONAL_ID_FAILED';

const initialState = Map({
  loading: false,
  failed: false,
  success: null,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case SUBMIT_PERSONAL_ID:
      return state.set('loading', true).set('failed', false).set('success', null);

    case SUBMIT_PERSONAL_ID_DONE:
      return state.set('success', action.payload.success);

    case SUBMIT_PERSONAL_ID_FAILED:
      return state.set('loading', false).set('failed', true).set('success', null);

    default:
      return state;
  }
};

export function submitPersonalId(personalId: string): Object {
  return (dispatch: Function): Promise<any> => {
    dispatch({ type: SUBMIT_PERSONAL_ID });

    return soleProprietorPersonalIdService.submitPersonalId(personalId).then(
      result => dispatch({ type: SUBMIT_PERSONAL_ID_DONE, payload: result }),
      error => dispatch({ type: SUBMIT_PERSONAL_ID_FAILED, payload: error })
    );
  };
}
