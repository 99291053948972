/* eslint-disable @typescript-eslint/consistent-type-definitions -- keep existing */
/* eslint-disable @typescript-eslint/naming-convention -- maintain as is */
import { Company } from '@typings/Company';
import { CompanyTermsOfSale } from '@typings/CompanyTermsOfSale';
import { CompanySlug } from '@typings/CompanySlug';
import { ListEntry } from '@app/components/entry-list/Entry';
import { BillingAddress } from '@app/components/company/settings/billing-address/types/BillingAddress';
import createClient from '../api/client';
import { User } from '../header/types/User';
import { CompanyApproval } from './company-summary-service';
import { CompanyImages } from './company-image-service';
import { Crawler } from '../crawl/crawl-service';

export const COMPANY_TYPES = Object.freeze({
  ULOSOTTO: 'Ulosotto',
  POLIISI: 'Poliisi',
  PUOLUSTUSVOIMAT: 'Puolustusvoimat',
  METSAHALLITUS: 'Metsähallitus',
  KUNNAT: 'Kunta',
  YRITYS: 'Yritys',
  KULUTTAJA: 'Kuluttaja',
});

export const CUSTOMER_FUNDS_PROVISION_INTERVALS = Object.freeze({
  DAILY: 1,
  TWICE_A_WEEK: 2,
  WEEKLY: 3,
  TWICE_A_MONTH: 4,
  MONTHLY: 5,
});

export const CUSTOMER_FUNDS_PROVISION_INTERVALS_LABELS = Object.freeze({
  [CUSTOMER_FUNDS_PROVISION_INTERVALS.DAILY]: 'Päivittäin',
  [CUSTOMER_FUNDS_PROVISION_INTERVALS.TWICE_A_WEEK]: 'Kahdesti viikossa',
  [CUSTOMER_FUNDS_PROVISION_INTERVALS.WEEKLY]: 'Kerran viikossa',
  [CUSTOMER_FUNDS_PROVISION_INTERVALS.TWICE_A_MONTH]: 'Joka toinen viikko',
  [CUSTOMER_FUNDS_PROVISION_INTERVALS.MONTHLY]: 'Kerran kuussa',
});

export const CUSTOMER_FUNDS_PROVISIONS_ARE_ENABLED = Object.freeze({
  YES: true,
  NO: false,
});

export const CUSTOMER_FUNDS_MESSAGE_TYPE = Object.freeze({
  MESSAGE: 'M',
  REFERENCE: 'R',
});

export const DISPLAY_REFERENCE_NUMBER = Object.freeze({
  YES: 'Y',
  NO: 'N',
});

export const NET_AUCTIONS_ARE_OWN_PAYMENT_BY_DEFAULT = Object.freeze({
  YES: 'Y',
  NO: 'N',
});

export const GROUP_INVOICING_ENABLED = Object.freeze({
  YES: 'Y',
  NO: 'N',
});

type TransferrablePaymentReturnType = {
  readonly company: Company;
  readonly images?: CompanyImages;
};
// Based on 'CompanyTerminationModal'
type TerminateCompanyResponseType = {
  readonly notes?: string;
  readonly addingToBlacklistFailed: boolean;
};

type ReInstationCompanyReturnType = {
  readonly notes?: string;
};

type EntriesSold = Record<string, number>;

interface BaseCompanyAuctionFormFields {
  readonly companyId: Company['id'];
  readonly businessId: Company['businessId'];
  readonly companyName: Company['name'];
  readonly description: Company['description'];
  readonly currentEntries: ListEntry[];
  readonly approvalRatio: CompanyApproval;
  readonly entriesSoldSince: EntriesSold;
  readonly slug: CompanySlug;
  readonly allowedCrawlers: Crawler[];
}

type CompanyConsumerNetAuctionFormFields = BaseCompanyAuctionFormFields;

type CompanyUsers = {
  readonly id: number;
  readonly name: string;
};

type CompanyNetAuctionFormFields = BaseCompanyAuctionFormFields & {
  readonly availableNetAuctionClasses: string[];
  readonly billingAddresses: BillingAddress[];
  readonly createdAt: string;
  readonly deliveryExpenses: number | null;
  readonly deliveryDescription: string | null;
  readonly deliveryExpensesUnavailable: boolean;
  readonly hasIdentifiedUsers: boolean;
  readonly isForeclosure: boolean;
  readonly isOwnPayment: boolean;
  readonly isVatRegistered: boolean;
  readonly isBookValueEnabled: boolean;
  readonly isCustomReferenceNumberDisplayed: boolean;
  readonly mostUsedNetAuctionClass: string;
  readonly retrievalExpenses: number | null;
  readonly retrievalDescription: string | null;
  readonly termsOfSale: CompanyTermsOfSale;
  readonly users?: CompanyUsers[];
};

type CompanyPublicInfo = {
  readonly id: number;
  readonly name: string;
  readonly slug: string;
  readonly feedbacksPublished?: string;
};

export const COMPANY_ID_MEZZOFORTE_CONSUMER_NET_AUCTIONS = 6129;
export const COMPANY_ID_MEZZOFORTE = 9440;

export default {
  fetch: async (id: number) =>
    createClient()
      .get<{ readonly company: Company; readonly images: CompanyImages }>(`/companies/${id}`)
      .then(response => response.data),

  fetchPublicInfo: async (id: number) =>
    createClient()
      .get<CompanyPublicInfo>(`/companies/${id}/public`)
      .then(response => response.data),

  fetchPublicInfoWithSlug: async (slug: string) =>
    createClient()
      .get<CompanyPublicInfo>(`/companies/${slug}/public`)
      .then(response => response.data),

  fetchCompanies: async (params: Record<string, unknown>) =>
    createClient()
      .get<Company[]>('/companies', { params })
      .then(response => response.data),

  delete: async (id: number) =>
    createClient()
      .delete<{ readonly id: number }>(`/companies/${id}`)
      .then(response => response.data),

  fetchDataForNetAuctionForm: async (id: number) =>
    createClient()
      .get<CompanyNetAuctionFormFields>(`/companies/${id}/net-auction-form`)
      .then(response => response.data),

  fetchDataForConsumerNetAuctionForm: async () =>
    createClient()
      .get<CompanyConsumerNetAuctionFormFields>(`/companies/consumer-net-auction-form`)
      .then(response => response.data),

  create: async (companyData: Record<string, unknown>) =>
    createClient()
      .post<{ readonly id: number }>('/company-create/persist', companyData)
      .then(response => response.data),

  fetchCompanyUsers: async (companyId: number) =>
    createClient()
      .get<User[]>(`/companies/${companyId}/users`)
      .then(response => response.data),

  updateCompany: async (id: number, data: Record<string, unknown>) =>
    createClient()
      .patch<{ readonly company: Company; readonly images: CompanyImages }>(`/companies/${id}`, { ...data })
      .then(response => response.data),

  fetchTransferrablePaymentsOfCompany: async (id: number) =>
    createClient()
      .get<TransferrablePaymentReturnType>(`/companies/${id}/transferrable-payments`)
      .then(response => response.data),

  terminateCompany: async (id: number, reasonText: string, message: string, shouldAddToBlacklist: boolean) =>
    createClient()
      .post<TerminateCompanyResponseType>(`/companies/${id}/terminate`, {
        reasonText,
        message,
        shouldAddToBlacklist,
      })
      .then(response => response.data),

  reInstateCompany: async (id: number) =>
    createClient()
      .post<ReInstationCompanyReturnType>(`/companies/${id}/reinstate`)
      .then(response => response.data),

  fetchCompaniesWaitingForBankAccount: async () =>
    createClient()
      .get<Company[]>('/companies', { params: { 'waiting-for-bank-account': true } })
      .then(response => response.data),
};
