import React from 'react';
import { Button, Row, Col, FormGroup } from 'react-bootstrap';
import { Field, Form as FinalForm } from 'react-final-form';
import { PasswordInputField } from '@components/final-form/password-input/PasswordInputField';
import { TextInputField } from '@components/final-form/text-input/TextInputField';

export interface LoginFormValues {
  readonly username: string;
  readonly password: string;
}

export interface LoginFormProps {
  readonly onSubmit: (values: LoginFormValues) => Promise<void>;
}

export function LoginForm({ onSubmit }: LoginFormProps) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup className="add-bottom">
            <Field
              name="username"
              label="Sähköpostiosoite"
              type="email"
              autoComplete="username"
              autoFocus
              isRequired
              component={TextInputField}
            />
          </FormGroup>

          <FormGroup>
            <Field
              name="password"
              label="Salasana"
              autoComplete="current-password"
              isRequired
              component={PasswordInputField}
            />
          </FormGroup>

          <Row>
            <Col xs={12}>
              <Button type="submit" bsStyle="success" className="add-bottom" disabled={submitting}>
                Kirjaudu
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a className="forgot-password" href="/unohditko-salasanasi">
                Ongelmia sisäänkirjautumisessa?
              </a>
            </Col>
          </Row>
        </form>
      )}
    />
  );
}
