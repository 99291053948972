import { Dispatch } from 'redux';
// @ts-ignore ToDo: TsService
import mediaService, { parseErrorMessage, MISSING_PARAMETER } from '../src/media/template-media-service';

interface ReduxAction<T, P> {
  readonly type: T;
  readonly payload?: P;
}

export const LoadVisualMedias = 'huutokaupat/visualMedias/LOAD_VISUAL_MEDIAS';
export const LoadVisualMediasDone = 'huutokaupat/visualMedias/LOAD_VISUAL_MEDIAS_DONE';
export const LoadVisualMediasFailed = 'huutokaupat/visualMedias/LOAD_VISUAL_MEDIAS_FAILED';

const VideoUpload = 'huutokaupat/visualMedias/VIDEO_UPLOAD';
const VideoUploadOpen = 'huutokaupat/visualMedias/VIDEO_UPLOAD_OPEN';
const VideoUploadDone = 'huutokaupat/visualMedias/VIDEO_UPLOAD_DONE';
const VideoUploadFailed = 'huutokaupat/visualMedias/VIDEO_UPLOAD_FAILED';

const RemoveVisualMedia = 'huutokaupat/visualMedias/REMOVE_VISUAL_MEDIA';
const RemoveVisualMediaDone = 'huutokaupat/visualMedias/REMOVE_VISUAL_MEDIA_DONE';
const RemoveVisualMediaFailed = 'huutokaupat/visualMedias/REMOVE_VISUAL_MEDIA_FAILED';

const RemoveAllVisualMedias = 'huutokaupat/visualMedias/REMOVE_ALL_VISUAL_MEDIAS';
const RemoveAllVisualMediasDone = 'huutokaupat/visualMedias/REMOVE_ALL_VISUAL_MEDIAS_DONE';
const RemoveAllVisualMediasFailed = 'huutokaupat/visualMedias/REMOVE_ALL_VISUAL_MEDIAS_FAILED';

const ImageUpload = 'huutokaupat/visualMedias/IMAGE_UPLOAD';
const ImageUploadDone = 'huutokaupat/visualMedias/IMAGE_UPLOAD_DONE';
const ImageUploadFailed = 'huutokaupat/visualMedias/IMAGE_UPLOAD_FAILED';
const ImageRotate = 'huutokaupat/visualMedias/IMAGE_ROTATE';
const ImageRotateDone = 'huutokaupat/visualMedias/IMAGE_ROTATE_DONE';
const ImageRotateFailed = 'huutokaupat/visualMedias/IMAGE_ROTATE_FAILED';

const MediaDrag = 'huutokaupat/visualMedias/MEDIA_DRAG';
const MediaDragPersistDone = 'huutokaupat/visualMedias/MEDIA_DRAG_PERSIST_DONE';
const MediaDragPersistFailed = 'huutokaupat/visualMedias/MEDIA_DRAG_PERSIST_FAILED';

const HideErrors = 'huutokaupat/visualMedias/HIDE_ERRORS';

type ImageType = 1;
type VideoType = 2;

export type MediaType = ImageType | VideoType;

// Used with refactored VisualMediaUploadWrapper
export interface VisualMediaProps {
  readonly id: number;
  readonly externalId?: string;
  readonly readonly?: boolean;
  readonly thumbnail: string;
  readonly url?: string;
  readonly type?: MediaType;
}

export interface VisualMediaState {
  readonly id: number | string; // when 'temp' we use id{string}
  readonly externalId?: number | null;
  readonly readonly?: boolean;
  readonly thumbnail: string | null;
  readonly type?: MediaType;
  readonly url?: string;
  // at some cases we might have
  readonly temp?: boolean | null;
  // remove brings other optionals into state
  readonly deleteLoading?: boolean | null;
  // rotate brings other optionals into state
  readonly rotateLoading?: boolean | null;
  readonly failed?: boolean | null;
}
export interface VideoUploadState {
  readonly open: boolean;
  readonly loading: boolean;
  readonly success: boolean;
  readonly badUrl: boolean;
}
export interface RemoveMediasState {
  readonly loading: boolean;
}
export interface VisualMediaReducerState {
  readonly loadingImages: boolean;
  readonly actionsDisabled: boolean;
  readonly errorMessages: string[]; // assumption
  readonly medias?: VisualMediaState[];
  readonly videoUpload: VideoUploadState;
  readonly removeMedias: RemoveMediasState;
}

export const initialState: VisualMediaReducerState = {
  loadingImages: false,
  actionsDisabled: false,
  errorMessages: [],
  medias: [],
  videoUpload: {
    open: false,
    loading: false,
    success: false,
    badUrl: false,
  },
  removeMedias: {
    loading: false,
  },
};

type ResponseError = Record<string, unknown>;

type VisualMediasLoadAction = ReduxAction<typeof LoadVisualMedias, number>;
type VisualMediasLoadDoneAction = ReduxAction<typeof LoadVisualMediasDone, { readonly data?: VisualMediaState[] }>;
type VisualMediasLoadFailedAction = ReduxAction<typeof LoadVisualMediasFailed, { readonly error: string | string[] }>;
// VideoTypes
type VideoUploadAction = ReduxAction<typeof VideoUpload, null>;
type VideoUploadOpenAction = ReduxAction<typeof VideoUploadOpen, null>;
type VideoUploadDoneAction = ReduxAction<typeof VideoUploadDone, { readonly data: VisualMediaState }>;
type VideoUploadFailedAction = ReduxAction<
  typeof VideoUploadFailed,
  { readonly error?: { readonly data?: ResponseError } }
>;
// MediaRemoveTypes
type RemoveVisualMediaAction = ReduxAction<typeof RemoveVisualMedia, VisualMediaState['id']>;
type RemoveVisualMediaDoneAction = ReduxAction<typeof RemoveVisualMediaDone, VisualMediaState['id']>;
interface MediaFailedErrorActionPayload {
  readonly mediaId: VisualMediaState['id'];
  readonly error: ResponseError;
}
type RemoveVisualMediaFailedAction = ReduxAction<typeof RemoveVisualMediaFailed, MediaFailedErrorActionPayload>;
// MediaRemoveAllTypes
type RemoveAllVisualMediasAction = ReduxAction<typeof RemoveAllVisualMedias, number>;
type RemoveAllVisualMediasDoneAction = ReduxAction<typeof RemoveAllVisualMediasDone, null>;
type RemoveAllVisualMediasFailedAction = ReduxAction<
  typeof RemoveAllVisualMediasFailed,
  { readonly error: ResponseError }
>;
// ImageUpload Types
interface ImageUploadPayload {
  readonly tempId: string;
}
type ImageUploadAction = ReduxAction<typeof ImageUpload, ImageUploadPayload>;
interface ImageUploadDonePayload {
  readonly tempId: string;
  readonly response: { readonly data: VisualMediaState };
}
type ImageUploadDoneAction = ReduxAction<typeof ImageUploadDone, ImageUploadDonePayload>;
interface ImageUploadFailedActionPayload {
  readonly tempId?: string;
  readonly error: ResponseError;
}
type ImageUploadFailedAction = ReduxAction<typeof ImageUploadFailed, ImageUploadFailedActionPayload>;
// ImageRotate Types
type ImageRotateAction = ReduxAction<typeof ImageRotate, number>;
interface ImageRotateDonePayload {
  readonly mediaId: VisualMediaState['id'];
  readonly thumb: VisualMediaState['thumbnail'];
  readonly url: VisualMediaState['url'];
}
type ImageRotateDoneAction = ReduxAction<typeof ImageRotateDone, ImageRotateDonePayload>;
type ImageRotateFailedAction = ReduxAction<typeof ImageRotateFailed, MediaFailedErrorActionPayload>;

// Media DragActions
interface MediaDragPayload {
  readonly index: number;
  readonly atIndex: number;
  readonly media: VisualMediaState;
}
type MediaDragAction = ReduxAction<typeof MediaDrag, MediaDragPayload>;
type MediaDragPersistDoneAction = ReduxAction<typeof MediaDragPersistDone, null>;
interface MediaDragPersistFailedPayload {
  readonly originalIndex: number;
  readonly index: number;
  readonly media: VisualMediaState;
  readonly error: ResponseError;
}
type MediaDragPersistFailedAction = ReduxAction<typeof MediaDragPersistFailed, MediaDragPersistFailedPayload>;
type HideErrorsAction = ReduxAction<typeof HideErrors, null>;

// Internal reducer helpers
const findMediaIndexById = (medias: VisualMediaState[], id: number | string) =>
  medias.findIndex(media => media.id === id);

type VisualMediaReducerActions =
  | VisualMediasLoadAction
  | VisualMediasLoadDoneAction
  | VisualMediasLoadFailedAction
  | VideoUploadAction
  | VideoUploadOpenAction
  | VideoUploadDoneAction
  | VideoUploadFailedAction
  | RemoveVisualMediaAction
  | RemoveVisualMediaDoneAction
  | RemoveVisualMediaFailedAction
  | RemoveAllVisualMediasAction
  | RemoveAllVisualMediasDoneAction
  | RemoveAllVisualMediasFailedAction
  | ImageUploadAction
  | ImageUploadDoneAction
  | ImageUploadFailedAction
  | ImageRotateAction
  | ImageRotateDoneAction
  | ImageRotateFailedAction
  | MediaDragAction
  | MediaDragPersistDoneAction
  | MediaDragPersistFailedAction
  | HideErrorsAction;

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Its the order how we do things
  state: VisualMediaReducerState = initialState,
  action: VisualMediaReducerActions
): VisualMediaReducerState => {
  switch (action.type) {
    case LoadVisualMedias:
      return { ...state, loadingImages: true, actionsDisabled: true };
    case LoadVisualMediasFailed:
      return {
        ...state,
        loadingImages: false,
        actionsDisabled: false,
        errorMessages: [...state.errorMessages, 'Kuvien haku epäonnistui.'],
      };
    case LoadVisualMediasDone:
      return { ...state, loadingImages: false, actionsDisabled: false, medias: action.payload?.data };
    case VideoUploadOpen:
      return { ...state, videoUpload: { open: true, loading: false, success: false, badUrl: false } };
    case VideoUpload:
      return { ...state, videoUpload: { open: true, loading: true, success: false, badUrl: false } };
    case VideoUploadDone: {
      const newMedia = action.payload?.data;
      const newMedias = newMedia ? [...(state.medias ?? []), newMedia] : [...(state.medias ?? [])];
      return {
        ...state,
        medias: newMedias,
        videoUpload: { open: false, loading: false, success: true, badUrl: false },
      };
    }
    case VideoUploadFailed: {
      if (action.payload?.error?.data && 'invalidUrl' in action.payload.error.data) {
        return {
          ...state,
          videoUpload: {
            open: true,
            loading: false,
            success: false,
            badUrl: true,
          },
        };
      }
      return {
        ...state,
        videoUpload: { open: true, loading: false, success: false, badUrl: false },
        errorMessages: [...state.errorMessages, parseErrorMessage(action.payload?.error, 'Videon lataus epäonnistui')],
      };
    }
    case RemoveVisualMedia: {
      const removeId = action.payload;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- id is number
      const removeIndex = state.medias && removeId && findMediaIndexById(state.medias, removeId as number);
      if (!removeIndex || !state.medias) return state;
      const updatedMedias = state.medias.map((media, idx) =>
        idx !== removeIndex
          ? media
          : {
              ...media,
              deleteLoading: true,
              failed: false,
            }
      );
      return { ...state, medias: updatedMedias };
    }
    case RemoveVisualMediaFailed: {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- id is number
      const mediaId = action.payload?.mediaId as number;
      const removeIndex = state.medias && mediaId && findMediaIndexById(state.medias, mediaId);
      if (!removeIndex) return { ...state };
      const updatedMedias =
        state.medias?.map((media, idx) =>
          idx !== removeIndex
            ? media
            : {
                ...media,
                deleteLoading: true,
                failed: true,
              }
        ) ?? [];
      return {
        ...state,
        medias: updatedMedias,
        errorMessages: [...state.errorMessages, parseErrorMessage(action.payload?.error, 'Median poisto epäonnistui')],
      };
    }
    case RemoveVisualMediaDone: {
      const removeId = action.payload;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- id is number
      const removeIndex = state.medias && removeId && findMediaIndexById(state.medias, removeId as number);
      if (!removeIndex || !state.medias) return state;
      const updatedMedias = state.medias.filter((_, idx) => idx !== removeIndex);
      return { ...state, medias: updatedMedias };
    }
    case RemoveAllVisualMedias:
      return { ...state, removeMedias: { loading: true }, actionsDisabled: true };
    case RemoveAllVisualMediasFailed:
      return {
        ...state,
        actionsDisabled: false,
        removeMedias: { loading: false },
        errorMessages: [
          ...state.errorMessages,
          parseErrorMessage(action.payload?.error, 'Medioiden poisto epäonnistui'),
        ],
      };
    case RemoveAllVisualMediasDone:
      return {
        ...state,
        medias: [],
        actionsDisabled: false,
        removeMedias: { loading: false },
      };
    case ImageUpload: {
      const tempId = action.payload?.tempId;
      return tempId
        ? { ...state, medias: [...(state.medias ?? []), { id: tempId, temp: true, thumbnail: null }] }
        : state;
    }
    case ImageUploadDone: {
      const tempId = action.payload?.tempId;
      const imageData = action.payload?.response.data;
      const updateIndex = state.medias && tempId && findMediaIndexById(state.medias, tempId);
      if (!updateIndex) return state;
      const updatedMedias = state.medias?.map((media, idx) => {
        if (idx === updateIndex && imageData) return { ...imageData };
        return media;
      });
      return { ...state, medias: updatedMedias };
    }
    case ImageUploadFailed: {
      const tempId = action.payload?.tempId;
      const updateIndex = state.medias && tempId && findMediaIndexById(state.medias, tempId);
      if (!updateIndex) return state;
      const updatedMedias = state.medias?.map((media, idx) =>
        idx !== updateIndex ? media : { ...media, failed: true }
      );
      return {
        ...state,
        medias: updatedMedias,
        errorMessages: [...state.errorMessages, parseErrorMessage(action.payload?.error, 'Kuvan lataus epäonnistui')],
      };
    }
    case ImageRotate: {
      const imageId = action.payload;
      const updateIndex = state.medias && imageId && findMediaIndexById(state.medias, imageId);
      if (!updateIndex) return state;
      const updatedMedias = state.medias?.map((media, idx) =>
        idx !== updateIndex ? media : { ...media, rotateLoading: true, failed: false }
      );
      return { ...state, medias: updatedMedias };
    }
    case ImageRotateDone: {
      const mediaId = action.payload?.mediaId;
      const thumb = action.payload?.thumb;
      const url = action.payload?.url;
      const updateIndex = state.medias && mediaId && findMediaIndexById(state.medias, mediaId);
      if (!updateIndex || !thumb || !url) return state;
      const updatedMedias = state.medias?.map((media, idx) =>
        idx !== updateIndex ? media : { ...media, thumbnail: thumb, url, rotateLoading: false, failed: false }
      );
      return { ...state, medias: updatedMedias };
    }
    case ImageRotateFailed: {
      const mediaId = action.payload?.mediaId;
      const updateIndex = state.medias && mediaId && findMediaIndexById(state.medias, mediaId);
      if (!updateIndex) return state;
      const updatedMedias = state.medias?.map((media, idx) =>
        idx !== updateIndex
          ? media
          : {
              ...media,
              rotateLoading: false,
              failed: true,
            }
      );
      return {
        ...state,
        medias: updatedMedias,
        errorMessages: [
          ...state.errorMessages,
          parseErrorMessage(action.payload?.error, 'Kuvan kääntäminen epäonnistui'),
        ],
      };
    }
    case MediaDrag: {
      const { index: oldIndex, atIndex: newIndex } = action.payload ?? {};
      const medias = state.medias;
      // just to ensure that nothing gets broken
      if (!medias || !oldIndex || !newIndex) return state;
      const filteredMedias = medias.filter(media => media.id !== medias[oldIndex].id);
      return {
        ...state,
        medias: [...filteredMedias.slice(0, newIndex), medias[oldIndex], ...filteredMedias.slice(newIndex)],
      };
    }
    case MediaDragPersistFailed: {
      const { index, originalIndex, media } = action.payload ?? {};
      const medias = state.medias;
      if (!index || !originalIndex || !media || !medias) return state;
      const filtered = medias.filter((_, idx) => idx !== originalIndex);
      // update originally transferred status
      const updMedias = [
        ...filtered.slice(0, index),
        { ...medias[originalIndex], failed: true },
        ...filtered.slice(index),
      ];

      return {
        ...state,
        medias: updMedias,
        errorMessages: [
          ...state.errorMessages,
          parseErrorMessage(action.payload?.error, 'Kuvan siirtäminen epäonnistui'),
        ],
      };
    }
    case HideErrors: {
      const clearStates = { failed: false, rotateLoading: false, deleteLoading: false };
      const medias = state.medias?.map(media => ({ ...media, ...clearStates })).filter(media => !media.temp);
      // eslint-disable-next-line @typescript-eslint/naming-convention -- do not alter
      const { errorMessages, removeMedias, videoUpload, actionsDisabled } = initialState;
      return {
        ...state,
        medias,
        errorMessages,
        removeMedias,
        videoUpload,
        actionsDisabled,
      };
    }
    case MediaDragPersistDone:
    default:
      return state;
  }
};

// LoadVisualMedias
type LoadDispatchs = VisualMediasLoadAction | VisualMediasLoadDoneAction | VisualMediasLoadFailedAction;
export const getVisualMedias = (id?: number) => async (dispatch: Dispatch<LoadDispatchs>) => {
  // seems that initialization requires this
  dispatch({ type: LoadVisualMedias, payload: id });
  if (!id) {
    dispatch({ type: LoadVisualMediasDone, payload: {} });
  }
  mediaService
    .getVisualMedias(id)
    .then((visualMedias: { readonly data: VisualMediaState[] }) =>
      dispatch({ type: LoadVisualMediasDone, payload: visualMedias })
    )
    .catch((response: string | string[]) => dispatch({ type: LoadVisualMediasFailed, payload: { error: response } }));
};

// VideoUploads
export const openVideoUpload = () => async (dispatch: Dispatch<VideoUploadAction>) => {
  dispatch({ type: VideoUpload });
};

type UploadDispatchs = VideoUploadFailedAction | VideoUploadDoneAction | VideoUploadAction;
export const uploadVideo = (referenceId: number, url: string) => async (dispatch: Dispatch<UploadDispatchs>) => {
  if (!referenceId) {
    dispatch({ type: VideoUploadFailed, payload: { error: MISSING_PARAMETER } });
    return;
  }
  dispatch({ type: VideoUpload }); // remove referenceId as it seems not to be used
  mediaService
    .uploadVideo(referenceId, url)
    .then((media: { readonly data: VisualMediaState }) => dispatch({ type: VideoUploadDone, payload: media }))
    .catch((response: ResponseError) => dispatch({ type: VideoUploadFailed, payload: { error: response } }));
};

type RemoveMediaDispatchs = RemoveVisualMediaAction | RemoveVisualMediaDoneAction | RemoveVisualMediaFailedAction;
export const removeMedia = (mediaId: number, type: MediaType) => async (dispatch: Dispatch<RemoveMediaDispatchs>) => {
  if (!mediaId || !type) {
    dispatch({ type: RemoveVisualMediaFailed, payload: { mediaId, error: MISSING_PARAMETER } });
    return;
  }
  dispatch({ type: RemoveVisualMedia, payload: mediaId });
  const removeVisualMedia = type === 1 ? mediaService.removeImage : mediaService.removeVideo;
  removeVisualMedia(mediaId)
    .then(() => dispatch({ type: RemoveVisualMediaDone, payload: mediaId }))
    .catch((response: ResponseError) =>
      dispatch({ type: RemoveVisualMediaFailed, payload: { mediaId, error: response } })
    );
};

type RemoveAllMediasDispatchs =
  | RemoveAllVisualMediasAction
  | RemoveAllVisualMediasDoneAction
  | RemoveAllVisualMediasFailedAction;
export const removeAllMedias = (referenceId: number) => async (dispatch: Dispatch<RemoveAllMediasDispatchs>) => {
  if (!referenceId) {
    dispatch({ type: RemoveAllVisualMediasFailed, payload: { error: MISSING_PARAMETER } });
    return;
  }
  dispatch({ type: RemoveAllVisualMedias, payload: referenceId });
  mediaService
    .removeAllMedias(referenceId)
    .then(() => dispatch({ type: RemoveAllVisualMediasDone }))
    .catch((response: ResponseError) => dispatch({ type: RemoveAllVisualMediasFailed, payload: { error: response } }));
};

type UploadImageDispatchs = ImageUploadAction | ImageUploadDoneAction | ImageUploadFailedAction;
export const uploadImage = (referenceId: number, file: File) => async (dispatch: Dispatch<UploadImageDispatchs>) => {
  if (!referenceId) {
    dispatch({ type: ImageUploadFailed, payload: { error: MISSING_PARAMETER } });
    return;
  }
  // gen tempId
  const tempId = `temp-${new Date().getTime()}`;
  dispatch({ type: ImageUpload, payload: { tempId } }); // reference not needed?
  const data = new FormData();
  data.append('file', file);

  mediaService
    .uploadImage(referenceId, data)
    .then((response: { readonly data: VisualMediaState }) =>
      dispatch({ type: ImageUploadDone, payload: { tempId, response } })
    )
    .catch((response: ResponseError) => dispatch({ type: ImageUploadFailed, payload: { tempId, error: response } }));
};
// ToDo: CrawledImage sourceType? is it same as "MediaType"
export const uploadCrawledImage =
  (referenceId: number, image: VisualMediaState, sourceType: string | number) =>
  async (dispatch: Dispatch<UploadImageDispatchs>) => {
    if (!referenceId || !image || !sourceType) {
      dispatch({ type: ImageUploadFailed, payload: { error: MISSING_PARAMETER } });
      return;
    }
    const tempId = `temp-${new Date().getTime()}`;
    dispatch({ type: ImageUpload, payload: { referenceId, tempId } });
    mediaService
      .uploadCrawledImage(referenceId, image, sourceType)
      .then((response: { readonly data: VisualMediaState }) =>
        dispatch({ type: ImageUploadDone, payload: { response, tempId } })
      )
      .catch((response: ResponseError) => dispatch({ type: ImageUploadFailed, payload: { tempId, error: response } }));
  };

type RotateImageDispatchs = ImageRotateAction | ImageRotateDoneAction | ImageRotateFailedAction;
export const rotateImage = (mediaId: number) => async (dispatch: Dispatch<RotateImageDispatchs>) => {
  if (!mediaId) {
    dispatch({ type: ImageRotateFailed, payload: { mediaId, error: MISSING_PARAMETER } });
    return;
  }
  dispatch({ type: ImageRotate, payload: mediaId });
  mediaService
    .rotateImage(mediaId)
    .then((media: ImageRotateDonePayload) => dispatch({ type: ImageRotateDone, payload: media }))
    .catch((response: ResponseError) => dispatch({ type: ImageRotateFailed, payload: { mediaId, error: response } }));
};

export const hoverMedia =
  (media: VisualMediaState, index: number, atIndex: number) => async (dispatch: Dispatch<MediaDragAction>) =>
    dispatch({ type: MediaDrag, payload: { media, index, atIndex } });

type MoveMediaDispatchs = MediaDragPersistDoneAction | MediaDragPersistFailedAction;
export const moveMedia =
  (media: VisualMediaState, index: number, nextToId: number | string | null, originalIndex: number) =>
  async (dispatch: Dispatch<MoveMediaDispatchs>) => {
    mediaService
      .moveMedia(media.id, nextToId)
      .then(() => dispatch({ type: MediaDragPersistDone }))
      .catch((response: ResponseError) =>
        dispatch({ type: MediaDragPersistFailed, payload: { media, index, originalIndex, error: response } })
      );
  };

export const hideErrors = () => async (dispatch: Dispatch<HideErrorsAction>) => dispatch({ type: HideErrors });
