import { List, Map } from 'immutable';
import attachmentService, { parseErrorMessage } from '../src/media/attachment-service';

const LOAD_ATTACHMENTS = 'huutokaupat/attachments/LOAD_ATTACHMENTS';
const LOAD_ATTACHMENTS_DONE = 'huutokaupat/attachments/LOAD_ATTACHMENTS_DONE';
const LOAD_ATTACHMENTS_FAILED = 'huutokaupat/attachments/LOAD_ATTACHMENTS_FAILED';

const ATTACHMENT_UPLOAD = 'huutokaupat/attachments/ATTACHMENT_UPLOAD';
const ATTACHMENT_UPLOAD_DONE = 'huutokaupat/attachments/ATTACHMENT_UPLOAD_DONE';
const ATTACHMENT_UPLOAD_FAILED = 'huutokaupat/attachments/ATTACHMENT_UPLOAD_FAILED';

const REMOVE_ATTACHMENT = 'huutokaupat/attachments/REMOVE_ATTACHMENT';
const REMOVE_ATTACHMENT_DONE = 'huutokaupat/attachments/REMOVE_ATTACHMENT_DONE';
const REMOVE_ATTACHMENT_FAILED = 'huutokaupat/attachments/REMOVE_ATTACHMENT_FAILED';

const REMOVE_ALL_ATTACHMENTS = 'huutokaupat/attachments/REMOVE_ALL_ATTACHMENTS';
const REMOVE_ALL_ATTACHMENTS_DONE = 'huutokaupat/attachments/REMOVE_ALL_ATTACHMENTS_DONE';
const REMOVE_ALL_ATTACHMENTS_FAILED = 'huutokaupat/attachments/REMOVE_ALL_ATTACHMENTS_FAILED';

const ORDER_ATTACHMENTS = 'huutokaupat/attachments/ORDER_ATTACHMENTS';

const HIDE_ERRORS = 'huutokaupat/attachments/HIDE_ERRORS';

const initialState = new Map({
  loadingAttachments: false,
  actionsDisabled: false,
  errorMessages: new List(),
  attachments: new List(),
  removeAttachments: {
    loading: false,
  },
});

const findAttachmentIndexById = function (attachments, id) {
  return parseInt(
    attachments.findIndex(attachment => parseInt(attachment.id, 10) === parseInt(id, 10)),
    10
  );
};

const findAttachmentTempIndexById = function (attachments, id) {
  return parseInt(
    attachments.findIndex(attachment => attachment.id === id),
    10
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ATTACHMENTS:
      return state.set('loadingAttachments', true).set('actionsDisabled', true);

    case LOAD_ATTACHMENTS_FAILED:
      return state
        .set('loadingAttachments', false)
        .set('actionsDisabled', false)
        .set('errorMessages', state.get('errorMessages').push('Liitetiedostojen haku epäonnistui.'));

    case LOAD_ATTACHMENTS_DONE:
      return state
        .set('attachments', new List(action.payload.data))
        .set('loadingAttachments', false)
        .set('actionsDisabled', false);

    case REMOVE_ATTACHMENT:
      return state.updateIn(
        ['attachments', findAttachmentIndexById(state.get('attachments'), action.payload)],
        attachment => ({ ...attachment, deleteLoading: true, failed: false })
      );

    case REMOVE_ATTACHMENT_FAILED:
      return state
        .updateIn(
          ['attachments', findAttachmentIndexById(state.get('attachments'), action.payload.attachmentId)],
          attachment => ({ ...attachment, deleteLoading: false, failed: true })
        )
        .set(
          'errorMessages',
          state.get('errorMessages').push(parseErrorMessage(action.payload.error, 'Liitetiedoston poisto epäonnistui'))
        );

    case REMOVE_ATTACHMENT_DONE:
      return state.deleteIn([
        'attachments',
        state.get('attachments').findIndex(attachment => attachment.id === action.payload),
      ]);

    case REMOVE_ALL_ATTACHMENTS:
      return state
        .set('removeAttachments', {
          loading: true,
        })
        .set('actionsDisabled', true);

    case REMOVE_ALL_ATTACHMENTS_FAILED:
      return state
        .set('removeAttachments', {
          loading: false,
        })
        .set(
          'errorMessages',
          state
            .get('errorMessages')
            .push(parseErrorMessage(action.payload.error, 'Liitetiedostojen poisto epäonnistui'))
        )
        .set('actionsDisabled', false);

    case REMOVE_ALL_ATTACHMENTS_DONE:
      return state
        .set('attachments', new List())
        .set('removeAttachments', {
          loading: false,
        })
        .set('actionsDisabled', false);

    case ATTACHMENT_UPLOAD:
      return state.set('attachments', state.get('attachments').push({ id: action.payload.tempId, temp: true }));

    case ATTACHMENT_UPLOAD_FAILED:
      return state
        .set(
          'errorMessages',
          state.get('errorMessages').push(parseErrorMessage(action.payload.error, 'Liitetiedoston lataus epäonnistui'))
        )
        .updateIn(
          ['attachments', findAttachmentTempIndexById(state.get('attachments'), action.payload.tempId)],
          attachment => ({ ...attachment, failed: true })
        );

    case ATTACHMENT_UPLOAD_DONE:
      return state.updateIn(
        ['attachments', findAttachmentTempIndexById(state.get('attachments'), action.payload.tempId)],
        () => action.payload.response.data
      );

    case ORDER_ATTACHMENTS:
      return state.set('attachments', List(action.payload.attachments));

    case HIDE_ERRORS:
      return state
        .set(
          'attachments',
          state
            .get('attachments')
            .map(attachment => ({
              ...attachment,
              failed: false,
              rotateLoading: false,
              deleteLoading: false,
            }))
            .filter(attachment => !attachment.temp)
        )
        .set('errorMessages', state.get('errorMessages').clear())
        .set('removeAttachments', {
          loading: false,
        })
        .set('actionsDisabled', false);

    default:
      return state;
  }
};

export function getAttachments(id) {
  return dispatch => {
    dispatch({ type: LOAD_ATTACHMENTS, payload: id });

    if (!id) {
      dispatch({ type: LOAD_ATTACHMENTS_FAILED, payload: {} });
      return;
    }

    attachmentService
      .getAttachments(id)
      .then(attachments => dispatch({ type: LOAD_ATTACHMENTS_DONE, payload: attachments }))
      .catch(response =>
        dispatch({
          type: LOAD_ATTACHMENTS_FAILED,
          payload: { error: response },
        })
      );
  };
}

export function removeAttachment(attachmentId) {
  return dispatch => {
    if (!attachmentId) {
      dispatch({ type: REMOVE_ATTACHMENT_FAILED, payload: { attachmentId } });
      return;
    }
    dispatch({ type: REMOVE_ATTACHMENT, payload: attachmentId });

    attachmentService
      .removeAttachment(attachmentId)
      .then(() => dispatch({ type: REMOVE_ATTACHMENT_DONE, payload: attachmentId }))
      .catch(response =>
        dispatch({
          type: REMOVE_ATTACHMENT_FAILED,
          payload: { attachmentId, error: response },
        })
      );
  };
}

export function removeAllAttachments(referenceId) {
  return dispatch => {
    if (!referenceId) {
      dispatch({ type: REMOVE_ALL_ATTACHMENTS_FAILED, payload: {} });
      return;
    }
    dispatch({ type: REMOVE_ALL_ATTACHMENTS, payload: referenceId });

    attachmentService
      .removeAllAttachments(referenceId)
      .then(response => dispatch({ type: REMOVE_ALL_ATTACHMENTS_DONE, payload: response }))
      .catch(response =>
        dispatch({
          type: REMOVE_ALL_ATTACHMENTS_FAILED,
          payload: { error: response },
        })
      );
  };
}

export function orderAttachments(id, attachments) {
  return dispatch => {
    dispatch({
      type: ORDER_ATTACHMENTS,
      payload: { attachments },
    });

    return attachmentService.orderAttachments(
      id,
      attachments.map(attachment => attachment.id)
    );
  };
}

export function uploadAttachment(referenceId, file) {
  return dispatch => {
    if (!referenceId) {
      dispatch({ type: ATTACHMENT_UPLOAD_FAILED, payload: {} });
      return;
    }
    const tempId = `temp-${new Date().getTime()}`;
    dispatch({ type: ATTACHMENT_UPLOAD, payload: { referenceId, tempId } });

    const data = new FormData();
    data.append('file', file);

    attachmentService
      .uploadAttachment(referenceId, data)
      .then(response =>
        dispatch({
          type: ATTACHMENT_UPLOAD_DONE,
          payload: { response, tempId },
        })
      )
      .catch(response =>
        dispatch({
          type: ATTACHMENT_UPLOAD_FAILED,
          payload: { tempId, error: response },
        })
      );
  };
}

export function hideErrors() {
  return dispatch => {
    dispatch({ type: HIDE_ERRORS, payload: {} });
  };
}
