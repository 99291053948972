//

import { List, Map } from 'immutable';

import netAuctionService from '../src/net-auction/net-auction-service';
import * as favoriteService from '../src/favorite/favorite-service';
import { fetchUserDetails } from './user-details';

const ADD_TO_FAVORITES = 'huutokaupat/favorite/ADD_TO_FAVORITES';
const ADD_TO_FAVORITES_DONE = 'huutokaupat/favorite/ADD_TO_FAVORITES_DONE';
const ADD_TO_FAVORITES_FAILED = 'huutokaupat/favorite/ADD_TO_FAVORITES_FAILED';
const REMOVE_FROM_FAVORITES = 'huutokaupat/favorite/REMOVE_FROM_FAVORITES';
const REMOVE_FROM_FAVORITES_DONE = 'huutokaupat/favorite/REMOVE_FROM_FAVORITES_DONE';
const REMOVE_FROM_FAVORITES_FAILED = 'huutokaupat/favorite/REMOVE_FROM_FAVORITES_FAILED';
const ENABLE_SMS_NOTIFICATIONS = 'huutokaupat/favorite/ENABLE_SMS_NOTIFICATIONS';
const ENABLE_SMS_NOTIFICATIONS_DONE = 'huutokaupat/favorite/ENABLE_SMS_NOTIFICATIONS_DONE';
const ENABLE_SMS_NOTIFICATIONS_FAILED = 'huutokaupat/favorite/ENABLE_SMS_NOTIFICATIONS_FAILED';
const ENABLE_SMS_NOTIFICATIONS_FAILED_INVALID_PHONE_NUMBER =
  'huutokaupat/favorite/ENABLE_SMS_NOTIFICATIONS_FAILED_INVALID_PHONE_NUMBER';
const DISABLE_SMS_NOTIFICATIONS = 'huutokaupat/favorite/DISABLE_SMS_NOTIFICATIONS';
const DISABLE_SMS_NOTIFICATIONS_DONE = 'huutokaupat/favorite/DISABLE_SMS_NOTIFICATIONS_DONE';
const DISABLE_SMS_NOTIFICATIONS_FAILED = 'huutokaupat/favorite/DISABLE_SMS_NOTIFICATIONS_FAILED';
const PHONE_NUMBER_UPDATED = 'huutokaupat/favorite/PHONE_NUMBER_UPDATED';
const PHONE_NUMBER_UPDATE_FAILED = 'huutokaupat/favorite/PHONE_NUMBER_UPDATE_FAILED';
const FETCH_ENTRIES = 'huutokaupat/favorite/FETCH_ENTRIES';
const FETCH_ENTRIES_DONE = 'huutokaupat/favorite/FETCH_ENTRIES_DONE';
const FETCH_ENTRIES_FAILED = 'huutokaupat/favorite/FETCH_ENTRIES_FAILED';
const REMOVE_ENDED_FROM_FAVORITES_DONE = 'huutokaupat/favorite/REMOVE_ENDED_FROM_FAVORITES_DONE';

const initialState = Map({
  favoriteIds: new List(),
  favoriteIdsWithNotifications: new List(),
  favoriteListContainsEndedEntries: false,
  entries: List(),
  isPhoneNumberValid: true,
  bidderIds: Map(),
  ongoingTransactions: new List(),
});

function showGeneralError() {
  // TODO: Move favorite handling to a hook and use useDialog instead.
  window.alert('Toiminto epäonnistui. Yritä myöhemmin uudelleen');
}

function showCustomError(message) {
  // TODO: Move favorite handling to a hook and use useDialog instead.
  window.alert(message);
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_TO_FAVORITES:
      return state.update('ongoingTransactions', entries => entries.push(action.payload));

    case ADD_TO_FAVORITES_DONE:
      return state
        .update('favoriteIds', ids => ids.push(action.payload))
        .update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case ADD_TO_FAVORITES_FAILED:
      return state.update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case REMOVE_FROM_FAVORITES:
      return state.update('ongoingTransactions', entries => entries.push(action.payload));

    case REMOVE_FROM_FAVORITES_DONE:
      return state
        .update('favoriteIds', ids => ids.filter(favoriteId => favoriteId !== action.payload))
        .update('favoriteIdsWithNotifications', ids => ids.filter(favoriteId => favoriteId !== action.payload))
        .update('entries', entries => entries.filter(entry => entry.id !== action.payload))
        .update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case REMOVE_FROM_FAVORITES_FAILED:
      return state.update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case ENABLE_SMS_NOTIFICATIONS:
      return state.update('ongoingTransactions', entries => entries.push(action.payload));

    case ENABLE_SMS_NOTIFICATIONS_DONE:
      return state
        .update('favoriteIdsWithNotifications', ids => ids.push(action.payload.entryId))
        .update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload.entryId))
        .set('isPhoneNumberValid', true);

    case ENABLE_SMS_NOTIFICATIONS_FAILED_INVALID_PHONE_NUMBER:
      return state
        .update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload.entryId))
        .set('isPhoneNumberValid', false);

    case ENABLE_SMS_NOTIFICATIONS_FAILED:
      return state.update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case DISABLE_SMS_NOTIFICATIONS:
      return state.update('ongoingTransactions', entries => entries.push(action.payload));

    case DISABLE_SMS_NOTIFICATIONS_DONE:
      return state
        .update('favoriteIdsWithNotifications', ids => ids.filter(id => id !== action.payload))
        .update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case DISABLE_SMS_NOTIFICATIONS_FAILED:
      return state.update('ongoingTransactions', entries => entries.filter(entry => entry !== action.payload));

    case FETCH_ENTRIES_DONE:
      return state
        .set('favoriteIds', List(action.payload.entries.map(entry => entry.id)))
        .set('entries', List(action.payload.entries))
        .set('bidderIds', Map(action.payload.bidderIds));

    case PHONE_NUMBER_UPDATED:
      return state.set('isPhoneNumberValid', true);

    case REMOVE_ENDED_FROM_FAVORITES_DONE:
      return state.set('favoriteListContainsEndedEntries', false);

    default:
      return state;
  }
};

export function addToFavorites(entryId) {
  return (dispatch, getState) => {
    if (getState().favorite.get('ongoingTransactions').contains(entryId)) {
      return undefined;
    }
    dispatch({ type: ADD_TO_FAVORITES, payload: entryId });

    return favoriteService.addToFavorites(entryId).then(
      () => dispatch({ type: ADD_TO_FAVORITES_DONE, payload: entryId }),
      () => {
        showGeneralError();
        dispatch({ type: ADD_TO_FAVORITES_FAILED, payload: entryId });
      }
    );
  };
}

export function removeFromFavorites(entryId) {
  return (dispatch, getState) => {
    if (getState().favorite.get('ongoingTransactions').contains(entryId)) {
      return undefined;
    }
    dispatch({ type: REMOVE_FROM_FAVORITES, payload: entryId });

    return favoriteService.removeFromFavorites(entryId).then(
      () => dispatch({ type: REMOVE_FROM_FAVORITES_DONE, payload: entryId }),
      () => {
        showGeneralError();
        dispatch({ type: REMOVE_FROM_FAVORITES_FAILED, payload: entryId });
      }
    );
  };
}

export function enableSmsNotifications(entryId) {
  return (dispatch, getState) => {
    if (getState().favorite.get('ongoingTransactions').contains(entryId)) {
      return undefined;
    }
    dispatch({
      type: ENABLE_SMS_NOTIFICATIONS,
      payload: entryId,
    });

    return netAuctionService.toggleSmsNotification(entryId, true).then(
      response => {
        const { phoneNumber } = response;

        dispatch({
          type: ENABLE_SMS_NOTIFICATIONS_DONE,
          payload: { entryId, phoneNumber },
        });
      },
      error => {
        if (error.response.data.message === 'Invalid phone number') {
          dispatch({
            type: ENABLE_SMS_NOTIFICATIONS_FAILED_INVALID_PHONE_NUMBER,
            payload: { phoneNumber: error.response.data.phoneNumber, entryId },
          });
          fetchUserDetails()(dispatch);
          showCustomError('Ilmoitusten asettaminen epäonnistui. Tarkista puhelinnumero');
          return;
        }
        dispatch({ type: ENABLE_SMS_NOTIFICATIONS_FAILED, payload: entryId });
        showGeneralError();
      }
    );
  };
}

export function disableSmsNotifications(entryId) {
  return (dispatch, getState) => {
    if (getState().favorite.get('ongoingTransactions').contains(entryId)) {
      return {};
    }
    dispatch({
      type: DISABLE_SMS_NOTIFICATIONS,
      payload: entryId,
    });

    return netAuctionService.toggleSmsNotification(entryId, false).then(
      () => dispatch({ type: DISABLE_SMS_NOTIFICATIONS_DONE, payload: entryId }),
      () => {
        dispatch({ type: DISABLE_SMS_NOTIFICATIONS_FAILED, payload: entryId });
      }
    );
  };
}

export function updatePhoneNumber(userId, newPhoneNumber) {
  return dispatch =>
    netAuctionService.updateSmsNotificationPhoneNumber(userId, newPhoneNumber).then(
      response => {
        dispatch({
          type: PHONE_NUMBER_UPDATED,
          payload: { phoneNumber: response.phoneNumber },
        });
        fetchUserDetails()(dispatch);
      },
      () => {
        showCustomError('Päivittäminen epäonnistui, tarkista antamasi numero.');
        dispatch({ type: PHONE_NUMBER_UPDATE_FAILED });
      }
    );
}

export function fetchFavorites() {
  return dispatch => {
    dispatch({ type: FETCH_ENTRIES });

    return favoriteService.fetchFavorites().then(
      data => {
        dispatch({ type: FETCH_ENTRIES_DONE, payload: data });
      },
      () => {
        dispatch({ type: FETCH_ENTRIES_FAILED });
      }
    );
  };
}

export function removeEndedAuctionsFromFavorites() {
  return dispatch =>
    favoriteService.removeEndedFromFavorites().then(() => {
      dispatch({ type: REMOVE_ENDED_FROM_FAVORITES_DONE });
      fetchFavorites()(dispatch);
    });
}
