import {
  Armchair,
  Bank,
  Bathtub,
  Beach,
  Bicycle,
  Boat,
  Bus,
  Car,
  Chair,
  City2,
  Cylinder,
  Drill,
  Excavator,
  ExhaustPipe,
  Fish,
  Flashlight,
  ForestMachine,
  ForkKnife,
  Forklift,
  GameController,
  Garage,
  Grill,
  Headphones,
  HeavyEquipment,
  House,
  Kitchen,
  Lamp,
  Laptop,
  Lawnmower,
  Lightbulb,
  Map,
  Moped,
  Motorcycle,
  Package,
  Packages,
  PianoKeys,
  Plug,
  Plugs,
  Printer,
  Snowmobile,
  Storefront,
  Tractor,
  Trailer,
  Trophy,
  Truck,
  Tv,
  Van,
  Wall,
  Watch,
  Wheel,
  WoodPlank,
  Wrench,
  Heart,
  List,
  Star,
  Categories,
  HourglassHigh,
  SquaresFour,
  UserCircle,
} from '@mezzoforte/forge-icons';

export const CategoryIcons = {
  Armchair,
  Bank,
  Bathtub,
  Beach,
  Bicycle,
  Boat,
  Bus,
  Car,
  Chair,
  City2,
  Cylinder,
  Drill,
  Excavator,
  ExhaustPipe,
  Fish,
  Flashlight,
  ForestMachine,
  ForkKnife,
  Forklift,
  GameController,
  Garage,
  Grill,
  Headphones,
  HeavyEquipment,
  House,
  Kitchen,
  Lamp,
  Laptop,
  Lawnmower,
  Lightbulb,
  Map,
  Moped,
  Motorcycle,
  Package,
  Packages,
  PianoKeys,
  Plug,
  Plugs,
  Printer,
  Snowmobile,
  Storefront,
  Tractor,
  Trailer,
  Trophy,
  Truck,
  Tv,
  Van,
  Wall,
  Watch,
  Wheel,
  WoodPlank,
  Wrench,
} as const;

export type CategorIconyNames = keyof typeof CategoryIcons;
export type CategoryIconTypes = typeof CategoryIcons;

const NavigationIcons = {
  Heart,
  List,
  Star,
  Categories,
  HourglassHigh,
  SquaresFour,
  UserCircle,
} as const;

// MainNavigation icons
export type MainNavigationIconNames = keyof typeof NavigationIcons;

export const NavigationIcon = (name: MainNavigationIconNames) => NavigationIcons[name];
