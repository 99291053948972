export enum VehicleGroup {
  RescueVehicle = '32',
  Ambulance = '33',
  PoliceVehicle = '34',
  Hearce = '48',
  VeterinaryCar = '68',
}

const specialVehicleGroups: string[] = [
  VehicleGroup.RescueVehicle,
  VehicleGroup.Ambulance,
  VehicleGroup.PoliceVehicle,
  VehicleGroup.Hearce,
  VehicleGroup.VeterinaryCar,
];

const specialVehicleNameParts = [
  'pelastus',
  'palo',
  'sammutus',
  'tikas',
  'saira',
  'lanssi',
  'poliisi',
  'ruumi',
  'eläinlääk',
  'pelastuslaitos',
  'monikäyttö',
  'säiliö',
  'hautaus',
];

export const containsSpecialVehicleGroup = (vehicleGroups: string[]) =>
  vehicleGroups.some(vehicleGroup => specialVehicleGroups.includes(vehicleGroup));

export const containsSpecialVehicleNamePart = (title: string) => {
  if (!title) {
    return false;
  }

  const lowercaseTitle = title.toLowerCase();

  return specialVehicleNameParts.some(subString => lowercaseTitle.includes(subString));
};

export interface FormValues {
  readonly title?: string;
  readonly subtitle?: string;
  readonly description?: string;
  readonly metadata?: Partial<{
    readonly vehicleGroups?: string[];
    readonly isSpecialVehicle?: boolean;
  }>;
}

export const isSpecialVehicle = ({ title = '', subtitle = '', description = '', metadata = {} }: FormValues) =>
  (metadata?.isSpecialVehicle ?? false) ||
  containsSpecialVehicleGroup(metadata?.vehicleGroups ?? []) ||
  containsSpecialVehicleNamePart(title) ||
  containsSpecialVehicleNamePart(subtitle) ||
  containsSpecialVehicleNamePart(description);
