import React, { FocusEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextInput, TextInputProps } from '@mezzoforte/forge';

type RenderProps = FieldRenderProps<string>;

export type TextInputFieldProps = RenderProps &
  Omit<TextInputProps, keyof RenderProps['input']> & {
    readonly onBlur?: (event: FocusEvent) => void;
  };

export function TextInputField({ input, meta, onBlur, ...inputProps }: TextInputFieldProps) {
  return (
    <TextInput
      w="100%"
      label=""
      isInvalid={meta.invalid && meta.touched}
      errorInfo={meta.error ?? meta.submitError}
      {...inputProps}
      {...input}
      onBlur={e => {
        onBlur?.(e);
        input.onBlur(e);
      }}
    />
  );
}
