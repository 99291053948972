// @flow

import createClient from '../api/client';

export default {
  fetchCompanyGroup: (companyGroupId: number) =>
    createClient()
      .get(`/company-groups/${companyGroupId}`)
      .then(response => response.data),

  fetchCompanyGroups: () =>
    createClient()
      .get('/company-groups')
      .then(response => response.data),

  deleteCompanyGroup: (companyGroupId: number) =>
    createClient()
      .delete(`/company-groups/${companyGroupId}`)
      .then(response => response.data),

  createCompanyGroup: (name: string) =>
    createClient()
      .post('/company-groups', { name })
      .then(response => response.data),

  updateCompanyGroup: (companyGroupId: number, data: Object) =>
    createClient()
      .patch(`/company-groups/${companyGroupId}`, { data })
      .then(response => response.data),
};
