import storage from '@app/src/util/storage';

export function getSelectedCompanyId() {
  const value = storage.get('selectedCompanyId');
  if (value === null || value === undefined) {
    return null;
  }

  return parseInt(value, 10);
}

export function saveSelectedCompanyId(companyId: number) {
  storage.set('selectedCompanyId', companyId.toString());
}

export function clearSelectedCompanyId() {
  storage.remove('selectedCompanyId');
}
