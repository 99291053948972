// @flow

import createClient from '../api/client';

export default {
  fetchOptions: () =>
    createClient()
      .get('/company-create/options')
      .then(response => response.data),
};
