import styled from '@emotion/styled';
import { Box, BoxProps, useTokens } from '@mezzoforte/forge';

export const containerWidth = '1800px';

const { breakpoints } = useTokens.huutokaupat;

export const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props: BoxProps) => props.width ?? containerWidth};
  width: 100%;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0 1rem;
  }
  @media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    padding: 0 1.5rem;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 2.5rem;
  }
`;
