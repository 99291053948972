import { Dispatch } from 'redux';

export const IncreaseNoteCountForFrozenNetAuction =
  'huutokaupat/frozen-net-auction/INCREASE_NOTE_COUNT_FOR_FROZEN_NET_AUCTION';

export interface IncreaseNoteCountForFrozenNetAuctionAction {
  readonly type: typeof IncreaseNoteCountForFrozenNetAuction;
  readonly payload: { readonly entryId: number };
}

export function increaseNoteCount(entryId: number) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: IncreaseNoteCountForFrozenNetAuction,
      payload: { entryId },
    });
  };
}
