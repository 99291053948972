// @flow

import { useEffect, useState } from 'react';

import privateNetAuctionGroupService from '../../src/net-auction/private-net-auction-group-service';
import type {
  FetchGroupsParams,
  PrivateNetAuctionGroup,
  UpdateGroupParams,
} from '../../src/net-auction/private-net-auction-group-service';

export const useNetAuctionsEligibleForGroups = (companyId: ?number): [boolean, boolean, Array<any>] => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [eligibleNetAuctions, setEligibleNetAuctions] = useState<Array<any>>([]);

  const fetchEligibleNetAuctions = async (): Promise<void> => {
    if (!companyId) {
      return;
    }

    setLoading(true);
    try {
      const netAuctions = await privateNetAuctionGroupService.fetchEligibleNetAuctions(companyId);
      setEligibleNetAuctions(netAuctions);
    } catch {
      setError(true);
    }
    setLoading(false);
  };

  useEffect((): void => {
    fetchEligibleNetAuctions();
  }, [companyId]);

  return [error, loading, eligibleNetAuctions];
};

type ReturnType = {
  error: boolean,
  loading: boolean,
  privateNetAuctionGroup: PrivateNetAuctionGroup | null,
  fetchPrivateNetAuctionGroup: Function,
};

export const usePrivateNetAuctionGroup = (groupId: ?number): ReturnType => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [privateNetAuctionGroup, setPrivateNetAuctionGroup] = useState<PrivateNetAuctionGroup | null>(null);

  const fetchPrivateNetAuctionGroup = async (): Promise<void> => {
    if (!groupId) {
      return;
    }

    setLoading(true);
    try {
      const group = await privateNetAuctionGroupService.fetchGroup(groupId);
      setPrivateNetAuctionGroup(group);
    } catch {
      setError(true);
    }
    setLoading(false);
  };

  useEffect((): void => {
    fetchPrivateNetAuctionGroup();
  }, [groupId]);

  return {
    error,
    loading,
    privateNetAuctionGroup,
    fetchPrivateNetAuctionGroup,
  };
};

type FetchGroupsReturnType = {
  error: boolean,
  loading: boolean,
  groups: PrivateNetAuctionGroup[],
  fetch: Function,
};

export const usePrivateNetAuctionGroups = (params?: FetchGroupsParams): FetchGroupsReturnType => {
  const [groups, setGroups] = useState<PrivateNetAuctionGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { company, status } = params || {};

  const fetch = async (): Promise<void> => {
    setLoading(true);

    try {
      const newGroups: PrivateNetAuctionGroup[] = await privateNetAuctionGroupService.fetchGroups(params ?? {});

      setGroups(newGroups);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  };

  useEffect((): void => {
    fetch();
  }, [company, status]);

  return { groups, loading, error, fetch };
};

type PrivateNetAuctionGroupUpdaterReturnType = {
  update: (values: UpdateGroupParams) => Promise<void>,
  isSubmitting: boolean,
  submitError: boolean,
};

export const usePrivateNetAuctionGroupUpdater = (groupId: number): PrivateNetAuctionGroupUpdaterReturnType => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const update = async (values: UpdateGroupParams): Promise<void> => {
    setIsSubmitting(true);
    setSubmitError(false);

    return privateNetAuctionGroupService
      .updateGroup(groupId, values)
      .then((): void => {
        setIsSubmitting(false);
      })
      .catch((): void => {
        setIsSubmitting(false);
        setSubmitError(true);
      });
  };

  return {
    update,
    isSubmitting,
    submitError,
  };
};
