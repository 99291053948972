// @flow

import { List, Map } from 'immutable';
import type { Type } from '../src/log/log-service';
import logService, { FetchType } from '../src/log/log-service';

const GET_LOGS = 'huutokaupat/log/GET_LOGS';
const GET_LOGS_FAILED = 'huutokaupat/log/GET_LOGS_DONE';
const GET_LOGS_DONE = 'huutokaupat/log/GET_LOGS_FAILED';

const initialState = Map({
  logs: List(),
  id: 0,
  type: FetchType.LATEST,
  page: 1,
  pageCount: 0,
  usesCursor: false,
  nextPageId: null,
  prevPageId: null,
  error: false,
  loading: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case GET_LOGS:
      return state
        .set('logs', List())
        .set('id', action.payload.id)
        .set('type', action.payload.type)
        .set('page', action.payload.page)
        .set('pageCount', 0)
        .set('usesCursor', false)
        .set('nextPageId', null)
        .set('prevPageId', null)
        .set('error', false)
        .set('loading', true);

    case GET_LOGS_FAILED:
      return state.set('logs', List()).set('error', true).set('loading', false);

    case GET_LOGS_DONE:
      return state
        .set('logs', List(action.payload.logs))
        .set('pageCount', action.payload.pageCount)
        .set('usesCursor', action.payload.usesCursor)
        .set('nextPageId', action.payload.nextPage)
        .set('prevPageId', action.payload.prevPage)
        .set('error', false)
        .set('loading', false);

    default:
      return state;
  }
};

export function fetchLogs(
  type: Type = FetchType.LATEST,
  id: number = 0,
  page: number = 1,
  order: string = 'desc'
): Function {
  return (dispatch: Function): Promise<any> => {
    dispatch({
      type: GET_LOGS,
      payload: {
        type,
        id,
        page,
        order,
      },
    });

    return logService.fetchLogs(type, id, page, order).then(
      data =>
        dispatch({
          type: GET_LOGS_DONE,
          payload: data,
        }),
      () =>
        dispatch({
          type: GET_LOGS_FAILED,
        })
    );
  };
}
