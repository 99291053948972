// @flow

import { Map } from 'immutable';
import netAuctionService from '../src/net-auction/net-auction-service';

const FETCH_OFFER_DONE = 'huutokaupat/net-auction/runner-up-offer/FETCH_OFFER_DONE';
const OFFER_ACCEPTED = 'huutokaupat/net-auction/runner-up-offer/OFFER_ACCEPTED';
const OFFER_DECLINED = 'huutokaupat/net-auction/runner-up-offer/OFFER_DECLINED';
const REQUEST_FAILED = 'huutokaupat/net-auction/runner-up-offer/REQUEST_FAILED';

const initialState = Map({
  entry: null,
  runnerUpBid: null,
  image: '',
  accepted: false,
  declined: false,
  loading: false,
  failed: false,
});

export default (state: Map<string, any> = initialState, action: Object = {}): Object => {
  switch (action.type) {
    case FETCH_OFFER_DONE:
      return state
        .set('loading', false)
        .set('entry', action.payload.entry)
        .set('image', action.payload.image)
        .set('runnerUpBid', parseFloat(action.payload.runnerUpBid));

    case REQUEST_FAILED:
      return state.set('loading', false).set('failed', true);

    case OFFER_ACCEPTED:
      return state.set('loading', false).set('accepted', true);

    case OFFER_DECLINED:
      return state.set('loading', false).set('declined', true);

    default:
      return state;
  }
};

export function fetchOffer(entryId: number): Function {
  return dispatch =>
    netAuctionService.fetchRunnerUpOffer(entryId).then(
      (response): void => dispatch({ type: FETCH_OFFER_DONE, payload: response }),
      (error): Object => dispatch({ type: REQUEST_FAILED, payload: error })
    );
}

export function acceptOffer(entryId: number): Function {
  return dispatch =>
    netAuctionService.acceptRunnerUpOffer(entryId).then(
      (response): void => dispatch({ type: OFFER_ACCEPTED, payload: response }),
      (error): Object => dispatch({ type: REQUEST_FAILED, payload: error })
    );
}

export function declineOffer(entryId: number): Function {
  return dispatch =>
    netAuctionService.declineRunnerUpOffer(entryId).then(
      (response): void => dispatch({ type: OFFER_DECLINED, payload: response }),
      (error): Object => dispatch({ type: REQUEST_FAILED, payload: error })
    );
}
