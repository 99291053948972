import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { reduxForm, Field } from 'redux-form';
import accounting from 'accounting';
import { Row, Col, Nav, NavItem, Form, FormGroup, InputGroup, FormControl, Button, Checkbox } from 'react-bootstrap';
import cx from 'classnames';
import netAuctionService from '../../../src/net-auction/net-auction-service';
import CompanySelect from '../../admin/util/CompanySelect';
import CompanyNavBar from '../../company/CompanyNavBar';
import AdvancedSearchModal from './modal/AdvancedSearchModal';

export const listTypes = [
  { label: 'Käynnissä', value: 'ongoing' },
  { label: 'Päättyneet', value: 'ended' },
  { label: 'Hintaneuvottelut', value: 'post-trading' },
  { label: 'Hyväksytyt', value: 'approved' },
  { label: 'Toimitettavat', value: 'deliverable' },
  { label: 'Luovutettavat', value: 'handover' },
  { label: 'Tulossa', value: 'unpublished' },
  { label: 'Luonnokset', value: 'draft' },
];

class NetAuctionListFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previousWindowWidth: window.outerWidth,
      isAdvancedSearchModalOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (window.outerWidth === this.state.previousWindowWidth) {
        return;
      }

      const { type, change } = this.props;

      if (window.outerWidth < 768 && type === 'dashboard') {
        change('type', 'ongoing');
      }

      // Force a rerender on resize.
      this.setState({ previousWindowWidth: window.outerWidth });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { change } = nextProps;

    if (this.props.type !== nextProps.type) {
      change('type', nextProps.type);
    }
  }

  clearCompanyField = () => {
    const { handleSubmit, change } = this.props;

    change('company', '');
    setTimeout(handleSubmit, 0);
  };

  clearTermField = () => {
    const { handleSubmit, change, type } = this.props;

    change('term', '');
    if (type === 'archive') {
      change('type', 'ongoing');
    }

    setTimeout(handleSubmit, 0);
  };

  changeIncludeOldField = () => {
    const { handleSubmit, change, includeOld } = this.props;

    change('includeOld', includeOld ? null : true);
    setTimeout(handleSubmit, 0);
  };

  renderTermField = ({ input, label }) => (
    <FormControl type="text" minLength={3} maxLength={160} placeholder={label} {...input} />
  );

  renderCheckboxField = ({ label }) => (
    <FormGroup>
      <Checkbox defaultChecked={this.props.includeOld} onChange={this.changeIncludeOldField}>
        <span>{label}</span>
      </Checkbox>
    </FormGroup>
  );

  renderTypeField = ({ input, onFieldChange }) => {
    const { term, sectionCounts } = this.props;

    return (
      <Nav
        bsStyle="tabs"
        activeKey={input.value}
        onSelect={e => {
          const ret = input.onChange(e);
          onFieldChange();

          return ret;
        }}
      >
        <NavItem eventKey="dashboard" className={netAuctionService.isDashboardHidden() ? 'hidden' : 'is-visible'}>
          <strong>Kaikki</strong>
          <small>Omat kohteet</small>
        </NavItem>
        {listTypes.map(type => (
          <NavItem
            key={type.value}
            className="is-visible"
            eventKey={type.value}
            disabled={
              term !== null && term.length > 0 && sectionCounts !== undefined && sectionCounts.get(type.value) === 0
            }
          >
            <strong>{type.label}</strong>
            <small>
              {sectionCounts !== undefined && sectionCounts.get(type.value) > 0
                ? `${accounting.formatNumber(sectionCounts.get(type.value), 0, ' ', ',')} kpl`
                : '-'}
            </small>
          </NavItem>
        ))}
        <NavItem className="is-visible" eventKey="archive">
          <strong>Arkisto</strong>
          <small>
            {sectionCounts !== undefined && sectionCounts.get('archive') > 0
              ? `${accounting.formatNumber(sectionCounts.get('archive'), 0, ' ', ',')} kpl`
              : 'x kpl'}
          </small>
        </NavItem>
      </Nav>
    );
  };

  renderCompanyField = ({ input, onFieldChange }) => {
    const { companies } = this.props;

    return (
      <CompanySelect
        placeholder="Yrityksen nimi"
        companies={companies.toJS()}
        {...input}
        onChange={e => {
          const ret = input.onChange(e);
          onFieldChange();

          return ret;
        }}
      />
    );
  };

  render() {
    const { isAdmin, handleSubmit, company, companies, term, includeOld, change } = this.props;
    const selectedCompany = companies.find(currentCompany => currentCompany.id === company);

    return (
      <div className="net-auction-list-filter-container">
        {this.state.isAdvancedSearchModalOpen && (
          <AdvancedSearchModal
            initialValues={{ ...netAuctionService.parseAdminListTerm(term), includeOld }}
            company={selectedCompany}
            onClose={() => this.setState({ isAdvancedSearchModalOpen: false })}
            onSubmit={fields => {
              change('includeOld', fields.includeOld ? true : null);

              const fieldsClone = { ...fields };
              delete fieldsClone.includeOld;

              change('term', netAuctionService.createAdminListTerm(fieldsClone));
              this.setState({ isAdvancedSearchModalOpen: false });
              setTimeout(handleSubmit, 0);
            }}
          />
        )}

        <Row className="search-row">
          <Col sm={6} md={7}>
            {isAdmin ? (
              <FormGroup controlId="company" className="clearable-input">
                <Field
                  name="company"
                  onFieldChange={() => setTimeout(handleSubmit, 0)}
                  component={this.renderCompanyField}
                />
                {company !== null && (
                  <button type="button" className="clearable-input__clear-button" onClick={this.clearCompanyField}>
                    <span className="fa fa-times" />
                  </button>
                )}
              </FormGroup>
            ) : (
              <h1>
                Omat huutokaupat
                {term !== null && term.length > 0 ? ` haulla "${term}"` : ''}
              </h1>
            )}
            <small>
              {term !== null && term.length > 0
                ? 'Oletuksena näytetään vain aktiiviset ja viimeisen kuuden kuukauden aikana päättyneet kohteet.'
                : 'Oletuksena näytetään vain aktiiviset ja viimeisen kuuden kuukauden aikana päättyneet kohteet. Valitse Arkisto nähdäksesi\n' +
                  ' arkistossa olevat kohteet ja niiden lukumäärän.'}
            </small>
          </Col>
          <Col sm={6} md={5}>
            <Form onSubmit={handleSubmit}>
              <FormGroup controlId="term" style={{ marginBottom: 5 }}>
                <InputGroup className="clearable-input">
                  <Field name="term" label="Hae kohteen nimellä tai kohdenumerolla" component={this.renderTermField} />
                  {term !== null && term.length > 0 && (
                    <button
                      type="button"
                      className="clearable-input__clear-button more-right-space"
                      onClick={this.clearTermField}
                    >
                      <span className="fa fa-times" />
                    </button>
                  )}
                  <span className="input-group-btn">
                    <Button type="submit" bsStyle="success">
                      Hae
                    </Button>
                  </span>
                </InputGroup>
              </FormGroup>

              <FormGroup className="pull-left">
                <Field name="includeOld" label="Hae myös vanhoista kohteista" component={this.renderCheckboxField} />
              </FormGroup>

              <a
                className="advanced-search-link pull-right"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ isAdvancedSearchModalOpen: true });
                }}
              >
                Tarkennettu haku
              </a>
            </Form>
          </Col>
        </Row>

        {selectedCompany && <CompanyNavBar company={selectedCompany} activePage="net-auctions" />}

        <div
          className={cx({
            hidden: isAdmin && company === null && (term === null || term.length === 0),
          })}
        >
          <Field name="type" onFieldChange={() => setTimeout(handleSubmit, 0)} component={this.renderTypeField} />
        </div>
      </div>
    );
  }
}

NetAuctionListFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  company: PropTypes.number,
  term: PropTypes.string,
  type: PropTypes.string,
  includeOld: PropTypes.bool,
  change: PropTypes.func,
  companies: ImmutablePropTypes.list.isRequired,
  sectionCounts: ImmutablePropTypes.map,
};

export default reduxForm({
  form: 'netAuctionListFilter',
  initialValues: {
    type: 'ongoing',
  },
})(NetAuctionListFilter);
